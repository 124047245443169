import React from 'react';
import {Chip} from "@material-ui/core";
import classNamesBind from "classnames/bind";
import styles from "./BadgeMngAgentWithSmsFeeTemp1.module.scss";

const cx = classNamesBind.bind(styles);

export const BadgeMngAgentWithSmsFeeTemp1 = (props: {
    isActivate: boolean
}) => {
    return (
        <>
            {props.isActivate && <Chip size="small" label="활성화 중" classes={{root: cx("chip1")}}/>}
            {!props.isActivate && <Chip size="small" label="비활성화 중" classes={{root: cx("chip3")}}/>}
        </>
    )
}
