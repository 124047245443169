import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './NotFound.module.scss';
import {navigate} from "hookrouter";

const cx = classNamesBind.bind(styles);

export const NotFound = () => {
    return (
        <div className={cx('cont')}>
            <div className={cx('box')}>
                <h4 style={{marginBottom: 30}}>페이지를 찾을수 없습니다.</h4>
                <button
                    className='btn btn-sm btn-outline-success'
                    onClick={event => {
                        navigate('/');
                    }}>홈으로</button>
            </div>
        </div>
    )
}
