import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ResultTelbookTemp1.module.scss'
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {OnVoidFunction} from "../../../interfaces";
import {ResultTelbookResp} from "../ResultTelBook";
import {Button, TextField} from "@material-ui/core";
import {ResultTelbookTemp1Chart} from "./chart/ResultTelbookTemp1Chart";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {TelNumber} from "../../../../graphql/types";
import {BadgeResultTelbookTemp1} from "../../../shares/temp1/badgeResultTelbook/BadgeResultTelbookTemp1";

const cx = classNamesBind.bind(styles);

export const ResultTelbookTemp1 = (props: {
    data: ResultTelbookResp,
    onDownload: OnVoidFunction,
}) => {
    const [telBook, setTelBook] = useState<TelNumber[]>([]);
    const [telNumber, setTelNumber] = useState("");

    useEffect(() => {
        setTelBook(props.data.telbook.numberList);
    }, [props.data.telbook.numberList]);

    const handler = {
        onFindNumber: () => {
            const nextTelBook = props.data.telbook.numberList.filter(value => {
                return value.number.includes(telNumber)
            })
            setTelBook(nextTelBook);
        }
    }

    return (
        <>
            <div className={cx("fullContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-left")}>
                                    전송 결과 상세내역
                                </div>
                                <div className={cx("titleBar-right")}>
                                    <Button
                                        classes={{root: cx("buttonOne")}}
                                        variant="outlined"
                                        style={{fontSize: 12}}
                                        fullWidth
                                        onClick={props.onDownload}>다운로드
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={cx("secondSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-title")}>
                                <div className={cx("title-left")} style={{width: "100%"}}>
                                    <FontAwesomeIcon icon={faChevronRight}
                                                     style={{paddingTop: 3, marginRight: 5}}/> 전체
                                    결과 요약
                                </div>
                            </div>
                            <ResultTelbookTemp1Chart data={props.data}/>
                        </div>
                    </div>
                </div>

                <div className={cx("thirdSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-title")}>
                                <div className={cx("title-left")} style={{width: "100%"}}>
                                    <FontAwesomeIcon icon={faChevronRight}
                                                     style={{paddingTop: 3, marginRight: 5}}/> 전송
                                    정보
                                </div>
                            </div>
                            <div className={cx("side-contentsColumn")}>
                                <div className={cx("contentsColumn-leftHeader")}>
                                    요청키
                                </div>
                                <div className={cx("contentsColumn-rightValue")}>
                                    {props.data.telbook.requestId}
                                </div>
                            </div>
                            <div className={cx("side-contentsColumn")}>
                                <div className={cx("contentsColumn-leftHeader")}>
                                    요청일
                                </div>
                                <div className={cx("contentsColumn-rightValue")}>
                                    {moment(props.data.telbook.createdAt).format('YYYY년 MM월 DD일 hh시 mm분 a')}
                                </div>
                            </div>
                            <div className={cx("side-contentsColumn")}>
                                <div className={cx("contentsColumn-leftHeader")}>
                                    메세지
                                </div>
                                <div className={cx("contentsColumn-rightValue")}>
                                    {props.data.telbook.msg}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cx("fourthSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-title")}>
                                    <div className={cx("title-left")}>
                                        <FontAwesomeIcon icon={faChevronRight}
                                                         style={{paddingTop: 3, marginRight: 5}}/> 전송번호 목록
                                    </div>
                                    <form className={cx("title-form")} onSubmit={ev => {
                                        ev.preventDefault();
                                        handler.onFindNumber();
                                    }}>
                                        <div className={cx("title-right")}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                type='text'
                                                inputProps={{style: {fontSize: 12}}}
                                                style={{marginRight: 10}}
                                                placeholder='전화번호 검색'
                                                value={telNumber}
                                                onChange={ev => {
                                                    setTelNumber(ev.target.value)
                                                }}/>
                                            <Button
                                                classes={{root: cx("buttonOne")}}
                                                variant="outlined"
                                                style={{fontSize: 12, height: 35}}
                                                type='submit'>찾기
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={cx("side-contentsColumn", "borderBottom")}>
                                    <div className={cx("contentsColumn-topHeader", "statusCell")}>
                                        상태
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "numberCell")}>
                                        전화번호
                                    </div>
                                </div>
                                {telBook.map((value, index) => (
                                    <div className={cx("side-contentsColumn", "hover")} key={index}>
                                        <div className={cx("contentsColumn-bottomValue", "statusCell")}>
                                            <BadgeResultTelbookTemp1 status={value.status}/>
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "numberCell")}>
                                            {value.number}
                                        </div>
                                    </div>
                                ))}

                                {telBook.length === 0 &&
                                <div className={cx("side-contentsColumn", "emptyColumn")}>
                                    검색 결과가 없습니다.
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
