import React from "react";
import {Chip} from "@material-ui/core";
import classNamesBind from "classnames/bind";
import styles from "./BadgeResultTelbookTemp1.module.scss";

const cx = classNamesBind.bind(styles);

export const BadgeResultTelbookTemp1 = (props: {
    status: string
}) => {
    return (
        <>
            {props.status === 'Sent' && <Chip size="small" label="발송완료" classes={{root: cx("chip1")}}/>}
            {props.status === 'Fail' && <Chip size="small" label="전송실패" classes={{root: cx("chip1")}}/>}
            {props.status === 'DlrDelivered' && <Chip size="small" label="수신완료" classes={{root: cx("chip1")}}/>}
            {props.status === 'DlrUndelivered' && <Chip size="small" label="수신실패" classes={{root: cx("chip1")}}/>}
        </>
    )
}
