import React, {useEffect, useState} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {DepositRequestHistoryList, RequestDepositRecharge} from "../../../graphql/types";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {BizRefreshSession} from "../../shares/default/bizRefreshSession/BizRefreshSession";
import {navigate} from "hookrouter";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {DepositRequestDefault} from "./default/DepositRequstDefault";
import {ViewTemplate} from "../../../environment/interfaces";
import {BizBackdrop} from "../../shares/default/bizBackdrop/BizBackdrop";
import {DepositRequestTemp1} from "./temp1/DepositRequestTemp1";
import {DepositRequestTemp2} from "./temp2/DepositRequestTemp2";
import { DepositRequestZsms } from "./zsms/DepositRequestZsms";

export const DepositRequest = (props: {
    page: number
}) => {
    const {data, loading, refetch} = useQuery<{
        depositRequestHistory: DepositRequestHistoryList
    }, {
        page: number
    }>(gqlData, {
        variables: {
            page: props.page,
        }
    });

    const [inputData, setInputData] = useState<DepositRequestInputData>({
        nm: "",
        point: 10000,
        tel: ""
    });

    const [mutRequestDepositRecharge, mutRequestDepositRechargeData] = useMutation<{
        requestDepositRecharge: boolean
    }, {
        input: RequestDepositRecharge
    }>(gqlRequestDepositRecharge);

    const [mutCancelDepositRecharge, mutCancelDepositRechargeData] = useMutation<{
        cancelDepositRecharge: boolean
    }, {
        id: number
    }>(gqlCancelDepositRecharge);

    useEffect(() => {
        refetch({page: props.page})
            .then(value => {
            })
            .catch(ApolloCatch({}));
    }, [props.page])


    const onChangePage = (page: number) => {
        navigate(`/deposit/request/${page}`);
    }

    const onDepositRequest = (value: DepositRequestInputData) => {
        if (value.point < 10000) {
            SwalUtil.ok({
                msg: "최소 10,000 원 이상부터 충전 가능합니다.",
                icon: "warning"
            });
            return;
        }

        if (!value.nm) {
            SwalUtil.ok({
                msg: "입금자명을 입력하여 주십시오.",
                icon: "warning"
            });
            return;
        }

        let sendTel = undefined;
        const regEx = /^(010)([0-9]{8})$/g;
        if (regEx.exec(value.tel)) {
            sendTel = "82" + value.tel.substr(1, value.tel.length);
        }

        mutRequestDepositRecharge({
            variables: {
                input: {
                    nm: value.nm, point: value.point, tel: sendTel
                }
            }
        }).then(res => {
            SwalUtil.ok({
                msg: value.tel ? "입금확인요청 되었습니다. 입금정보는 입력하신 연락처로 전송되었습니다." : "입금확인 요청 되었습니다.",
                icon: "success"
            });

            if (data) {
                refetch({page: data.depositRequestHistory.page})
                    .catch(ApolloCatch({}));
            }
        }).catch(ApolloCatch({}));
    }

    const onCancelDepositRecharge = (id: number) => {
        SwalUtil.yn({
            msg: '취소 하시겠습니까?',
            icon: "question",
            ok: () => {
                mutCancelDepositRecharge({variables: {id}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: '취소 되었습니다.',
                            icon: "success",
                        })

                        if (data) {
                            refetch({page: data.depositRequestHistory.page})
                                .catch(ApolloCatch({}));
                        }
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.bizError()
                    }))
            }
        })
    }

    return (
        <BizRefreshSession>
            <BizBackdrop
                loading={loading || mutRequestDepositRechargeData.loading || mutCancelDepositRechargeData.loading}/>

            {data &&
            <ViewTemplateSelector
                view={{
                    [ViewTemplate.default]: <DepositRequestDefault
                        data={data}
                        value={inputData}
                        setValue={setInputData}
                        onDepositRequest={onDepositRequest}
                        onChangePage={onChangePage}
                        onCancelDepositRecharge={onCancelDepositRecharge}/>,
                    [ViewTemplate.temp1]: <DepositRequestTemp1
                        data={data}
                        value={inputData}
                        setValue={setInputData}
                        onDepositRequest={onDepositRequest}
                        onChangePage={onChangePage}
                        onCancelDepositRecharge={onCancelDepositRecharge}/>,
                    [ViewTemplate.temp2]: <DepositRequestTemp2
                        data={data}
                        value={inputData}
                        setValue={setInputData}
                        onDepositRequest={onDepositRequest}
                        onChangePage={onChangePage}
                        onCancelDepositRecharge={onCancelDepositRecharge}/>,
                    [ViewTemplate.zsms]: <DepositRequestZsms
                        data={data}
                        value={inputData}
                        setValue={setInputData}
                        onDepositRequest={onDepositRequest}
                        onChangePage={onChangePage}
                        onCancelDepositRecharge={onCancelDepositRecharge}/>,
                }}
            />
            }
        </BizRefreshSession>
    )
}

export interface DepositRequestInputData {
    nm: string;
    point: number;
    tel: string;
}

export interface DepositRequestResData {
    depositRequestHistory: DepositRequestHistoryList;
}

const gqlRequestDepositRecharge = gql`
    mutation RequestDepositRecharge($input: RequestDepositRecharge!) {
        requestDepositRecharge(input: $input)
    }
`;

const gqlData = gql`
    query DepositRequestHistory($page: Int!) {
        depositRequestHistory(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                nm
                smsFee
                amount
                point
                requestedAt
                status
            }
        }
    }
`

const gqlCancelDepositRecharge = gql`
    mutation CancelDepositRecharge($id: Int!) {
        cancelDepositRecharge(id: $id)
    }
`
