import React, {Fragment, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './RechargeCommissionTemp1SettleModal.module.scss';
import {OnChangeValue} from "../../../../interfaces";
import {MutationRequestRechargeCommissionSettleArgs, SettleCommissionType} from "../../../../../graphql/types";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";

const cx = classNamesBind.bind(styles);

export const RechargeCommissionTemp1SettleModal = (props: {
    onRequestSettle: OnChangeValue<MutationRequestRechargeCommissionSettleArgs>
}) => {
    const [value, setValue] = useState<MutationRequestRechargeCommissionSettleArgs>({
        type: SettleCommissionType.ChargePoint,
        bankNumber: ''
    })

    const handler = {
        onRequest: () => {
            if ((value.type === SettleCommissionType.Withdraw) && (value.bankNumber === "")) {
                SwalUtil.ok({
                    msg: '입금 계좌번호를 확인하여 주십시오.',
                    icon: 'error'
                });
                return;
            }

            SwalUtil.yn({
                msg: '<div>정산 요청을 하시겠습니까?<br/><b style="color: crimson;">취소 할 수 없습니다.</b></div>',
                icon: 'question',
                ok: () => {
                    props.onRequestSettle(value);
                }
            })
        }
    }

    return (
        <Fragment>
            <div className={cx('table')}>
                <div className='text-left' style={{width: '20%'}}>
                    <FormControlLabel
                        classes={{
                            root: 'm-0'
                        }}
                        control={
                            <Checkbox
                                color='primary'
                                checked={value.type === SettleCommissionType.ChargePoint}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(value);
                                    copy.type = ev.target.checked ? SettleCommissionType.ChargePoint : SettleCommissionType.Withdraw;
                                    copy.bankNumber = ev.target.checked ? "" : copy.bankNumber;
                                    setValue(copy);
                                }}/>
                        }
                        label={'포인트 정산'}/>
                </div>
                <div style={{width: '80%'}}/>
            </div>
            <div className={cx('table')}>
                <div className='text-left' style={{width: '20%'}}>
                    <FormControlLabel
                        classes={{
                            root: 'm-0'
                        }}
                        control={
                            <Checkbox
                                color='primary'
                                checked={value.type === SettleCommissionType.Withdraw}
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(value);
                                    copy.type = ev.target.checked ? SettleCommissionType.Withdraw : SettleCommissionType.ChargePoint;
                                    copy.bankNumber = ev.target.checked ? copy.bankNumber : "";
                                    setValue(copy);
                                }}/>
                        }
                        label={'입금 정산'}/>
                </div>
                <div style={{width: '80%'}}>
                    <input
                        disabled={value.type === SettleCommissionType.ChargePoint}
                        className='form-control form-control-sm'
                        value={value.bankNumber}
                        placeholder='입금 계좌번호를 입력하여 주십시오.'
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(value);
                            copy.bankNumber = ev.target.value;
                            setValue(copy);
                        }}/>
                </div>
            </div>

            <div className={cx('table')}>
                <div className='text-left' style={{width: '80%', color: 'crimson'}}>* 정산요청은 취소 할 수 없습니다!</div>
                <div className='text-right' style={{width: '20%'}}>
                    <button
                        className='btn btn-sm btn-success'
                        onClick={handler.onRequest}>정산요청
                    </button>
                </div>
            </div>

        </Fragment>
    )
}
