import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {Mutation, MutationCreateUserChatArgs, Query} from "../../../graphql/types";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {CsListDefault} from "./default/CsListDefault";
import {navigate} from "hookrouter";
import {FadeIn} from "../../shares/default/fadeIn/FadeIn";
import {CsListTemp1} from "./temp1/CsListTemp1";
import {CsListTemp2} from "./temp2/CsListTemp2";

export const CsList = (props: {
    page: number,
}) => {
    const {data, refetch} = useQuery<Query, {
        userAlertPage: number
    }>(gqlData, {
        variables: {
            userAlertPage: props.page
        },
        context: {
            loadingView: false,
        },
        pollInterval: 5000,
    });

    const [mutCreateUserChat] = useMutation<Mutation, MutationCreateUserChatArgs>(gqlCreateUserChat, {
        context: {
            loadingView: false,
        }
    });

    const handler = {
        onChangeUserAlertPage: (page: number) => {
            navigate(`/cs/${page}`);
        },
        onCreateUserChat: (value: MutationCreateUserChatArgs) => {
            mutCreateUserChat({variables: value})
                .then(() => {
                    return refetch({userAlertPage: props.page});
                })
                .catch(ApolloCatch({}));
        },
    };

    return (
        <>
            {data &&
            <FadeIn>
                <ViewTemplateSelector view={{
                    [ViewTemplate.default]:
                        <CsListDefault
                            value={data}
                            {...handler}/>,
                    [ViewTemplate.temp1]:
                        <CsListTemp1
                            value={data}
                            {...handler}/>,
                    [ViewTemplate.temp2]:
                        <CsListTemp2
                            value={data}
                            {...handler}/>,
                    [ViewTemplate.zsms]:
                        <CsListTemp1
                            value={data}
                            {...handler}/>,
                }}/>
            </FadeIn>
            }
        </>
    )
}

const gqlData = gql`
    query DATA($userAlertPage: Int!) {
        userAlertList(page: $userAlertPage, size: 5) {
            page
            size
            total
            list {
                id
                content
                createdAt
            }
        }
        chatList(page: 0, size: 50) {
            page
            size
            total
            list {
                id
                writer
                content
                createdAt
            }
        }
    }
`;

const gqlCreateUserChat = gql`
    mutation CreateUserChat($input: CreateUserChat!) {
        createUserChat(input: $input)
    }
`;
