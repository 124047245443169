import React, {Fragment, useEffect, useState} from 'react';
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationRequestRechargeCommissionSettleArgs,
    Query,
    RechargeCommissionList, RechargeCommissionSettleRequestList,
    TotalRechargeCommission
} from "../../../graphql/types";
import {navigate} from "hookrouter";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {MngRechargeCommissionDefault} from "./default/MngRechargeCommissionDefault";
import {MngRechargeCommissionTemp1} from './temp1/MngRechargeCommissionTemp1';
import {MngRechargeCommissionTemp2} from './temp2/MngRechargeCommissionTemp2';
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {OnChangeValue} from "../../interfaces";


export const MngRechargeCommission = (props: {
    page: number,
    requestPage: number,
}) => {
    const {data, refetch} = useQuery<Query, any>(gqlData, {
        variables: props
    });

    const [mutRequest] = useMutation<Mutation, MutationRequestRechargeCommissionSettleArgs>(gqlRequestSettle);

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/mng/recharge/${page}/${props.requestPage}`);
        },
        onChangeRequestPage: (page: number) => {
            navigate(`/mng/recharge/${props.page}/${page}`);
        },
        onRequestSettle: (args: MutationRequestRechargeCommissionSettleArgs) => {
            mutRequest({
                variables: args
            }).then(() => {
                return refetch(props)
            }).catch(ApolloCatch({
                [BizErrors.notFoundCommission]: SwalUtil.bizErrorMsg({
                    msg: '정산요청 할 커미션 정보가 없습니다.',
                    icon: 'warning'
                })
            }));
        },
    }


    return (
        <Fragment>
            <ViewTemplateSelector view={{
                [ViewTemplate.default]:
                    <MngRechargeCommissionDefault
                        data={data}
                        {...handler}/>,
                [ViewTemplate.temp1]:
                    <MngRechargeCommissionTemp1
                        data={data}
                        {...handler}/>,
                [ViewTemplate.temp2]:
                    <MngRechargeCommissionTemp2
                        data={data}
                        {...handler}/>,
                [ViewTemplate.zsms]:
                    <MngRechargeCommissionTemp1
                        data={data}
                        {...handler}/>,
            }}/>

        </Fragment>
    )
}


export interface MngRechargeCommissionResp {
    totalRechargeCommission: TotalRechargeCommission,
    rechargeCommissionList: RechargeCommissionList,
    rechargeCommissionSettleRequestList: RechargeCommissionSettleRequestList,
}

const gqlRequestSettle = gql`
    mutation RequestSettle($type: SettleCommissionType!, $bankNumber: String!) {
        requestRechargeCommissionSettle(type: $type, bankNumber: $bankNumber)
    }
`;

const gqlData = gql`
    query RechargeCommission($page: Int!, $requestPage: Int!) {
        totalRechargeCommission {
            total
            notPaid
            paid
        }
        rechargeCommissionSettleRequestList(page: $requestPage, size: 10) {
            page
            size
            total
            list {
                id
                status
                commission
                commissionCount
                commissionType
                createdAt
            }
        }
        rechargeCommissionList(page: $page, size: 10) {
            page
            size
            total
            list {
                id
                isRequestedSettle
                agentEmail
                agentRechargeAmount
                agentRechargePoint
                rate
                commission
                isWithdrawal
                createdAt
                withdrawalAt
            }
        }
    }

`
