import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngAgentWithSmsFeeDefaultUpdateAgentModal.module.scss'
import {Agent, MutationUpdateAgentApiPermissionArgs, UpdateAgentApiPermission} from "../../../../../graphql/types";
import {BadgeIsActivate} from "../../../../shares/default/badgeIsActivate/BadgeIsActivate";
import {Pipe} from "../../../../../utils/pipe/Pipe";
import {BizSmTitle} from "../../../../shares/default/bizSmTitle/BizSmTitle";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import {Switch} from "@material-ui/core";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import NumberFormat from "react-number-format";
import Decimal from "decimal.js";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {OnChangeValue} from "../../../../interfaces";

const cx = classNamesBind.bind(styles);

export const MngAgentWithSmsFeeDefaultUpdateAgentModal = (props: {
    agent: Agent,
    onUpdateAgentApiPermission: OnChangeValue<MutationUpdateAgentApiPermissionArgs>
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const [input, setInput] = useState<Input[]>([]);

    useEffect(() => {
        const nextInputList: Input[] = [];
        for (let userApiPermission of user.user.apiPermission) {
            const appendInput: Input = {
                isActivate: false,
                smsFee: 0,
                nm: userApiPermission.nm,
                hashedApiKey: userApiPermission.hashedApiKey,
                defaultSmsFee: userApiPermission.smsFee,
            }

            const agentApiPermission = props.agent.apiPermission.find(value => {
                return value.hashedApiKey === userApiPermission.hashedApiKey
            });

            if (agentApiPermission) {
                appendInput.isActivate = true;
                appendInput.smsFee = agentApiPermission.smsFee
            }

            nextInputList.push(appendInput);
        }

        setInput(nextInputList);
    }, [user.user.apiPermission]);

    const handler = {
        onUpdateAgentApiPermission: () => {
            if (!handler.onValidation()) {
                return;
            }

            props.onUpdateAgentApiPermission({
                userId: props.agent.id,
                updateAgentPermissionList: handler.onCreateArgs(),
            });
        },
        onCreateArgs: (): UpdateAgentApiPermission[] => {
            const list: UpdateAgentApiPermission[] = [];

            for (let elem of input) {
                if (!elem.isActivate) {
                    continue;
                }

                if (new Decimal(elem.smsFee).lt(elem.defaultSmsFee)) {
                    continue;
                }

                list.push({
                    hashedApiKey: elem.hashedApiKey,
                    smsFee: elem.smsFee
                })
            }

            return list;
        },
        onValidation: (): boolean => {
            let hasActivation = false;

            for (let elem of input) {
                if (elem.isActivate) {
                    hasActivation = true;
                }

                if (new Decimal(elem.smsFee).lt(elem.defaultSmsFee)) {
                    SwalUtil.ok({
                        msg: '대리점 회원의 건당가격이 리셀러 회원보다 낮을수 없습니다.',
                        icon: 'error'
                    });
                    return false;
                }
            }

            if (!hasActivation) {
                SwalUtil.ok({
                    msg: '최소 1개 이상의 회선을 활성화 하여 주십시오.',
                    icon: 'error',
                })
            }

            return hasActivation;
        },
    }


    return (
        <Fragment>
            <BizSmTitle marginLeft={20}>회원 정보</BizSmTitle>
            <div className={cx('table')}>
                <div style={{width: 200}}>회원번호</div>
                <div style={{width: 'calc(100% - 200px)'}}>{props.agent.id}</div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 200}}>활성화</div>
                <div style={{width: 'calc(100% - 200px)'}}>
                    <BadgeIsActivate isActivate={props.agent.isActivate}/>
                </div>
            </div>
            <div className={cx('table')}>
                <div style={{width: 200}}>잔고</div>
                <div style={{width: 'calc(100% - 200px)'}}>
                    {Pipe.toSeparatorNumber(props.agent.point)} P
                </div>
            </div>

            <div style={{height: 50}}/>
            <BizSmTitle marginLeft={20}>회선</BizSmTitle>
            <div className={cx('table')}>
                <div style={{width: '25%'}}>활성화</div>
                <div style={{width: '25%'}}>회선이름</div>
                <div style={{width: '25%'}}>건당가격</div>
                <div style={{width: '25%'}}>대리점 가격</div>
            </div>

            {input.map((value, index) => (
                <div className={cx('table', 'hover')} key={index}>
                    <div style={{width: '25%'}}>
                        <Switch
                            checked={value.isActivate}
                            color='primary'
                            onChange={ev => {
                                const copy = CopyUtil.copyAll(input);
                                copy[index].isActivate = ev.target.checked;
                                setInput(copy);
                            }}/>
                    </div>
                    <div style={{width: '25%'}}>{value.nm}</div>
                    <div style={{width: '25%'}}>{value.defaultSmsFee} 원</div>
                    <div style={{width: '25%'}}>
                        <NumberFormat
                            thousandSeparator
                            decimalScale={1}
                            allowNegative={false}
                            suffix={' 원'}
                            className='form-control form-control-sm text-center'
                            value={value.smsFee}
                            onValueChange={values => {
                                const copy = CopyUtil.copyAll(input);
                                copy[index].smsFee = values.floatValue || 0;
                                setInput(copy);
                            }}/>
                    </div>
                </div>
            ))}

            <div className={cx('table')}>
                <div style={{width: '100%'}} className='text-right'>
                    <button
                        className='btn btn-success'
                        onClick={handler.onUpdateAgentApiPermission}>적용
                    </button>
                </div>
            </div>


        </Fragment>
    )
}

type Input = (UpdateAgentApiPermission & { isActivate: boolean, defaultSmsFee: number, nm: string })
