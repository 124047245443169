import React, {ReactNode, useEffect, useState} from 'react';
import {DataUtil} from "../../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";

export const BizRefreshSession = (props: {
    children: ReactNode
}) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        DataUtil
            .refreshSession()
            .then(user => {
                setLoading(false);
            })
            .catch(ApolloCatch({
                [BizErrors.default]: () => {

                }
            }))
    }, []);


    return <>
        {!loading && props.children}
    </>
}
