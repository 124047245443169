import React, {ReactNode} from 'react';

export const BizContainer = (props: {
    children: ReactNode
}) => {
    return (
        <div className='container'>
            <div className='row'>
                {props.children}
            </div>
        </div>
    )
}


export const BizCol12 = (props: {
    children: ReactNode
}) => {
    return (
        <div className='col-12' style={{marginBottom: 30}}>
            {props.children}
        </div>
    )
}
