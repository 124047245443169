export class BizErrors {
    static default = 'default';
    static needLogin = "needLogin";
    static notFoundJoinCode = 'notFoundJoinCode';
    static loginFail = 'loginFail';
    static notEnoughBalance = 'notEnoughBalance';
    static cannotSetSmsFee = "cannotSetSmsFee";
    static notFoundApiPermission = "notFoundApiPermission"
    static serverInChecking = "serverInChecking"
    static notFoundCommission = 'notFoundCommission';
}
