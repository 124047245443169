import React from "react";
import {gql, useMutation, useQuery,} from "@apollo/client";
import {MsgReceiptList, Mutation, MutationHideMsgReceiptArgs, PeriodTimeDay, Query} from "../../../graphql/types";
import {navigate} from "hookrouter";
import {ViewTemplate} from "../../../environment/interfaces";
import {ResultMsgReceiptDefault} from "./default/ResultMsgReceiptDefault";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ResultMsgReceiptTemp1} from "./temp1/ResultMsgReceiptTemp1";
import {ResultMsgReceiptTemp2} from "./temp2/ResultMsgReceiptTemp2";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";

export const ResultMsgReceipt = (props: {
    page: number,
    periodTimeDay?: PeriodTimeDay
}) => {
    const {data, refetch} = useQuery<Query, {
        page: number,
        periodTimeDay?: PeriodTimeDay
    }>(gqlMsgReceipt, {
        variables: props
    });

    const [mutHideMsgReceipt] = useMutation<Mutation, MutationHideMsgReceiptArgs>(gqlHideMsgReceipt);
    const [mutHideAllMsgReceipt] = useMutation<Mutation>(gqlHideAllMsgReceipt);

    const handler = {
        onChangePage: (page: number) => {
            if (props.periodTimeDay) {
                navigate(`/result/receipt/${page}/${props.periodTimeDay.stDate}/${props.periodTimeDay.edDate}`);
            } else {
                navigate(`/result/receipt/${page}`);
            }
        },
        onChangePeriod: (period: PeriodTimeDay) => {
            navigate(`/result/receipt/${0}/${period.stDate}/${period.edDate}`);
        },
        onHideMsgReceipt: (args: MutationHideMsgReceiptArgs) => {
            SwalUtil.yn({
                msg: '삭제 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutHideMsgReceipt({
                        variables: args
                    }).then(() => {
                        SwalUtil.ok({
                            msg: '삭제되었습니다.',
                            icon: 'success'
                        });
                        return refetch(props)
                    }).catch(ApolloCatch({}));
                }
            })
        },
        onHideAllMsgReceipt: () => {
            SwalUtil.yn({
                msg: '모두 삭제하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutHideAllMsgReceipt()
                        .then(() => {
                            SwalUtil.ok({
                                msg: '삭제되었습니다.',
                                icon: 'success'
                            });
                            return refetch(props)
                        }).catch(ApolloCatch({}));
                }
            })

        },
    }

    return (
        <>
            {data &&
            <ViewTemplateSelector view={{
                [ViewTemplate.default]:
                    <ResultMsgReceiptDefault
                        data={data}
                        periodTimeDay={props.periodTimeDay}
                        {...handler}/>,
                [ViewTemplate.temp1]:
                    <ResultMsgReceiptTemp1
                        data={data}
                        periodTimeDay={props.periodTimeDay}
                        {...handler}/>,
                [ViewTemplate.temp2]:
                    <ResultMsgReceiptTemp2
                        data={data}
                        {...handler}/>,
                [ViewTemplate.zsms]:
                    <ResultMsgReceiptTemp1
                        data={data}
                        {...handler}/>,
            }}/>}
        </>

    )
}

export interface ResultMsgReceiptResp {
    msgReceipt: MsgReceiptList
}

const gqlMsgReceipt = gql`
    query MsgReceipt($page: Int!, $periodTimeDay: PeriodTimeDay) {
        msgReceipt(page: $page, size: 20, periodTimeDay: $periodTimeDay) {
            page
            size
            total
            list {
                id
                apiKeyNm
                contents
                createdAt
                requestId
                sent
                smsFee
            }
        }
    }
`;

const gqlHideMsgReceipt = gql`
    mutation HideMsgReceipt($msgReceiptIdList: [Int!]!) {
        hideMsgReceipt(msgReceiptIdList: $msgReceiptIdList)
    }
`;

const gqlHideAllMsgReceipt = gql`
    mutation HideAllMsgReceipt {
        hideAllMsgReceipt
    }
`;

