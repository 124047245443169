import React, {ReactNode, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './LayoutWeb.module.scss'
import {Button} from "@material-ui/core";
import {Dashboard} from "@material-ui/icons";
import {navigate} from "hookrouter";
import {FooterWeb} from "./footer/FooterWeb";
import {useDispatch, useSelector} from "react-redux";
import {SystemAction, SystemState} from "../../../../redux/System";
import {LayoutState} from "../../../../redux/Layout";
import {UserState} from "../../../../redux/User";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {A} from 'hookrouter';
import {Spring} from "react-spring/renderprops-universal";
import {NavBar} from "../../Layout";

const cx = classNamesBind.bind(styles);

export const LayoutWeb = (props: {
    navBar: NavBar[],
    children: ReactNode
}) => {
    const system = useSelector<any, SystemState>(state => state.system);
    const user = useSelector<any, UserState>(state => state.user);
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const dispatch = useDispatch();

    const [aniState, setAniState] = useState(
        ((system.scrollY < 200) && (layout.appBarTransparent)) ?
            AniProps.navbarHide :
            AniProps.navbarShow)

    useEffect(() => {
        if ((system.scrollY < 200) && (layout.appBarTransparent)) {
            setAniState(AniProps.navbarHide)
        } else {
            setAniState(AniProps.navbarShow)
        }
    }, [system.scrollY, layout.appBarTransparent])


    const onLogout = () => {
        SwalUtil.yn({
            msg: "로그아웃 하시겠습니까?",
            icon: "question",
            ok: () => {
                DataUtil.logout()
                    .then(res => {
                        navigate('/');
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: () => {
                            navigate('/');
                        }
                    }));
            }
        })
    }

    const onChangeTemplate = () => {
        SwalUtil.input(value => {
            dispatch(SystemAction.setInit({...system.init, viewTemplate: value}));
        }, '템플릿 이름을 입력하여 주십시오.', 'Template 이름');
    }

    return (
        <>
            <Spring
                from={aniState}
                to={aniState}>
                {params => (
                    <header
                        style={{background: params.background, boxShadow: params.boxShadow}}
                        className={cx("header")}>
                        <div className={cx("header__navBar")}>
                            <div className={cx("navBar__container")}>
                                <div
                                    style={{color: params.titleColor, cursor: 'default'}}
                                    className={cx("navBar__logo")}
                                    onClick={event => {
                                        navigate('/');
                                    }}>
                                    {system.init.appNm}
                                </div>
                                <div className={cx("navBar__leftMenu")}>
                                    {props.navBar.map((value, index) => {
                                        return (
                                            <A
                                                style={{color: params.color}}
                                                href={value.router}
                                                key={index}
                                                className={cx('btn')}>
                                                {value.title}
                                            </A>
                                        )
                                    })}

                                    {process.env.REACT_APP_ENV === 'dev' &&
                                    <Button
                                        className={cx("btn")}
                                        startIcon={<Dashboard/>}
                                        onClick={onChangeTemplate} size={'small'}>
                                        {system.init.viewTemplate}
                                    </Button>
                                    }

                                    {user.isLogin &&
                                    <div
                                        style={{color: params.color, cursor: "pointer"}}
                                        className={cx('btn')}
                                        onClick={() => onLogout()}>로그아웃</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </header>
                )}
            </Spring>

            <div className={cx('contents')}>
                {layout.topMargin && <div style={{height: 80}}/>}
                {props.children}
            </div>

            <FooterWeb appName={system.init.appNm}/>
        </>
    )
}

const AniProps = {
    navbarShow: {
        background: 'rgba(255,255,255,1)',
        color: 'rgba(0,0,0,1)',
        borderColor: 'rgba(255,255,255,0.2)',
        boxShadow: '5px 5px 5px rgba(0,0,0,0.08)',
        titleColor: 'rgb(0, 101, 239)',
    },
    navbarHide: {
        background: 'rgba(255,255,255,0)',
        color: 'rgba(255,255,255,1)',
        borderColor: 'rgba(0,0,0,0)',
        boxShadow: '5px 5px 5px rgba(0,0,0,0)',
        titleColor: 'rgb(255, 255, 255)',
    },
}



