import React, {useState} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {MutationUpdateAgentApiPermissionArgs, Query} from "../../../graphql/types";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {MngAgentDefault} from "./default/MngAgentDefault";
import {navigate} from "hookrouter";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {MngAgentTemp1} from "./temp1/MngAgentTemp1";
import {MngAgentTemp2} from "./temp2/MngAgentTemp2";
import {BizErrors} from "../../../graphql/errors";

export const MngAgent = (props: {
    page: number,
    email: string,
}) => {
    const {data, refetch, error} = useQuery<Query, {
        page: number,
        email: string,
    }>(gqlData, {
        variables: props
    });

    const [input, setInput] = useState<MngAgentInputData>({
        email: props.email,
    });

    const [mutUpdateAgentActivation] = useMutation<any, {
        userId: number,
        activation: boolean,
    }>(gqlUpdateAgentActivation);

    const [mutUpdateAgentApiPermission] =
        useMutation<any, MutationUpdateAgentApiPermissionArgs>(gqlUpdateAgentApiPermission);

    const handler = {
        onReload: () => {
            refetch(props).catch(ApolloCatch({}));
        },
        onChangePage: (value: { page: number, email: string }) => {
            navigate(`/mng/agent/${value.page}/${value.email}`)
        },
        onChangeAgentActivation: (value: { userId: number, activation: boolean }) => {
            SwalUtil.yn({
                msg: value.activation ? '차단 해제 하시겠습니까?' : '차단 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutUpdateAgentActivation({variables: value})
                        .then(() => {
                            SwalUtil.ok({
                                msg: value.activation ? '해제 되었습니다.' : '차단 되었습니다.',
                                icon: 'success',
                            });
                            return refetch(props);
                        })
                        .catch(ApolloCatch({}))
                }

            })
        },
        onUpdateAgentApiPermission: (args: MutationUpdateAgentApiPermissionArgs) => {
            SwalUtil.ok({
                msg: '변경하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutUpdateAgentApiPermission({variables: args})
                        .then(() => {
                            SwalUtil.ok({
                                msg: '수정되었습니다.',
                                icon: 'success',
                            })
                            return refetch(props);
                        })
                        .catch(ApolloCatch({
                            [BizErrors.cannotSetSmsFee]: SwalUtil.bizErrorMsg({
                                msg: '건당 가격을 확인하여 주십시오.',
                                icon: 'error'
                            }),
                            [BizErrors.notFoundApiPermission]: SwalUtil.bizErrorMsg({
                                msg: '회선 권한이 없습니다.',
                                icon: 'error'
                            })
                        }))
                }
            })
        }
    }


    return <ViewTemplateSelector view={{
        [ViewTemplate.default]:
            <MngAgentDefault
                data={data}
                value={input}
                onChangeValue={setInput}
                {...handler}/>,
        [ViewTemplate.temp1]:
            <MngAgentTemp1
                data={data}
                value={input}
                onChangeValue={setInput}
                {...handler}/>,
        [ViewTemplate.temp2]:
            <MngAgentTemp2
                data={data}
                value={input}
                onChangeValue={setInput}
                {...handler}/>,
        [ViewTemplate.zsms]:
            <MngAgentTemp1
                data={data}
                value={input}
                onChangeValue={setInput}
                {...handler}/>,
    }}/>
}

export interface MngAgentInputData {
    email: string;
}

const gqlUpdateAgentActivation = gql`
    mutation UpdateAgentPermission($userId: Int!, $activation: Boolean!) {
        updateAgentActivation(userId: $userId, activation: $activation)
    }
`;

const gqlUpdateAgentApiPermission = gql`
    mutation UpdateAgentApiPermissoin($userId: Int!, $updateAgentApiPermissoinList: [UpdateAgentApiPermision!]!) {
        updateAgentApiPermission(userId: $userId, updateAgentPermissionList: $updateAgentApiPermissoinList)
    }
`;

const gqlData = gql`
    query Data($email: String, $page: Int!){
        agentList(email: $email, page: $page, size: 20) {
            page
            size
            total
            list {
                id
                email
                point
                createdAt
                isActivate
            }
        }
    }
`;
