import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './FooterWeb.module.scss';

const cx = classNamesBind.bind(styles);

export const FooterWeb = (props: {
    appName: string
}) => {
    return (
        <div className={cx('cont')}>
            <div className={cx("textBox")}>
                © 2018 {props.appName}. ALL RIGHTS RESERVED.
            </div>
        </div>
    )
}
