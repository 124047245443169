import {Apollo} from "../apollo/apollo";
import {gql} from "@apollo/client";
import {ApolloCatch} from "../apollo/apolloCatch";
import {BizErrors} from "../../graphql/errors";
import {ReduxStore} from "../../index";
import {AppAuth, SystemAction} from "../../redux/System";
import {Init, User} from "../../graphql/types";
import {FB} from "../fb/FB";
import {UserAction} from "../../redux/User";
import {LocalStorage} from "../localstorage/LocalStorage";
import {EnvAction} from "../../redux/Env";
import {GlobalVars} from "../../globalVars/globalVars";

export class DataUtil {
    static init() {
        Apollo.client.query<{ init: Init }>({
            query: gql`
                query Init {
                    init {
                        fb
                        appNm
                        env
                        viewTemplate
                    }
                }
            `
        }).then(res => {
            ReduxStore.dispatch(SystemAction.setInit(res.data.init));
            ReduxStore.dispatch(EnvAction.setViewTemplate(res.data.init.viewTemplate))
            FB.initFirebase(JSON.parse(res.data.init.fb));
            GlobalVars.auth.env(JSON.parse(res.data.init.env));
            ReduxStore.dispatch(SystemAction.setAppAuth(AppAuth.Ok));
            if (res.data.init.appNm === "KingdomSMS") {
                document.title = "킹덤문자 해외문자 국제문자";
            } else {

                document.title = res.data.init.appNm;
            }

            return DataUtil.refreshSession()
        }).then(user => {
            ReduxStore.dispatch(UserAction.update(user));
            return user;
        }).catch(ApolloCatch({
            [BizErrors.default]: () => {
                ReduxStore.dispatch(SystemAction.setAppAuth(AppAuth.Ban));
            },
            [BizErrors.needLogin]: () => {
                LocalStorage.clearSession();
            },
            [BizErrors.serverInChecking]: () => {
                ReduxStore.dispatch(SystemAction.setAppAuth(AppAuth.ShutDown));
            }
        }))
    }

    static refreshSession(): Promise<User> {
        return Apollo.client.query<{ user: User }>({
            query: gql`
                query User {
                    user {
                        email
                        userType
                        point
                        tax
                        msgPermission
                        apiPermission {
                            hashedApiKey
                            smsFee
                            nm
                        }
                        commissionPermission
                        fbJoinType
                    }
                }
            `
        }).then(res => {
            const user = res.data!.user;
            ReduxStore.dispatch(UserAction.update(user));
            return user;
        })
    }

    static logout(): Promise<boolean> {
        return Apollo.client.mutate<{ logout: boolean }>({
            mutation: gql`
                mutation Logout {
                    logout
                }
            `
        }).then(res => {
            LocalStorage.clearSession();
            ReduxStore.dispatch(UserAction.logout());
            return res.data!.logout
        })
    }
}
