import React, {useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import styles from './BizPeriodPickerTemp1.module.scss'
import classNamesBind from "classnames/bind";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {OnChangeValue} from "../../../interfaces";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ko from "date-fns/locale/ko";

const cx = classNamesBind.bind(styles);

export const BizPeriodPickerTemp1 = (props: {
    period: Period,
    onChange: OnChangeValue<Period>
}) => {

    const [stDate, setStDate] = useState<Date | null>(
        props.period.stDate
    );

    const [edDate, setEdDate] = useState<Date | null>(
        props.period.edDate
    );

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
                <div className={cx("searchColumn-input")}>
                    <div className={cx("cell-valueText")}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="dialog"
                            inputVariant="outlined"
                            format="yyyy년 MM월 dd일"
                            margin="none"
                            label="검색 시작일"
                            value={props.period.stDate}
                            inputProps={{style: {padding: 10}}}
                            onChange={date => {
                                setStDate(date);

                                if (date instanceof Date) {
                                    if (moment(props.period.edDate).toDate().getTime() < date.getTime()) {
                                        SwalUtil.ok({
                                            msg: '시작일이 종료일보다 뒤입니다.',
                                            icon: 'error',
                                        })
                                        return;
                                    }

                                    const copy = Object.assign({}, props.period);
                                    copy.stDate = date;
                                    props.onChange(copy);
                                }
                            }}/>
                    </div>
                </div>
                <div className={cx("searchColumn-input")}>
                    <div className={cx("cell-valueText")}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="dialog"
                            inputVariant="outlined"
                            format="yyyy년 MM월 dd일"
                            margin="none"
                            label="검색 종료일"
                            value={props.period.edDate}
                            inputProps={{style: {padding: 10}}}
                            onChange={date => {
                                setEdDate(date);

                                if (date instanceof Date) {
                                    if (moment(props.period.edDate).toDate().getTime() < date.getTime()) {
                                        SwalUtil.ok({
                                            msg: '시작일이 종료일보다 뒤입니다.',
                                            icon: 'error',
                                        })
                                        return;
                                    }

                                    const copy = Object.assign({}, props.period);
                                    copy.edDate = date;
                                    props.onChange(copy);
                                }
                            }}/>
                    </div>
                </div>
            </MuiPickersUtilsProvider>
        </>
    )
}

export type Period = {
    stDate: Date;
    edDate: Date;
}
