import React, {Fragment} from "react";
import {Query} from "../../../../graphql/types";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {MngTreeUserDefaultList} from "./list/MngTreeUserDefaultList";
import {Pager} from "../../../shares/default/pager/Pager";
import {OnChangeValue} from "../../../interfaces";


export const MngTreeUserDefault = (props: {
    query: Query,
    onChangePage: OnChangeValue<number>
}) => {
    return (
        <Fragment>
            <BizTitle>회원 목록</BizTitle>
            <MngTreeUserDefaultList
                list={props.query.treeUserList}/>
            <Pager
                page={props.query.treeUserList.page}
                size={props.query.treeUserList.size}
                total={props.query.treeUserList.total} 
                onChange={props.onChangePage}/>
        </Fragment>
    )
}
