import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {Query} from "../../../graphql/types";
import {MngLayout} from "../layout/MngLayout";
import {FadeIn} from "../../shares/default/fadeIn/FadeIn";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {MngTreeUserDefault} from "./default/MngTreeUserDefault";
import {navigate} from "hookrouter";

export const MngTreeUser = (props: {
    page: number,
    email: string
}) => {
    const {data, refetch} = useQuery<Query, typeof props>(gqlData, {
        variables: props
    });

    const handler = {
        onChangePage: (p: number) => {
            navigate(`/mng/agent/${p}`);
        }
    };

    return (
        <MngLayout title={'대리점관리'}>
            {data &&
            <FadeIn>
                <ViewTemplateSelector view={{
                    [ViewTemplate.default]:
                        <MngTreeUserDefault
                            {...handler}
                            query={data}/>
                }}/>
            </FadeIn>
            }
        </MngLayout>
    )
}

const gqlData = gql`
    query DATA ($page: Int!, $email: String!) {
        treeUserList(page: $page, size: 20, email: $email) {
            page
            size
            total
            list {
                id
                email
                point
                createdAt
            }
        }
    }
`;
