import React, {Fragment, ReactNode, useEffect} from "react";
import classNamesBind from "classnames/bind";
import styles from './BizVideoBackground.module.scss'
import video from "../../../../assets/video/background.mp4";
import mySmsVideo from "../../../../assets/video/mysms.mp4";
import dubaiVideo from "../../../../assets/video/dubai.mp4";
import {Spring} from "react-spring/renderprops-universal";
import {LayoutAction} from "../../../../redux/Layout";
import {useDispatch} from "react-redux";

const cx = classNamesBind.bind(styles);

export const BizVideoBackground = (props: {
    children: ReactNode,
    fadeIn?: boolean
}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LayoutAction.setAppBarTransparent(true));
        dispatch(LayoutAction.setTopMargin(false));
        return () => {
            dispatch(LayoutAction.setAppBarTransparent(false));
            dispatch(LayoutAction.setTopMargin(true));
        }
    }, [])
    return (
        <Fragment>
            <Spring
                from={props.fadeIn ? {opacity: 0} : {opacity: 1}}
                to={{opacity: 1}}>
                {params => (
                    <div className={cx('cont')} style={params}>
                        <video className={cx('video')} autoPlay loop muted>
                            <ExportVideo host={window.location.host}/>
                        </video>
                        <div className={cx('filter')}/>
                    </div>
                )}
            </Spring>
            {props.children}
        </Fragment>

    )
}

function ExportVideo(props: { host: string }) {
    const {host} = props;

    if (host === "my-2017.com" || host === "www.my-2017.com") {
        return <source src={mySmsVideo} type='video/mp4'/>;
    } else if (host === "dubaisms.net" || host === "www.dubaisms.net") {
        return <source src={dubaiVideo} type='video/mp4'/>
    } else {
        return <source src={video} type='video/mp4'/>
    }
}
