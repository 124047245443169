import {combineReducers} from "redux";
import {AppReducer} from "./App";
import {UserReducer} from "./User";
import {SystemReducer} from "./System";
import {WebReducer} from "./Web";
import {MobileReducer} from "./Mobile";
import {LayoutReducer} from "./Layout";
import {EnvReducer} from "./Env";

export const RootReducer = combineReducers({
    app: AppReducer,
    user: UserReducer,
    system: SystemReducer,
    web: WebReducer,
    mobile: MobileReducer,
    layout: LayoutReducer,
    env: EnvReducer,
});
