export class Validator {
    // 인덱스에 사용되는 숫자인지 판단하고, 숫자가 아니면 0을 리턴한다.
    static isNumber(idx: any): number {
        if (!isNaN(idx)
            && (0 <= idx)) {
            return Number(idx)
        } else {
            return 0
        }
    }
}
