import React, {Fragment, ReactNode} from "react";
import classNamesBind from "classnames/bind";
import styles from './CenterContents.module.scss'
import {Spring} from "react-spring/renderprops-universal";

const cx = classNamesBind.bind(styles);

export const CenterContents = (props: {
    children: ReactNode,
    animatedContents?: boolean
}) => {
    return (
        <div className={cx('cont')}>
            <Spring
                from={{opacity: props.animatedContents ? 0 : 1}}
                to={{opacity: 1}}>
                {params => (
                    <div style={params}>
                        {props.children}
                    </div>
                )}
            </Spring>
        </div>
    )
}
