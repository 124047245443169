import React, {ReactNode} from "react";
import classNamesBind from "classnames/bind";
import styles from './LayoutMobile.module.scss'
import {
    AppBar,
    IconButton,
    SwipeableDrawer,
    Toolbar,
    Typography
} from "@material-ui/core";
import {navigate} from "hookrouter";
import {Menu} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {SystemState} from "../../../../redux/System";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {MobileAction, MobileState} from "../../../../redux/Mobile";
import {LayoutMobileSidebar} from "./sidebar/LayoutMobileSidebar";
import {LayoutState} from "../../../../redux/Layout";
import {NavBar} from "../../Layout";
import {UserState} from "../../../../redux/User";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";

const cx = classNamesBind.bind(styles);

export const LayoutMobile = (props: {
    children: ReactNode,
    navBar: NavBar[],
}) => {
    const system = useSelector<any, SystemState>(state => state.system);
    const user = useSelector<any, UserState>(state => state.user);
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const mobile = useSelector<any, MobileState>(state => state.mobile);
    const dispatch = useDispatch();

    const onLogout = () => {
        dispatch(MobileAction.setSidebar(false));
        if (user.isLogin) {
            SwalUtil.yn({
                msg: "로그아웃 하시겠습니까?",
                icon: "question",
                ok: () => {
                    DataUtil.logout()
                        .then(res => {
                            navigate('/');
                        })
                        .catch(ApolloCatch({
                            [BizErrors.default]: () => {
                                navigate('/');
                            }
                        }));
                }
            })
        }
    }

    const onChangeSidebar = (swt: boolean) => {
        dispatch(MobileAction.setSidebar(swt));
    }

    return (
        <FadeIn>
            <AppBar className={cx('appbar')} position="fixed" color='transparent'>
                <Toolbar>
                    <Typography
                        className={cx('btn-color')}
                        onClick={event => {
                            navigate('/');
                        }}>{system.init.appNm}</Typography>
                    <div className={cx('space')}/>

                    <IconButton
                        className={cx('btn-color')}
                        edge="end" color="inherit" aria-label="menu"
                        onClick={event => {
                            onChangeSidebar(true);
                        }}>
                        <Menu/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <SwipeableDrawer
                anchor="left"
                onClose={() => {
                    onChangeSidebar(false);
                }}
                onOpen={() => {
                    onChangeSidebar(true);
                }}
                open={mobile.showSidebar}>
                <LayoutMobileSidebar navBar={props.navBar} onLogout={onLogout}/>
            </SwipeableDrawer>

            {layout.topMargin && <div style={{height: 60}}/>}
            {props.children}
        </FadeIn>
    )
}
