import React from "react";
import classNamesBind from "classnames/bind";
import styles from './SettleListTemp1.module.scss'
import {Query} from "../../../../graphql/types";
import {OnChangeValue} from "../../../interfaces";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {PagerTemp1} from "../../../shares/temp1/pager/PagerTemp1";
import {A} from "hookrouter";

const cx = classNamesBind.bind(styles);

export const SettleListTemp1 = (props: {
    data: Query,
    onChangePage: OnChangeValue<number>,
}) => {
    return (
        <>
            <div className={cx("fullContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-left")}>
                                    통계
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={cx("thirdSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-contentsColumn", "headerColumn")}>
                                <div className={cx("contentsColumn-topHeader", "dateCell")}>
                                    날짜
                                </div>
                                <div className={cx("contentsColumn-topHeader", "pointCell")}>
                                    사용포인트
                                </div>
                                <div className={cx("contentsColumn-topHeader", "chargeCell")}>
                                    충전금액
                                </div>
                                <div className={cx("contentsColumn-topHeader", "chargePointCell")}>
                                    충전포인트
                                </div>
                                <div className={cx("contentsColumn-topHeader", "spendCell")}>
                                    전송량
                                </div>
                            </div>
                            {props.data.settle.list.map((value, index) => (
                                <A href={`/result/receipt/0/${Pipe.toTimeDay(value.day)}/${Pipe.toTimeDay(value.day)}`}
                                   key={index} className={cx("side-contentsColumn", "hover")}>
                                    <div className={cx("contentsColumn-bottomValue", "dateCell")}>
                                        {moment(value.day).format('YY.MM.DD')}
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "pointCell")}>
                                        {Pipe.toSeparatorNumber(-value.usedPoint)} P
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "chargeCell")}>
                                        {Pipe.toSeparatorNumber(value.chargeAmount)} 원
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "chargePointCell")}>
                                        {Pipe.toSeparatorNumber(value.chargePoint)} P
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "spendCell")}>
                                        {Pipe.toSeparatorNumber(value.sentSms)} 개
                                    </div>
                                </A>
                            ))}

                            <PagerTemp1
                                page={props.data.settle.page}
                                size={props.data.settle.size}
                                total={props.data.settle.total}
                                onChange={props.onChangePage}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
