import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {Environment, ViewTemplate} from "../environment/interfaces";
import {EnvUnit} from "../environment/environment";


export interface EnvState {
    env: Environment,
}

const initState: EnvState = {
    env: EnvUnit[ViewTemplate.default],
}

class Env extends ImmerReducer<typeof initState> {
    setViewTemplate(template: string) {
        if (EnvUnit.hasOwnProperty(template)) {
            this.draftState.env = EnvUnit[template]
        } else {
            this.draftState.env = EnvUnit[ViewTemplate.default]
        }
    }
}


export const EnvAction = createActionCreators(Env);
export const EnvReducer = createReducerFunction(Env, initState);
