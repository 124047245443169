import React, {Fragment, useEffect} from 'react';
import {BizRefreshSession} from "../../shares/default/bizRefreshSession/BizRefreshSession";
import {FB} from "../../../utils/fb/FB";
import {useSelector} from "react-redux";
import {UserState} from "../../../redux/User";
import {gql, useQuery} from "@apollo/client";
import {PointHistoryList} from "../../../graphql/types";
import {navigate} from "hookrouter";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {MyPageInfoDefault} from "./default/MyPageInfoDefault";
import {MyPageInfoTemp1} from "./temp1/MyPageInfoTemp1";
import { MyPageInfoTemp2 } from './temp2/MyPageInfoTemp2';


export const MypageInfo = (props: {
    page: number
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const {data, loading, error} = useQuery<MyPageInfoResp, { page: number }>(gqlData, {
        variables: {
            page: props.page
        }
    });

    useEffect(() => {
        onChangePage(0);
    }, []);

    const onResetPw = () => {
        FB.resetPwWithSwal(user.user.email);
    }

    const onChangePage = (page: number) => {
        navigate(`/mypage/info/${page}`);
    };

    return (
        <Fragment>
            {data &&
            <BizRefreshSession>
                <ViewTemplateSelector view={{
                    [ViewTemplate.default]:
                        <MyPageInfoDefault
                            data={data}
                            onResetPw={onResetPw}
                            onChangePage={onChangePage}/>,
                    [ViewTemplate.temp1]:
                        <MyPageInfoTemp1
                            data={data}
                            onResetPw={onResetPw}
                            onChangePage={onChangePage}/>,
                    [ViewTemplate.temp2]:
                        <MyPageInfoTemp2
                            data={data}
                            onResetPw={onResetPw}
                            onChangePage={onChangePage}/>,
                    [ViewTemplate.zsms]:
                        <MyPageInfoTemp1
                            data={data}
                            onResetPw={onResetPw}
                            onChangePage={onChangePage}/>,
                }}/>
            </BizRefreshSession>
            }
        </Fragment>
    )
}

export interface MyPageInfoResp {
    pointHistory: PointHistoryList,
}

const gqlData = gql`
    query Data($page: Int!) {
        pointHistory(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                point
                prevPoint
                changedPoint
                receiptType
                createdAt
            }
        }
    }
`;
