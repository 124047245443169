import React, {useEffect, useState} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import {BoardList} from "../../../graphql/types";
import {navigate} from "hookrouter";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {NoticeForAllDefault} from "./default/NoticeForAllDefault";
import { NoticeForAllTemp1 } from "./temp1/NoticeForAllTemp1";
import {NoticeForAllTemp2} from "./temp2/NoticeForAllTemp2";

export const NoticeForAll = (props: {
    page: number,
}) => {
    const [getData, resData] = useLazyQuery<NoticeForAllResp, {
        page: number
    }>(gqlData);

    const [data, setData] = useState<NoticeForAllResp | undefined>();

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/notice/${page}`);
        },
    }

    useEffect(() => {
        getData({variables: {page: props.page}});
    }, [props.page]);

    useEffect(() => {
        if (resData.data) {
            setData(resData.data);
        }
    }, [resData.data]);

    return (
        <ViewTemplateSelector
            view={{
                [ViewTemplate.default]:
                    <NoticeForAllDefault
                        data={data}
                        {...handler}/>,
                [ViewTemplate.temp1]:
                    <NoticeForAllTemp1
                        data={data}
                        {...handler}/>,
                [ViewTemplate.temp2]:
                    <NoticeForAllTemp2
                        data={data}
                        {...handler}/>,
                [ViewTemplate.zsms]:
                    <NoticeForAllTemp1
                        data={data}
                        {...handler}/>,
            }}/>
    )
}

export interface NoticeForAllResp {
    notice: BoardList
}

const gqlData = gql`
    query Notice($page: Int!) {
        notice(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                title
                content
                createdAt
            }
        }
    }
`;
