import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngCodeDefaultCreate.module.scss'
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import {OnChangeValue} from "../../../../interfaces";
import {
    CommissionPermission,
    CreateApiPermission,
    MutationCreateDisposableJoinCodeWithSmsFeeArgs,
    MutationCreateReusableJoinCodeWithSmsFeeArgs
} from "../../../../../graphql/types";
import Decimal from "decimal.js";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {Switch} from "@material-ui/core";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import NumberFormat from "react-number-format";

const cx = classNamesBind.bind(styles);

export const MngCodeDefaultCreate = (props: {
    onCreateReusableCode: OnChangeValue<MutationCreateReusableJoinCodeWithSmsFeeArgs>,
    onCreateDisposableCode: OnChangeValue<MutationCreateDisposableJoinCodeWithSmsFeeArgs>,
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const [input, setInput] = useState<Input>([]);

    useEffect(() => {
        const nextInput: Input = [];
        for (let hashedApiKey of user.user.apiPermission) {
            nextInput.push({
                nm: hashedApiKey.nm,
                hashedApiKey: hashedApiKey.hashedApiKey,
                isActivate: true,
                smsFee: hashedApiKey.smsFee,
                defaultSmsFee: hashedApiKey.smsFee
            })
        }
        setInput(nextInput);
    }, [user.user.apiPermission]);

    const handler = {
        onValidation: (): boolean => {
            let hasActivation = false;

            for (let elem of input) {
                if (elem.isActivate) {
                    hasActivation = true;
                }

                if (new Decimal(elem.smsFee).lt(elem.defaultSmsFee)) {
                    SwalUtil.ok({
                        msg: '대리점 회원의 건당가격이 리셀러 회원보다 낮을수 없습니다.',
                        icon: 'error'
                    });
                    return false;
                }
            }

            if (!hasActivation) {
                SwalUtil.ok({
                    msg: '최소 1개 이상의 회선을 활성화 하여 주십시오.',
                    icon: 'error',
                })
            }

            return hasActivation;
        },
        onCreateArgs: (): CreateApiPermission[] => {
            const list: CreateApiPermission[] = [];


            for (let elem of input) {
                if (!elem.isActivate) {
                    continue;
                }

                if (new Decimal(elem.smsFee).lt(elem.defaultSmsFee)) {
                    continue;
                }

                list.push({
                    hashedApiKey: elem.hashedApiKey,
                    smsFee: elem.smsFee
                })
            }


            return list;
        },
        onCreateReusableCode: () => {
            if (!handler.onValidation()) {
                return;
            }

            const args = handler.onCreateArgs()
            props.onCreateReusableCode({
                input: {apiPermission: args}
            })
        },
        onCreateDisposableCode: () => {
            if (!handler.onValidation()) {
                return;
            }

            const args = handler.onCreateArgs()
            props.onCreateDisposableCode({
                input: {apiPermission: args}
            })
        }
    }


    return (
        <>
            {/* 충전 커미션용 리스트 */}
            {user.user.commissionPermission?.includes(CommissionPermission.Recharge) &&
            <>
                <div className={cx('table')}>
                    <div style={{width: '33%'}}>활성화</div>
                    <div style={{width: '33%'}}>회선이름</div>
                    <div style={{width: '34%'}}>건당가격</div>
                </div>

                {input.map((value, index) => (
                    <div className={cx('table', 'hover')} key={index}>
                        <div style={{width: '33%'}}>
                            <Switch
                                checked={value.isActivate}
                                color='primary'
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(input);
                                    copy[index].isActivate = ev.target.checked;
                                    setInput(copy);
                                }}/>
                        </div>
                        <div style={{width: '33%'}}>{value.nm}</div>
                        <div style={{width: '34%'}}>{value.defaultSmsFee} 원</div>
                    </div>
                ))}
            </>
            }

            {/* 전송 커미션용 리스트 */}
            {user.user.commissionPermission?.includes(CommissionPermission.Send) &&
            <>
                <div className={cx('table')}>
                    <div style={{width: '25%'}}>활성화</div>
                    <div style={{width: '25%'}}>회선이름</div>
                    <div style={{width: '25%'}}>건당가격</div>
                    <div style={{width: '25%'}}>대리점 가격</div>
                </div>

                {input.map((value, index) => (
                    <div className={cx('table', 'hover')} key={index}>
                        <div style={{width: '25%'}}>
                            <Switch
                                checked={value.isActivate}
                                color='primary'
                                onChange={ev => {
                                    const copy = CopyUtil.copyAll(input);
                                    copy[index].isActivate = ev.target.checked;
                                    setInput(copy);
                                }}/>
                        </div>
                        <div style={{width: '25%'}}>{value.nm}</div>
                        <div style={{width: '25%'}}>{value.defaultSmsFee} 원</div>
                        <div style={{width: '25%'}}>
                            <NumberFormat
                                thousandSeparator
                                decimalScale={1}
                                allowNegative={false}
                                suffix={' 원'}
                                className='form-control form-control-sm text-center'
                                value={value.smsFee}
                                onValueChange={values => {
                                    const copy = CopyUtil.copyAll(input);
                                    copy[index].smsFee = values.floatValue || 0;
                                    setInput(copy);
                                }}/>
                        </div>
                    </div>
                ))}
            </>
            }

            <div className={cx('table')}>
                <div style={{width: '100%'}} className='text-right'>
                    <button
                        className='btn btn-outline-success'
                        style={{marginRight: 10}}
                        onClick={handler.onCreateReusableCode}>재사용 코드
                    </button>
                    <button
                        className='btn btn-success'
                        onClick={handler.onCreateDisposableCode}>일회용 코드
                    </button>
                </div>
            </div>
        </>
    )
}

type Input = (CreateApiPermission & { isActivate: boolean, defaultSmsFee: number, nm: string })[];
