import React from 'react';
import {BalanceReceiptType} from "../../../../graphql/types";
import {Chip} from "@material-ui/core";
import classNamesBind from "classnames/bind";
import styles from "./BadgeMyPageInfoBalanceTemp1.module.scss";

const cx = classNamesBind.bind(styles);

export const BadgeMyPageInfoBalanceTemp1 = (props: {
    value: BalanceReceiptType
}) => {
    return (
        <>
            {props.value === BalanceReceiptType.Init &&
            <Chip size="small" label="회원가입" classes={{root: cx("myPage-chip1-color")}}/>
            }

            {props.value === BalanceReceiptType.Admin &&
            <Chip size="small" label="관리자" classes={{root: cx("myPage-chip2-color")}}/>
            }

            {props.value === BalanceReceiptType.SendBulk &&
            <Chip size="small" label="대량전송" classes={{root: cx("myPage-chip3-color")}}/>
            }

            {props.value === BalanceReceiptType.SendTemplate &&
            <Chip size="small" label="템플릿전송" classes={{root: cx("myPage-chip4-color")}}/>
            }

            {props.value === BalanceReceiptType.Deposit &&
            <Chip size="small" label="충전" classes={{root: cx("myPage-chip5-color")}}/>
            }

            {props.value === BalanceReceiptType.SharePoint &&
            <Chip size="small" label="리셀러/대리점" classes={{root: cx("myPage-chip6-color")}}/>
            }

            {props.value === BalanceReceiptType.Commission &&
            <Chip size="small" label="커미션" classes={{root: cx("myPage-chip6-color")}}/>
            }
        </>
    )
}
