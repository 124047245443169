import React, {useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {MutationUpdateAgentApiPermissionArgs, Query} from "../../../graphql/types";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {MngAgentWithSmsFeeDefault} from "./default/MngAgentWithSmsFeeDefault";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {navigate} from "hookrouter";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {BizErrors} from "../../../graphql/errors";
import {MngAgentWithSmsFeeTemp1} from "./temp1/MngAgentWithSmsFeeTemp1";

export const MngAgentWithSmsFee = (props: {
    page: number,
    email?: string,
}) => {
    const {data, loading, error, refetch} = useQuery<Query, MngAgentWithSmsFeeReq>(gqlData, {
        variables: props
    });

    const [input, setInput] = useState<MngAgentWithSmsFeeInputData>({
        email: props.email || '',
    });

    const [mutUpdateAgentActivation] = useMutation<any, {
        userId: number,
        activation: boolean,
    }>(gqlUpdateAgentActivation);

    const [mutUpdateAgentApiPermission] =
        useMutation<any, MutationUpdateAgentApiPermissionArgs>(gqlUpdateAgentApiPermission);

    const handler = {
        onReload: () => {
            refetch(props).catch(ApolloCatch({}));
        },
        onChangePage: (value: { page: number, email: string }) => {
            navigate(`/mng/agent/${value.page}/${value.email}`)
        },
        onChangeAgentActivation: (value: { userId: number, activation: boolean }) => {
            SwalUtil.yn({
                msg: value.activation ? '차단 해제 하시겠습니까?' : '차단 하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutUpdateAgentActivation({variables: value})
                        .then(() => {
                            SwalUtil.ok({
                                msg: value.activation ? '해제 되었습니다.' : '차단 되었습니다.',
                                icon: 'success',
                            });
                            return refetch(props);
                        })
                        .catch(ApolloCatch({}))
                }

            })
        },
        onUpdateAgentApiPermission: (args: MutationUpdateAgentApiPermissionArgs) => {
            SwalUtil.yn({
                msg: '변경하시겠습니까?',
                icon: 'question',
                ok: () => {
                    mutUpdateAgentApiPermission({variables: args})
                        .then(() => {
                            SwalUtil.ok({
                                msg: '수정되었습니다.',
                                icon: 'success',
                            })
                            return refetch(props);
                        })
                        .catch(ApolloCatch({
                            [BizErrors.cannotSetSmsFee]: SwalUtil.bizErrorMsg({
                                msg: '건당 가격을 확인하여 주십시오.',
                                icon: 'error'
                            }),
                            [BizErrors.notFoundApiPermission]: SwalUtil.bizErrorMsg({
                                msg: '회선 권한이 없습니다.',
                                icon: 'error'
                            })
                        }))
                }
            })
        }
    }

    return (
        <>
            {data &&
            <ViewTemplateSelector view={{
                [ViewTemplate.default]:
                    <MngAgentWithSmsFeeDefault
                        query={data}
                        value={input}
                        onChangeValue={setInput}
                        {...handler}/>,
                [ViewTemplate.temp1]:
                    <MngAgentWithSmsFeeTemp1
                        query={data}
                        value={input}
                        onChangeValue={setInput}
                        {...handler}/>,
                [ViewTemplate.zsms]:
                    <MngAgentWithSmsFeeTemp1
                        query={data}
                        value={input}
                        onChangeValue={setInput}
                        {...handler}/>,
            }}/>
            }
        </>
    )
}

interface MngAgentWithSmsFeeReq {
    page: number,
    email?: string,
}

export interface MngAgentWithSmsFeeInputData {
    email: string;
}

const gqlUpdateAgentActivation = gql`
    mutation UpdateAgentPermission($userId: Int!, $activation: Boolean!) {
        updateAgentActivation(userId: $userId, activation: $activation)
    }
`;

const gqlUpdateAgentApiPermission = gql`
    mutation UpdateAgentApiPermissoin($userId: Int!, $updateAgentPermissionList: [UpdateAgentApiPermission!]!) {
        updateAgentApiPermission(userId: $userId, updateAgentPermissionList: $updateAgentPermissionList)
    }
`;


const gqlData = gql`
    query DATA($page: Int!, $email: String) {
        agentList(page: $page, size: 20, email: $email) {
            page
            size
            total
            list {
                id
                isActivate
                email
                point
                createdAt
                apiPermission {
                    hashedApiKey
                    smsFee
                    nm
                }
            }
        }
    }
`;
