import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ResultMsgReceiptTemp1.module.scss'
import {OnChangeValue, OnVoidFunction} from "../../../interfaces";
import {ResultMsgReceiptResp} from "../ResultMsgReceipt";
import {A} from "hookrouter";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {PagerTemp1} from "../../../shares/temp1/pager/PagerTemp1";
import {MutationHideMsgReceiptArgs, PeriodTimeDay} from "../../../../graphql/types";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {BizPeriodPickerTemp1} from "../../../shares/temp1/bizPeriodPicker/BizPeriodPickerTemp1";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

const cx = classNamesBind.bind(styles);

export const ResultMsgReceiptTemp1 = (props: {
    data: ResultMsgReceiptResp,
    periodTimeDay?: PeriodTimeDay,
    onHideMsgReceipt: OnChangeValue<MutationHideMsgReceiptArgs>,
    onHideAllMsgReceipt: OnVoidFunction,
    onChangePeriod: OnChangeValue<PeriodTimeDay>,
    onChangePage: OnChangeValue<number>
}) => {
    const [period, setPeriod] = useState({
        stDate: new Date(),
        edDate: new Date(),
    });

    useEffect(() => {
        if (!props.periodTimeDay) {
            return;
        }

        setPeriod({
            stDate: Pipe.cvtTimeDayToDate(props.periodTimeDay.stDate),
            edDate: Pipe.cvtTimeDayToDate(props.periodTimeDay.edDate),
        })
    }, [props.periodTimeDay]);

    const handler = {
        onChangePeriod: () => {
            props.onChangePeriod({
                stDate: Pipe.toTimeDay(period.stDate),
                edDate: Pipe.toTimeDay(period.edDate),
            });
        }
    }

    return (
        <>
            <div className={cx("fullContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-left")}>
                                    전송결과
                                </div>
                                <div className={cx("titleBar-right")}>
                                    <BizPeriodPickerTemp1
                                        period={period}
                                        onChange={setPeriod}/>

                                    <Button
                                        classes={{root: cx("buttonOne")}}
                                        variant="outlined"
                                        style={{fontSize: 12, height: 39, width: 60}}
                                        onClick={handler.onChangePeriod}>
                                        검색
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx("secondSection")} style={{marginTop: 15}}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-contentsColumn", "borderBottom")}>
                                <div className={cx("contentsColumn-topHeader", "dateCell")}>
                                    전송일자
                                </div>
                                <div className={cx("contentsColumn-topHeader", "descCell")}>
                                    내용
                                </div>
                                <div className={cx("contentsColumn-topHeader", "valueCell")}>
                                    전송건수
                                </div>
                                <div className={cx("contentsColumn-topHeader", "funcCell")}>
                                    기능
                                </div>
                            </div>

                            {props.data.msgReceipt.list.map((value, index) => {
                                return (
                                    <div className={cx("side-contentsColumn", "hover")} key={index}>
                                        <div className={cx("contentsColumn-bottomValue", "dateCell")}>
                                            {moment(value.createdAt).format('MM월 DD일 hh:mm(A)')}
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "descCell")}>
                                            <A href={`/result/telbook/${value.requestId}`}>
                                                {value.contents}
                                            </A>
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "valueCell")}>
                                            {Pipe.toSeparatorNumber(value.sent)} 건
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "funcCell")}>
                                            <Tooltip title={'삭제'}>
                                                <IconButton
                                                    size='small'
                                                    classes={{label: cx("fontcolor-red")}}
                                                    onClick={() => {
                                                        props.onHideMsgReceipt({msgReceiptIdList: [value.id]});
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className={cx("side-searchColumn")}>
                                <div className={cx("searchColumn-right")}>
                                    <Button
                                        classes={{root: cx("buttonFour")}}
                                        variant="outlined"
                                        style={{fontSize: 12, width: 120, marginRight: 30}}
                                        onClick={props.onHideAllMsgReceipt}>모두삭제
                                    </Button>
                                </div>
                            </div>

                            <PagerTemp1
                                page={props.data.msgReceipt.page}
                                size={props.data.msgReceipt.size}
                                total={props.data.msgReceipt.total}
                                onChange={props.onChangePage}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
