import withReactContent from "sweetalert2-react-content";
import Swal, {SweetAlertIcon} from "sweetalert2";

export class SwalUtil {
    private static swal = withReactContent(Swal);

    static inputSmsVerifier(): Promise<string> {
        return this.swal.fire({
            title: 'SMS 인증 번호를 입력하여 주십시오.',
            icon: 'question',
            input: 'text',
            inputPlaceholder: '비밀번호',
            inputAttributes: {
                autocapitalize: 'off',
                autocorrect: 'off'
            },
            backdrop: false,
            showCancelButton: true
        }).then(value => {
            return value.value
        })
    }

    static yn(param: {
        text?: string,
        msg: string, icon: SweetAlertIcon, ok?: () => void, cancel?: () => void
    }) {
        this.swal.fire({
            title: param.msg,
            text: param.text,
            icon: param.icon,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
        }).then(value => {
            if (value.value) {
                if (param.ok) {
                    param.ok();
                }
            } else {
                if (param.cancel) {
                    param.cancel();
                }
            }
        });
    }

    static ok(param: {
        msg: string,
        text?: string,
        icon: SweetAlertIcon,
        ok?: () => void
    }) {
        this.swal.fire({
            title: param.msg,
            textContent: param.text,
            icon: param.icon,
            confirmButtonText: '확인',
        }).then(value => {
            if (value.value) {
                if (param.ok) {
                    param.ok();
                }
            }
        });
    }

    static input(ok : (input: string) => void, msg: string, placeholder: string) {
        this.swal.fire({
            text: msg,
            input: 'text',
            inputPlaceholder: placeholder,
            inputAttributes: {
                autocapitalize: 'off',
                autocorrect: 'off'
            },
            backdrop: false,
            showCancelButton: true
        }).then(value => {
            if (value.dismiss !== Swal.DismissReason.cancel) {
                const password = value.value as string;
                if (password) {
                    ok(password);
                } else {
                    this.ok({msg: '입력값을 입력하여 주십시오.', icon: 'error'});
                }
            }
        });
    }

    static pw(ok: (pw: string) => void) {
        this.swal.fire({
            text: '비밀번호를 입력하여 주십시오',
            input: 'password',
            inputPlaceholder: '비밀번호',
            inputAttributes: {
                autocapitalize: 'off',
                autocorrect: 'off'
            },
            backdrop: false,
            showCancelButton: true
        }).then(value => {
            if (value.dismiss !== Swal.DismissReason.cancel) {
                const password = value.value as string;
                if (password) {
                    ok(password);
                } else {
                    this.ok({msg: '비밀번호를 입력하여 주십시오.', icon: 'error'});
                }
            }
        });
    }

    static error() {
        this.swal.fire({
            text: "다시 시도 하여 주십시오.",
            icon: "error"
        }).then();
    }

    static bizError(): () => void {
        return () => {
            this.swal.fire({
                text: "다시 시도 하여 주십시오.",
                icon: "error"
            }).then();
        }
    }

    static bizErrorMsg(params: {
        msg: string,
        icon: SweetAlertIcon
    }): () => void {
        return () => {
            this.ok(params);
        }
    }
}

