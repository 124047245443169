import React from "react";
import classNamesBind from "classnames/bind";
import styles from './SmsBulkTemp1CharInputPopover.module.scss';
import {Button, IconButton, Popover} from "@material-ui/core";
import {SendBulkInputData} from "../../SmsBulk";
import {OnChangeValue} from "../../../../interfaces";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import {CharList} from "../../../../shares/default/charList/CharList";

const cx = classNamesBind.bind(styles);

export const SmsBulkTemp1CharInputPopover = (props: {
    value: SendBulkInputData,
    onChangeValue: OnChangeValue<SendBulkInputData>,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const onClickChar = (value: string) => {
        const copy = CopyUtil.copyAll(props.value);
        copy.msg.msg += value;
        props.onChangeValue(copy);
    }

    return (
        <>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <div className={cx('cont')}>
                    {CharList.map((value, index) => (
                        <IconButton
                            key={index} size={'small'} className={cx('btn')}
                            onClick={ev => {
                                onClickChar(value);
                            }}>
                            {value}
                        </IconButton>
                    ))}

                </div>
            </Popover>

            <Button
                classes={{root: cx("buttonTwo")}}
                variant="outlined"
                size={'small'}
                fullWidth
                onClick={ev => {
                    setAnchorEl(ev.currentTarget);
                }}>
                특수문자 추가
            </Button>
        </>

    )
}