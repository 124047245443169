import React from "react";
import classNamesBind from "classnames/bind";
import styles from './IndexTemp1Section1.module.scss'
import {Button, Divider, Grid, Paper, Theme, withStyles} from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import {DoneAll, EuroSymbol, Forum, Help, Reorder, StarBorder, TapAndPlay, Textsms} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {SystemState} from "../../../../redux/System";
import {navigate} from "hookrouter";
import {UserState} from "../../../../redux/User";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import imgSection2 from '../../../../assets/img/index/temp1/2.jpg';

const cx = classNamesBind.bind(styles);

const ColorButton1 = withStyles((theme: Theme) => ({
    root: {
        color: "#1e89ba",
        backgroundColor: "rgba(0,0,0,0.5)",
        '&:hover': {
            color: "#fff",
            backgroundColor: "#1e89ba",
        },
    },
}))(Button);

export const IndexTemp1Section1 = () => {
    AOS.init({
        duration: 1000
    });

    const system = useSelector<any, SystemState>(state => state.system);
    const user = useSelector<any, UserState>(state => state.user);

    return (
        <>
            <div className={cx('cont-section-1')}>
                <div>
                    <div className={cx('title')} data-aos="fade-down">
                        {system.init.appNm} 는<br/>
                        빠르고 안정적인 <span style={{color: '#f15757'}}>문자 서비스</span> 입니다.
                    </div>
                    <div data-aos="fade-up" className={cx('sub-title')}>고객분들의 성공적인 비즈니스에 확실한 동반자가 되겠습니다.</div>
                </div>
            </div>

            <div className={cx("fullContainer", "sectionTwo")}>
                <div className={cx("sectionTwo__background")}>
                    <div className={cx("sectionTwo__container")}>
                        <img className={cx('bg-img')} src={imgSection2}/>
                        <div className={cx("sectionTwo__textBox", "section__textBox")} data-aos="fade-down">
                            <div className={cx('title')}>Enterprise Mobile Message Service</div>
                            <div className={cx('description')}>
                                {system.init.appNm} 는 가장 빠르고 편하게 문자를 전송합니다.<br/>
                                또한 웹사이트와 어떤 모바일 환경에서도 문자발송이 가능합니다.
                                이제 문자 서비스는 {system.init.appNm} 입니다.
                            </div>
                        </div>
                        <Grid container spacing={2} style={{marginBottom: 10}}>
                            <Grid item xs>
                                <Paper className={cx("sectionTwo__paper")} data-aos="fade-right">
                                    <div className={cx("paper__iconBox")}>
                                        <Forum/>
                                    </div>
                                    <h5>손쉬운 발송</h5>
                                    <h6>유저 친화적 UI로 누구나 쉽게 문자 작성 가능</h6>
                                </Paper>
                            </Grid>
                            <Grid item xs>
                                <Paper className={cx("sectionTwo__paper")} data-aos="fade-right" data-aos-delay="300">
                                    <div className={cx("paper__iconBox")}>
                                        <Reorder/>
                                    </div>
                                    <h5>길어진 문자</h5>
                                    <h6>일반 단문문자보다 약 2배 정도 길게 문자 작성이 가능</h6>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Paper className={cx("sectionTwo__paper")} data-aos="fade-right" data-aos-delay="600">
                                    <div className={cx("paper__iconBox")}>
                                        <TapAndPlay/>
                                    </div>
                                    <h5>실시간 발송</h5>
                                    <h6>웹 서버를 통한 실시간 메시지 발송 및 결과내역 제공</h6>
                                </Paper>
                            </Grid>
                            <Grid item xs>
                                <Paper className={cx("sectionTwo__paper")} data-aos="fade-right" data-aos-delay="900">
                                    <div className={cx("paper__iconBox")}>
                                        <StarBorder/>
                                    </div>
                                    <h5>높은 성공률</h5>
                                    <h6>안정적인 서비스제공으로 95%이상의 성공률 보장</h6>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            <Divider/>
            <div className={cx("fullContainer", "sectionThird")}>
                <div className={cx("sectionThird__background")}>
                    <div className={cx("sectionThird__container")} data-aos="fade-down">
                        <div className={cx("container__left")}>
                            Fastest and Easiest,<br/>Mobile Marketing
                        </div>
                        <div className={cx("container__right")} style={{
                            textAlign: "left"
                        }}>
                            <div>
                                <DoneAll style={{
                                    color: "red"
                                }}/> 한번을 이용해도, 한건만 구매해도 최저가 보장!
                            </div>
                            <div>
                                <DoneAll style={{
                                    color: "red"
                                }}/> 경제적인 가격과 선충전제 서비스
                            </div>
                            <div>
                                <DoneAll style={{
                                    color: "red"
                                }}/> 비용 계산이 쉽도록, 단일 요금제를 지원
                            </div>
                            <div>
                                <DoneAll style={{
                                    color: "red"
                                }}/> 최소의 비용으로 최고의 마케팅 효과를 경험
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Divider/>
            <div className={cx("fullContainer", "sectionFourth")}>
                <div className={cx("sectionFourth__background")}>
                    <div className={cx("sectionFourth__container")} data-aos="fade-down">
                        <div className={cx("sectionFourth__textBox", "section__textBox")} data-aos="fade-down">
                            <div className={cx('title')}>Customizable, Cost-effective and Reliable</div>
                        </div>
                        <div className={cx("sectionFourth-contentBox")}>
                            <Paper className={cx("sectionFourth__paper")} elevation={0} data-aos="fade-down"
                                   data-aos-delay="300">
                                <div className={cx("paper__iconBox")}>
                                    <div className={cx("iconBox__box", "iconBox__firstColor")}>
                                        <Help style={{color: "white"}}/>
                                    </div>
                                </div>
                                <div className={cx("paper__textBox")}>
                                    <h5>C/S Center</h5>
                                    <h6>{system.init.appNm}는 연중무휴 365일 24시간 고객센터를 운영합니다.</h6>
                                </div>
                            </Paper>
                            <Paper className={cx("sectionFourth__paper")} elevation={0} data-aos="fade-down"
                                   data-aos-delay="600">
                                <div className={cx("paper__iconBox")}>
                                    <div className={cx("iconBox__box", "iconBox__secondColor")}>
                                        <DoneAll style={{color: "white"}}/>
                                    </div>
                                </div>
                                <div className={cx("paper__textBox")}>
                                    <h5>API</h5>
                                    <h6>사용하기 쉬운 API를 지원하여 손쉬운 커스터마이징 환경을 제공</h6>
                                </div>
                            </Paper>
                            <Paper className={cx("sectionFourth__paper")} elevation={0} data-aos="fade-down"
                                   data-aos-delay="900">
                                <div className={cx("paper__iconBox")}>
                                    <div className={cx("iconBox__box", "iconBox__thirdColor")}>
                                        <Textsms style={{color: "white"}}/>
                                    </div>
                                </div>
                                <div className={cx("paper__textBox")}>
                                    <h5>Arrival</h5>
                                    <h6>정교한로드 밸런싱 프로세스와 결합하여 결함없이 메시지를 전달</h6>
                                </div>
                            </Paper>
                            <Paper className={cx("sectionFourth__paper")} elevation={0} data-aos="fade-down"
                                   data-aos-delay="1200">
                                <div className={cx("paper__iconBox")}>
                                    <div className={cx("iconBox__box", "iconBox__fourthColor")}>
                                        <EuroSymbol style={{color: "white"}}/>
                                    </div>
                                </div>
                                <div className={cx("paper__textBox")}>
                                    <h5>Low cost</h5>
                                    <h6>포인트 대량 구매시 가격 할인</h6>
                                </div>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
            <Divider/>
            <div className={cx("fullContainer", "sectionFifth")}>
                <div className={cx("sectionFifth__background")}>
                    <div className={cx("sectionFifth__container")} data-aos="fade-down">
                        <div className={cx("sectionFifth__textBox")}>
                            성공적인 문자 발송 비즈니스를<br/>지금 시작해보세요
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
