import React from "react";
import {Query} from "../../../../graphql/types";
import {MngCodeWithTreeDefaultList} from "./list/MngCodeWithTreeDefaultList";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {Pager} from "../../../shares/default/pager/Pager";
import {OnChangeValue, OnVoidFunction} from "../../../interfaces";


interface Props {
    query: Query,
    onChangeDisposablePage: OnChangeValue<number>,
    onChangeReusablePage: OnChangeValue<number>,
    onCreateDisposableCode: OnVoidFunction,
    onCreateReusableCode: OnVoidFunction,
    onDeleteCode: OnChangeValue<string>,
}

export const MngCodeWithTreeDefault = (props: Props) => {
    return (
        <>
            <BizTitle
                desc={
                    <button
                        className='btn btn-sm btn-success'
                        onClick={() => props.onCreateDisposableCode()}>
                        생성
                    </button>
                }>
                일회용 코드
            </BizTitle>

            <MngCodeWithTreeDefaultList
                onDelete={props.onDeleteCode}
                list={props.query.disposableTreeJoinCode}/>
            <Pager
                page={props.query.disposableTreeJoinCode.page}
                size={props.query.disposableTreeJoinCode.size}
                total={props.query.disposableTreeJoinCode.total}
                onChange={props.onChangeDisposablePage}/>


            <div style={{height: 80}}/>
            <BizTitle
                desc={
                    <button
                        className='btn btn-sm btn-success'
                        onClick={() => props.onCreateReusableCode()}>
                        생성
                    </button>
                }>
                다회용 코드
            </BizTitle>
            <MngCodeWithTreeDefaultList
                onDelete={props.onDeleteCode}
                list={props.query.reusableTreeJoinCode}/>
            <Pager
                page={props.query.reusableTreeJoinCode.page}
                size={props.query.reusableTreeJoinCode.size}
                total={props.query.reusableTreeJoinCode.total}
                onChange={props.onChangeReusablePage}/>
        </>
    )
}

