import React, {Fragment} from "react";
import {MyPageInfoResp} from "../MypageInfo";
import {OnChangeValue, OnVoidFunction} from "../../../interfaces";
import {useSelector} from "react-redux";
import {UserState} from "../../../../redux/User";
import {UserType} from "../../../../graphql/types";
import NumberFormat from "react-number-format";
import classNamesBind from "classnames/bind";
import styles from './MyPageInfoTemp2.module.scss'
import moment from "moment";
import {PagerTemp2} from "../../../shares/temp2/pager/PagerTemp2";
import Decimal from "decimal.js";
import {Button, withStyles} from "@material-ui/core";
import {FadeInContainerTemp1} from "../../../shares/temp1/fadeInContainer/FadeInContainerTemp1";
import {BadgeMyPageInfoBalanceTemp1} from "../../../shares/temp1/badgeMypageInfoBalance/BadgeMyPageInfoBalanceTemp1";
import {AccountCircle, Mail} from "@material-ui/icons";
import HistoryIcon from '@material-ui/icons/History';

const cx = classNamesBind.bind(styles);

const ColorButton1 = withStyles(() => ({
    root: {
        color: "#fff",
        backgroundColor: "#2196f3",
        '&:hover': {
            color: "#2196f3",
            backgroundColor: "#fff",
        },
        paddingLeft: 5,
        paddingRight: 5
    },
}))(Button);

export const MyPageInfoTemp2 = (props: {
    data: MyPageInfoResp,
    onResetPw: OnVoidFunction,
    onChangePage: OnChangeValue<number>
}) => {
    const user = useSelector<any, UserState>(state => state.user);

    return (
        <FadeInContainerTemp1>
            <Fragment>
                <div className={cx('fullContainer')}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-icon")}>
                                        <AccountCircle/>
                                    </div>
                                    <div className={cx("titleBar-text")}>
                                        <div className={cx("text-main")}>
                                            회원정보
                                        </div>
                                        <div className={cx("text-sub")}>
                                            Account Info
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        유저아이디
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        {user.user.email}
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        등급정보
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        {user.user.userType === UserType.General && "정회원"}
                                        {user.user.userType === UserType.Reseller && "총판회원"}
                                        {user.user.userType === UserType.Agent && "대리점회원"}
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        보유 포인트
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        <NumberFormat
                                            thousandSeparator
                                            value={user.user.point}
                                            suffix={' P'}
                                            displayType='text'/>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        비밀번호 변경
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        <ColorButton1
                                            className="box__cellButton"
                                            variant="outlined"
                                            style={{fontSize: 12}}
                                            onClick={props.onResetPw}>비밀번호변경
                                        </ColorButton1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-icon")}>
                                        <Mail/>
                                    </div>
                                    <div className={cx("titleBar-text")}>
                                        <div className={cx("text-main")}>
                                            회선정보
                                        </div>
                                        <div className={cx("text-sub")}>
                                            Transmission Line Info
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader")}>
                                        회선명
                                    </div>
                                    <div className={cx("contentsColumn-topHeader")}>
                                        건 당 가격
                                    </div>
                                    <div className={cx("contentsColumn-topHeader")}>
                                        전송 가능 건 수
                                    </div>
                                </div>
                                {user.user.apiPermission.map((value, index) => (
                                    <div className={cx("side-contentsColumn")} key={index}>
                                        <div className={cx("contentsColumn-bottomValue")}>
                                            {value.nm}
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue")}>
                                            {value.smsFee} 원
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue")}>
                                            {new Decimal(user.user.point).divToInt(value.smsFee).toNumber()} 건
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{height: 30}}></div>
                    <div className={cx("secondSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-icon")}>
                                        <HistoryIcon/>
                                    </div>
                                    <div className={cx("titleBar-text")}>
                                        <div className={cx("text-main")}>
                                            포인트 히스토리
                                        </div>
                                        <div className={cx("text-sub")}>
                                            Point History
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "box__dateCell")}>
                                        날짜
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "box__statusCell")}>
                                        종류
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "box__pointCell", "box__hidden")}>
                                        기존 포인트
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "box__pointCell")}>
                                        변경 포인트
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "box__pointCell")}>
                                        남은 포인트
                                    </div>
                                </div>
                                {props.data.pointHistory.list.map((value, index) => {
                                    return (
                                        <div className={cx("side-contentsColumn")} key={index}>
                                            <div className={cx("contentsColumn-bottomValue", "box__dateCell")}>
                                                {moment(value.createdAt).format('YYYY년 MM월 DD일 hh:mm a')}
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "box__statusCell")}>
                                                <BadgeMyPageInfoBalanceTemp1 value={value.receiptType}/>
                                            </div>
                                            <div
                                                className={cx("contentsColumn-bottomValue", "box__pointCell", "box__hidden")}>
                                                <NumberFormat
                                                    thousandSeparator
                                                    displayType='text'
                                                    suffix={' P'}
                                                    value={value.prevPoint}/>
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "box__pointCell")}>
                                                <NumberFormat
                                                    thousandSeparator
                                                    displayType='text'
                                                    suffix={' P'}
                                                    value={value.changedPoint}/>
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "box__pointCell")}>
                                                <NumberFormat
                                                    thousandSeparator
                                                    displayType='text'
                                                    suffix={' P'}
                                                    value={value.point}/>
                                            </div>
                                        </div>
                                    )
                                })}
                                <PagerTemp2
                                    page={props.data.pointHistory.page}
                                    size={props.data.pointHistory.size}
                                    total={props.data.pointHistory.total}
                                    onChange={props.onChangePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </FadeInContainerTemp1>
    )
}
