import React from "react";
import {gql, useQuery} from "@apollo/client";
import {Board} from "../../graphql/types";
import {ViewTemplateSelector} from "../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../environment/interfaces";
import {IndexDefault} from "./default/IndexDefault";
import {IndexTemp1} from "./temp1/IndexTemp1";
import {BizNavTransparent} from "../shares/default/bizNavTransparent/BizNavTransparent";
import {IndexPopup} from "../shares/default/popup/IndexPopup";
import {IndexZsms} from "./zsms/IndexZsms";

export const Index = () => {
    const {data} = useQuery<{
        popupNotice: Board[]
    }>(gqlPopupNotice);

    return (
        <>
            {data &&
            <BizNavTransparent>
                <ViewTemplateSelector view={{
                    [ViewTemplate.default]:
                        <IndexDefault/>,
                    [ViewTemplate.temp1]:
                        <IndexTemp1/>,
                    [ViewTemplate.zsms]:
                        <IndexZsms/>,
                }}/>
                <IndexPopup
                    value={data.popupNotice}/>
            </BizNavTransparent>
            }
        </>
    );
}

const gqlPopupNotice = gql`
    query PopupNotice {
        popupNotice {
            id
            title
            content
            bgColor
            createdAt
        }
    }
`;
