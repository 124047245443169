import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizNoDataTable.module.scss';

const cx = classNamesBind.bind(styles);

export const BizNoDataTable = () => {
    return (
        <div className={cx('table')}>
            <div style={{width: '100%'}}>데이터가 없습니다.</div>
        </div>
    )
}
