import React, {Fragment, useState} from "react";
import {useSelector} from "react-redux";
import {SystemState} from "../../../../../redux/System";

export const LayoutDefaultLogo = () => {
    const system = useSelector<any, SystemState>(state => state.system);
    const [notFound, setNotFound] = useState(false);
    return (
        <Fragment>

            {!notFound ?
                <img
                    alt={system.init.appNm}
                    style={{height: 90}}
                    src={`/logo/${system.init.appNm}.png`}
                    onError={ev => {
                        setNotFound(true);
                    }}/>
                :
                system.init.appNm
            }

        </Fragment>
    )
}
