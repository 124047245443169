import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngRechargeCommissionDefault.module.scss'
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {OnChangeValue} from "../../../interfaces";
import moment from "moment";
import {Pager} from "../../../shares/default/pager/Pager";
import {MngLayout} from "../../layout/MngLayout";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import Decimal from "decimal.js";
import {
    MutationRequestRechargeCommissionSettleArgs,
    Query,
    SettleCommissionType
} from "../../../../graphql/types";
import {BizNoDataTable} from "../../../shares/default/bizNoDataTable/BizNoDataTable";
import {BizModal} from "../../../shares/default/bizModal/BizModal";
import {RechargeCommissionDefaultSettleModal} from "./settleModal/RechargeCommissionDefaultSettleModal";
import {BadgeRechargeCommissionSettleRequestStatus} from "../../../shares/default/badgeRechargeCommissionSettleRequestStatus/BadgeRechargeCommissionSettleRequestStatus";

const cx = classNamesBind.bind(styles);

export const MngRechargeCommissionDefault = (props: {
    data?: Query,
    onChangePage: OnChangeValue<number>,
    onChangeRequestPage: OnChangeValue<number>,
    onRequestSettle: OnChangeValue<MutationRequestRechargeCommissionSettleArgs>,
}) => {
    const [settleModal, setSettleModal] = useState(false);

    return (
        <MngLayout title={'대리점관리'}>
            {props.data &&
            <FadeIn>
                <BizModal
                    title={'정산 방식을 선택하여 주십시오.'}
                    size={'lg'}
                    show={settleModal}
                    onChangeShow={setSettleModal}>
                    <RechargeCommissionDefaultSettleModal
                        onRequestSettle={value => {
                            setSettleModal(false);
                            props.onRequestSettle(value);
                        }}/>
                </BizModal>


                <BizTitle>충전 커미션</BizTitle>
                <div className={cx('table-title')}>합계</div>
                <div className={cx('table')}>
                    <div style={{width: '33%'}}>전체</div>
                    <div style={{width: '33%'}}>지급완료</div>
                    <div style={{width: '34%'}}>미지급</div>
                </div>

                <div className={cx('table')}>
                    <div style={{width: '33%'}}>{Pipe.toSeparatorNumber(props.data.totalRechargeCommission.total)} 원
                    </div>
                    <div style={{width: '33%'}}>{Pipe.toSeparatorNumber(props.data.totalRechargeCommission.paid)} 원
                    </div>
                    <div style={{width: '34%'}}>{Pipe.toSeparatorNumber(props.data.totalRechargeCommission.notPaid)} 원
                    </div>
                </div>

                <div className={cx('table')}>
                    <div className='text-right' style={{width: '100%'}}>
                        <button
                            className='btn btn-outline-success btn-sm'
                            onClick={() => {
                                setSettleModal(true);
                            }}>정산요청
                        </button>
                    </div>
                </div>

                <div className={cx('table-title')}>정산요청 목록</div>
                <div className={cx('table')}>
                    <div style={{width: '20%'}}>날짜</div>
                    <div style={{width: '20%'}}>상태</div>
                    <div style={{width: '20%'}}>지불종류</div>
                    <div style={{width: '20%'}}>커미션 수</div>
                    <div style={{width: '20%'}}>커미션 합</div>
                </div>
                {props.data.rechargeCommissionSettleRequestList.list.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div style={{width: '20%'}}>{moment(value.createdAt).format('MM.DD - hh:mm a')}</div>
                        <div style={{width: '20%'}}>
                            <BadgeRechargeCommissionSettleRequestStatus
                                status={value.status}/>
                        </div>
                        <div style={{width: '20%'}}>
                            {value.commissionType === SettleCommissionType.ChargePoint && "포인트 정산"}
                            {value.commissionType === SettleCommissionType.Withdraw && "입금 정산"}
                        </div>
                        <div className='text-right'
                             style={{width: '20%'}}>{Pipe.toSeparatorNumber(value.commissionCount)} 건
                        </div>
                        <div className='text-right'
                             style={{width: '20%'}}>{Pipe.toSeparatorNumber(value.commission)} 원
                        </div>
                    </div>
                ))}

                {props.data.rechargeCommissionSettleRequestList.total === 0 && <BizNoDataTable/>}

                <Pager
                    page={props.data.rechargeCommissionSettleRequestList.page}
                    size={props.data.rechargeCommissionSettleRequestList.size}
                    total={props.data.rechargeCommissionSettleRequestList.total}
                    onChange={props.onChangeRequestPage}/>


                <div className={cx('table-title')}>커미션 정보</div>
                <div className={cx('table')}>
                    <div className={cx("dateCell", "hiddenCell")}>날짜</div>
                    <div className={cx("badgeCell")}>정산</div>
                    <div className={cx("badgeCell", "hiddenCell")}>요청</div>
                    <div className={cx("agentCell")}>대리점</div>
                    <div className={cx("valueCell")}>충전포인트</div>
                    <div className={cx("percentCell")}>커미션비율</div>
                    <div className={cx("commissionCell")}>커미션</div>
                </div>

                {props.data.rechargeCommissionList.list.map((value, index) => {
                    return (
                        <div key={index} className={cx('table', 'hover', 'pointer')}>
                            <div className={cx("dateCell", "hiddenCell")}>
                                {moment(value.createdAt).format('YY.MM.DD')}
                            </div>
                            <div className={cx("badgeCell")}>
                                {value.isWithdrawal && <span className='badge badge-success'>지급완료</span>}
                                {!value.isWithdrawal && <span className='badge badge-secondary'>미지급</span>}
                            </div>
                            <div className={cx("badgeCell", "hiddenCell")}>
                                {value.isRequestedSettle && <span className='badge badge-success'>요청됨</span>}
                                {!value.isRequestedSettle && <span className='badge badge-secondary'>미요청</span>}
                            </div>
                            <div className={cx("agentCell")}>
                                {value.agentEmail}
                            </div>
                            <div className={cx("valueCell", "textRight")}>{Pipe.toSeparatorNumber(value.agentRechargePoint)} P
                            </div>
                            <div className={cx("percentCell", "textRight")}>{new Decimal(value.rate).mul(100).toNumber()} %
                            </div>
                            <div className={cx("commissionCell", "textRight")}>{Pipe.toSeparatorNumber(value.commission)} 원
                            </div>
                        </div>
                    )
                })}

                <Pager
                    page={props.data.rechargeCommissionList.page}
                    size={props.data.rechargeCommissionList.size}
                    total={props.data.rechargeCommissionList.total}
                    onChange={props.onChangePage}/>
            </FadeIn>
            }
        </MngLayout>
    )
}
