import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './DepositRequestInputTemp2.module.scss'
import {DepositRequestInputData} from "../../DepositRequest";
import {OnChangeValue} from "../../../../interfaces";
import NumberFormat from "react-number-format";
import Decimal from "decimal.js";
import {CopyUnit} from "../../../../../utils/copy/CopyUtil";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {Button, TextField, withStyles} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const cx = classNamesBind.bind(styles);

const ColorButton1 = withStyles(() => ({
    root: {
        color: "#fff",
        backgroundColor: "#2196f3",
        '&:hover': {
            color: "#2196f3",
            backgroundColor: "#fff",
        },
    },
}))(Button);

export const DepositRequestInputTemp2 = (props: {
    value: DepositRequestInputData,
    onChangeValue: OnChangeValue<DepositRequestInputData>,
    onRequest: OnChangeValue<DepositRequestInputData>,
}) => {
    const copyUnit = new CopyUnit(props.onChangeValue);
    const user = useSelector<any, UserState>(state => state.user);


    const onBlurInputPoint = () => {
        if (0 < props.value.point % 1000) {
            // SwalUtil.ok({
            //     msg: "최소 충전 기본단위는 1000원 입니다.",
            //     icon: "info"
            // });
            copyUnit.onChangeValue(props.value, 'point', props.value.point - (props.value.point % 1000));
        }

        if (props.value.point < 10000) {
            SwalUtil.ok({
                msg: "최소 충전 금액은 10,000 원 입니다.",
                icon: "info"
            });
            copyUnit.onChangeValue(props.value, 'point', 10000);
        }
    }


    return (
        <Fragment>
            <div className={cx('fullContainer')}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-icon")}>
                                    <MonetizationOnIcon/>
                                </div>
                                <div className={cx("titleBar-text")}>
                                    <div className={cx("text-main")}>
                                        입금확인요청
                                    </div>
                                    <div className={cx("text-sub")}>
                                        Request for payment confirmation
                                    </div>
                                </div>
                            </div>
                            <div className={cx("side-rowFlexBox")}>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader")}>
                                        입금자명
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue")}>
                                        <TextField
                                            variant="outlined"
                                            type='text'
                                            inputProps={{style: {textAlign: "center"}}}
                                            value={props.value.nm}
                                            onChange={event => {
                                                copyUnit.onChangeValue(props.value, 'nm', event.target.value);
                                            }}/>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader")}>
                                        충전포인트
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue")}>
                                        <NumberFormat
                                            thousandSeparator
                                            allowNegative={false}
                                            decimalScale={0}
                                            placeholder='충전 포인트를 입력하여 주십시오.'
                                            customInput={TextField}
                                            variant="outlined"
                                            inputProps={{style: {textAlign: "center"}}}
                                            value={props.value.point}
                                            onValueChange={value => {
                                                copyUnit.onChangeValue(props.value, 'point', value.floatValue || 0);
                                            }}
                                            onBlur={onBlurInputPoint}/>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader")}>
                                        연락처 <span style={{color: "#999999"}}>(*선택 입력 사항)</span>
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue")}>
                                        <TextField
                                            variant="outlined"
                                            type='text'
                                            inputProps={{style: {textAlign: "center"}}}
                                            value={props.value.tel}
                                            onChange={event => {
                                                copyUnit.onChangeValue(props.value, "tel", event.target.value);
                                            }}/>
                                    </div>
                                </div>
                            </div>
                            <div className={cx("side-rowFlexBox")}>
                                <fieldset className={cx("fieldset")}>
                                    <legend>최종 입금 금액</legend>
                                    <div className={cx("value")}>
                                    <div><NumberFormat
                                        thousandSeparator
                                        allowNegative={false}
                                        decimalScale={0}
                                        displayType="text"
                                        type="text"
                                        style={{marginRight: 5}}
                                        value={new Decimal(props.value.point)
                                            .times(new Decimal(user.user.tax).add(1))
                                            .toNumber()}/>원</div>
                                    </div>
                                </fieldset>
                                <div className={cx("description")}>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 최종 금액은 부가세 10%가 포함된 금액입니다.</div>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 입력하신 연락처로 입금정보를 전송 해드립니다.</div>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 연락처는 추가 입력 사항으로, 입력하지 않을 시 안내문자는 발송되지
                                        않습니다.
                                    </div>
                                    <div className={cx("button")}>
                                        <ColorButton1
                                            variant="outlined"
                                            size="large"
                                            style={{width: 130}}
                                            type="submit"
                                            onClick={() => {
                                                props.onRequest(props.value);
                                            }}>확인요청
                                        </ColorButton1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
