import {OnChangeValue} from "../../components/interfaces";

export class CopyUtil {
    static Copy<T>(list: T[]): T[] {
        const res: T[] = [];

        for (let t of list) {
            res.push(Object.assign({}, t))
        }

        return res;
    }

    static copyAll<T>(value: T): T {
        return JSON.parse(JSON.stringify(value));
    }

    static onChangeValue<T>(origin: T, key: string, value: any): T {
        const copy = CopyUtil.copyAll(origin) as any;
        copy[key] = value;
        return copy as T;
    }
}

export class CopyUnit<T> {
    setValue: OnChangeValue<T>;

    constructor(setValue: OnChangeValue<T>) {
        this.setValue = setValue
    }

    onChangeValue(origin: T, key: string, value: any) {
        const copy = CopyUtil.copyAll(origin) as any;
        copy[key] = value;
        this.setValue(copy);
    }
}
