import React from "react";
import {Pagination} from "@material-ui/lab";
import styles from "./pagerTemp1.module.scss";
import {makeStyles} from "@material-ui/core";
import classNamesBind from "classnames/bind";

const cx = classNamesBind.bind(styles);

const useStyles = makeStyles(() => ({
    ul: {
        "& .Mui-selected": {
            color: "#ffffff",
            background: "#5f8ee9",

            "&:hover": {
                background: "#0065EF",
            }
        }
    }
}));

export const PagerTemp1 = (props: {
    page: number,
    size: number,
    total: number,
    onChange: (page: number) => void
}) => {
    let count = Math.floor(props.total / props.size);
    count += props.total % props.size === 0 ? 0 : 1;

    const classes = useStyles();

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 30, marginBottom: 30
        }}
             className={cx("pagination")}>
            <Pagination
                count={count}
                page={props.page + 1}
                classes={{ul: classes.ul}}
                shape='rounded'
                onChange={(event, page) => {
                    window.scrollTo(0, 0);
                    props.onChange(page - 1);
                }}/>
        </div>
    )
}
