import React from "react";
import classNamesBind from "classnames/bind";
import styles from './MngAgentDefault.module.scss'
import {MngAgentInputData} from "../MngAgent";
import {OnChangeValue} from "../../../interfaces";
import {MngLayout} from "../../layout/MngLayout";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {Pager} from "../../../shares/default/pager/Pager";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {Switch} from "@material-ui/core";
import {Query} from "../../../../graphql/types";

const cx = classNamesBind.bind(styles);

export const MngAgentDefault = (props: {
    data?: Query,
    value: MngAgentInputData,
    onChangeValue: OnChangeValue<MngAgentInputData>,
    onChangePage: OnChangeValue<{ page: number, email: string }>,
    onChangeAgentActivation: OnChangeValue<{ userId: number, activation: boolean }>
}) => {

    const onFindAgent = () => {
        props.onChangePage({page: 0, email: props.value.email});
    }

    const onInit = () => {
        props.onChangePage({page: 0, email: ''});
    }

    return (
        <MngLayout title={'대리점관리'}>
            {props.data &&
            <FadeIn>
                <BizTitle>회원 목록</BizTitle>
                <div className={cx('table')}>
                    <div className={cx("numCell", "hiddenCell")}>회원번호</div>
                    <div className={cx("activateCell")}>활성화</div>
                    <div className={cx("userCell")}>유저</div>
                    <div className={cx("valueCell")}>잔고</div>
                </div>

                {props.data.agentList.list.length === 0 &&
                <div className={cx('table')}>
                    <div style={{flexGrow: 1}}>등록된 대리점이 없습니다.</div>
                </div>
                }

                {props.data.agentList.list.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div className={cx("numCell", "hiddenCell")}>
                            {value.id}
                        </div>
                        <div className={cx("activateCell")}>
                            <Switch
                                color='primary'
                                checked={value.isActivate}
                                onChange={event => {
                                    props.onChangeAgentActivation({userId: value.id, activation: event.target.checked});
                                }}/>
                        </div>
                        <div className={cx("userCell")}>
                            {value.email}
                        </div>
                        <div className={cx("valueCell")}>
                            {Pipe.toSeparatorNumber(value.point)} P
                        </div>
                    </div>

                ))}

                <div className={cx('cont-finder')}>
                    <input
                        value={props.value.email}
                        className='form-control form-control-sm'
                        placeholder='이메일을 입력하여 주십시오.'
                        onChange={event => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.email = event.target.value;
                            props.onChangeValue(copy);
                        }}
                        onKeyUp={event => {
                            if (event.key === 'Enter') {
                                onFindAgent();
                            }
                        }}/>
                    <button
                        style={{marginRight: 10}}
                        className='btn btn-sm btn-success'
                        onClick={onFindAgent}>찾기
                    </button>
                    <button
                        className='btn btn-sm btn-outline-success'
                        onClick={onInit}>
                        초기화
                    </button>
                </div>

                <Pager
                    page={props.data.agentList.page}
                    size={props.data.agentList.size}
                    total={props.data.agentList.total}
                    onChange={page => {
                        props.onChangePage({page, email: props.value.email});
                    }}/>
            </FadeIn>
            }
        </MngLayout>
    )
}
