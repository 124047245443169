import React, {ReactNode} from 'react';
import {useSelector} from "react-redux";
import {UserState} from "../../../redux/User";
import {CommissionPermission} from "../../../graphql/types";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {MngLayoutDefault} from "./default/MngLayoutDefault";
import {MngLayoutTemp1} from "./temp1/MngLayoutTemp1";
import {MngLayoutTemp2} from './temp2/MngLayoutTemp2';

export const MngLayout = (props: {
    title: ReactNode,
    children: ReactNode
}) => {
    const user = useSelector<any, UserState>(state => state.user);

    const menu: MngLayoutMenu[] = [
        {title: '코드관리', router: '/mng/code', key: 'code'},
        {title: '회원목록', router: '/mng/agent', key: 'agent'}
    ]

    if (user.user.commissionPermission) {
        if (user.user.commissionPermission.includes(CommissionPermission.Send)) {
            menu.push({title: '전송 커미션', router: '/mng/send', key: 'send'})


        }

        if (user.user.commissionPermission.includes(CommissionPermission.Recharge)) {
            menu.push({title: '충전 커미션', router: '/mng/recharge', key: 'recharge'})
        }
    }

    return (
        <ViewTemplateSelector
            view={{
                [ViewTemplate.default]:
                    <MngLayoutDefault
                        title={props.title}
                        menu={menu} children={props.children}/>,
                [ViewTemplate.temp1]:
                    <MngLayoutTemp1
                        menu={menu} children={props.children}/>,
                [ViewTemplate.temp2]:
                    <MngLayoutTemp2
                        menu={menu} children={props.children}/>,
                [ViewTemplate.zsms]:
                    <MngLayoutTemp1
                        menu={menu} children={props.children}/>,
            }}/>
    )
}

export interface MngLayoutMenu {
    title: string;
    router: string;
    key: string;
}
