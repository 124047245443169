import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './CsListTemp1.module.scss';
import {OnChangeValue} from "../../../interfaces";
import {MutationCreateUserChatArgs, Query} from "../../../../graphql/types";
import {CsListTemp1UserAlertList} from "./userAlertList/CsListTemp1UserAlertList";
import {CsListTemp1Chat} from "./chat/CsListTemp1Chat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

const cx = classNamesBind.bind(styles);

export const CsListTemp1 = (props: {
    value: Query,
    onChangeUserAlertPage: OnChangeValue<number>,
    onCreateUserChat: OnChangeValue<MutationCreateUserChatArgs>
}) => {
    return (
        <>
            <div className={cx("fullContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-left")}>
                                    고객센터
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={cx("secondSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-rowBox")}>
                            <div className={cx("side-flexBox", "marginRight")}>
                                <div className={cx("side-title")}>
                                    <div className={cx("title-left")} style={{width: "100%"}}>
                                        <FontAwesomeIcon icon={faChevronRight} style={{paddingTop: 3, marginRight: 5}}/> 관리자 문의
                                    </div>
                                </div>
                                <CsListTemp1Chat {...props}/>
                            </div>
                            <div className={cx("side-flexBox", "marginTop")}>
                                <div className={cx("side-title")}>
                                    <div className={cx("title-left")} style={{width: "100%"}}>
                                        <FontAwesomeIcon icon={faChevronRight} style={{paddingTop: 3, marginRight: 5}}/> 알림 내역
                                    </div>
                                </div>
                                <CsListTemp1UserAlertList {...props}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
