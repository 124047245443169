import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './LayoutUserAlertDefault.module.scss';
import {MutationNotifiedUserAlertArgs, UserAlert} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../interfaces";
import {Alert} from "@material-ui/lab";
import {Collapse, IconButton} from "@material-ui/core";
import {Close} from '@material-ui/icons';

const cx = classNamesBind.bind(styles);

export const LayoutUserAlertDefault = (props: {
    userAlert: UserAlert[],
    onNotifiedUserAlert: OnChangeValue<MutationNotifiedUserAlertArgs>
}) => {
    return (
        <Fragment>
            <div className={cx('cont')}>
                <Collapse in={props.userAlert.length !== 0}>
                    {props.userAlert.map((value, index) => (
                        <Fragment key={index}>
                            <Alert
                                className={cx('cont-alert')}
                                color={value.alertType.toLocaleLowerCase() as any}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            props.onNotifiedUserAlert({userAlertId: value.id});
                                        }}>
                                        <Close fontSize="inherit"/>
                                    </IconButton>
                                }>
                                <div style={{fontSize: '1.1rem'}}>
                                    {value.content}
                                </div>
                            </Alert>
                            <div style={{height: 20}}/>
                        </Fragment>
                    ))}
                </Collapse>
            </div>
        </Fragment>
    )
}
