import React, {useEffect, useState} from "react";
import {SendBulkInputData} from "../SmsBulk";
import {OnChangeValue, OnVoidFunction} from "../../../interfaces";
import {HashedApiKey, Query, SendBulkSms, SendTestSms} from "../../../../graphql/types";
import {useSelector} from "react-redux";
import {UserState} from "../../../../redux/User";
import {NumberUtil} from "../../../../utils/numbUtil/NumbUtil";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {ProgressBar} from "react-bootstrap";
import Decimal from "decimal.js";
import classNamesBind from "classnames/bind";
import 'react-perfect-scrollbar/dist/css/styles.css';
import styles from "./SmsBulkTemp1.module.scss";
import {BizModal} from "../../../shares/default/bizModal/BizModal";
import {SmsBulkTemp1ConfirmModal} from "./confirmModal/SmsBulkTemp1ConfirmModal";
import {SmsBulkTemp1CharInputPopover} from "./charInputPopover/SmsBulkTemp1CharInputPopover";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {Button, TextField} from "@material-ui/core";
import {SmsBulkTemp1SelectApiKeyModal} from "./selectApiKeyModal/SmsBulkTemp1SelectApiKeyModal";
import {navigate} from "hookrouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {SystemState} from "../../../../redux/System";

const cx = classNamesBind.bind(styles);

export const SmsBulkTemp1 = (props: {
    value: SendBulkInputData,
    onChangeValue: OnChangeValue<SendBulkInputData>,
    data: Query,
    onClearNumb: OnVoidFunction,
    onClearEmoji: OnVoidFunction,
    onSendBulk: OnChangeValue<SendBulkSms>
    onSendTest: OnChangeValue<SendTestSms>
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const system = useSelector<any, SystemState>(state => state.system);
    const [apiPermission, setApiPermission] = useState<HashedApiKey | undefined>
    (user.user.apiPermission.length !== 0 ? user.user.apiPermission[0] : undefined);
    const [confirmModal, setConfirmModal] = useState(false);
    const [apiKeySelectModal, setApiKeySelectModal] = useState(false);

    const onSendBulk = () => {
        if (!apiPermission) {
            return;
        }

        props.onSendBulk({
            hashedApiKey: apiPermission.hashedApiKey,
            msg: props.value.msg.msg,
            numberList: NumberUtil.appendNationNumber
            ('82', NumberUtil.splitNumb(`${props.value.numb.rawNumb}\n${props.value.numb.rawAppendNumb}`))
        })

        setConfirmModal(false);
    }

    const onClickHistory = (msg: string) => {
        const copy = CopyUtil.copyAll(props.value);
        copy.msg.msg = msg;
        props.onChangeValue(copy);
    }

    const onCancel = () => {
        setConfirmModal(false);
    }

    const onAppendTestNumber = (testNumber: string) => {
        let numberList = NumberUtil.splitNumb(`${props.value.numb.rawAppendNumb}\n${props.value.numb.rawNumb}`)
        if (NumberUtil.hasOwnNumber(numberList, testNumber)) {
            SwalUtil.ok({
                msg: `이미 추가되어 있습니다.`,
                icon: 'warning'
            })
            return;
        }

        numberList = NumberUtil.exceptWrongContext(numberList);
        numberList.push(testNumber);

        const copy = CopyUtil.copyAll(props.value);
        copy.numb.rawAppendNumb = NumberUtil.toRawData(numberList);
        props.onChangeValue(copy);
    }

    const onClickAppendAllTestNumber = () => {
        let numberList = NumberUtil.splitNumb(props.value.numb.rawAppendNumb);
        for (let testNumber of props.data.testNumberList) {
            if (NumberUtil.hasOwnNumber(numberList, testNumber.number)) {
                continue;
            }

            numberList = NumberUtil.exceptWrongContext(numberList);
            numberList.push(testNumber.number);
        }

        const copy = CopyUtil.copyAll(props.value);
        copy.numb.rawAppendNumb = NumberUtil.toRawData(numberList);
        props.onChangeValue(copy);
    }

    return (
        <>
            <BizModal
                title={'전송 확인'}
                show={confirmModal}
                onChangeShow={setConfirmModal}>
                {apiPermission &&
                <SmsBulkTemp1ConfirmModal
                    {...props}
                    onCancel={onCancel}
                    onSendBulk={onSendBulk}
                    hashedApiKey={apiPermission}/>
                }
            </BizModal>

            <BizModal
                title={'회선 선택'}
                show={apiKeySelectModal}
                onChangeShow={setApiKeySelectModal}
                size='lg'>
                <SmsBulkTemp1SelectApiKeyModal
                    onChangeApiKey={value => {
                        setApiPermission(value);
                        setApiKeySelectModal(false);
                    }}/>
            </BizModal>

            {/*<BizModal*/}
            {/*    title={'테스트 확인'}*/}
            {/*    show={telReportModal}*/}
            {/*    onChangeShow={setTelReportModal}>*/}
            {/*    <SmsBulkTemp1TelReceiverReportModal*/}
            {/*        query={props.data}/>*/}
            {/*</BizModal>*/}

            <div className={cx("fullContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-left")}>
                                    {user.user.email} 님
                                </div>
                                <div className={cx("titleBar-right")}>
                                    <div className={cx("right-cell")}>
                                        <div className={cx("cell-titleText")}>
                                            소지 포인트
                                        </div>
                                        <div className={cx("cell-valueText")}>
                                            {Pipe.toSeparatorNumber(user.user.point)} P
                                        </div>
                                    </div>
                                    <div className={cx("right-cell")}>
                                        <div className={cx("cell-titleText")}>
                                            건 당 가격
                                        </div>
                                        <div className={cx("cell-valueText")}>
                                            {user.user.apiPermission.map((value, index) => {
                                                const isSelected = apiPermission && apiPermission.hashedApiKey === value.hashedApiKey

                                                return (
                                                    <div className={cx("rightCell__valueText")} key={index}>
                                                        {
                                                            isSelected &&
                                                            <div>
                                                                {value.smsFee} P
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className={cx("right-cell")}>
                                        <div className={cx("cell-titleText")}>
                                            발송가능 갯수
                                        </div>
                                        <div className={cx("cell-valueText")}>
                                            {user.user.apiPermission.map((value, index) => {
                                                const isSelected = apiPermission && apiPermission.hashedApiKey === value.hashedApiKey

                                                return (
                                                    <div className={cx("rightCell__valueText")} key={index}>
                                                        {
                                                            isSelected &&
                                                            <div>
                                                                {Pipe.toSeparatorNumber(new Decimal(user.user.point).divToInt(value.smsFee).toNumber())} 건
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className={cx("right-cell")}>
                                        <Button
                                            classes={{root: cx("buttonOne")}}
                                            variant="outlined"
                                            style={{fontSize: 12}}
                                            fullWidth
                                            onClick={event => {
                                                navigate("/deposit/request")
                                            }}>입금확인요청
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx("secondSection")} style={{marginTop: 15}}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-title")}>
                                <div className={cx("title-left")} style={{width: "100%"}}>
                                    <FontAwesomeIcon icon={faChevronRight} style={{paddingTop: 3, marginRight: 5}}/> 최근
                                    전송 정보
                                </div>
                            </div>
                            <div className={cx("side-contentsColumn", "borderBottom")}>
                                <div className={cx("contentsColumn-topHeader", "dateCell")}>
                                    전송일자
                                </div>
                                <div className={cx("contentsColumn-topHeader", "descCell")}>
                                    내용
                                </div>
                                <div className={cx("contentsColumn-topHeader", "valueCell")}>
                                    전송갯수
                                </div>
                            </div>
                            <PerfectScrollbar style={{width: "100%", height: "100%"}}>
                                {props.data.msgReceipt.list.map((value, index) => (
                                    <div className={cx("side-contentsColumn", "hover")} key={index} onClick={event => {
                                        onClickHistory(value.contents);
                                    }}>
                                        <div className={cx("contentsColumn-bottomValue", "dateCell")}>
                                            {moment(value.createdAt).format('MM.DD/hh:mm(a)')}
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "descCell")}>
                                            {value.contents.substring(0, 9)}...
                                        </div>
                                        <div
                                            className={cx("contentsColumn-bottomValue", "valueCell", "hiddenCell")}>{Pipe.toSeparatorNumber(value.sent)} 개
                                        </div>
                                    </div>
                                ))}

                                {props.data.msgReceipt.list.length === 0 &&
                                <div className={cx("side-contentsColumn", "empty")}>
                                    최근 전송 데이터가 없습니다.
                                </div>
                                }
                            </PerfectScrollbar>
                        </div>
                    </div>
                    <div className={cx("section-side", "secondSection-side")}>
                        <div className={cx("side-rowBox")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-title")}>
                                    <div className={cx("title-left")}>
                                        <FontAwesomeIcon icon={faChevronRight}
                                                         style={{paddingTop: 3, marginRight: 5}}/> 문자메세지
                                    </div>
                                    <div className={cx("title-right")}/>
                                </div>
                                <div className={cx("side-contentsColumn", "borderBottom")}>
                                    <div className={cx("contentsColumn-topBox")}>
                                        <div className={cx("topBox-textField")}>
                                            문자메세지 입력
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={14}
                                                value={props.value.msg.msg}
                                                placeholder='문자 메시지 입력'
                                                onChange={event => {
                                                    const copy = CopyUtil.copyAll(props.value);
                                                    copy.msg.msg = event.target.value;
                                                    props.onChangeValue(copy);
                                                }}/>
                                        </div>
                                        <div className={cx("topBox-bottom")}>
                                            <div className={cx("bottom-left")}>
                                                <div className={cx("topBox-progressBox")}>
                                                    <div className={cx("progressBox__left")}>
                                                        글자 수
                                                    </div>
                                                    <div className={cx("progressBox__right")}>
                                                        ({props.value.msg.msg.length} / {props.value.msg.maxLength})
                                                    </div>
                                                </div>
                                                <ProgressBar
                                                    variant={props.value.msg.msg.length < props.value.msg.maxLength ? '' : 'danger'}
                                                    now={props.value.msg.msg.length / props.value.msg.maxLength * 100}/>
                                            </div>
                                            <div className={cx("bottom-right")}>
                                                <Button
                                                    classes={{root: cx("buttonOne")}}
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={props.onClearEmoji}>이모지 정리
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")} style={{margin: "20px 0"}}>
                                    <Button
                                        classes={{root: cx("buttonTwo")}}
                                        variant="outlined"
                                        fullWidth
                                        style={{marginRight: 5}}
                                        size='small' onClick={ev => {
                                        window.open('https://app.abit.ly/', '_blank')
                                    }}>
                                        주소 줄이기
                                    </Button>
                                    <SmsBulkTemp1CharInputPopover {...props}/>
                                </div>
                            </div>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-title")}>
                                    <div className={cx("title-left")}>
                                        <FontAwesomeIcon icon={faChevronRight}
                                                         style={{paddingTop: 3, marginRight: 5}}/> 전화번호
                                    </div>
                                    <div className={cx("title-right")}/>
                                </div>
                                <div className={cx("side-contentsColumn", "borderBottom")}>
                                    <div className={cx("contentsColumn-topBox")}>
                                        <div className={cx("topBox-textField")}>
                                            전화번호 입력
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={5}
                                                value={props.value.numb.rawNumb}
                                                placeholder='전화번호 입력'
                                                onChange={event => {
                                                    const copy = CopyUtil.copyAll(props.value);
                                                    copy.numb.rawNumb = event.target.value;
                                                    props.onChangeValue(copy);
                                                }}/>
                                            <div style={{height: 18}}/>
                                            고정 전화번호 입력
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={5}
                                                value={props.value.numb.rawAppendNumb}
                                                placeholder='고정 전화번호 입력'
                                                onChange={event => {
                                                    const copy = CopyUtil.copyAll(props.value);
                                                    copy.numb.rawAppendNumb = event.target.value;
                                                    props.onChangeValue(copy);
                                                }}/>
                                        </div>
                                        <div className={cx("topBox-bottom")}>
                                            <div className={cx("bottom-left")}>
                                                <div className={cx("topBox-progressBox")}>
                                                    <div className={cx("progressBox__left")}>
                                                        전화번호 수
                                                    </div>
                                                    <div className={cx("progressBox__right")}>
                                                        ({props.value.numb.numbLength} / {props.value.numb.maxLength})
                                                    </div>
                                                </div>
                                                <ProgressBar
                                                    variant={props.value.numb.numbLength < props.value.numb.maxLength ? '' : 'danger'}
                                                    now={props.value.numb.numbLength / props.value.numb.maxLength * 100}/>
                                            </div>
                                            <div className={cx("bottom-right")}>
                                                <Button
                                                    classes={{root: cx("buttonOne")}}
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={props.onClearNumb}>전화번호 정리
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")} style={{margin: "20px 0"}}>
                                    {props.data.testNumberList.map((value, index) => {
                                        const list = NumberUtil.splitNumb(props.value.numb.rawNumb);
                                        const hasNumber = NumberUtil.hasOwnNumber(list, value.number);

                                        return <Button
                                            classes={{root: cx("buttonTwo")}}
                                            key={index}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            disabled={hasNumber}
                                            style={{marginRight: 5}}
                                            onClick={() => {
                                                onAppendTestNumber(value.number);
                                            }}>{value.vendor}
                                        </Button>
                                    })}

                                    <Button
                                        classes={{root: cx("buttonTwo")}}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onClick={onClickAppendAllTestNumber}>All
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className={cx("side-rowBox")} style={{marginTop: 15}}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-title")}>
                                    <div className={cx("title-left")}>
                                        <FontAwesomeIcon icon={faChevronRight}
                                                         style={{paddingTop: 3, marginRight: 5}}/> 주의사항
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn", "aboutBox")}>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 최대 5,000개의 번호를 한 번에 전송할 수 있습니다.
                                    </div>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 중복된 전화번호는 자동으로 1번만 발송됩니다.</div>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 이모지 사용시 수신율이 떨어질 수 있습니다.</div>
                                </div>
                            </div>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-title")}>
                                    <div className={cx("title-left")}>
                                        <FontAwesomeIcon icon={faChevronRight}
                                                         style={{paddingTop: 3, marginRight: 5}}/> 전송하기
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")} style={{height: "100%", minHeight: 90}}>
                                    <Button
                                        classes={{root: cx("buttonOne")}}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!apiPermission}
                                        onClick={() => {
                                            setConfirmModal(true);
                                        }}>전송
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
