import {FbJoinType} from "../../graphql/types";

export class LocalStorage {
    static loginTypeKey = 'loginType';
    static apiKey = 'api-token'

    static setSession(token: string) {
        localStorage.setItem(this.apiKey, token);
    }

    static getSession(): string {
        return localStorage.getItem(this.apiKey) || '';
    }

    static clearSession() {
        localStorage.removeItem(this.apiKey);
    }

    static setLangCode(langCode: string) {
        localStorage.setItem("langCode", langCode);
    }

    static getLangCode(): string {
        return localStorage.getItem("langCode") || 'en';
    }

    static getLoginType(): FbJoinType {
        const loginType = localStorage.getItem("loginType");
        switch (loginType) {
            case 'Email':
                return FbJoinType.Email;
            case 'Phone':
                return FbJoinType.Phone;
            default:
                return FbJoinType.Email;
        }
    }

    static setLoginType(value: FbJoinType) {
        localStorage.setItem("loginType", value);
    }
}
