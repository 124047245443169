import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface MobileState {
    showSidebar: boolean
}

const initState: MobileState = {
    showSidebar: false,
}

class Mobile extends ImmerReducer<typeof initState> {
    setSidebar(swt: boolean) {
        this.draftState.showSidebar = swt;
    }
}


export const MobileAction = createActionCreators(Mobile);
export const MobileReducer = createReducerFunction(Mobile, initState);

