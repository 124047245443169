import React, { useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngAgentWithSmsFeeTemp1UpdateAgentModal.module.scss'
import {Agent, MutationUpdateAgentApiPermissionArgs, UpdateAgentApiPermission} from "../../../../../graphql/types";
import {Pipe} from "../../../../../utils/pipe/Pipe";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import {Button, TextField} from "@material-ui/core";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import NumberFormat from "react-number-format";
import Decimal from "decimal.js";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {OnChangeValue} from "../../../../interfaces";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {BadgeMngAgentWithSmsFeeTemp1} from "../../../../shares/temp1/badgeMngAgentWithSmsFee/BadgeMngAgentWithSmsFeeTemp1";

const cx = classNamesBind.bind(styles);

export const MngAgentWithSmsFeeTemp1UpdateAgentModal = (props: {
    agent: Agent,
    onUpdateAgentApiPermission: OnChangeValue<MutationUpdateAgentApiPermissionArgs>
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const [input, setInput] = useState<Input[]>([]);

    useEffect(() => {
        const nextInputList: Input[] = [];
        for (let userApiPermission of user.user.apiPermission) {
            const appendInput: Input = {
                isActivate: false,
                smsFee: 0,
                nm: userApiPermission.nm,
                hashedApiKey: userApiPermission.hashedApiKey,
                defaultSmsFee: userApiPermission.smsFee,
            }

            const agentApiPermission = props.agent.apiPermission.find(value => {
                return value.hashedApiKey === userApiPermission.hashedApiKey
            });

            if (agentApiPermission) {
                appendInput.isActivate = true;
                appendInput.smsFee = agentApiPermission.smsFee
            }

            nextInputList.push(appendInput);
        }

        setInput(nextInputList);
    }, [user.user.apiPermission]);

    const handler = {
        onUpdateAgentApiPermission: () => {
            if (!handler.onValidation()) {
                return;
            }

            props.onUpdateAgentApiPermission({
                userId: props.agent.id,
                updateAgentPermissionList: handler.onCreateArgs(),
            });
        },
        onCreateArgs: (): UpdateAgentApiPermission[] => {
            const list: UpdateAgentApiPermission[] = [];

            for (let elem of input) {
                if (!elem.isActivate) {
                    continue;
                }

                if (new Decimal(elem.smsFee).lt(elem.defaultSmsFee)) {
                    continue;
                }

                list.push({
                    hashedApiKey: elem.hashedApiKey,
                    smsFee: elem.smsFee
                })
            }

            return list;
        },
        onValidation: (): boolean => {
            let hasActivation = false;

            for (let elem of input) {
                if (elem.isActivate) {
                    hasActivation = true;
                }

                if (new Decimal(elem.smsFee).lt(elem.defaultSmsFee)) {
                    SwalUtil.ok({
                        msg: '대리점 회원의 건당가격이 리셀러 회원보다 낮을수 없습니다.',
                        icon: 'error'
                    });
                    return false;
                }
            }

            if (!hasActivation) {
                SwalUtil.ok({
                    msg: '최소 1개 이상의 회선을 활성화 하여 주십시오.',
                    icon: 'error',
                })
            }

            return hasActivation;
        },
    }


    return (
        <>
            <div className={cx("modalContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("side-title")}>
                                    <div className={cx("title-left")}>
                                        <FontAwesomeIcon icon={faChevronRight}
                                                         style={{paddingTop: 3, marginRight: 5}}/> 회원 정보 수정
                                    </div>
                                </div>
                            </div>
                            <div className={cx("side-contentsColumn", "borderBottom")}>
                                <div className={cx("contentColumn-leftHeader", "userHeader")}>
                                    회원번호
                                </div>
                                <div className={cx("contentColumn-rightValue", "userValue")}>
                                    {props.agent.id}
                                </div>
                            </div>
                            <div className={cx("side-contentsColumn", "borderBottom")}>
                                <div className={cx("contentColumn-leftHeader", "userHeader")}>
                                    유저 아이디
                                </div>
                                <div className={cx("contentColumn-rightValue", "userValue")}>
                                    {props.agent.email}
                                </div>
                            </div>
                            <div className={cx("side-contentsColumn", "borderBottom")}>
                                <div className={cx("contentColumn-leftHeader", "userHeader")}>
                                    상태
                                </div>
                                <div className={cx("contentColumn-rightValue", "userValue")}>
                                    <BadgeMngAgentWithSmsFeeTemp1 isActivate={props.agent.isActivate}/>
                                </div>
                            </div>
                            <div className={cx("side-contentsColumn", "borderBottom")}>
                                <div className={cx("contentColumn-leftHeader", "userHeader")}>
                                    잔고
                                </div>
                                <div className={cx("contentColumn-rightValue", "userValue")}>
                                    {Pipe.toSeparatorNumber(props.agent.point)} P
                                </div>
                            </div>
                            {input.map((value, index) => (
                                <>
                                    <div className={cx("side-contentsColumn", "borderBottom")}>
                                        <div className={cx("contentColumn-leftHeader", "userHeader")}>
                                            건당 가격
                                        </div>
                                        <div className={cx("contentColumn-rightValue", "userValue")}>
                                            {value.defaultSmsFee} 원
                                        </div>
                                    </div>
                                    <div className={cx("side-contentsColumn", "borderBottom", "marginBottom")}>
                                        <div className={cx("contentColumn-leftHeader", "userHeader")}>
                                            대리점 가격
                                        </div>
                                        <div className={cx("contentColumn-rightValue", "userValue")}>
                                            <NumberFormat
                                                thousandSeparator
                                                decimalScale={1}
                                                allowNegative={false}
                                                suffix={' 원'}
                                                value={value.smsFee}
                                                customInput={TextField}
                                                variant="outlined"
                                                inputProps={{style: {textAlign: "center", fontSize: "14px", padding: "7px 0"}}}
                                                onValueChange={values => {
                                                    const copy = CopyUtil.copyAll(input);
                                                    copy[index].smsFee = values.floatValue || 0;
                                                    setInput(copy);
                                                }}/>
                                        </div>
                                    </div>
                                </>
                            ))}
                            <div className={cx("side-contentsColumn", "buttonBox")}>
                                <Button
                                    classes={{root: cx("buttonOne")}}
                                    variant="outlined"
                                    onClick={handler.onUpdateAgentApiPermission}>적용
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

type Input = (UpdateAgentApiPermission & { isActivate: boolean, defaultSmsFee: number, nm: string })