import React, {Fragment, ReactNode, useEffect} from "react";
import {LayoutAction} from "../../../../redux/Layout";
import {useDispatch} from "react-redux";

export const BizNavTransparent = (props: {
    children?: ReactNode
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LayoutAction.setAppBarTransparent(true));
        dispatch(LayoutAction.setTopMargin(false));
        return () => {
            dispatch(LayoutAction.setAppBarTransparent(false));
            dispatch(LayoutAction.setTopMargin(true));
        }
    }, [])

    return (
        <Fragment>
            {props.children}
        </Fragment>
    )
}
