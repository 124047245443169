import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ResultTelBookDefaultChart.module.scss'
import {ResultTelbookResp} from "../../ResultTelBook";
import {BizTitle} from "../../../../shares/default/bizTitle/BizTitle";
import {BizContainer} from "../../../../shares/default/bizContainer/BizContainer";
import {ResultTelbookChart} from "../../share/ResultTelbookChart";
import {Pipe} from "../../../../../utils/pipe/Pipe";

const cx = classNamesBind.bind(styles);

export const ResultTelBookDefaultChart = (props: {
    data: ResultTelbookResp,
}) => {
    const [result, setResult] = useState<Result>({
        delivered: 0, fail: 0, requested: 0, sent: 0, undelivered: 0
    });

    useEffect(() => {
        const nextResult: Result = {
            delivered: 0, fail: 0, requested: 0, sent: 0, undelivered: 0
        }

        nextResult.requested = props.data.telbook.requestTotal;

        for (let telNumber of props.data.telbook.numberList) {
            switch (telNumber.status) {
                case "Sent":
                    nextResult.sent += 1;
                    break;
                case "Fail":
                    nextResult.fail += 1;
                    break;
                case "DlrDelivered":
                    nextResult.delivered += 1;
                    break;
                case "DlrUndelivered":
                    nextResult.undelivered += 1;
                    break;
            }
        }

        setResult(nextResult);
    }, [props.data]);


    return (
        <BizContainer>
            <div className='col-12 col-lg-6'>
                <div style={{height: 40}}/>
                <ResultTelbookChart data={props.data}/>
            </div>
            <div className='col-12 col-lg-6' style={{padding: 0}}>
                <BizTitle>전송 요약</BizTitle>
                <div className={cx('table')}>
                    <div style={{width: 100}}>전송</div>
                    <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                        {Pipe.toSeparatorNumber(props.data.telbook.requestTotal)} 개
                    </div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: 100}}>완료</div>
                    <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                        {Pipe.toSeparatorNumber(props.data.telbook.sent)} 개
                    </div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: 100}}>실패</div>
                    <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                        {Pipe.toSeparatorNumber(props.data.telbook.fail)} 개
                    </div>
                </div>

                <div style={{height: 30}}/>
                <BizTitle>수신 요약</BizTitle>
                <div className={cx('table')}>
                    <div style={{width: 100}}>총 수신량</div>
                    <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                        {Pipe.toSeparatorNumber(props.data.telbook.requestTotal)} 개
                    </div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: 100}}>수신완료</div>
                    <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                        {Pipe.toSeparatorNumber(result.delivered)} 개
                    </div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: 100}}>수신결과<br />대기</div>
                    <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                        {Pipe.toSeparatorNumber(props.data.telbook.requestTotal - (result.delivered + result.undelivered))} 개
                    </div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: 100}}>수신실패</div>
                    <div className='text-right' style={{width: 'calc(100% - 100px)'}}>
                        {Pipe.toSeparatorNumber(result.undelivered)} 개
                    </div>
                </div>
            </div>
        </BizContainer>
    )
}

interface Result {
    delivered: number;
    undelivered: number;
    requested: number;
    sent: number;
    fail: number;
}
