import React, {ReactNode} from "react";
import {CreateUserData} from "../AuthJoin";
import {OnChangeValue} from "../../../interfaces";
import {FbJoinType, UserType} from "../../../../graphql/types";
import classNamesBind from "classnames/bind";
import styles from "./AuthJoinTemp1.module.scss";
import {CopyUnit, CopyUtil} from "../../../../utils/copy/CopyUtil";
import {A} from "hookrouter";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {Button, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField} from "@material-ui/core";
import {CenterContents} from "../../../shares/default/centerContents/CenterContents";

const cx = classNamesBind.bind(styles);

export const AuthJoinTemp1 = (props: {
    value: CreateUserData,
    onChangeValue: OnChangeValue<CreateUserData>,
    onCreateUserWithEmail: OnChangeValue<CreateUserData>,
    onCreateUserWithPhoneNumber: OnChangeValue<CreateUserData>,
    onVerifyCode: (code: string, userType: UserType) => void
}) => {

    return (
        <CenterContents animatedContents>
            <div className={cx('cont')}>
                <form className={cx("box-form")} onSubmit={event => {
                    event.preventDefault();
                }}>
                    <h3>Register</h3>
                    <h4>Register on the internal platform</h4>
                    <Tabs
                        value={props.value.loginType}
                        onChange={(ev, value) => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.loginType = value;
                            props.onChangeValue(copy);
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        style={{marginBottom: 15}}
                        variant="fullWidth">
                        <Tab label="이메일" value={FbJoinType.Email}/>
                        <Tab label="전화번호" value={FbJoinType.Phone}/>
                    </Tabs>

                    <TabPanel value={props.value.loginType} index={FbJoinType.Email}>
                        <JoinWithEmail {...props}/>
                    </TabPanel>
                    <TabPanel value={props.value.loginType} index={FbJoinType.Phone}>
                        <JoinWithPhoneNumber {...props}/>
                    </TabPanel>

                    <p style={{marginRight: 10, textAlign: "right", color: "#0768ac"}}>
                        <A href={'/auth/login'}>아이디가 이미 있으십니까? 로그인하기</A>
                    </p>
                </form>
            </div>
        </CenterContents>
    )
};

const TabPanel = (props: {
    children: ReactNode,
    value: FbJoinType,
    index: FbJoinType,
}) => {
    return (
        <>
            {props.value === props.index && props.children}
        </>
    );
};

const JoinWithEmail = (props: {
    value: CreateUserData,
    onChangeValue: OnChangeValue<CreateUserData>,
    onVerifyCode: (code: string, userType: UserType) => void,
    onCreateUserWithEmail: OnChangeValue<CreateUserData>
}) => {
    const copyUnit = new CopyUnit(props.onChangeValue);

    return (
        <>
            <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type='email'
                style={{marginBottom: 10}}
                placeholder='이메일을 입력하여 주십시오.'
                value={props.value.email}
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'email', event.target.value);
                }}/>

            <TextField
                label="Password"
                variant="outlined"
                fullWidth
                style={{marginBottom: 10}}
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'pw', event.target.value);
                }}
                value={props.value.pw}
                type="password"
                placeholder={'비밀번호를 입력하여 주십시오.'} required/>

            <TextField
                label="Confirm Password"
                variant="outlined"
                fullWidth
                style={{marginBottom: 10}}
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'confirm', event.target.value);
                }}
                value={props.value.confirm}
                type="password"
                placeholder={'비빌번호를 다시 입력하여 주십시오.'} required/>

            <TextField
                label="Contact"
                variant="outlined"
                fullWidth
                style={{marginBottom: 10}}
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'contact', event.target.value);
                }}
                value={props.value.contact}
                type="text"
                placeholder={'연락처를 입력해주십시오.'} required/>
            <div className={cx('info')}>* 휴대폰 번호, 카카오톡, 텔레그램 등 메신져 종류를 같이 기입하여 주십시오.</div>

            <JoinCodeVerifier {...props}/>
            <hr/>

            <Button
                classes={{root: cx("buttonOne")}}
                variant="contained" color="primary"
                size="large"
                disabled={!props.value.verified}
                type="submit"
                fullWidth
                onClick={event => {
                    props.onCreateUserWithEmail(props.value);
                }}>
                회원가입
            </Button>
        </>
    )
}

const JoinWithPhoneNumber = (props: {
    value: CreateUserData,
    onChangeValue: OnChangeValue<CreateUserData>,
    onCreateUserWithPhoneNumber: OnChangeValue<CreateUserData>,
    onVerifyCode: (code: string, userType: UserType) => void
}) => {
    const copyUnit = new CopyUnit(props.onChangeValue);

    return (
        <>
            <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                style={{marginBottom: 10}}
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'phoneNumber', event.target.value);
                }}
                value={props.value.phoneNumber}
                type="text"
                placeholder={'전화번호를 입력하여 주십시오.'} required/>

            <TextField
                label="Contact"
                variant="outlined"
                fullWidth
                style={{marginBottom: 10}}
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'contact', event.target.value);
                }}
                value={props.value.contact}
                type="text"
                placeholder={'연락처를 입력해주십시오.'} required/>
            <div className={cx('info')} style={{marginBottom: 30}}>* 휴대폰 번호, 카카오톡, 텔레그램 등 메신져 종류를 같이 기입하여 주십시오.</div>

            <JoinCodeVerifier {...props}/>
            <hr/>

            <div className={cx('info')}>* 핸드폰 번호로 인증번호가 발송됩니다. 잠시만 기다려 주십시오.</div>
            <div className={cx('info')}>* 전화번호 로그인은 비밀번호 없이 매 로그인마다 인증번호를 통해 로그인하게 됩니다.</div>
            <div className={cx('info')} style={{marginBottom: 65}}>* 인증 실패를 여러번 하시면 해당 전화번호는 회원가입이 제한됩니다.</div>

            <Button
                classes={{root: cx("buttonOne")}}
                variant="contained"
                size="large"
                disabled={!props.value.verified}
                type="submit"
                fullWidth
                onClick={event => {
                    props.onCreateUserWithPhoneNumber(props.value);
                }}>
                회원가입
            </Button>
        </>
    );
}

const JoinCodeVerifier = (props: {
    value: CreateUserData,
    onChangeValue: OnChangeValue<CreateUserData>,
    onVerifyCode: (code: string, userType: UserType) => void,
}) => {
    const copyUnit = new CopyUnit(props.onChangeValue);

    return (
        <>
            <p style={{textAlign: "center", fontSize: 16}}>Code</p>
            <div className={cx('box-code')}>
                <div style={{width: 99, marginRight: 5}}>
                    <FormControl variant="outlined" className="select">
                        <InputLabel>회원 종류</InputLabel>
                        <Select
                            value={props.value.userType.toString()}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                const copy = CopyUtil.copyAll(props.value);
                                copy.userType = Pipe.userType(event.target.value as string);
                                props.onVerifyCode(props.value.joinCode, copy.userType);
                                props.onChangeValue(copy);
                            }}
                            label="회원 종류">
                            <MenuItem value={UserType.General.toString()}>일반</MenuItem>
                            <MenuItem value={UserType.Reseller.toString()}>총판</MenuItem>
                            <MenuItem value={UserType.Agent.toString()}>대리점</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <TextField
                    label="Join Code"
                    variant="outlined"
                    fullWidth
                    type='text'
                    placeholder='회원가입코드'
                    value={props.value.joinCode}
                    onChange={event => {
                        copyUnit.onChangeValue(props.value, 'joinCode', event.target.value);
                        props.onVerifyCode(event.target.value, props.value.userType);
                    }}/>
            </div>

            <p>
                {props.value.verified && <span>* 가입 가능한 코드 입니다.</span>}
                {!props.value.verified && <span style={{color: '#d45e5e'}}>* 회원 종류와 가입코드를 확인하여 주십시오.</span>}
            </p>
        </>
    );
};
