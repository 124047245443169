import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './indexZsmsSection1.module.scss';
import {useSelector} from "react-redux";
import {SystemState} from "../../../../redux/System";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faFileDownload,
    faBroadcastTower, faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import ReactPageScroller from "react-page-scroller";
import AOS from "aos";
import "aos/dist/aos.css";
import {A} from "hookrouter";
import { BizVideoBackgroundZsms } from "../../../shares/zsms/bizVideoBackground/bizVideoBackgroundZsms";

const cx = classNamesBind.bind(styles);

export const IndexZsmsSection1 = () => {
    const system = useSelector<any, SystemState>(state => state.system);
    const [currentPage, setCurrentPage] = useState(0);

    const handlePageChange = (value: number) => {
        setCurrentPage(value);
    };

    const handleBeforePageChange = (value: number) => {
    };

    AOS.init({
        duration: 1000
    });

    return (
        <>
            <div id={cx("firstSection")}>
                <BizVideoBackgroundZsms>
                    <div className={cx("contentBox")} data-aos="fade-up">
                        <h2>
                            <p className={cx("contentBox-first")}>We are the best partner</p>
                            <p className={cx("contentBox-second")}>for your business success.</p>
                        </h2>
                        <p className={cx("contentBox-sub")}>{system.init.appNm}는 정직한 국제문자 발송 서비스 입니다.</p>
                    </div>
                </BizVideoBackgroundZsms>
            </div>

            <div id={cx("secondSection")}>
                <div className={cx("secondSection-headBox")}>
                    <h2 data-aos="fade-right">{system.init.appNm} Facts</h2>
                    <p data-aos="fade-left">언제나 높은 수신률과 실시간으로 이루어지는 전송, 사용하기 직관적인 유저 인터페이스를 통한 메세징의 서비스
                        최강자 {system.init.appNm}</p>
                </div>

                <div className={cx("secondSection-cardArea")}>
                    <div className={cx("cardBox")} data-aos="fade-up" data-aos-delay="300">
                        <div className={cx("card-icon")}>
                            <FontAwesomeIcon icon={faEnvelope}/>
                        </div>
                        <h5>손쉬운 발송</h5>
                        <h6>유저 친화적 UI로 누구나 쉽게 문자 작성 가능</h6>
                    </div>
                    <div className={cx("cardBox")} data-aos="fade-up" data-aos-delay="600">
                        <div className={cx("card-icon")}>
                            <FontAwesomeIcon icon={faFileDownload}/>
                        </div>
                        <h5>길어진 문자</h5>
                        <h6>단문문자보다 약 2배 정도 긴 문자 작성이 가능</h6>
                    </div>
                    <div className={cx("cardBox")} data-aos="fade-up" data-aos-delay="900">
                        <div className={cx("card-icon")}>
                            <FontAwesomeIcon icon={faBroadcastTower}/>
                        </div>
                        <h5>실시간 발송</h5>
                        <h6>웹에서 실시간 메시지 발송 및 결과내역 제공</h6>
                    </div>
                    <div className={cx("cardBox")} data-aos="fade-up" data-aos-delay="1200">
                        <div className={cx("card-icon")}>
                            <FontAwesomeIcon icon={faThumbsUp}/>
                        </div>
                        <h5>높은 성공률</h5>
                        <h6>안정적 환경으로 95% 이상의 성공률 보장</h6>
                    </div>
                </div>
            </div>

            <div id={cx("thirdSection")}>
                <div className={cx("thirdSection-background")}/>

                <div className={cx("contentBox")}>
                    <div className={cx("contentBox-textArea")} data-aos="fade-right">
                        <h2>
                            <p className={cx("contentBox-first")}>
                                Always
                            </p>
                            <p className={cx("contentBox-second")}>
                                the Perfect Solution
                            </p>
                        </h2>
                        <p className={cx("contentBox-sub")}>
                            끊임없는 도전과 업데이트를 통해 언제나 업계 최고의 솔루션을 제시합니다.
                        </p>
                    </div>

                    <div className={cx("imageArea")}>
                        <div className={cx("imageBox", "blank")}/>
                        <div className={cx("imageBox", "firstBox")} data-aos="fade-up" data-aos-delay="300">
                            <h5>C/S Center</h5>
                            <h6>{system.init.appNm}는 연중무휴 365일 24시간 고객센터를 운영합니다.</h6>
                        </div>
                        <div className={cx("imageBox", "secondBox")} data-aos="fade-up" data-aos-delay="600">
                            <h5>Arrival</h5>
                            <h6>정교한 로드 밸런싱과 결합하여 결함없이 메시지를 전달</h6>
                        </div>
                        <div className={cx("imageBox", "blank")}/>
                        <div className={cx("imageBox", "blank")}/>
                        <div className={cx("imageBox", "thirdBox")} data-aos="fade-up" data-aos-delay="900">
                            <h5>Low Cost</h5>
                            <h6>포인트 대량 구매시 가격 할인</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div id={cx("fourthSection")}>
                <div className={cx("fourthSection-background")}/>

                <div className={cx("contentBox")} data-aos="fade-up">
                    <h2>
                        <p className={cx("contentBox-first")}>
                            Start a Successful
                        </p>
                        <p className={cx("contentBox-second")}>
                            Texting Business Today
                        </p>
                    </h2>
                    <A href="/auth/login">
                        <span className={cx("btnTxt")}>성공적인 문자 발송 비즈니스를 지금 시작해보세요</span>
                        <span className={cx("btnIcon")}/>
                    </A>
                </div>
            </div>
        </>
    );
};
