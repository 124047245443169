import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './SmsBulkDefaultTelReceiverReportModal.module.scss'
import {Query} from "../../../../../graphql/types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";

const cx = classNamesBind.bind(styles);

export const SmsBulkDefaultTelReceiverReportModal = (props: {
    query: Query,
}) => {
    const [telReportRef, setTelReportRef] = useState<HTMLElement | undefined>();

    const onScrollDownTelReport = () => {
        if (telReportRef) {
            telReportRef.scrollTo(0, telReportRef.scrollHeight);
        }
    }

    useEffect(() => {
        onScrollDownTelReport();
    }, [props.query.telReceiverReport]);

    onScrollDownTelReport();
    return (
        <PerfectScrollbar className={cx('cont-tel-reporter')} containerRef={setTelReportRef}>
            <div className={cx('box-tel-reporter')}>
                {props.query.telReceiverReport.map((value, index) => (
                    <div key={index} className={cx('msg')}>
                        <div className={cx('msg-info')}>수신번호 : {value.receiverNumber}</div>
                        <div className={cx('msg-info')}>발신번호 : {value.senderNumber}</div>
                        <div className={cx('msg-info')}>수신시각
                            : {moment(value.createdAt).format("YY.MM.DD hh:mm a")}</div>
                        <div className={cx('msg-info')}>통신사 : {value.vendor}</div>
                        <div className={cx('content')} ref={ref => {
                            if (ref) {
                                ref.innerText = value.msg;
                            }
                        }}/>
                    </div>
                ))}
            </div>
        </PerfectScrollbar>
    )
}
