import React, {useEffect, useState} from 'react';
import {
    CreateUser,
    FbJoinType,
    Mutation, MutationCreateAgentArgs,
    MutationCreateGeneralArgs, MutationCreateResellerArgs, MutationCreateTreeArgs,
    Query,
    QueryVerifyCodeArgs,
    UserType
} from "../../../graphql/types";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {FB} from "../../../utils/fb/FB";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {navigate} from "hookrouter";
import {CopyUtil} from "../../../utils/copy/CopyUtil";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {AuthJoinDefault} from "./default/AuthJoinDefault";
import {ViewTemplate} from "../../../environment/interfaces";
import {AuthJoinTemp1} from './temp1/AuthJoinTemp1';
import {AuthJoinTemp2} from './temp2/AuthJoinTemp2';
import {GlobalVars} from "../../../globalVars/globalVars";
import {BizVideoBackgroundZsms} from "../../shares/zsms/bizVideoBackground/bizVideoBackgroundZsms";
import {AuthJoinZsms} from './zsms/AuthJoinZsms';

export const AuthJoin = (props: {
    code: string,
    type: UserType,
}) => {

    const [inputData, setInputData] = useState<CreateUserData>({
        confirm: "",
        email: "",
        pw: "",
        joinCode: props.code,
        userType: props.type,
        verified: false,
        phoneNumber: '',
        contact: '',
        loginType: FbJoinType.Email,
    });

    const [verifyCode, verified] = useLazyQuery<Query, QueryVerifyCodeArgs>(gqlVerifyUserCode);
    const [mutCreateGeneral] = useMutation<Mutation, MutationCreateGeneralArgs>(gqlCreateGeneral);
    const [mutCreateAgent] = useMutation<Mutation, MutationCreateAgentArgs>(gqlCreateAgent);
    const [mutCreateReseller] = useMutation<Mutation, MutationCreateResellerArgs>(gqlCreateReseller);
    const [mutCreateTree] = useMutation<Mutation, MutationCreateTreeArgs>(gqlCreateTree);

    useEffect(() => {
        if (!verified.data) {
            return;
        }

        const copy = CopyUtil.copyAll(inputData);
        copy.verified = verified.data.verifyCode;
        setInputData(copy);
    }, [verified.data]);

    useEffect(() => {
        FB.connectReCAPCHAToContainer("recapcha");
        onVerifyCode(props.code, props.type);
    }, [])


    const onVerifyCode = (
        code: string,
        userType: UserType,
    ) => {
        verifyCode({variables: {code, userType}})
    }

    const onCreateGeneral = (param: CreateUser) => {
        mutCreateGeneral({
            variables: {input: param}
        }).then(res => {
            SwalUtil.ok({
                msg: "가입완료!",
                icon: "success",
            });
            navigate('/auth/login');
        }).catch(ApolloCatch({}));
    }

    const onCreateReseller = (param: CreateUser) => {
        mutCreateReseller({
            variables: {input: param}
        }).then(res => {
            SwalUtil.ok({
                msg: "가입완료!",
                icon: "success",
            });
            navigate('/auth/login');
        }).catch(ApolloCatch({}));
    }

    const onCreateAgent = (param: CreateUser) => {
        mutCreateAgent({
            variables: {input: param}
        }).then(res => {
            SwalUtil.ok({
                msg: "가입완료!",
                icon: "success",
            })
            navigate('/auth/login');
        }).catch(ApolloCatch({}));
    }

    const onCreateTree = (param: CreateUser) => {
        mutCreateTree({
            variables: {input: param}
        }).then(res => {
            SwalUtil.ok({
                msg: "가입완료!",
                icon: "success",
            })
            navigate('/auth/login');
        }).catch(ApolloCatch({}));

    }

    const onCreateUser = (input: CreateUserData) => {
        if (input.pw !== input.confirm) {
            SwalUtil.ok({
                msg: "두 비밀번호가 틀립니다.",
                icon: "error"
            });
            return;
        }

        if (input.pw.length < 8) {
            SwalUtil.ok({
                msg: "비밀번호는 8자리 이상 입력하여 주십시오.",
                icon: "error"
            });
            return;
        }

        // if (input.email.includes("@yopmail.com")) {
        //     SwalUtil.ok({
        //         msg: '보안상 이유로 YOPMAIL 은 사용금지 대상입니다.',
        //         icon: 'warning'
        //     });
        //     return;
        // }

        FB.join({email: input.email, pw: input.pw})
            .then(uid => {
                joinToSystem({uid, ...input})
            })
    }

    const onCreateUserWithPhoneNumber = (input: CreateUserData) => {
        if (input.phoneNumber === "") {
            SwalUtil.ok({
                msg: '전화번호를 입력하여 주십시오.',
                icon: 'warning',
            })
            return;
        }

        // 전화번호 파싱
        const regEx = /^(010)([0-9]{8})$/g;
        if (!regEx.exec(input.phoneNumber)) {
            SwalUtil.ok({
                msg: '전화번호를 정확하게 입력하여 주십시오.',
                icon: 'warning',
            })
            return;
        }

        let phoneNumber = `+82${input.phoneNumber.substring(1, input.phoneNumber.length)}`;


        GlobalVars.layout.loadingView(true);
        FB.signInWithPhoneNumber({phoneNumber: phoneNumber})
            .then(uid => {
                if (!uid) {
                    SwalUtil.ok({
                        msg: '전화번호 인증에 실패 하였습니다.',
                        icon: 'error',
                    });
                    return;
                }
                joinToSystem({uid, ...input})
            })
            .catch(reason => {
                GlobalVars.layout.loadingView(false);
                if (reason.hasOwnProperty("code")) {
                    switch (reason.code) {
                        case "auth/invalid-verification-code":
                            SwalUtil.ok({
                                msg: '인증번호가 틀렸습니다.',
                                icon: 'error',
                            })
                            break;
                        case "auth/email-already-in-use":
                            SwalUtil.ok({
                                msg: '이미 가입되어 있는 전화번호 입니다.',
                                icon: 'error'
                            });
                            break;
                        case "auth/captcha-check-failed":
                            SwalUtil.ok({
                                msg: 'reCAPTCHA 를 확인하여 주십시오.',
                                icon: 'error'
                            });
                            break;
                        case "auth/too-many-requests":
                            SwalUtil.ok({
                                msg: '제한된 번호입니다. (요청수제한)',
                                icon: 'error'
                            });
                            break;
                        default:
                            SwalUtil.ok({
                                msg: '회원 가입에 실패하였습니다.',
                                icon: 'error',
                            })
                    }
                }
            })
        // ).then();
    }

    const joinToSystem = (params: {
        uid: string,
        joinCode: string,
        userType: UserType,
        loginType: FbJoinType,
        contact: string,
    }) => {
        const joinData = {
            uid: params.uid,
            joinCode: params.joinCode,
            loginType: params.loginType,
            contact: params.contact,
        };

        switch (params.userType) {
            case UserType.Agent:
                onCreateAgent(joinData);
                break;
            case UserType.General:
                onCreateGeneral(joinData);
                break;
            case UserType.Reseller:
                onCreateReseller(joinData);
                return;
            case UserType.Tree:
                onCreateTree(joinData);
        }
    }

    return (
        <>
            <ViewTemplateSelector view={{
                [ViewTemplate.default]:
                    <AuthJoinDefault
                        value={inputData}
                        onChangeValue={setInputData}
                        onCreateUserWithEmail={onCreateUser}
                        onVerifyCode={onVerifyCode}
                        onCreateUserWithPhoneNumber={onCreateUserWithPhoneNumber}/>,
                [ViewTemplate.temp1]:
                    <AuthJoinTemp1
                        value={inputData}
                        onChangeValue={setInputData}
                        onCreateUserWithEmail={onCreateUser}
                        onVerifyCode={onVerifyCode}
                        onCreateUserWithPhoneNumber={onCreateUserWithPhoneNumber}/>,
                [ViewTemplate.temp2]:
                    <AuthJoinTemp2
                        value={inputData}
                        onChangeValue={setInputData}
                        onCreateUserWithEmail={onCreateUser}
                        onVerifyCode={onVerifyCode}
                        onCreateUserWithPhoneNumber={onCreateUserWithPhoneNumber}/>,
                [ViewTemplate.zsms]:
                    <AuthJoinZsms
                        value={inputData}
                        onChangeValue={setInputData}
                        onCreateUserWithEmail={onCreateUser}
                        onVerifyCode={onVerifyCode}
                        onCreateUserWithPhoneNumber={onCreateUserWithPhoneNumber}/>,
            }}/>
            <div id='recapcha'/>
        </>
    )
}

export type CreateUserData = {
    email: string,
    pw: string,
    phoneNumber: string,
    contact: string,
    confirm: string,
    joinCode: string,
    userType: UserType,
    verified: boolean,
    loginType: FbJoinType,
}

const gqlVerifyUserCode = gql`
    query VerifyUserCode($code: String!, $userType: UserType!) {
        verifyCode(code: $code, userType: $userType)
    }
`;

const gqlCreateGeneral = gql`
    mutation CreateGeneral($input: CreateUser!) {
        createGeneral(input: $input)
    }
`;

const gqlCreateAgent = gql`
    mutation CreateAgent($input: CreateUser!) {
        createAgent(input: $input)
    }
`;

const gqlCreateReseller = gql`
    mutation CreateReseller($input: CreateUser!) {
        createReseller(input: $input)
    }
`;

const gqlCreateTree = gql`
    mutation CreateTree($input: CreateUser!) {
        createTree(input: $input)
    }
`;
