import React, {Fragment} from "react";
import {Chip} from "@material-ui/core";
import classNamesBind from "classnames/bind";
import styles from "./BadgeMngCommissionStatusTemp2.module.scss";

const cx = classNamesBind.bind(styles);

export const BadgeMngCommissionStatusTemp2 = (props: {
    status: Boolean
}) => {
    return (
        <Fragment>
            {props.status &&
            <Chip size="small" label="지급완료" className={cx("true")} />
            }

            {!props.status &&
            <Chip size="small" label="미지급" className={cx("false")} />
            }
        </Fragment>
    )
}
