import React from "react";
import {DepositRequestStatus} from "../../../../graphql/types";
import {Chip} from "@material-ui/core";
import classNamesBind from "classnames/bind";
import styles from "./BadgeDepositRequestStatusTemp1.module.scss";

const cx = classNamesBind.bind(styles);

export const BadgeDepositRequestStatus = (props: {
    status: DepositRequestStatus
}) => {
    return (
        <>
            {props.status === DepositRequestStatus.Allow &&
            <Chip size="small" label="승인" classes={{root: cx("chip1")}}/>
            }

            {props.status === DepositRequestStatus.Cancel &&
            <Chip size="small" label="취소" classes={{root: cx("chip2")}}/>
            }

            {props.status === DepositRequestStatus.Wait &&
            <Chip size="small" label="대기" classes={{root: cx("chip3")}}/>
            }

            {props.status === DepositRequestStatus.Deny &&
            <Chip size="small" label="거절" classes={{root: cx("chip4")}}/>
            }
        </>
    )
}
