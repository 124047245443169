import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './ResultMsgReceiptTemp2.module.scss'
import {PagerTemp2} from "../../../shares/temp2/pager/PagerTemp2";
import {OnChangeValue} from "../../../interfaces";
import {ResultMsgReceiptResp} from "../ResultMsgReceipt";
import {A} from "hookrouter";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/Pipe";
import { FadeInContainerTemp1 } from "../../../shares/temp1/fadeInContainer/FadeInContainerTemp1";
import HistoryIcon from "@material-ui/icons/History";

const cx = classNamesBind.bind(styles);

export const ResultMsgReceiptTemp2 = (props: {
    data: ResultMsgReceiptResp,
    onChangePage: OnChangeValue<number>
}) => {
    return (
        <FadeInContainerTemp1>
            <Fragment>
                <div className={cx('fullContainer')}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-icon")}>
                                        <HistoryIcon/>
                                    </div>
                                    <div className={cx("titleBar-text")}>
                                        <div className={cx("text-main")}>
                                            발송결과 히스토리
                                        </div>
                                        <div className={cx("text-sub")}>
                                            History for sent result
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "box__dateCell")}>
                                        전송일
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "box__detailCell")} style={{ flexGrow: 1 }}>
                                        내용
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "box__lineCell")}>
                                        회선
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "box__amountCell")}>
                                        전송 수
                                    </div>
                                </div>
                                {props.data.msgReceipt.list.map((value, index) => {
                                    return (
                                        <A href={`/result/telbook/${value.requestId}`} key={index}>
                                            <div className={cx("side-contentsColumn")} key={index}>
                                                <div className={cx("contentsColumn-bottomValue", "box__dateCell")}>
                                                    {moment(value.createdAt).format('MM월 DD일 hh:mm(A)')}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "box__detailCell")} style={{ flexGrow: 1 }}>
                                                    {value.contents}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "box__lineCell")}>
                                                    {value.apiKeyNm}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "box__amountCell")}>
                                                    {Pipe.toSeparatorNumber(value.sent)} 건
                                                </div>
                                            </div>
                                        </A>
                                    )
                                })}

                                <PagerTemp2
                                    page={props.data.msgReceipt.page}
                                    size={props.data.msgReceipt.size}
                                    total={props.data.msgReceipt.total}
                                    onChange={props.onChangePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </FadeInContainerTemp1>
    )
}
