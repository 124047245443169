import React, {Fragment, ReactNode} from "react";
import {useSelector} from "react-redux";
import {ViewTemplate} from "../../../../environment/interfaces";
import {SystemState} from "../../../../redux/System";

export const ViewTemplateSelector = (props: {
    view: { [viewMode: string]: ReactNode }
}) => {
    const env = useSelector<any, SystemState>(state => state.system)

    let view: ReactNode;
    if (props.view.hasOwnProperty(env.init.viewTemplate)) {
        view = props.view[env.init.viewTemplate];
    } else if (props.view.hasOwnProperty(ViewTemplate.default)) {
        view = props.view[ViewTemplate.default];
    } else {
        view = <div>지정된 뷰 템플릿이 없습니다.</div>
    }

    return (
        <Fragment>
            {view}
        </Fragment>
    )
}

