import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './IndexPopup.module.scss'
import {Board} from "../../../../graphql/types";

const cx = classNamesBind.bind(styles);

export const IndexPopup = (props: {
    value: Board[],
}) => {
    const [idx, setIdx] = useState(0);
    const [view, setView] = useState(true);

    useEffect(() => {
        setIdx(props.value.length - 1);
    }, [props.value])


    const onCountDown = () => {
        const newIdx = idx - 1;
        if (0 <= newIdx) {
            setIdx(newIdx);
        } else {
            setView(false);
        }
    }

    const data = props.value[idx];

    return (
        <Fragment>
            {view && data &&
                <div className={cx('cont')}>
                    <div className={cx('box')}>
                        <div className={cx("relative")}>
                            <div className={cx('contents')}
                                 style={{background: data.bgColor}}
                                 ref={ref => {
                                     if (ref) {
                                         ref.innerHTML = data.content;
                                     }
                                 }}/>

                            <div className={cx('footer')}>
                                <button
                                    className='btn btn-sm'
                                    style={{width: "90%", backgroundColor: "#000000", color: "white"}}
                                    onClick={onCountDown}>닫기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
}
