import React, {ReactNode} from "react";
import {useSpring, animated} from "react-spring";

export const FadeInContainerTemp1 = (props: {
    children: ReactNode,
    defaultTopMargin?: boolean
}) => {
    const [contProps, setContProps] = useSpring(() => ({opacity: 0}));
    setContProps({opacity: 1})

    return (
        <animated.div style={contProps}>
            <div className='container'>
                <div className='row'>
                    {props.children}
                </div>
            </div>
        </animated.div>
    )
}
