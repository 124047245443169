import React from "react";
import classNamesBind from "classnames/bind";
import styles from "./CsListTemp2.module.scss";
import {OnChangeValue} from "../../../interfaces";
import {MutationCreateUserChatArgs, Query} from "../../../../graphql/types";
import {CsListTemp2UserAlertList} from "./userAlertList/CsListTemp2UserAlertList";
import {CsListTemp2Chat} from "./chat/CsListTemp2Chat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

const cx = classNamesBind.bind(styles);

export const CsListTemp2 = (props: {
    value: Query,
    onChangeUserAlertPage: OnChangeValue<number>,
    onCreateUserChat: OnChangeValue<MutationCreateUserChatArgs>
}) => {
    return (
        <>
            <div className={cx("fullContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-icon")}>
                                    <FontAwesomeIcon icon={faQuestionCircle}/>
                                </div>
                                <div className={cx("titleBar-text")}>
                                    <div className={cx("text-main")}>
                                        관리자 문의
                                    </div>
                                    <div className={cx("text-sub")}>
                                        Contact Manager
                                    </div>
                                </div>
                            </div>

                            <CsListTemp2Chat {...props}/>
                        </div>
                    </div>
                </div>

                <div className={cx("secondSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-icon")}>
                                    <FontAwesomeIcon icon={faExclamationCircle}/>
                                </div>
                                <div className={cx("titleBar-text")}>
                                    <div className={cx("text-main")}>
                                        알림 내역
                                    </div>
                                    <div className={cx("text-sub")}>
                                        Alert List
                                    </div>
                                </div>
                            </div>

                            <CsListTemp2UserAlertList {...props}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
