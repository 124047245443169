import {UserType} from "../../graphql/types";
import moment from "moment";

export class Pipe {
    static userType(code: string): UserType {
        switch (code.toLocaleLowerCase()) {
            case "user" :
                return UserType.General;
            case "reseller":
                return UserType.Reseller;
            case "agent":
                return UserType.Agent;
            case "tree":
                return UserType.Tree;
            default:
                return UserType.General;
        }
    }

    static toSeparatorNumber(numb: number): string {
        return numb.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    static toTimeDay(time: Date): number {
        return Number(moment(time).format("YYYYMMDD"));
    }

    static cvtTimeDayToDate(day: number): Date {
        if (day < 10000000) {
            return new Date();
        }

        const strDay = String(day);
        return moment(`${strDay.substring(0, 4)}-${strDay.substring(4, 6)}-${strDay.substring(6, 8)} 00:00:00z`).toDate()
    }
}
