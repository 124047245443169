import React from "react";
import classNamesBind from "classnames/bind";
import styles from "./MngSendCommissionTemp2.module.scss";
import {Pipe} from "../../../../utils/pipe/Pipe";
import moment from "moment";
import {PagerTemp2} from "../../../shares/temp2/pager/PagerTemp2";
import {MngLayout} from "../../layout/MngLayout";
import {OnChangeValue} from "../../../interfaces";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {BadgeMngCommissionStatusTemp2} from "../../../shares/temp2/badgeMngCommissionStatus/BadgeMngCommissionStatusTemp2";
import QueueIcon from "@material-ui/icons/Queue";
import {Query} from "../../../../graphql/types";

const cx = classNamesBind.bind(styles);

export const MngSendCommissionTemp2 = (props: {
    data?: Query,
    onChangePage: OnChangeValue<number>,
}) => {
    return (
        <MngLayout title={'대리점관리'}>
            {props.data &&
            <FadeIn>
                <div className={cx('fullContainer')}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-icon")}>
                                        <QueueIcon/>
                                    </div>
                                    <div className={cx("titleBar-text")}>
                                        <div className={cx("text-main")}>
                                            전송 커미션 - 전체 합계
                                        </div>
                                        <div className={cx("text-sub")}>
                                            Sent message commission - Total value
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "topBox")}>
                                        전체
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "topBox")}>
                                        지급완료
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "topBox")}>
                                        미지급
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")} style={{ borderBottom: "none" }}>
                                    <div className={cx("contentsColumn-bottomValue", "topBox")}>
                                        {Pipe.toSeparatorNumber(props.data.totalSendCommission.total)} 원
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "topBox")}>
                                        {Pipe.toSeparatorNumber(props.data.totalSendCommission.paid)} 원
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "topBox")}>
                                        {Pipe.toSeparatorNumber(props.data.totalSendCommission.notPaid)} 원
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{height: 30}} />

                    <div className={cx("secondSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-icon")}>
                                        <QueueIcon/>
                                    </div>
                                    <div className={cx("titleBar-text")}>
                                        <div className={cx("text-main")}>
                                            전송 커미션 - 히스토리
                                        </div>
                                        <div className={cx("text-sub")}>
                                            Sent message commission - History
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "dateCell", "hidden")}>
                                        날짜
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "statusCell")}>
                                        상태
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "agencyCell")}>
                                        대리점
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "sendCell")}>
                                        전송갯수
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "valueCell")}>
                                        커미션
                                    </div>
                                </div>
                                {props.data.sendCommissionList.list.map((value, index) => {
                                    return (
                                <div className={cx("side-contentsColumn")} key={index}>
                                    <div className={cx("contentsColumn-bottomValue", "dateCell", "hidden")}>
                                        {moment(value.createdAt).format('YY.MM.DD')}
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "statusCell")}>
                                        {value.isWithdrawal && <BadgeMngCommissionStatusTemp2 status={true} />}
                                        {!value.isWithdrawal && <BadgeMngCommissionStatusTemp2 status={false} />}
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "agencyCell")}>
                                        {value.agentEmail}
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "sendCell", "alignRight")}>
                                        {Pipe.toSeparatorNumber(value.agentSendTotal)} 개
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "valueCell", "alignRight")}>
                                        {Pipe.toSeparatorNumber(value.commission)} 원
                                    </div>
                                </div>
                                    )
                                })}
                                <div className={cx("pagerColumn")}>
                                    <div className={cx("pagerColumn-pager")}>
                                        <PagerTemp2
                                            page={props.data.sendCommissionList.page}
                                            size={props.data.sendCommissionList.size}
                                            total={props.data.sendCommissionList.total}
                                            onChange={props.onChangePage}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn>
            }
        </MngLayout>
    )
}
