import React from "react";
import classNamesBind from "classnames/bind";
import styles from './SmsBulkDefaultCharInputPopover.module.scss'
import {Button, IconButton, Popover} from "@material-ui/core";
import {SentimentSatisfiedAltOutlined} from "@material-ui/icons";
import {SendBulkInputData} from "../../SmsBulk";
import {OnChangeValue} from "../../../../interfaces";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";

const cx = classNamesBind.bind(styles);

export const SmsBulkDefaultCharInputPopover = (props: {
    value: SendBulkInputData,
    onChangeValue: OnChangeValue<SendBulkInputData>,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const onClickChar = (value: string) => {
        const copy = CopyUtil.copyAll(props.value);
        copy.msg.msg += value;
        props.onChangeValue(copy);
    }

    return (
        <>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <div className={cx('cont')}>
                    {CharList.map((value, index) => (
                        <IconButton
                            key={index} size={'small'} className={cx('btn')}
                            onClick={ev => {
                                onClickChar(value);
                            }}>
                            {value}
                        </IconButton>
                    ))}

                </div>
            </Popover>

            <Button size={'small'} className={cx('btn-emoji')} onClick={ev => {
                setAnchorEl(ev.currentTarget);
            }}>
                <SentimentSatisfiedAltOutlined/> 특수문자 추가
            </Button>
        </>

    )
}

const CharList: string[] = [
    '❤️',
    '🫰',
    '🤙',
    '👈',
    '👉',
    '👍',
    '🍎',
    '🍉',
    '🍊',
    '🍑',
    '🍒',
    '🎁',
    '🏆',
    '🎊',
    '🎉',
    '✨',
    '⛳',
    '⚽',
    '⚾',
    '🏀',
    '🏐',
    '🏈',
    '🎾',
    '🎴',
    '🃏',
    '🌏',
    '💒',
    '🚨',
    '✈️',
    '⏰',
    '⭐️',
    '🌜',
    '🌝',
    '☔️',
    '☃️',
    '🔥',
    '💧',
    '👜',
    '👑',
    '🔔',
    '💰',
    '🪙',
    '💸',
    '💵',
    '💴',
    '📈',
    '📉',
    '📌',
    '📍',
    '🔒',
    '🔐',
    '🔑',
    '🗝',
    '🔨',
    '🚬',
    '🧯',
    '🏧',
    '~',
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '\\',
    '"',
    '\'',
    '+',
    '=',
    '`',
    '|',
    '(',
    ')',
    '[',
    ']',
    '{',
    '}',
    ':',
    ';',
    '-',
    '_',
    '-',
    '＃',
    '＆',
    '＆',
    '＠',
    '§',
    '※',
    '☆',
    '★',
    '○',
    '●',
    '◎',
    '◇',
    '◆',
    '□',
    '■',
    '△',
    '▲',
    '▽',
    '▼',
    '→',
    '←',
    '←',
    '↑',
    '↓',
    '↔',
    '〓',
    '◁',
    '◀',
    '▷',
    '▶',
    '♤',
    '♠',
    '♡',
    '♥',
    '♧',
    '♣',
    '⊙',
    '◈',
    '▣',
    '◐',
    '◑',
    '▒',
    '▤',
    '▥',
    '▨',
    '▧',
    '▦',
    '▩',
    '♨',
    '☏',
    '☎',
    '☜',
    '☞',
    '¶',
    '†',
    '‡',
    '↕',
    '↗',
    '↙',
    '↖',
    '↘',
    '♭',
    '♩',
    '♪',
    '♬',
    '㉿',
    '㈜',
    '№',
    '㏇',
    '™',
    '㏂',
    '㏘',
    '℡',
    '®',
    'ª',
    'º',
    '─',
    '│',
    '┌',
    '┐',
    '┘',
    '└',
    '├',
    '┬',
    '┤',
    '┴',
    '┼',
    '━',
    '┃',
    '┏',
    '┓',
    '┛',
    '┗',
    '┣',
    '┳',
    '┫',
    '┻',
    '╋',
    '┠',
    '┯',
    '┨',
    '┷',
    '┿',
    '┝',
    '┰',
    '┥',
    '┸',
    '╂',
    '┒',
    '┑',
    '┚',
    '┙',
    '┖',
    '┕',
    '┎',
    '┍',
    '┞',
    '┟',
    '┡',
    '┢',
    '┦',
    '┧',
    '┩',
    '┪',
    '┭',
    '┮',
    '┱',
    '┲',
    '┵',
    '┶',
    '┹',
    '┺',
    '┽',
    '┾',
    '╀',
    '╁',
    '╃',
    '╄',
    '╅',
    '╆',
    '╇',
    '╈',
    '╉',
    '╊',
    '＋',
    '－',
    '＜',
    '＝',
    '＞',
    '±',
    '×',
    '÷',
    '≠',
    '≤',
    '≥',
    '∞',
    '∴',
    '♂',
    '♀',
    '∠',
    '⊥',
    '⌒',
    '∂',
    '∇',
    '≡',
    '≒',
    '≪',
    '≫',
    '√',
    '∽',
    '∝',
    '∵',
    '∫',
    '∬',
    '∈',
    '∋',
    '⊆',
    '⊇',
    '⊂',
    '⊃',
    '∪',
    '∩',
    '∧',
    '∨',
    '￢',
    '⇒',
    '⇔',
    '∀',
    '∃',
    '∮',
    '∑',
    '∏',
    '！',
    '＇',
    '，',
    '．',
    '／',
    '：',
    '；',
    '？',
    '＾',
    '＿',
    '｀',
    '｜',
    '￣',
    '、',
    '。',
    '·',
    '‥',
    '…',
    '¨',
    '〃',
    '­',
    '―',
    '∥',
    '＼',
    '∼',
    '´',
    '～',
    'ˇ',
    '˘',
    '˝',
    '˚',
    '˙',
    '¸',
    '˛',
    '¡',
    '¿',
    'ː',
    '＂',
    '”',
    '〔',
    '〕',
    '｛',
    '｝',
    '‘',
    '’',
    '“',
    '”',
    '〔',
    '〕',
    '〈',
    '〉',
    '《',
    '》',
    '「',
    '」',
    '『',
    '』',
    '【',
    '】',
    '㉠',
    '㉡',
    '㉢',
    '㉣',
    '㉤',
    '㉥',
    '㉦',
    '㉧',
    '㉨',
    '㉩',
    '㉪',
    '㉫',
    '㉬',
    '㉭',
    '㉮',
    '㉯',
    '㉰',
    '㉱',
    '㉲',
    '㉳',
    '㉴',
    '㉵',
    '㉶',
    '㉷',
    '㉸',
    '㉹',
    '㉺',
    '㉻',
    '㈀',
    '㈁',
    '㈂',
    '㈃',
    '㈄',
    '㈅',
    '㈆',
    '㈇',
    '㈈',
    '㈉',
    '㈊',
    '㈋',
    '㈌',
    '㈍',
    '㈎',
    '㈏',
    '㈐',
    '㈑',
    '㈒',
    '㈓',
    '㈔',
    '㈕',
    '㈖',
    '㈗',
    '㈘',
    '㈙',
    '㈚',
    '㈛',
    'ⓐ',
    'ⓑ',
    'ⓒ',
    'ⓓ',
    'ⓔ',
    'ⓕ',
    'ⓖ',
    'ⓗ',
    'ⓘ',
    'ⓙ',
    'ⓚ',
    'ⓛ',
    'ⓜ',
    'ⓝ',
    'ⓞ',
    'ⓟ',
    'ⓠ',
    'ⓡ',
    'ⓢ',
    'ⓣ',
    'ⓤ',
    'ⓥ',
    'ⓦ',
    'ⓧ',
    'ⓨ',
    'ⓩ',
    '⒜',
    '⒝',
    '⒞',
    '⒟',
    '⒠',
    '⒡',
    '⒢',
    '⒣',
    '⒤',
    '⒥',
    '⒦',
    '⒧',
    '⒨',
    '⒩',
    '⒪',
    '⒫',
    '⒬',
    '⒭',
    '⒮',
    '⒯',
    '⒰',
    '⒱',
    '⒲',
    '⒳',
    '⒴',
    '⒵',
    '①',
    '②',
    '③',
    '④',
    '⑤',
    '⑥',
    '⑦',
    '⑧',
    '⑨',
    '⑩',
    '⑪',
    '⑫',
    '⑬',
    '⑭',
    '⑮',
    '⑴',
    '⑵',
    '⑶',
    '⑷',
    '⑸',
    '⑹',
    '⑺',
    '⑻',
    '⑼',
    '⑽',
    '⑾',
    '⑿',
    '⒀',
    '⒁',
    '⒂'
];


