import React, {Fragment, ReactNode} from 'react';
import {useSelector} from "react-redux";
import {SystemState, ViewMode} from "../../../../redux/System";

export const ViewSelector = (props: {
    web: ReactNode,
    mobile: ReactNode
}) => {
    const system = useSelector<any, SystemState>(state => state.system);
    return (
        <Fragment>
            {system.viewMode === ViewMode.Web && props.web}
            {system.viewMode === ViewMode.Mobile && props.mobile}
        </Fragment>
    )
}
