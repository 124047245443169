import React from "react";
import {Pagination} from "@material-ui/lab";
import classNamesBind from "classnames/bind";
import styles from "./PagerTemp2.module.scss";
import {makeStyles} from "@material-ui/core";

const cx = classNamesBind.bind(styles);

const useStyles = makeStyles(() => ({
    ul: {
        "& .Mui-selected": {
            color: "#ffffff",
            background: "#2196f3",

            "&:hover": {
                background: "#008fff",
            }
        }
    }
}));

export const PagerTemp2 = (props: {
    page: number,
    size: number,
    total: number,
    onChange: (page: number) => void
}) => {
    let count = Math.floor(props.total / props.size);
    count += props.total % props.size === 0 ? 0 : 1;

    const classes = useStyles();

    return (
        <div className={cx("pagination")}>
            <Pagination
                count={count}
                page={props.page + 1}
                classes={{ul: classes.ul}}
                onChange={(event, page) => {
                    window.scrollTo(0, 0);
                    props.onChange(page - 1);
                }}/>
        </div>
    )
}
