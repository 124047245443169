import React, {useEffect, useState} from 'react';
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {
    JoinCodeList,
    Mutation,
    MutationCreateDisposableJoinCodeWithSmsFeeArgs,
    MutationCreateReusableJoinCodeWithSmsFeeArgs
} from "../../../graphql/types";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {MngCodeDefault} from "./default/MngCodeDefault";
import {navigate} from "hookrouter";
import {MngCodeTemp1} from './temp1/MngCodeTemp1';

export const MngCode = (props: {
    disposablePage: number,
    reusablePage: number
}) => {
    const [getData, resData] = useLazyQuery<MngCodeResp, { disposablePage: number, reusablePage: number }>(gqlData);
    const [data, setData] = useState<MngCodeResp | undefined>();

    const [mutDelete] = useMutation<{ deleteJoinCode: boolean }, { code: string }>(gqlDeleteJoinCode);
    const [mutCreateDisposableJoinCode] = useMutation<Mutation, MutationCreateDisposableJoinCodeWithSmsFeeArgs>(gqlCreateDisposableCode);
    const [mutCreateReusableJoinCode] = useMutation<Mutation, MutationCreateReusableJoinCodeWithSmsFeeArgs>(gqlCreateReusableCode);

    useEffect(() => {
        onReload();
    }, [props.disposablePage, props.reusablePage]);


    useEffect(() => {
        if (resData.data) {
            setData(resData.data);
        }
    }, [resData.data]);

    const onChangeDisposableCodePage = (page: number) => {
        navigate(`/mng/code/${page}/${props.reusablePage}`);
    }

    const onChangeReusableCodePage = (page: number) => {
        navigate(`/mng/code/${props.reusablePage}/${page}`);
    }

    const onReload = () => {
        getData({
            variables: {
                disposablePage: props.disposablePage,
                reusablePage: props.reusablePage,
            }
        });
    }

    const onDeleteCode = (code: string) => {
        SwalUtil.yn({
            msg: `삭제 하시겠습니까? <br/>${code}`,
            icon: 'question',
            ok: () => {
                mutDelete({variables: {code}})
                    .then(res => {
                        SwalUtil.ok({
                            msg: `삭제되었습니다.<br/>${code}`,
                            icon: "success"
                        })
                        onReload();
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.bizError()
                    }))
            }
        });
    }

    const onCreateDisposableCode = (args: MutationCreateDisposableJoinCodeWithSmsFeeArgs) => {
        SwalUtil.yn({
            msg: '일회용 코드를 발급하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutCreateDisposableJoinCode({
                    variables: args
                })
                    .then(res => {
                        SwalUtil.ok({
                            msg: `발급되었습니다.`,
                            icon: "success"
                        });
                        onReload();
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.bizError()
                    }))
            }
        });
    }

    const onCreateReusableCode = (args: MutationCreateReusableJoinCodeWithSmsFeeArgs) => {
        SwalUtil.yn({
            msg: '재사용 코드를 발급하시겠습니까?',
            icon: 'question',
            ok: () => {
                mutCreateReusableJoinCode({
                    variables: args
                })
                    .then(res => {
                        SwalUtil.ok({
                            msg: `발급되었습니다.`,
                            icon: "success"
                        });
                        onReload();
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: SwalUtil.bizError()
                    }));
            }
        })

    }

    return (
        <ViewTemplateSelector view={{
            [ViewTemplate.default]:
                <MngCodeDefault
                    data={data}
                    onCreateDisposableCode={onCreateDisposableCode}
                    onCreateReusableCode={onCreateReusableCode}
                    onDeleteCode={onDeleteCode}
                    onChangeReusableCodePage={onChangeReusableCodePage}
                    onChangeDisposableCodePage={onChangeDisposableCodePage}/>,
            [ViewTemplate.temp1]:
                <MngCodeTemp1
                    data={data}
                    onCreateDisposableCode={onCreateDisposableCode}
                    onCreateReusableCode={onCreateReusableCode}
                    onDeleteCode={onDeleteCode}
                    onChangeReusableCodePage={onChangeReusableCodePage}
                    onChangeDisposableCodePage={onChangeDisposableCodePage}/>,
            // [ViewTemplate.temp2]:
            //     <MngCodeTemp2
            //         data={data}
            //         onCreateDisposableCode={onCreateDisposableCode}
            //         onCreateReusableCode={onCreateReusableCode}
            //         onDeleteCode={onDeleteCode}
            //         onChangeReusableCodePage={onChangeReusableCodePage}
            //         onChangeDisposableCodePage={onChangeDisposableCodePage}/>,
            [ViewTemplate.zsms]:
                <MngCodeTemp1
                    data={data}
                    onCreateDisposableCode={onCreateDisposableCode}
                    onCreateReusableCode={onCreateReusableCode}
                    onDeleteCode={onDeleteCode}
                    onChangeReusableCodePage={onChangeReusableCodePage}
                    onChangeDisposableCodePage={onChangeDisposableCodePage}/>,
        }}/>
    )
}


export interface MngCodeResp {
    disposableJoinCodeList: JoinCodeList;
    reusableJoinCodeList: JoinCodeList;
}


const gqlData = gql`
    query Data($disposablePage: Int!, $reusablePage: Int!) {
        disposableJoinCodeList(page: $disposablePage, size: 20) {
            page
            size
            total
            list {
                code
                reusable
                createdAt
                apiPermission {
                    nm
                    smsFee
                }
            }
        }
        reusableJoinCodeList(page: $reusablePage, size: 20) {
            page
            size
            total
            list {
                code
                reusable
                createdAt
                apiPermission {
                    nm
                    smsFee
                }
            }
        }
    }
`;

const gqlDeleteJoinCode = gql`
    mutation DeleteJoinCode($code: String!) {
        deleteJoinCode(code: $code)
    }
`;

const gqlCreateDisposableCode = gql`
    mutation CreateJoinCode($input: CreateJoinCodeWithSmsFee!) {
        createDisposableJoinCodeWithSmsFee(input: $input)
    }
`;

const gqlCreateReusableCode = gql`
    mutation CreateReusableCode($input: CreateJoinCodeWithSmsFee!) {
        createReusableJoinCodeWithSmsFee(input: $input)
    }
`;
