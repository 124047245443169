import React, {useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './CsListTemp2Chat.module.scss';
import {MutationCreateUserChatArgs, Query, UserChat, WriterType} from "../../../../../graphql/types";
import {useSelector} from "react-redux";
import {SystemState} from "../../../../../redux/System";
import {CopyUtil} from "../../../../../utils/copy/CopyUtil";
import {OnChangeValue} from "../../../../interfaces";
import moment from "moment";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';
import {Button} from "@material-ui/core";

const cx = classNamesBind.bind(styles);

export const CsListTemp2Chat = (props: {
    value: Query,
    onCreateUserChat: OnChangeValue<MutationCreateUserChatArgs>
}) => {

    const [input, setInput] = useState<MutationCreateUserChatArgs>({
        input: {
            content: ""
        }
    })
    const [refChatBox, setRefChatBox] = useState<HTMLElement | null>();


    useEffect(() => {
        handler.onScrollDownChat();
    }, [props.value.chatList])


    const handler = {
        onCreateUserChat: () => {
            if (input.input.content === "") {
                SwalUtil.ok({
                    msg: '내용을 입력하여 주십시오.',
                    icon: 'warning'
                });
                return;
            }

            props.onCreateUserChat(CopyUtil.copyAll(input));
            setInput({
                input: {
                    content: ""
                }
            })
        },
        onScrollDownChat: () => {
            if (refChatBox) {
                refChatBox.scrollTo(0, refChatBox.scrollHeight);
            }

        }
    }

    handler.onScrollDownChat();

    return (
        <>
            <PerfectScrollbar className={cx('cont-scroll')} containerRef={setRefChatBox}>
                <div className={cx('cont-chat')}>
                    {props.value.chatList.list.map((value, index) => (
                        <ChatBox key={index} chat={value}/>
                    ))}
                </div>
            </PerfectScrollbar>

            <form className={cx('table')} onSubmit={ev => {
                ev.preventDefault();
                handler.onCreateUserChat();
            }}>
                <div className={cx("text")}>입력</div>
                <div className={cx("input")}>
                    <input
                        required
                        className='form-control form-control-sm'
                        max={200}
                        placeholder={'이곳에 메시지를 입력하여 주십시오.'}
                        value={input.input.content}
                        onChange={ev => {
                            const copy = CopyUtil.copyAll(input);
                            copy.input.content = ev.target.value;
                            setInput(copy);
                        }}/>
                </div>
                <div className={cx("button")}>
                    <Button
                        fullWidth
                        classes={{root: cx("buttonOne")}}
                        type={"submit"}
                    >전송
                    </Button>
                </div>
            </form>
        </>
    )
}


const ChatBox = (props: {
    chat: UserChat
}) => {
    const system = useSelector<any, SystemState>(state => state.system)
    return (
        <>
            {props.chat.writer === WriterType.Admin &&
            <div className={cx('cont-chat-box', 'box-admin')}>
                <div className={cx('app-nm')}>{system.init.appNm}</div>
                <div>{props.chat.content}</div>
                <div className={cx('created-at')}>{moment(props.chat.createdAt).format('YY.MM.DD hh:mm a')}</div>
            </div>
            }

            {props.chat.writer === WriterType.User &&
            <div className={cx('cont-chat-box', 'box-user')}>
                <div>{props.chat.content}</div>
                <div className={cx('created-at')}>{moment(props.chat.createdAt).format('YY.MM.DD hh:mm a')}</div>
            </div>
            }
        </>
    )
}
