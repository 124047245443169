import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ResultTelbookDefault.module.scss'
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import moment from "moment";
import {BizCol12, BizContainer} from "../../../shares/default/bizContainer/BizContainer";
import {OnVoidFunction} from "../../../interfaces";
import {ResultTelbookResp} from "../ResultTelBook";
import {ResultTelBookDefaultChart} from "./chart/ResultTelBookDefaultChart";
import {BadgeNumberStatus} from "../../../shares/default/badgeNumberStatus/BadgeNumberStatus";
import {TelNumber} from "../../../../graphql/types";

const cx = classNamesBind.bind(styles);

export const ResultTelbookDefault = (props: {
    data: ResultTelbookResp,
    onDownload: OnVoidFunction,
}) => {
    const [telBook, setTelBook] = useState<TelNumber[]>([]);
    const [telNumber, setTelNumber] = useState("");

    useEffect(() => {
        setTelBook(props.data.telbook.numberList);
    }, [props.data.telbook.numberList]);

    const handler = {
        onFindNumber: () => {
            const nextTelBook = props.data.telbook.numberList.filter(value => {
                return value.number.includes(telNumber)
            })
            setTelBook(nextTelBook);
        }
    }

    return (
        <BizContainer>
            <BizCol12>
                <BizTitle>개요</BizTitle>
                <hr/>
                <ResultTelBookDefaultChart data={props.data}/>
                <hr/>
                <div style={{height: 30}}/>
            </BizCol12>

            <BizCol12>
                <BizTitle>전송 결과</BizTitle>
                <div className={cx('table')}>
                    <div style={{width: '20%'}}>요청키</div>
                    <div className='text-left'
                         style={{width: '80%'}}>
                        {props.data.telbook.requestId}
                    </div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: '20%'}}>요청일</div>
                    <div className='text-left'
                         style={{width: '80%'}}>
                        {moment(props.data.telbook.createdAt).format('YY.MM.DD - hh:mm a')}
                    </div>
                </div>

                <div className={cx('table')}>
                    <div style={{width: '20%'}}>메세지</div>
                    <div className='text-left'
                         style={{width: '80%'}}
                         ref={ref => {
                             if (ref) {
                                 ref.innerText = props.data.telbook.msg
                             }
                         }}/>
                </div>

                <div className={cx('table')}>
                    <div className='text-right'
                         style={{width: '100%'}}>
                        <button
                            style={{width: 100}}
                            className='btn btn-sm btn-outline-success'
                            onClick={props.onDownload}>결과저장
                        </button>
                    </div>
                </div>
            </BizCol12>


            <BizCol12>
                <BizTitle>전화번호부 검색</BizTitle>
                <form onSubmit={ev => {
                    ev.preventDefault();
                    handler.onFindNumber();
                }}>
                    <div className={cx('table')}>
                        <div style={{width: '20%'}}>전화번호</div>
                        <div style={{width: '80%'}}>
                            <input
                                placeholder='전화번호를 입력하여 주십시오.'
                                className='form-control form-control-sm'
                                value={telNumber}
                                onChange={ev => {
                                    setTelNumber(ev.target.value)
                                }}/>
                        </div>
                    </div>
                    <div className={cx('table')}>
                        <div className='text-right' style={{width: '100%'}}>
                            <button
                                style={{width: 100}}
                                type='submit'
                                className='btn btn-sm btn-success'>찾기
                            </button>
                        </div>
                    </div>
                </form>
            </BizCol12>

            <BizCol12>
                <BizTitle>검색결과</BizTitle>
                <div className={cx('table')}>
                    <div style={{width: '20%'}}>상태</div>
                    <div className='text-left' style={{width: '80%'}}>전화번호</div>
                </div>

                {telBook.map((value, index) => (
                    <div className={cx('table')} key={index}>
                        <div style={{width: '20%'}}>
                            <BadgeNumberStatus numberStatus={value.status}/>
                        </div>
                        <div className='text-left' style={{width: '80%'}}>{value.number}</div>
                    </div>
                ))}

                {telBook.length === 0 &&
                <div className={cx('table')}>
                    <div style={{width: '100%'}}>검색 결과가 없습니다.</div>
                </div>}
            </BizCol12>
        </BizContainer>
    )
}
