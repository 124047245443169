import React, {useEffect, useState} from 'react';
import {FB} from "../../../utils/fb/FB";
import {gql, useMutation} from "@apollo/client";
import {LocalStorage} from "../../../utils/localstorage/LocalStorage";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {DataUtil} from "../../../utils/data/DataUtil";
import {navigate} from "hookrouter";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {BizVideoBackground} from "../../shares/default/bizVideoBackground/BizVideoBackground";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {AuthLoginDefault} from "./default/AuthLoginDefault";
import {ViewTemplate} from "../../../environment/interfaces";
import {AuthLoginTemp1} from "./temp1/AuthLoginTemp1";
import {AuthLoginTemp2} from './temp2/AuthLoginTemp2';
import {FbJoinType} from '../../../graphql/types';
import {GlobalVars} from "../../../globalVars/globalVars";
import {AuthLoginZsms} from './zsms/AuthLoginZsms';
import {BizVideoBackgroundZsms} from "../../shares/zsms/bizVideoBackground/bizVideoBackgroundZsms";

export const AuthLogin = () => {
    const [mutLogin] = useMutation<{ login: string }, { uid: string }>(gqlLogin);
    const [inputData, setInputData] = useState<LoginInputData>({
        loginType: LocalStorage.getLoginType(),
        email: "",
        phoneNumber: "",
        pw: "",
    });

    useEffect(() => {
        FB.connectReCAPCHAToContainer('recapcha');
    }, []);


    const onChangeValue = (data: LoginInputData) => {
        LocalStorage.setLoginType(data.loginType);
        setInputData(data);
    }

    const onLoginWithEmail = (param: { email: string, pw: string }) => {
        GlobalVars.layout.loadingView(true);
        FB.login(param)
            .then(uid => {
                return mutLogin({variables: {uid}})
            })
            .then(res => {
                LocalStorage.setSession(res.data!.login)
                return DataUtil.refreshSession()
            })
            .then(user => {
                navigate('/');
            })
            .catch(ApolloCatch({}))
    }

    const onLoginWithPhoneNumber = (input: LoginInputData) => {
        if (input.phoneNumber === "") {
            SwalUtil.ok({
                msg: '전화번호를 입력하여 주십시오.',
                icon: 'warning',
            })
            return;
        }

        // 전화번호 파싱
        const regEx = /^(010)([0-9]{8})$/g;
        if (!regEx.exec(input.phoneNumber)) {
            SwalUtil.ok({
                msg: '전화번호를 정확하게 입력하여 주십시오.',
                icon: 'warning',
            })
            return;
        }

        let phoneNumber = `+82${input.phoneNumber.substring(1, input.phoneNumber.length)}`;

        GlobalVars.layout.loadingView(true);
        FB.signInWithPhoneNumber({phoneNumber})
            .then(uid => {
                return mutLogin({variables: {uid}})
            })
            .then(res => {
                LocalStorage.setSession(res.data!.login)
                return DataUtil.refreshSession()
            })
            .then(user => {
                navigate('/');
            })
            .catch(ApolloCatch({}));

    }

    return (
        <>
            <ViewTemplateSelector view={{
                [ViewTemplate.default]:
                    <BizVideoBackground>
                        <AuthLoginDefault
                            value={inputData}
                            onChangeValue={onChangeValue}
                            onLoginWithEmail={onLoginWithEmail}
                            onLoginWithPhoneNumber={onLoginWithPhoneNumber}/>
                    </BizVideoBackground>,
                [ViewTemplate.temp1]:
                    <AuthLoginTemp1
                        value={inputData}
                        onChangeValue={onChangeValue}
                        onLoginWithEmail={onLoginWithEmail}
                        onLoginWithPhoneNumber={onLoginWithPhoneNumber}/>,
                [ViewTemplate.temp2]:
                    <AuthLoginTemp2
                        value={inputData}
                        onChangeValue={onChangeValue}
                        onLoginWithEmail={onLoginWithEmail}
                        onLoginWithPhoneNumber={onLoginWithPhoneNumber}/>,
                [ViewTemplate.zsms]:
                        <AuthLoginZsms
                            value={inputData}
                            onChangeValue={onChangeValue}
                            onLoginWithEmail={onLoginWithEmail}
                            onLoginWithPhoneNumber={onLoginWithPhoneNumber}/>,
            }}/>
            <div id='recapcha'/>
        </>
    )
}

export interface LoginInputData {
    loginType: FbJoinType,
    email: string;
    phoneNumber: string;
    pw: string
}

const gqlLogin = gql`
    mutation Login($uid: String!) {
        login(uid: $uid)
    }
`;
