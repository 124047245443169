import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface WebState {
    background: boolean;
}

const initState: WebState = {
    background: true,
}

class Web extends ImmerReducer<typeof initState> {
    setBackground(swt: boolean) {
        this.draftState.background = swt;
    }
}


export const WebAction = createActionCreators(Web);
export const WebReducer = createReducerFunction(Web, initState);

