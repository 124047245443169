import React, {ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizModal.module.scss';
import {Close} from "@material-ui/icons";
import {Modal} from "react-bootstrap";
import {OnChangeBoolean} from "../../../interfaces";
import PerfectScrollbar from "react-perfect-scrollbar";


const cx = classNamesBind.bind(styles);

export const BizModal = (props: {
    children: ReactNode,
    title: ReactNode,
    show: boolean,
    onChangeShow: OnChangeBoolean,
    size?: 'sm' | 'lg' | 'xl';
}) => {
    return (
        <Modal
            show={props.show}
            scrollable
            onHide={() => {
                props.onChangeShow(false);
            }}
            size={props.size ? props.size : 'lg'}
            centered>
            <PerfectScrollbar style={{maxHeight: '100%'}}>
                <div className={cx('box-title')}>
                    <div className={cx('title')}>{props.title}</div>
                    <Close onClick={event => {
                        props.onChangeShow(false);
                    }}/>
                </div>
                {props.children}
            </PerfectScrollbar>


        </Modal>
    )
}
