import {Environment, ViewTemplate} from "./interfaces";

export const EnvUnit: { [key: string]: Environment } = {
    [ViewTemplate.default]: {
        viewTemplate: ViewTemplate.default,
        nav: {
            background: {
                active: "rgba(187,219,172,1)",
                inactive: "rgba(187,219,172,0)"
            },
            fontColor: {
                active: 'rgba(0,0,0,1)',
                inactive: 'rgba(255,255,255,1)'
            },
            barColor: 'rgba(0,0,0,1)',
        }
    }
}

