import React from "react";
import {BizVideoBackground} from "../../shares/default/bizVideoBackground/BizVideoBackground";
import {IndexV1Section1} from "./section1/IndexV1Section1";
import {IndexMySMS} from "./mysms/IndexMySMS";
import {IndexDubai} from "./dubai/IndexDubai";

export const IndexDefault = () => {
    return (
        <BizVideoBackground>
            <ExportComponent host={window.location.host}/>
        </BizVideoBackground>
    );
};

function ExportComponent(props: { host: string }) {
    const {host} = props;
    if (host === "my-2017.com" || host === "www.my-2017.com") {
        return <IndexMySMS/>;
    } else if (host === "dubaisms.net" || host === "www.dubaisms.net") {
        return <IndexDubai/>
    } else {
        return <IndexV1Section1/>;
    }
}
