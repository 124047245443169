import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './SmsBulkTemp1SelectApiKeyModal.module.scss'
import {OnChangeValue} from "../../../../interfaces";
import {HashedApiKey} from "../../../../../graphql/types";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import {Pipe} from "../../../../../utils/pipe/Pipe";
import Decimal from "decimal.js";

const cx = classNamesBind.bind(styles);

export const SmsBulkTemp1SelectApiKeyModal = (props: {
    onChangeApiKey: OnChangeValue<HashedApiKey>
}) => {
    const user = useSelector<any, UserState>(state => state.user);

    const onClickApiKey = (value: HashedApiKey) => {
        props.onChangeApiKey(value);
    }

    return (
        <Fragment>
            <div className={cx('table')}>
                <div style={{width: 300}}>회선</div>
                <div style={{flexGrow: 1}}>전송가능</div>
            </div>

            {user.user.apiPermission.map((value, index) => (
                <div className={cx('table', 'hover')} key={index} onClick={ev => {
                    onClickApiKey(value);
                }}>
                    <div style={{width: 300}}>{value.nm}</div>
                    <div className='text-right' style={{flexGrow: 1}}>
                        {Pipe.toSeparatorNumber(new Decimal(user.user.point).divToInt(value.smsFee).toNumber())} 개 전송가능
                    </div>
                </div>
            ))}
        </Fragment>
    )
}
