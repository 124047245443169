import React, {Fragment, ReactNode, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './BizMenuLayout.module.scss'
import {ViewSelector} from "../viewSelector/ViewSelector";
import {Spring} from "react-spring/renderprops-universal";
import {navigate, usePath} from "hookrouter";
import {
    Divider,
    Fab,
    List,
    ListItem, ListItemText,
    SwipeableDrawer,
    Tooltip
} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

const cx = classNamesBind.bind(styles);

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    btmNav: {
        height: 50,
        lineHeight: 50,
        padding: 10,
        color: 'black'
    }
}));

export const BizMenuLayout = (props: Attr) => {
    return (
        <Fragment>
            <ViewSelector
                web={
                    <Web data={props.data} title={props.title}>
                        {props.children}
                    </Web>
                }
                mobile={
                    <Mobile data={props.data} title={props.title}>
                        {props.children}
                    </Mobile>
                }/>
        </Fragment>
    )
}

const Web = (props: Attr) => {
    const [idx, setIdx] = useState(0);
    const activeFontColor = 'rgb(0,0,0)';
    const inactiveFontColor = 'rgb(222,222,222)';
    const path = usePath();

    useEffect(() => {
        const pathList = path.split('/');
        const idx = props.data.findIndex(value => {
            const routerList = value.router.split('/');

            if (routerList.length < pathList.length) {
                return false;
            }

            for (let i = 0; i < pathList.length; i++) {
                if (pathList[i] != routerList[i]) {
                    return false
                }
            }

            return true;
        });
        setIdx(idx !== -1 ? idx : 0);
    }, [path, props.data]);

    return (
        <div className={cx('cont')}>
            <div className={cx('nav')}>
                {props.title && <div className={cx('title')}>
                    {props.title}
                </div>}
                <div className={cx('box-btn')}>
                    {props.data.map((value, index) => {
                        const activated = index === idx;
                        return (
                            <Spring
                                key={index}
                                from={{color: inactiveFontColor}}
                                to={{color: activated ? activeFontColor : inactiveFontColor}}>
                                {params =>
                                    <Fragment>
                                        <div
                                            style={params}
                                            className={cx('btn-nav')}
                                            onClick={event => {
                                                setIdx(index);
                                                window.scrollTo(0, 0);
                                                navigate(value.router);
                                            }}>
                                            {value.title}
                                        </div>
                                    </Fragment>
                                }
                            </Spring>

                        )
                    })}
                </div>
            </div>

            <div className={cx('box-contents')}>
                {props.children}
            </div>
        </div>
    )
}

const Mobile = (props: Attr) => {
    const classes = useStyles();
    const [drawer, setDrawer] = useState(false);

    return (
        <Fragment>
            <div style={{padding: 10}}>
                {props.children}
            </div>

            <Tooltip title="Add" aria-label="add">
                <Fab
                    className={classes.absolute}
                    style={{left: 10}}
                    onClick={event => {
                        setDrawer(true);
                    }}>
                    <Add/>
                </Fab>
            </Tooltip>

            <SwipeableDrawer
                anchor="left"
                onClose={() => {
                    setDrawer(false);
                }}
                onOpen={() => {
                    setDrawer(false);
                }}
                open={drawer}>

                {props.data.map((value, index) => {
                    return (
                        <Fragment key={index}>
                            <List onClick={event => {
                                navigate(value.router);
                                setDrawer(false);
                                window.scrollTo(0, 0);
                            }}>
                                <ListItem>
                                    <ListItemText primary={value.title}/>
                                </ListItem>
                            </List>
                            <Divider/>
                        </Fragment>
                    )
                })}
            </SwipeableDrawer>
        </Fragment>
    )
}


interface Attr {
    title: ReactNode
    children: ReactNode,
    data: BizMenuData[]
}

export interface BizMenuData {
    title: string;
    icon?: ReactNode;
    router: string;
}
