import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {MngLayout} from "../layout/MngLayout";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {MngCodeWithTreeDefault} from "./default/MngCodeWithTreeDefault";
import {
    Mutation,
    MutationCreateDisposableTreeJoinCodeWithSmsFeeArgs,
    MutationCreateReusableTreeJoinCodeWithSmsFeeArgs, MutationDeleteTreeJoinCodeArgs,
    Query
} from "../../../graphql/types";
import {FadeIn} from '../../shares/default/fadeIn/FadeIn';
import {navigate} from "hookrouter";
import {useSelector} from "react-redux";
import {UserState} from '../../../redux/User';
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {SwalUtil} from "../../../utils/swal/swalUtil";

export const MngCodeWithTree = (props: {
    disposablePage: number,
    reusablePage: number,
}) => {
    const {data, refetch} = useQuery<Query, typeof props>(gqlData, {
        variables: props
    });

    const user = useSelector<any, UserState>(state => state.user);
    const [mutCreateDisposable] = useMutation<Mutation, MutationCreateDisposableTreeJoinCodeWithSmsFeeArgs>(gqlCreateDisposableCode);
    const [mutCreateReusable] = useMutation<Mutation, MutationCreateReusableTreeJoinCodeWithSmsFeeArgs>(gqlCreateReusableCode);
    const [mutDelete] = useMutation<Mutation, MutationDeleteTreeJoinCodeArgs>(gqlDeleteJoinCode);

    const handler = {
        onChangeDisposablePage: (p: number) => {
            navigate(`/mng/code/${p}/${props.reusablePage}`);

        },
        onChangeReusablePage: (p: number) => {
            navigate(`/mng/code/${props.disposablePage}/${p}`);
        },
        onCreateDisposableCode: () => {
            const args: MutationCreateDisposableTreeJoinCodeWithSmsFeeArgs = {
                input: {
                    apiPermission: user.user.apiPermission.map(value => ({
                        hashedApiKey: value.hashedApiKey,
                        smsFee: value.smsFee
                    }))
                }
            };

            mutCreateDisposable({
                variables: args
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: '생성 되었습니다.',
                    icon: 'success'
                })
            }).catch(ApolloCatch({}))

        },
        onCreateReusableCode: () => {
            const args: MutationCreateReusableTreeJoinCodeWithSmsFeeArgs = {
                input: {
                    apiPermission: user.user.apiPermission.map(value => ({
                        hashedApiKey: value.hashedApiKey,
                        smsFee: value.smsFee
                    }))
                }
            };

            mutCreateReusable({
                variables: args
            }).then(() => {
                return refetch()
            }).then(() => {
                SwalUtil.ok({
                    msg: '생성 되었습니다.',
                    icon: 'success'
                })
            }).catch(ApolloCatch({}))

        },
        onDeleteCode: (code: string) => {
            SwalUtil.yn({
                msg: `삭제 하시겠습니까?<br/>${code}`,
                icon: 'question',
                ok: () => {
                    mutDelete({
                        variables: {code}
                    }).then(() => {
                        return refetch();
                    }).then(() => {
                        SwalUtil.ok({
                            msg: '삭제 되었습니다.',
                            icon: 'success'
                        })
                    }).catch(ApolloCatch({}))
                }
            })

        }
    };

    return (
        <MngLayout title={'대리점관리'}>
            {data &&
            <FadeIn>
                <ViewTemplateSelector view={{
                    [ViewTemplate.default]:
                        <MngCodeWithTreeDefault
                            {...handler}
                            query={data}/>,
                }}/>
            </FadeIn>
            }


        </MngLayout>
    )
}

const gqlData = gql`
    query DATA ($reusablePage: Int!, $disposablePage: Int!) {
        disposableTreeJoinCode(page: $disposablePage, size: 20) {
            page
            size
            total
            list {
                code
                reusable
                createdAt
                apiPermission {
                    nm
                    hashedApiKey
                    smsFee
                }
            }
        }
        reusableTreeJoinCode(page: $reusablePage, size: 20) {
            page
            size
            total
            list {
                code
                reusable
                createdAt
                apiPermission {
                    nm
                    hashedApiKey
                    smsFee
                }
            }
        }
    }
`;


const gqlDeleteJoinCode = gql`
    mutation DeleteJoinCode($code: String!) {
        deleteTreeJoinCode(code: $code)
    }
`;

const gqlCreateDisposableCode = gql`
    mutation CreateJoinCode($input: CreateJoinCodeWithSmsFee!) {
        createDisposableTreeJoinCodeWithSmsFee(input: $input)
    }
`;

const gqlCreateReusableCode = gql`
    mutation CreateReusableCode($input: CreateJoinCodeWithSmsFee!) {
        createReusableTreeJoinCodeWithSmsFee(input: $input)
    }
`;
