import React from "react";
import {Pagination} from "@material-ui/lab";

export const Pager = (props: {
    page: number,
    size: number,
    total: number,
    onChange: (page: number) => void
}) => {
    let count = Math.floor(props.total / props.size);
    count += props.total % props.size === 0 ? 0 : 1;

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 50
        }}>
            <Pagination
                count={count}
                page={props.page + 1}
                shape='rounded'
                onChange={(event, page) => {
                    window.scrollTo(0, 0);
                    props.onChange(page - 1);
                }}/>
        </div>
    )
}
