import React, {Fragment} from 'react';
import {BalanceReceiptType} from "../../../../graphql/types";

export const BizBadgeBalanceReceiptType = (props: {
    value: BalanceReceiptType
}) => {
    return (
        <Fragment>
            {props.value === BalanceReceiptType.Init &&
            <span className='badge badge-secondary'>회원가입</span>
            }

            {props.value === BalanceReceiptType.Admin &&
            <span className='badge badge-warning'>관리자</span>
            }

            {props.value === BalanceReceiptType.SendBulk &&
            <span className='badge badge-success'>대량전송</span>
            }

            {props.value === BalanceReceiptType.SendTemplate &&
            <span className='badge badge-success'>템플릿전송</span>
            }

            {props.value === BalanceReceiptType.Deposit &&
            <span className='badge badge-primary'>충전</span>
            }

            {props.value === BalanceReceiptType.SharePoint &&
            <span className='badge badge-primary'>리셀러/대리점</span>
            }

            {props.value === BalanceReceiptType.Commission &&
            <span className='badge badge-info'>커미션</span>
            }


        </Fragment>
    )
}
