import React from "react";
import {LoginInputData} from "../AuthLogin";
import {OnChangeValue} from "../../../interfaces";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import {CenterContents} from "../../../shares/default/centerContents/CenterContents";
import {navigate} from "hookrouter";
import styles from './AuthLoginZsms.module.scss';
import classNamesBind from "classnames/bind";
import {Tab, Tabs} from "@material-ui/core";
import {FbJoinType} from "../../../../graphql/types";
import {FB} from "../../../../utils/fb/FB";

const cx = classNamesBind.bind(styles);

export const AuthLoginZsms = (props: {
    value: LoginInputData,
    onChangeValue: OnChangeValue<LoginInputData>,
    onLoginWithEmail: OnChangeValue<LoginInputData>,
    onLoginWithPhoneNumber: OnChangeValue<LoginInputData>,
}) => {

    return (
        <CenterContents animatedContents>
            <div className={cx('cont')}>
                <div className={cx('box-login')}>
                    <h3 style={{marginBottom: 30}}>로그인</h3>

                    <Tabs
                        value={props.value.loginType}
                        onChange={(ev, value) => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.loginType = value;
                            props.onChangeValue(copy);
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth">
                        <Tab label="이메일" value={FbJoinType.Email}/>
                        <Tab label="전화번호" value={FbJoinType.Phone}/>
                    </Tabs>

                    <div style={{height: 30}}/>
                    {props.value.loginType === FbJoinType.Email && <LoginWithEmail {...props}/>}
                    {props.value.loginType === FbJoinType.Phone && <LoginWithPhoneNumber {...props}/>}
                </div>
            </div>
        </CenterContents>
    )
}

const LoginWithPhoneNumber = (props: {
    value: LoginInputData,
    onChangeValue: OnChangeValue<LoginInputData>,
    onLoginWithPhoneNumber: OnChangeValue<LoginInputData>,
}) => {
    const onChangeValue = (key: string, value: any) => {
        props.onChangeValue(CopyUtil.onChangeValue(props.value, key, value));
    }

    const onLogin = () => {
        props.onLoginWithPhoneNumber(props.value);
    }

    return (
        <form
            className={cx("box-form")}
            onSubmit={event => {
                event.preventDefault();
                onLogin();
            }}>

            <p>PhoneNumber</p>
            <input
                className='form-control'
                type='text'
                placeholder='전화번호를 입력하여 주십시오.'
                value={props.value.phoneNumber}
                onChange={event => {
                    onChangeValue("phoneNumber", event.target.value);
                }}/>

            <div style={{height: 42}}/>

            <hr/>
            <div className={cx('info')}>* 인증에 여러번 실패하시면 로그인 차단 대상이 됩니다.</div>
            <button
                style={{marginBottom: 10}}
                type="submit"
                className="btn btn-primary btn-block">
                인증번호 발송
            </button>

            {/*회원가입*/}
            <button
                onClick={event => {
                    navigate('/auth/join');
                }}
                className='btn btn-outline-secondary btn-block'>
                회원가입
            </button>

        </form>
    )
}

const LoginWithEmail = (props: {
    value: LoginInputData,
    onChangeValue: OnChangeValue<LoginInputData>,
    onLoginWithEmail: OnChangeValue<LoginInputData>,
}) => {
    const onChangeValue = (key: string, value: any) => {
        props.onChangeValue(CopyUtil.onChangeValue(props.value, key, value));
    }

    const onLogin = () => {
        props.onLoginWithEmail(props.value);
    }

    return (
        <form
            className={cx("box-form")}
            onSubmit={event => {
                event.preventDefault();
                onLogin();
            }}>
            <p>Email</p>
            <input
                className='form-control'
                type='email'
                placeholder='이메일을 입력하여 주십시오.'
                value={props.value.email}
                onChange={event => {
                    onChangeValue("email", event.target.value);
                }}/>
            <p>Password</p>
            <input
                className='form-control'
                type='password'
                placeholder='비밀번호를 입력하여 주십시오.'
                value={props.value.pw}
                onKeyUp={event => {
                    if (event.key === 'Enter') {
                        props.onChangeValue(props.value)
                    }
                }}
                onChange={event => {
                    onChangeValue("pw", event.target.value);
                }}/>

            <hr/>
            <button
                style={{marginBottom: 10}}
                type="submit"
                className="btn btn-primary btn-block">
                로그인
            </button>

            {/*회원가입*/}
            <button
                onClick={event => {
                    navigate('/auth/join');
                }}
                className='btn btn-outline-secondary btn-block'>
                회원가입
            </button>

            {/*회원가입, 비밀번호 찾기 안내 */}
            <div style={{height: 10}}/>
            <p>비밀번호를 잊으셨습니까?
                <span style={{
                    paddingLeft: 10,
                    color: '#86a7cd',
                    cursor: "pointer"
                }} onClick={FB.resetPwWithEmailUsingSwal}>초기화</span>
            </p>
        </form>
    )
}
