import React from "react";
import classNamesBind from "classnames/bind";
import styles from './MngCodeDefault.module.scss'
import {MngLayout} from "../../layout/MngLayout";
import {MngCodeResp} from "../MngCode";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import moment from "moment";
import {IconButton, Tooltip} from "@material-ui/core";
import {Close, FileCopyOutlined} from "@material-ui/icons";
import {OnChangeValue} from "../../../interfaces";
import {Pager} from "../../../shares/default/pager/Pager";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import copy from "copy-to-clipboard";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {
    MutationCreateDisposableJoinCodeWithSmsFeeArgs,
    MutationCreateReusableJoinCodeWithSmsFeeArgs
} from "../../../../graphql/types";
import {MngCodeDefaultCreate} from "./create/MngCodeDefaultCreate";

const cx = classNamesBind.bind(styles);

export const MngCodeDefault = (props: {
    data?: MngCodeResp,
    onCreateReusableCode: OnChangeValue<MutationCreateReusableJoinCodeWithSmsFeeArgs>,
    onCreateDisposableCode: OnChangeValue<MutationCreateDisposableJoinCodeWithSmsFeeArgs>,
    onDeleteCode: OnChangeValue<string>,
    onChangeReusableCodePage: OnChangeValue<number>,
    onChangeDisposableCodePage: OnChangeValue<number>,
}) => {
    const onCopyClipboard = (joinCode: string) => {
        SwalUtil.ok({
            msg: `클립보드에 복사되었습니다.<br/>${joinCode}`,
            icon: 'success',
        });

        copy(`${window.location.host}/auth/join/Agent/${joinCode}`);
    }

    return (
        <MngLayout title={'대리점관리'}>
            {props.data &&
            <FadeIn>
                <BizTitle>코드생성</BizTitle>
                <MngCodeDefaultCreate {...props}/>
                <div style={{height: 50}}/>

                <BizTitle>재사용 코드</BizTitle>
                <div className={cx('table')}>
                    <div className={cx("codeCell")}>가입코드</div>
                    <div className={cx("dateCell")}>생성일</div>
                    <div className={cx("lineCell")}>회선정보</div>
                    <div className={cx("funcCell")}>기능</div>
                </div>

                {props.data.reusableJoinCodeList.list.length === 0 &&
                <div className={cx('table')}>
                    <div style={{flexGrow: 1}}>가입 코드가 없습니다.</div>
                </div>
                }

                {props.data.reusableJoinCodeList.list.map((value, index) => (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx("codeCell")} style={{cursor: 'pointer'}}
                             onClick={ev => {
                                 onCopyClipboard(value.code);
                             }}>{value.code} <FileCopyOutlined/> </div>
                        <div className={cx("dateCell")}>
                            {moment(value.createdAt).format('YY.MM.DD hh:mm a')}
                        </div>
                        <div className={cx("lineCell", "textLeft")}>
                            {value.apiPermission.map((apiPermission, apiPermissionIdx) => (
                                <span style={{marginRight: 10}}
                                      key={apiPermissionIdx}>{apiPermission.nm} : {apiPermission.smsFee} 원, </span>
                            ))}
                        </div>
                        <div className={cx("funcCell")}>
                            <Tooltip title={'삭제'}>
                                <IconButton size='small' onClick={ev => {
                                    props.onDeleteCode(value.code);
                                }}>
                                    <Close/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ))}

                <Pager
                    page={props.data.reusableJoinCodeList.page}
                    size={props.data.reusableJoinCodeList.size}
                    total={props.data.reusableJoinCodeList.total}
                    onChange={props.onChangeReusableCodePage}/>

                <div style={{height: 50}}/>

                <BizTitle>일회용 코드</BizTitle>
                <div className={cx('table')}>
                    <div className={cx("codeCell")}>가입코드</div>
                    <div className={cx("dateCell")}>생성일</div>
                    <div className={cx("lineCell")}>회선정보</div>
                    <div className={cx("funcCell")}>기능</div>
                </div>

                {props.data.disposableJoinCodeList.list.length === 0 &&
                <div className={cx('table')}>
                    <div style={{flexGrow: 1}}>가입 코드가 없습니다.</div>
                </div>
                }

                {props.data.disposableJoinCodeList.list.map((value, index) => (
                    <div key={index} className={cx('table', 'hover')}>
                        <div className={cx("codeCell")} style={{cursor: 'pointer'}}
                             onClick={ev => {
                                 onCopyClipboard(value.code);
                             }}>
                            {value.code} <FileCopyOutlined/>
                        </div>
                        <div className={cx("dateCell")}>
                            {moment(value.createdAt).format('YY.MM.DD hh:mm a')}
                        </div>
                        <div className={cx("lineCell")}>
                            {value.apiPermission.map((apiPermission, apiPermissionIdx) => (
                                <span style={{marginRight: 10}}
                                      key={apiPermissionIdx}>{apiPermission.nm} : {apiPermission.smsFee} 원, </span>
                            ))}
                        </div>
                        <div className={cx("funcCell")}>
                            <Tooltip title={'삭제'}>
                                <IconButton size='small' onClick={ev => {
                                    props.onDeleteCode(value.code);
                                }}>
                                    <Close/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ))}

                <Pager
                    page={props.data.disposableJoinCodeList.page}
                    size={props.data.disposableJoinCodeList.size}
                    total={props.data.disposableJoinCodeList.total}
                    onChange={props.onChangeDisposableCodePage}/>
            </FadeIn>
            }
        </MngLayout>
    )
}
