import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './CsListTemp2UserAlertList.module.scss';
import {Query} from "../../../../../graphql/types";
import moment from "moment";
import {OnChangeValue} from "../../../../interfaces";
import {PagerTemp2} from "../../../../shares/temp2/pager/PagerTemp2";

const cx = classNamesBind.bind(styles);

export const CsListTemp2UserAlertList = (props: {
    value: Query,
    onChangeUserAlertPage: OnChangeValue<number>,
}) => {
    return (
        <>
                <div className={cx("side-contentsColumn", "headerColumn")}>
                    <div className={cx("contentsColumn-topHeader", "dateCell")}>
                        날짜
                    </div>
                    <div className={cx("contentsColumn-topHeader", "descCell")}>
                        내용
                    </div>
                </div>

                {props.value.userAlertList.list.map((value, index) => (
                    <div key={index} className={cx('side-contentsColumn', 'hover')}>
                        <div className={cx("contentsColumn-bottomValue", "dateCell")}>
                            {moment(value.createdAt).format('YY.MM.DD hh:mm a')}
                        </div>
                        <div className={cx("contentsColumn-bottomValue", "descCell")}>
                            {value.content}
                        </div>
                    </div>
                ))}

                <PagerTemp2
                    page={props.value.userAlertList.page}
                    size={props.value.userAlertList.size}
                    total={props.value.userAlertList.total}
                    onChange={props.onChangeUserAlertPage}/>
        </>
    )
}
