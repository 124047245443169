import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './NoticeForAllTemp1.module.scss';
import {NoticeForAllResp} from "../NoticeForAll";
import {OnChangeValue} from "../../../interfaces";
import moment from "moment";
import {FadeInContainer} from "../../../shares/default/fadeInContainer/FadeInContainer";
import {BizModal} from "../../../shares/default/bizModal/BizModal";
import {Board} from "../../../../graphql/types";
import {PagerTemp1} from "../../../shares/temp1/pager/PagerTemp1";

const cx = classNamesBind.bind(styles);

export const NoticeForAllTemp1 = (props: {
    data?: NoticeForAllResp,
    onChangePage: OnChangeValue<number>,
}) => {
    const [data, setData] = useState<{
        modal: boolean,
        board: Board | undefined,
    }>({
        modal: false,
        board: undefined,
    })

    return (
        <>
            {data.modal && data.board &&
            <BizModal
                title={data.board.title}
                show={data.modal}
                onChangeShow={value => {
                    setData({
                        modal: value,
                        board: undefined,
                    })
                }}>
                <div className={cx('cont-notice')} ref={ref => {
                    if (ref) {
                        ref.innerHTML = data.board?.content || ''
                    }
                }}/>
            </BizModal>
            }

            {props.data &&
            <FadeInContainer>
                <div className={cx("fullContainer")}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-left")}>
                                        공지사항
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cx("secondSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-contentsColumn", "headerColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "numCell")}>
                                        번호
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "titleCell")}>
                                        제목
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "dateCell")}>
                                        공지일
                                    </div>
                                </div>

                                {props.data.notice.list.map((value, index) => {
                                    return (
                                        <div className={cx("side-contentsColumn", "hover")} key={index} onClick={() => {
                                            setData({
                                                modal: true,
                                                board: value,
                                            })
                                        }}>
                                            <div className={cx("contentsColumn-bottomValue", "numCell")}>
                                                {value.id}
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "titleCell")}>
                                                {value.title}
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "dateCell")}>
                                                {moment(value.createdAt).format('YY.MM.DD')}
                                            </div>
                                        </div>
                                    )
                                })}
                                <PagerTemp1
                                    page={props.data.notice.page}
                                    size={props.data.notice.size}
                                    total={props.data.notice.total}
                                    onChange={props.onChangePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeInContainer>
            }
        </>

    )
}
