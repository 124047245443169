import React, {Fragment, ReactNode, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './LayoutWeb.module.scss'
import {IconButton, Tooltip} from "@material-ui/core";
import {Dashboard, PowerSettingsNew} from "@material-ui/icons";
import {A, navigate} from "hookrouter";
import {FooterWeb} from "./footer/FooterWeb";
import {useDispatch, useSelector} from "react-redux";
import {SystemAction, SystemState} from "../../../../redux/System";
import {LayoutState} from "../../../../redux/Layout";
import {UserState} from "../../../../redux/User";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {Spring} from "react-spring/renderprops-universal";
import {NavBar} from "../../Layout";
import {LayoutDefaultLogo} from "../share/logo/LayoutDefaultLogo";

const cx = classNamesBind.bind(styles);

export const LayoutWeb = (props: {
    navBar: NavBar[],
    children: ReactNode
}) => {
    const system = useSelector<any, SystemState>(state => state.system);
    const user = useSelector<any, UserState>(state => state.user);
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const dispatch = useDispatch();


    const [aniState, setAniState] = useState(
        ((system.scrollY < 200) && (layout.appBarTransparent)) ?
            AniProps.navbarHide :
            AniProps.navbarShow)

    useEffect(() => {
        if ((system.scrollY < 200) && (layout.appBarTransparent)) {
            setAniState(AniProps.navbarHide)
        } else {
            setAniState(AniProps.navbarShow)
        }
    }, [system.scrollY, layout.appBarTransparent])


    const onLogout = () => {
        SwalUtil.yn({
            msg: "로그아웃 하시겠습니까?",
            icon: "question",
            ok: () => {
                DataUtil.logout()
                    .then(res => {
                        navigate('/');
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: () => {
                            navigate('/');
                        }
                    }));
            }
        })
    }

    const onChangeTemplate = () => {
        SwalUtil.input(value => {
            dispatch(SystemAction.setInit({...system.init, viewTemplate: value}));
        }, '템플릿 이름을 입력하여 주십시오.', 'Template 이름');
    }

    return (
        <Fragment>
            <Spring
                from={aniState}
                to={aniState}>
                {params => (
                    <Fragment>
                        <div
                            className={cx('appbar')}
                            style={{
                                background: params.background,
                            }}>
                        </div>

                        <div className={cx('appbar')}>
                            <div
                                style={{color: params.color}}
                                className={cx('btn')}
                                onClick={event => {
                                    navigate('/');
                                }}>
                                <LayoutDefaultLogo/>
                            </div>

                            {props.navBar.map((value, index) => {
                                return (
                                    <A href={value.router}
                                       key={index}
                                       style={{color: params.color}}
                                       className={cx('btn')}>
                                        {value.title}
                                    </A>
                                )
                            })}

                            {process.env.REACT_APP_ENV === 'dev' &&
                            <IconButton onClick={onChangeTemplate} size={'small'}>
                                <Dashboard/> {system.init.viewTemplate}
                            </IconButton>
                            }

                            {user.isLogin &&
                            <Fragment>
                                <IconButton
                                    className={cx('btn-color')}
                                    edge="end" color="inherit" aria-label="menu"
                                    onClick={event => {
                                        onLogout();
                                    }}>
                                    <Tooltip title='로그아웃'>
                                        <PowerSettingsNew/>
                                    </Tooltip>
                                </IconButton>
                            </Fragment>
                            }
                        </div>
                    </Fragment>
                )}
            </Spring>

            {layout.topMargin && <div style={{height: 120}}/>}
            <div className={cx('contents')}>
                {props.children}
            </div>

            {window.location.pathname !== "/" && <FooterWeb appName={system.init.appNm}/>}
        </Fragment>
    )
}

const AniProps = {
    navbarShow: {
        background: 'rgba(0,0,0,0.5)',
        color: 'rgba(255,255,255,1)',
    },
    navbarHide: {
        background: 'rgba(0,0,0,0.0)',
        color: 'rgba(255,255,255,1)',
    },
}



