import {ApolloClient, ApolloLink, from, InMemoryCache} from "@apollo/client";
import {LocalStorage} from "../localstorage/LocalStorage";
import {setContext} from '@apollo/client/link/context';
import {createUploadLink} from 'apollo-upload-client';
import {onError} from "@apollo/client/link/error";
import {SwalUtil} from "../swal/swalUtil";
import {BizErrors} from "../../graphql/errors";
import {navigate} from "hookrouter";
import {ReduxStore} from "../../index";
import {UserAction} from "../../redux/User";
import {GlobalVars} from "../../globalVars/globalVars";
import {AppAuth, SystemAction} from "../../redux/System";

const apolloLink = from([
    setContext((operation, prevContext) => {
        return {
            headers: {
                ...prevContext.headers,
                Authorization: LocalStorage.getSession(),
                apikey: process.env.REACT_APP_APIKEY
            }
        }
    }),
    onError(error => {
        const {graphQLErrors, networkError} = error;
        GlobalVars.layout.loadingView(false);

        if (networkError) {
            return error.forward(error.operation);
        }

        if (!graphQLErrors) {
            return error.forward(error.operation);
        }

        for (let error of graphQLErrors) {
            if (error.message === BizErrors.needLogin) {
                if (ReduxStore.getState().user.isLogin) {
                    SwalUtil.ok({
                        msg: "로그인이 필요한 서비스 입니다.",
                        icon: "error"
                    });
                    navigate("/");
                }
                ReduxStore.dispatch(UserAction.logout());
                LocalStorage.clearSession();
            }

            if (error.message === BizErrors.serverInChecking) {
                ReduxStore.dispatch(SystemAction.setAppAuth(AppAuth.ShutDown));
            }
        }
        return error.forward(error.operation);
    }),
    new ApolloLink((operation, forward) => {
        if (operation.getContext().hasOwnProperty('loadingView')) {
            GlobalVars.layout.loadingView(operation.getContext().loadingView);
        } else {
            GlobalVars.layout.loadingView(true);
        }

        return forward(operation).map((data) => {
            GlobalVars.layout.loadingView(false);
            return data
        })
    }),
    createUploadLink({
        uri: process.env.REACT_APP_API || '',
    }) as any,
]);

export class Apollo {
    static client = new ApolloClient({
        link: apolloLink,
        connectToDevTools: true,
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
                nextFetchPolicy: 'network-only'
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            }
        }
    })
}
