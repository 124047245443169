import React, {Fragment, ReactNode, useEffect} from "react";

export const BizScrollTop = (props: {
    children: ReactNode
}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Fragment>
            {props.children}
        </Fragment>
    )
}
