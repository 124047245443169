import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ResultMsgReceiptDefault.module.scss'
import {Pager} from "../../../shares/default/pager/Pager";
import {OnChangeValue, OnVoidFunction} from "../../../interfaces";
import {ResultMsgReceiptResp} from "../ResultMsgReceipt";
import {BizCol12, BizContainer} from "../../../shares/default/bizContainer/BizContainer";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {A} from "hookrouter";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {MutationHideMsgReceiptArgs, PeriodTimeDay} from "../../../../graphql/types";
import {BizPeriodPicker} from "../../../shares/default/bizPeriodPicker/BizPeriodPicker";
import {IconButton} from "@material-ui/core";
import {Delete} from "@material-ui/icons";

const cx = classNamesBind.bind(styles);

export const ResultMsgReceiptDefault = (props: {
    data: ResultMsgReceiptResp,
    periodTimeDay?: PeriodTimeDay,
    onHideMsgReceipt: OnChangeValue<MutationHideMsgReceiptArgs>,
    onHideAllMsgReceipt: OnVoidFunction,
    onChangePeriod: OnChangeValue<PeriodTimeDay>,
    onChangePage: OnChangeValue<number>
}) => {
    const [period, setPeriod] = useState({
        stDate: new Date(),
        edDate: new Date(),
    });

    useEffect(() => {
        if (!props.periodTimeDay) {
            return;
        }

        setPeriod({
            stDate: Pipe.cvtTimeDayToDate(props.periodTimeDay.stDate),
            edDate: Pipe.cvtTimeDayToDate(props.periodTimeDay.edDate),
        })
    }, [props.periodTimeDay]);

    const handler = {
        onChangePeriod: () => {
            props.onChangePeriod({
                stDate: Pipe.toTimeDay(period.stDate),
                edDate: Pipe.toTimeDay(period.edDate),
            });
        }
    }

    return (
        <BizContainer>
            <BizCol12>
                <BizTitle>기간선택</BizTitle>
                <div className={cx('table')}>
                    <div style={{width: '100%'}}>
                        <BizPeriodPicker
                            period={period}
                            onChange={setPeriod}/>
                    </div>
                </div>
                <div className={cx('table')}>
                    <div className='text-right' style={{width: '100%'}}>
                        <button
                            style={{width: 100}}
                            className='btn btn-sm btn-success'
                            onClick={handler.onChangePeriod}>검색
                        </button>
                    </div>
                </div>

                <div className={cx('notice')}>
                    <div className={cx('title')}>👑 전송 결과값이 적용되기까지는 최대 48시간이 소요됩니다.</div>
                    <div className={cx('text')}>전송 결과값은 대상 휴대폰에서 한국 통신사를 거쳐, 해외 통신사까지 도착되어야 하기 때문에 최대 48시간의 시간이 소요될 수 있습니다.</div>
                    <div className={cx('text')}>"수신결과대기" 상태란, 전송은 이미 처리 완료되었으나 그에 대한 전송 결과값에 대한 수신이 대기 중인 상태를 뜻합니다.</div>
                    <div className={cx('text')}>"수신실패" 상태는, ① 없는 번호 이거나, ②스팸차단 되었거나, ③ 수신 불가 지역에 있거나, ④ 휴대폰이 꺼져있는 등으로 인해 발생될 수 있습니다.</div>
                </div>

                <div style={{height: 40}}/>
                <div className={cx('cont-title')}>
                    <div className={cx('half')}>
                        <BizTitle>전송결과</BizTitle>
                    </div>
                    <div className={cx('half', 'text-right')}>
                        <button
                            className='btn btn-sm btn-outline-danger'
                            onClick={props.onHideAllMsgReceipt}>모두삭제</button>
                    </div>
                </div>

                <div className={cx('table')}>
                    <div className={cx('send-date')}>전송일</div>
                    <div className={cx('content')}>내용</div>
                    <div className={cx('mb-hidden', 'api')}>회선</div>
                    <div className={cx('mb-hidden', 'sent-sms', 'text-center')}>전송</div>
                    <div className={cx('delete')}>삭제</div>
                </div>

                {props.data.msgReceipt.list.map((value, index) => {
                    return (
                        <div key={index} className={cx('table', 'hover')}>
                            <div
                                className={cx('send-date')}>{moment(value.createdAt).format('MM.DD - hh:mm a')}</div>
                            <div className={cx('content')}>
                                <A href={`/result/telbook/${value.requestId}`}>
                                    {value.contents}
                                </A>
                            </div>
                            <div className={cx('mb-hidden', 'api')}>{value.apiKeyNm}</div>
                            <div className={cx('mb-hidden', 'sent-sms')}>
                                {Pipe.toSeparatorNumber(value.sent)} 건
                            </div>
                            <div className={cx('delete')}>
                                <IconButton size='small' onClick={() => {
                                    props.onHideMsgReceipt({msgReceiptIdList: [value.id]});
                                }}>
                                    <Delete/>
                                </IconButton>
                            </div>
                        </div>
                    )
                })}

                {props.data.msgReceipt.list.length === 0 &&
                <div className={cx('table')}>
                    <div style={{width: '100%'}}>전송 결과가 없습니다.</div>
                </div>
                }

                <Pager
                    page={props.data.msgReceipt.page}
                    size={props.data.msgReceipt.size}
                    total={props.data.msgReceipt.total}
                    onChange={props.onChangePage}/>
            </BizCol12>
        </BizContainer>

    )
}
