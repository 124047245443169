import React from "react";
import classNamesBind from "classnames/bind";
import styles from './DepositRequestInput.module.scss'
import {DepositRequestInputData} from "../../DepositRequest";
import {OnChangeValue} from "../../../../interfaces";
import NumberFormat from "react-number-format";
import Decimal from "decimal.js";
import {CopyUnit} from "../../../../../utils/copy/CopyUtil";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {Button, TextField} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {Pipe} from "../../../../../utils/pipe/Pipe";

const cx = classNamesBind.bind(styles);

export const DepositRequestInput = (props: {
    value: DepositRequestInputData,
    onChangeValue: OnChangeValue<DepositRequestInputData>,
    onRequest: OnChangeValue<DepositRequestInputData>,
}) => {
    const copyUnit = new CopyUnit(props.onChangeValue);
    const user = useSelector<any, UserState>(state => state.user);


    const onBlurInputPoint = () => {
        if (0 < props.value.point % 1000) {
            // SwalUtil.ok({
            //     msg: "최소 충전 기본단위는 1000원 입니다.",
            //     icon: "info"
            // });
            copyUnit.onChangeValue(props.value, 'point', props.value.point - (props.value.point % 1000));
        }

        if (props.value.point < 10000) {
            SwalUtil.ok({
                msg: "최소 충전 금액은 10,000 원 입니다.",
                icon: "info"
            });
            copyUnit.onChangeValue(props.value, 'point', 10000);
        }
    }


    return (
        <>
            <div className={cx("fullContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-left")}>
                                    입금확인요청
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={cx("secondSection")}>
                    <div className={cx("side-rowBox")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox", "leftBox")}>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        입금자명
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            type='text'
                                            inputProps={{style: {textAlign: "center"}}}
                                            value={props.value.nm}
                                            onChange={event => {
                                                copyUnit.onChangeValue(props.value, 'nm', event.target.value);
                                            }}/>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        충전포인트
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        <NumberFormat
                                            thousandSeparator
                                            allowNegative={false}
                                            decimalScale={0}
                                            placeholder='충전 포인트를 입력하여 주십시오.'
                                            customInput={TextField}
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{style: {textAlign: "center"}}}
                                            value={props.value.point}
                                            onValueChange={value => {
                                                copyUnit.onChangeValue(props.value, 'point', value.floatValue || 0);
                                            }}
                                            onBlur={onBlurInputPoint}/>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        연락처 <span style={{color: "#999999"}}>(*선택 입력 사항)</span>
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            type='text'
                                            inputProps={{style: {textAlign: "center"}}}
                                            value={props.value.tel}
                                            onChange={event => {
                                                copyUnit.onChangeValue(props.value, "tel", event.target.value);
                                            }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox", "resultBox")}>
                                최종 입금 하셔야 하는 금액은
                                <div className={cx("alertColor")}>
                                    <NumberFormat
                                        thousandSeparator
                                        allowNegative={false}
                                        decimalScale={0}
                                        displayType="text"
                                        type="text"
                                        style={{marginRight: 5}}
                                        value={new Decimal(props.value.point)
                                            .times(new Decimal(user.user.tax).add(1))
                                            .toNumber()}/>원
                                </div>
                                입니다
                            </div>
                        </div>
                    </div>
                    <div className={cx("section-side", "marginTop")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-title")}>
                                    <div className={cx("title-left")}>
                                        <FontAwesomeIcon icon={faChevronRight}
                                                         style={{paddingTop: 3, marginRight: 5}}/> 충전 후 결과 예상
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn", "borderBottom")}>
                                    <div className={cx("contentsColumn-topHeader", "variableCell")}>
                                        구분
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "balanceCell")}>
                                        잔고
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "pointCell")}>
                                        충전량
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "sumCell")}>
                                        합계
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn", "borderBottom")}>
                                    <div className={cx("contentsColumn-bottomValue", "variableCell")}>
                                        잔고
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "balanceCell")}>
                                        {Pipe.toSeparatorNumber(user.user.point)} P
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "pointCell")}>
                                        {Pipe.toSeparatorNumber(props.value.point)} P
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "sumCell")}>
                                        {Pipe.toSeparatorNumber(new Decimal(user.user.point).add(props.value.point).toNumber())} P
                                    </div>
                                </div>
                                {user.user.apiPermission.map((value, index) => {
                                    const hasPointPerSms = new Decimal(user.user.point).divToInt(value.smsFee).toNumber();
                                    const rechargePointPerSms = new Decimal(props.value.point).divToInt(value.smsFee).toNumber();
                                    const totalPerSms = hasPointPerSms + rechargePointPerSms;
                                    return (
                                        <div className={cx("side-contentsColumn")} key={index}>
                                            <div className={cx("contentsColumn-bottomValue", "variableCell")}>
                                                전송가능 건 수
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "balanceCell")}>
                                                {Pipe.toSeparatorNumber(hasPointPerSms)} 건
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "pointCell")}>
                                                {Pipe.toSeparatorNumber(rechargePointPerSms)} 건
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "sumCell")}>
                                                {Pipe.toSeparatorNumber(totalPerSms)} 건
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={cx("section-side", "marginTop")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-contentsColumn", "infoColumn")}>
                                <div className={cx("aboutBox")}>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 입금계좌문의 텔레그램 : <a href="https://t.me/Zsms82" target="_blank">@Zsms82</a></div>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 입력하신 연락처로 입금정보를 전송 해드립니다.</div>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 연락처는 추가 입력 사항으로, 입력하지 않을 시 안내문자는
                                        발송되지 않습니다.
                                    </div>
                                    <div><FontAwesomeIcon icon={faExclamationCircle}/> 입금하셔야 하는 금액은 자동으로 계산됩니다.</div>
                                </div>
                                <div className={cx("buttonBox")}>
                                    <Button
                                        classes={{root: cx("buttonOne")}}
                                        variant="outlined"
                                        size="large"
                                        style={{width: 130}}
                                        type="submit"
                                        onClick={() => {
                                            props.onRequest(props.value);
                                        }}>확인요청
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
