import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './NoticeForAllDefault.module.scss'
import {NoticeForAllResp} from "../NoticeForAll";
import {BizCol12} from "../../../shares/default/bizContainer/BizContainer";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {Pager} from "../../../shares/default/pager/Pager";
import {OnChangeValue} from "../../../interfaces";
import moment from "moment";
import {FadeInContainer} from "../../../shares/default/fadeInContainer/FadeInContainer";
import {BizModal} from "../../../shares/default/bizModal/BizModal";
import {Board} from "../../../../graphql/types";

const cx = classNamesBind.bind(styles);

export const NoticeForAllDefault = (props: {
    data?: NoticeForAllResp,
    onChangePage: OnChangeValue<number>,
}) => {
    const [data, setData] = useState<{
        modal: boolean,
        board: Board | undefined,
    }>({
        modal: false,
        board: undefined,
    })

    return (
        <>
            {data.modal && data.board &&
            <BizModal
                title={data.board.title}
                show={data.modal}
                onChangeShow={value => {
                    setData({
                        modal: value,
                        board: undefined,
                    })
                }}>
                <div className={cx('cont-notice')} ref={ref => {
                    if (ref) {
                        ref.innerHTML = data.board?.content || ''
                    }
                }}/>
            </BizModal>
            }

            {props.data &&
            <FadeInContainer>
                <BizCol12>
                    <BizTitle>공지사항</BizTitle>
                    <div className={cx('table')}>
                        {/*<div className={cx("numCell", "hiddenCell")}>번호</div>*/}
                        <div className={cx("titleCell")}>제목</div>
                        <div className={cx("dateCell")}>공지일</div>
                    </div>

                    {props.data.notice.list.map((value, index) => {
                        return (
                            <div
                                key={index} className={cx('table', 'hover')}
                                onClick={event => {
                                    setData({
                                        modal: true,
                                        board: value,
                                    })
                                }}>
                                {/*<div className={cx("numCell", "hiddenCell")}>*/}
                                {/*    {value.id}*/}
                                {/*</div>*/}
                                <div className={cx("titleCell")}>
                                    {value.title}
                                </div>
                                <div className={cx("dateCell")}>
                                    {moment(value.createdAt).format('YY.MM.DD')}
                                </div>
                            </div>
                        )
                    })}

                    <Pager
                        page={props.data.notice.page}
                        size={props.data.notice.size}
                        total={props.data.notice.total}
                        onChange={props.onChangePage}/>
                </BizCol12>
            </FadeInContainer>
            }
        </>

    )
}
