import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";

export enum ViewMode {
    Web, Mobile
}

export interface AppState {
    viewMode: ViewMode;
    loadingView: boolean;
    showTopNav: boolean;
    photoBackground: boolean;
}

const initState: AppState = {
    viewMode: ViewMode.Web,
    loadingView: false,
    showTopNav: false,
    photoBackground: true,
}

class App extends ImmerReducer<typeof initState> {
    setViewMode(viewMode: ViewMode) {
        this.draftState.viewMode = viewMode;
    }

    setWindowWidth(width: number) {
        if (width < 998) {
            this.draftState.viewMode = ViewMode.Mobile;
        } else {
            this.draftState.viewMode = ViewMode.Web;
        }
    }

    setLoadingView(isView: boolean) {
        this.draftState.loadingView = isView;
    }

    setTopNav(isView: boolean) {
        this.draftState.showTopNav = isView;
    }

    setPhotoBackground(isView: boolean) {
        this.draftState.photoBackground = isView;
    }
}


export const AppAction = createActionCreators(App);
export const AppReducer = createReducerFunction(App, initState);

