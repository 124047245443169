import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './SmsBulkDefaultConfirmModal.module.scss'
import {OnVoidFunction} from "../../../../interfaces";
import {ProgressBar} from "react-bootstrap";
import {SendBulkInputData, SendBulkResp} from "../../SmsBulk";
import emojiStrip from "emoji-strip";
import {NumberUtil} from "../../../../../utils/numbUtil/NumbUtil";
import {Tooltip} from "@material-ui/core";
import {HashedApiKey} from "../../../../../graphql/types";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import Decimal from "decimal.js";
import {Pipe} from "../../../../../utils/pipe/Pipe";
import {ReduxStore} from "../../../../../index";

const cx = classNamesBind.bind(styles);

export const SmsBulkDefaultConfirmModal = (props: {
    onSendBulk: OnVoidFunction,
    onCancel: OnVoidFunction,
    value: SendBulkInputData,
    data: SendBulkResp,
    hashedApiKey: HashedApiKey,
}) => {
    const [activate, setActivate] = useState<Activation>({
        noMsg: false,
        noNumb: false,
        overMsg: false,
        overNumber: false,
        hasEmoji: false,
        hasWrongNumber: false,
        hasDuplicatedNumber: false,
        notEnoughPoint: false,
        sendAvailable: true,
    });

    const user = useSelector<any, UserState>(state => state.user);

    useEffect(() => {
        const copy: Activation = {
            noMsg: false,
            noNumb: false,
            overMsg: false,
            overNumber: false,
            hasEmoji: false,
            hasWrongNumber: false,
            hasDuplicatedNumber: false,
            notEnoughPoint: false,
            sendAvailable: true
        };

        if (props.value.msg.msg === "") {
            copy.noMsg = true;
        }

        if ((props.value.numb.rawNumb + props.value.numb.rawAppendNumb) === "") {
            copy.noNumb = true;
        }

        if (props.value.msg.maxLength < props.value.msg.msgLength) {
            copy.overMsg = true;
        }
        if (props.value.msg.msg !== emojiStrip(props.value.msg.msg)) {
            copy.hasEmoji = true;
        }

        if (props.value.numb.maxLength < props.value.numb.numbLength) {
            copy.overNumber = true;
        }

        // 전화번호부
        let origin = NumberUtil.splitNumb(`${props.value.numb.rawNumb}\n${props.value.numb.rawAppendNumb}`);
        if (origin.length !== NumberUtil.exceptWrongNumber(origin).length) {
            copy.hasWrongNumber = true;
        }

        if (origin.length !== NumberUtil.exceptDuplicated(origin).length) {
            copy.hasDuplicatedNumber = true;
        }

        // 잔고확인
        copy.notEnoughPoint = new Decimal(props.hashedApiKey.smsFee).mul(props.value.numb.numbLength).gt(ReduxStore.getState().user.user.point);

        // 조건에 하나라도 거절되면 전송이 금지된다.
        if (copy.overNumber || copy.overMsg || copy.hasWrongNumber || copy.noMsg || copy.noNumb || copy.notEnoughPoint) {
            copy.sendAvailable = false;
        }

        setActivate(copy);
    }, [props]);

    return (
        <>
            <div className={cx('cont')}>
                <div className={cx('box-title')}>잔고</div>

                <hr/>
                <div className={cx('box-check')}>
                    <div className={cx('label')}>포인트</div>
                    <div className={cx('text-right', 'gray')}
                         style={{flexGrow: 1}}>{Pipe.toSeparatorNumber(user.user.point)} P
                    </div>
                </div>

                <div className={cx('box-check')}>
                    <div className={cx('label')}>전송비용</div>
                    <div className={cx('text-right', 'gray')}
                         style={{flexGrow: 1}}>
                        {props.hashedApiKey.nm} ({props.hashedApiKey.smsFee} P) X {props.value.numb.numbLength} 개
                        = {Pipe.toSeparatorNumber(new Decimal(props.hashedApiKey.smsFee).mul(props.value.numb.numbLength).toNumber())} P
                    </div>
                </div>
                <hr/>
                <div className={cx('box-check')}>
                    <div className={cx('label')}>전송 후 포인트</div>
                    <div className={cx('text-right')}
                         style={{flexGrow: 1}}>
                        {Pipe.toSeparatorNumber(new Decimal(user.user.point).sub(new Decimal(props.hashedApiKey.smsFee).mul(props.value.numb.numbLength)).toNumber())} P
                    </div>
                </div>

                {activate.notEnoughPoint && <div className={cx('text-right', 'red')}>* 잔고가 부족합니다.</div>}


                <div style={{height: 50}}/>
                <div className={cx('box-title')}>입력값 확인</div>
                <hr/>
                <div className={cx('box-check')}>
                    <div className={cx('label')}>글자수</div>
                    <div className={cx('bar')}>
                        <ProgressBar
                            variant={props.value.msg.msgLength < props.value.msg.maxLength ? '' : 'danger'}
                            now={props.value.msg.msgLength / props.value.msg.maxLength * 100}/>
                        {activate.noMsg && <p className={cx('info')}>* 문자내용이 없습니다.</p>}
                        {activate.hasEmoji && <p className={cx('info')}>* 이모지가 추가되어있습니다.</p>}
                        {activate.overMsg && <p className={cx('info')}>* 글자수가 많습니다.</p>}
                    </div>
                    <div
                        className={cx('label', 'text-right', 'gray')}>({props.value.msg.msgLength} / {props.value.msg.maxLength})
                    </div>
                </div>
                <hr/>
                <div className={cx('box-check')}>
                    <div className={cx('label')}>전화번호</div>
                    <div className={cx('bar')}>
                        <ProgressBar
                            variant={props.value.numb.numbLength < props.value.numb.maxLength ? '' : 'danger'}
                            now={props.value.numb.numbLength / props.value.numb.maxLength * 100}/>
                        {activate.noNumb && <p className={cx('info')}>* 전화번호가 없습니다.</p>}
                        {activate.overNumber && <p className={cx('info')}>* 전화번호가 너무 많습니다.</p>}
                        {activate.hasWrongNumber && <p className={cx('info')}>* 잘못된 형식의 전화번호가 있습니다.</p>}
                        {activate.hasDuplicatedNumber && <p className={cx('info')}>* 중복된 전화번호가 있습니다.</p>}
                    </div>
                    <div
                        className={cx('label', 'text-right', 'gray')}>({props.value.numb.numbLength} / {props.value.numb.maxLength})
                    </div>
                </div>
                <div className={cx('cont-btn', 'text-right')}>
                    <button
                        className='btn btn-sm btn-outline-danger'
                        style={{marginRight: 10}}
                        onClick={props.onCancel}>취소
                    </button>

                    <button
                        className='btn btn-sm btn-success'
                        disabled={!activate.sendAvailable}
                        onClick={props.onSendBulk}>동의 후 전송
                    </button>

                </div>

                <div style={{height: 50}}/>
                <div className={cx('box-title', 'red')}>주의사항</div>
                <div className='text-left' style={{width: '100%'}}>
                    <div className={cx('info')}>* 최대 {Pipe.toSeparatorNumber(props.value.numb.maxLength)} 개의 번호를 한번에 전송 할 수 있습니다.</div>
                    <div className={cx('info')}>* 전화번호 정리 기능을 활용하여 잘못된 번호와 중복된 전화번호를 삭제하여 주십시오.</div>
                    <div className={cx('info')}>* 중복된 전화번호는 1번만 발송 됩니다.</div>
                    <div className={cx('info')}>* 형식이 맞지 않는 잘못된 전화번호는 발송되지 않습니다.</div>
                    <div className={cx('info')}>* 이모지 사용시 수신율이 떨어질수 있습니다.</div>
                    <div className={cx('info')}>* 회선 추가는 관리자에게 문의하여 주십시오.</div>
                </div>
            </div>
        </>

    )
}

interface Activation {
    noMsg: boolean;
    noNumb: boolean;
    overMsg: boolean;
    hasEmoji: boolean;
    overNumber: boolean;
    hasWrongNumber: boolean;
    hasDuplicatedNumber: boolean;
    sendAvailable: boolean;
    notEnoughPoint: boolean;
}
