export enum ViewTemplate {
    default = 'default',
    temp1 = 'temp1',
    temp2 = 'temp2',
    zsms = 'zsms'
}
export interface Environment {
    viewTemplate: string,
    nav: {
        background: ColorChange
        fontColor: ColorChange
        barColor: string
    }
}

export type ColorChange = { active: string, inactive: string };
