import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ResultTelbookTemp1Chart.module.scss'
import {ResultTelbookResp} from "../../ResultTelBook";
import {Pipe} from "../../../../../utils/pipe/Pipe";
import {ApexOptions} from "apexcharts";
import Chart from "react-apexcharts";

const cx = classNamesBind.bind(styles);

export const ResultTelbookTemp1Chart = (props: {
    data: ResultTelbookResp,
}) => {
    const [result, setResult] = useState<Result>({
        delivered: 0, fail: 0, requested: 0, sent: 0, undelivered: 0
    });

    useEffect(() => {
        const nextResult: Result = {
            delivered: 0, fail: 0, requested: 0, sent: 0, undelivered: 0
        }

        nextResult.requested = props.data.telbook.requestTotal;

        for (let telNumber of props.data.telbook.numberList) {
            switch (telNumber.status) {
                case "Sent":
                    nextResult.sent += 1;
                    break;
                case "Fail":
                    nextResult.fail += 1;
                    break;
                case "DlrDelivered":
                    nextResult.delivered += 1;
                    break;
                case "DlrUndelivered":
                    nextResult.undelivered += 1;
                    break;
            }
        }

        setResult(nextResult);
    }, [props.data]);

    /* 차트용 */
    const data: Data[] = [];

    if (result.delivered !== 0) {
        data.push({name: "수신완료", data: [result.delivered]});
    }

    if ((result.requested - (result.delivered + result.undelivered)) !== 0) {
        data.push({name: '수신결과대기', data: [result.requested - (result.delivered + result.undelivered)]});
    }

    if (result.undelivered !== 0) {
        data.push({name: '수신실패', data: [result.undelivered]});
    }

    if (result.fail !== 0) {
        data.push({name: '전송실패', data: [result.fail]});
    }

    const chartData: ApexOptions = {
        chart: {
            type: "bar",
            width: "100%",
            height: "200px",
            stacked: true,
            stackType: "100%",
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: true,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: false,
            }
        },
        series: data,
        xaxis: {
            categories: ["전송통계"]
        },
        fill: {
            opacity: 1,
        },
        stroke: {
            width: 1,
            colors: ["#ffffff"],
        },
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: true,
                color: "#000000",
                blur: 1,
                opacity: 1,
            },
            style: {
                colors: ["#FFFFFF"]
            },
        },
        colors: ["rgba(111,168,245,0.9)", "rgba(238,210,113,0.9)", "rgba(124,245,168,0.9)", "rgba(252,124,146,0.9)"],
    }

    return (
        <>
            <div className={cx("side-contentsColumn", "chart")}>
                <Chart
                    type='bar'
                    options={chartData}
                    series={chartData.series}
                    width={"100%"}
                    height={'200px'}/>
            </div>

            <div className={cx("side-rowBox")}>
                <div className={cx("side-flexBox", "padding", "rightDivide")}>
                    <div className={cx("side-contentsColumn", "headerColumn")}>
                        <div className={cx("contentsColumn-topHeader", "divide")}>
                            전송 갯수
                        </div>
                        <div className={cx("contentsColumn-topHeader", "divide")}>
                            전송 완료
                        </div>
                        <div className={cx("contentsColumn-topHeader", "divide")}>
                            전송 실패
                        </div>
                    </div>
                    <div className={cx("side-contentsColumn")}>
                        <div className={cx("contentsColumn-bottomValue", "divide")}>
                            {Pipe.toSeparatorNumber(result.requested)} 개
                        </div>
                        <div className={cx("contentsColumn-bottomValue", "divide")}>
                            {Pipe.toSeparatorNumber(result.sent)} 개
                        </div>
                        <div className={cx("contentsColumn-bottomValue", "divide")}>
                            {Pipe.toSeparatorNumber(result.fail)} 개
                        </div>
                    </div>
                </div>

                <div className={cx("side-flexBox", "padding", "rightDivide")}>
                    <div className={cx("side-contentsColumn", "headerColumn")}>
                        <div className={cx("contentsColumn-topHeader", "divide")}>
                            총 수신량
                        </div>
                        <div className={cx("contentsColumn-topHeader", "divide")}>
                            수신 완료
                        </div>
                        <div className={cx("contentsColumn-topHeader", "divide")}>
                            수신 대기
                        </div>
                        <div className={cx("contentsColumn-topHeader", "divide")}>
                            수신 실패
                        </div>
                    </div>
                    <div className={cx("side-contentsColumn")}>
                        <div className={cx("contentsColumn-bottomValue", "divide")}>
                            {Pipe.toSeparatorNumber(result.delivered + result.undelivered)} 개
                        </div>
                        <div className={cx("contentsColumn-bottomValue", "divide")}>
                            {Pipe.toSeparatorNumber(result.delivered)} 개
                        </div>
                        <div className={cx("contentsColumn-bottomValue", "divide")}>
                            {Pipe.toSeparatorNumber(props.data.telbook.requestTotal - (result.delivered + result.undelivered))} 개
                        </div>
                        <div className={cx("contentsColumn-bottomValue", "divide")}>
                            {Pipe.toSeparatorNumber(result.undelivered)} 개
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

interface Data {
    name: string,
    data: number[]
};

interface Result {
    delivered: number;
    undelivered: number;
    requested: number;
    sent: number;
    fail: number;
}
