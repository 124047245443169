import React from "react";
import classNamesBind from "classnames/bind";
import styles from './DepositRequestList.module.scss'
import {DepositRequestHistoryList, DepositRequestStatus} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../interfaces";
import {BadgeDepositRequestStatus} from "../../../../shares/temp1/badgeDepositRequestStatus/BadgeDepositRequestStatus";
import moment from "moment";
import NumberFormat from "react-number-format";
import {IconButton, Tooltip} from "@material-ui/core";
import {PagerTemp1} from "../../../../shares/temp1/pager/PagerTemp1";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";

const cx = classNamesBind.bind(styles);

export const DepositRequestList = (props: {
    list: DepositRequestHistoryList,
    onChangePage: OnChangeValue<number>
    onCancel: OnChangeValue<number>
}) => {
    return (
        <>
            <div className={cx("fullContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-left")}>
                                    입금확인요청 기록
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={cx("secondSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-contentsColumn", "headerColumn")}>
                                <div className={cx("contentsColumn-topHeader", "statusCell")}>
                                    상태
                                </div>
                                <div className={cx("contentsColumn-topHeader", "dateCell")}>
                                    날짜
                                </div>
                                <div className={cx("contentsColumn-topHeader", "depositorCell")}>
                                    입금자명
                                </div>
                                <div className={cx("contentsColumn-topHeader", "pointCell")}>
                                    포인트
                                </div>
                                <div className={cx("contentsColumn-topHeader", "depositCell")}>
                                    입금액
                                </div>
                                <div className={cx("contentsColumn-topHeader", "cancelCell")}>
                                    기능
                                </div>
                            </div>
                            {props.list.list.map((value, index) => (
                                <div className={cx("side-contentsColumn", "hover")} key={index}>
                                    <div className={cx("contentsColumn-bottomValue", "statusCell")}>
                                        <BadgeDepositRequestStatus status={value.status}/>
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "dateCell")}>
                                        {moment(value.requestedAt).format('YY.MM.DD hh:mm a')}
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "depositorCell")}>
                                        {value.nm}
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "pointCell")}>
                                        <NumberFormat
                                            thousandSeparator
                                            value={value.point}
                                            suffix={' P'}
                                            displayType='text'/>
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "depositCell")}>
                                        <NumberFormat
                                            thousandSeparator
                                            value={value.amount}
                                            suffix={' 원'}
                                            displayType='text'/>
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "cancelCell")}>
                                        {value.status === DepositRequestStatus.Wait &&
                                        <Tooltip title={'삭제'}>
                                            <span className={cx("fontcolor-red")}>
                                                <IconButton
                                                    size='small'
                                                    classes={{label: cx("fontcolor-red")}}
                                                    onClick={ev => {
                                                        props.onCancel(value.id);
                                                    }}>
                                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        }
                                    </div>
                                </div>
                            ))}
                            <PagerTemp1
                                page={props.list.page}
                                size={props.list.size}
                                total={props.list.total}
                                onChange={props.onChangePage}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
