import React from "react";
import classNamesBind from "classnames/bind";
import styles from './DepositRequestList.module.scss'
import {DepositRequestHistoryList, DepositRequestStatus} from "../../../../../../graphql/types";
import {OnChangeValue} from "../../../../../interfaces";
import {Pager} from "../../../../../shares/default/pager/Pager";
import {BizTitle} from "../../../../../shares/default/bizTitle/BizTitle";
import {BadgeDepositRequestStatus} from "../../../../../shares/default/badgeDepositRequestStatus/BadgeDepositRequestStatus";
import moment from "moment";
import NumberFormat from "react-number-format";
import {Close} from "@material-ui/icons";

const cx = classNamesBind.bind(styles);

export const DepositRequestList = (props: {
    list: DepositRequestHistoryList,
    onChangePage: OnChangeValue<number>
    onCancel: OnChangeValue<number>
}) => {
    return (
        <>
            <BizTitle>입금확인요청 기록</BizTitle>
            <div className={cx('table')}>
                <div className={cx("statusCell")}>상태</div>
                <div className={cx("dateCell", "hiddenCell")}>날짜</div>
                <div className={cx("depositorCell")}>입금자명</div>
                <div className={cx("pointCell", "hiddenCell")}>포인트</div>
                <div className={cx("depositCell")}>입금액</div>
                <div className={cx("funcCell")}>취소</div>
            </div>

            {props.list.list.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div className={cx("statusCell")}>
                        <BadgeDepositRequestStatus status={value.status}/>
                    </div>
                    <div className={cx("dateCell", "hiddenCell")}>{moment(value.requestedAt).format('YY.MM.DD hh:mm a')}</div>
                    <div className={cx("depositorCell")}>
                        {value.nm}
                    </div>
                    <div className={cx("pointCell", "hiddenCell", "textRight")}>
                        <NumberFormat
                            thousandSeparator
                            value={value.point}
                            suffix={' P'}
                            displayType='text'/>
                    </div>
                    <div className={cx("depositCell", "textRight")}>
                        <NumberFormat
                            thousandSeparator
                            value={value.amount}
                            suffix={' 원'}
                            displayType='text'/>
                    </div>
                    <div className={cx("funcCell")}>
                        {value.status === DepositRequestStatus.Wait &&
                        <button
                            className='btn btn-sm btn-outline-danger'
                            onClick={event => {
                                props.onCancel(value.id);
                            }}>
                            <Close/>
                        </button>
                        }
                    </div>
                </div>
            ))}

            <Pager
                page={props.list.page}
                size={props.list.size}
                total={props.list.total}
                onChange={props.onChangePage}/>
        </>
    )
}
