import {HookRouter} from "hookrouter";
import {SmsBulk} from "./sms/bulk/SmsBulk";
import {DepositRequest} from "./deposit/request/DepositRequest";
import {MypageInfo} from "./mypage/info/MypageInfo";
import {AuthLogin} from "./auth/login/AuthLogin";
import {AuthJoin} from "./auth/join/AuthJoin";
import React from "react";
import {UserState} from "../redux/User";
import {CommissionPermission, MsgPermission, UserType} from "../graphql/types";
import {MngCode} from "./mng/code/MngCode";
import {MngAgent} from "./mng/agent/MngAgent";
import {ResultMsgReceipt} from "./result/msgReceipt/ResultMsgReceipt";
import {ResultTelBook} from "./result/telbook/ResultTelBook";
import {MngSendCommission} from "./mng/sendCommission/MngSendCommission";
import {MngRechargeCommission} from "./mng/rechargeCommission/MngRechargeCommission";
import {Validator} from "../utils/validator/Validator";
import {NoticeForAll} from "./notice/forAll/NoticeForAll";
import {Index} from "./index";
import {SettlementList} from "./settle/list/SettlementList";
import {CsList} from "./cs/list/CsList";
import {MngAgentWithSmsFee} from "./mng/agentWithSmsFee/MngAgentWithSmsFee";
import {MngCodeWithTree} from "./mng/codeWithTree/MngCodeWithTree";
import {MngTreeUser} from "./mng/treeUser/MngTreeUser";

export const CreateRouter = (user: UserState): HookRouter.RouteObject => {
    const appendRouter = user.isLogin ? AuthRouter : NotAuthRouter;
    let router = {
        ...CommonRouter,
        ...appendRouter,
    }

    if (user.user.msgPermission.includes(MsgPermission.Bulk)) {
        router = {
            ...router,
            '/sms/bulk': params => <SmsBulk/>,
        }
    }

    if (user.user.msgPermission.includes(MsgPermission.Template)) {
        router = {
            ...router,
            '/sms/template': params => <div/>,
        }
    }

    switch (user.user.userType) {
        case UserType.Tree:
            router = {
                ...router,
                '/mng/code': () =>
                    <MngCodeWithTree
                        disposablePage={0}
                        reusablePage={0}/>,
                '/mng/code/:disposable/:reusable': ({disposable, reusable}) =>
                    <MngCodeWithTree
                        disposablePage={disposable}
                        reusablePage={reusable}/>,
            }

            if ((user.user.commissionPermission) && (user.user.commissionPermission.includes(CommissionPermission.Recharge))) {
                router = {
                    ...router,
                    '/mng/recharge': () =>
                        <MngRechargeCommission
                            page={0}
                            requestPage={0}/>,
                    '/mng/recharge/:page/:requestPage': ({page, requestPage}) =>
                        <MngRechargeCommission
                            page={Validator.isNumber(page)}
                            requestPage={Validator.isNumber(requestPage)}/>,
                    '/mng/agent': () =>
                        <MngTreeUser
                            page={0}
                            email={""}/>,
                    '/mng/agent/:page': ({page}) =>
                        <MngTreeUser
                            page={Validator.isNumber(page)}
                            email={""}/>,
                    '/mng/agent/:page/': ({page}) =>
                        <MngTreeUser
                            page={Validator.isNumber(page)}
                            email={""}/>,
                    '/mng/agent/:page/:email': ({page, email}) =>
                        <MngTreeUser
                            page={Validator.isNumber(page)}
                            email={email}/>,
                }
            }
            break;
        case UserType.Reseller:
            router = {
                ...router,
                '/mng/code': () =>
                    <MngCode
                        disposablePage={0}
                        reusablePage={0}/>,
                '/mng/code/:disposable/:reusable': ({disposable, reusable}) =>
                    <MngCode
                        disposablePage={disposable}
                        reusablePage={reusable}/>,
            }

            if (user.user.commissionPermission) {
                if (user.user.commissionPermission.includes(CommissionPermission.Send)) {
                    router = {
                        ...router,
                        '/mng/send': () =>
                            <MngSendCommission
                                page={0}
                                requestPage={0}/>,
                        '/mng/send/:page/:requestPage': ({page, requestPage}) =>
                            <MngSendCommission
                                page={Validator.isNumber(page)}
                                requestPage={Validator.isNumber(requestPage)}/>,
                        '/mng/agent': () =>
                            <MngAgentWithSmsFee
                                page={0}/>,
                        '/mng/agent/:page': ({page}) =>
                            <MngAgentWithSmsFee
                                page={Validator.isNumber(page)} email={""}/>,
                        '/mng/agent/:page/': ({page}) =>
                            <MngAgentWithSmsFee
                                page={Validator.isNumber(page)} email={""}/>,
                        '/mng/agent/:page/:email': ({page, email}) =>
                            <MngAgentWithSmsFee
                                page={Validator.isNumber(page)}
                                email={email}/>,
                    }
                } else if (user.user.commissionPermission.includes(CommissionPermission.Recharge)) {
                    router = {
                        ...router,
                        '/mng/recharge': () =>
                            <MngRechargeCommission
                                page={0}
                                requestPage={0}/>,
                        '/mng/recharge/:page/:requestPage': ({page, requestPage}) =>
                            <MngRechargeCommission
                                page={Validator.isNumber(page)}
                                requestPage={Validator.isNumber(requestPage)}/>,
                        '/mng/agent': () =>
                            <MngAgent
                                page={0}
                                email={""}/>,
                        '/mng/agent/:page': ({page}) =>
                            <MngAgent
                                page={Validator.isNumber(page)}
                                email={""}/>,
                        '/mng/agent/:page/': ({page}) =>
                            <MngAgent
                                page={Validator.isNumber(page)}
                                email={""}/>,
                        '/mng/agent/:page/:email': ({page, email}) =>
                            <MngAgent
                                page={Validator.isNumber(page)}
                                email={email}/>,
                    }
                }
            }
            break;
    }


    return router;
}


const AuthRouter: HookRouter.RouteObject = {
    '/deposit/request': () =>
        <DepositRequest
            page={0}/>,
    '/deposit/request/:page': ({page}) =>
        <DepositRequest
            page={Validator.isNumber(page)}/>,
    '/result/receipt': () =>
        <ResultMsgReceipt
            page={0}/>,
    '/result/receipt/:page': ({page}) =>
        <ResultMsgReceipt
            page={Validator.isNumber(page)}/>,
    '/result/receipt/:page/:stDate/:edDate': ({page, stDate, edDate}) =>
        <ResultMsgReceipt
            page={Validator.isNumber(page)}
            periodTimeDay={{stDate: Validator.isNumber(stDate), edDate: Validator.isNumber(edDate)}}/>,
    '/result/telbook/:id': ({id}) =>
        <ResultTelBook
            id={id}/>,
    '/mypage/info': () =>
        <MypageInfo
            page={0}/>,
    '/mypage/info/:page': ({page}) =>
        <MypageInfo
            page={Validator.isNumber(page)}/>,
    '/settlement': () =>
        <SettlementList
            page={0}/>,
    '/settlement/:page': ({page}) =>
        <SettlementList
            page={Validator.isNumber(page)}/>,
    '/cs': () =>
        <CsList
            page={0}/>,
    '/cs/:page': ({page}) =>
        <CsList
            page={Validator.isNumber(page)}/>,
    '/notice': () =>
        <NoticeForAll
            page={0}/>,
    '/notice/:page': ({page}) =>
        <NoticeForAll
            page={Validator.isNumber(page)}/>
};

const NotAuthRouter: HookRouter.RouteObject = {
    '/auth/login': () =>
        <AuthLogin/>,
    '/auth/join': () =>
        <AuthJoin
            type={UserType.General}
            code={""}/>,
    '/auth/join/:type/:code': params =>
        <AuthJoin
            type={params.type}
            code={params.code}/>,
}

const CommonRouter: HookRouter.RouteObject = {
    '/': () =>
        <Index/>,
}
