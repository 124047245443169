import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './DepositRequestListTemp2.module.scss'
import {DepositRequestHistoryList, DepositRequestStatus} from "../../../../../graphql/types";
import {OnChangeValue} from "../../../../interfaces";
import {PagerTemp2} from "../../../../shares/temp2/pager/PagerTemp2";
import {BadgeDepositRequestStatus} from "../../../../shares/temp1/badgeDepositRequestStatus/BadgeDepositRequestStatus";
import moment from "moment";
import NumberFormat from "react-number-format";
import {Button, withStyles} from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";

const cx = classNamesBind.bind(styles);

const ColorButton1 = withStyles(() => ({
    root: {
        color: "#de3378",
        backgroundColor: "#fff",
        borderColor: "#de3378",
        '&:hover': {
            color: "#fff",
            backgroundColor: "#de3378",
        },
    },
}))(Button);

export const DepositRequestListTemp2 = (props: {
    list: DepositRequestHistoryList,
    onChangePage: OnChangeValue<number>
    onCancel: OnChangeValue<number>
}) => {
    return (
        <Fragment>
            <div className={cx('fullContainer')}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox")}>
                            <div className={cx("side-titleBar")}>
                                <div className={cx("titleBar-icon")}>
                                    <HistoryIcon/>
                                </div>
                                <div className={cx("titleBar-text")}>
                                    <div className={cx("text-main")}>
                                        입금확인요청 히스토리
                                    </div>
                                    <div className={cx("text-sub")}>
                                        Request history for payment confirmation
                                    </div>
                                </div>
                            </div>
                            <div className={cx("side-contentsColumn")}>
                                <div className={cx("contentsColumn-topHeader", "box__statusCell")}>
                                    상태
                                </div>
                                <div className={cx("contentsColumn-topHeader", "box__hidden")} style={{width: 200}}>
                                    날짜
                                </div>
                                <div className={cx("contentsColumn-topHeader", "box__mobileCell")}
                                     style={{minWidth: 'calc((100% - 380px)/3)', flexGrow: 1}}>
                                    입금자명
                                </div>
                                <div className={cx("contentsColumn-topHeader", "box__hidden", "right")}
                                     style={{minWidth: 'calc((100% - 380px)/3)'}}>
                                    포인트
                                </div>
                                <div className={cx("contentsColumn-topHeader", "box__mobileCell", 'right')}
                                     style={{minWidth: 'calc((100% - 380px)/3)', flexGrow: 1}}>
                                    입금액
                                </div>
                                <div className={cx("contentsColumn-topHeader")} style={{width: 80}}>
                                    취소
                                </div>
                            </div>
                            {props.list.list.map((value, index) => (
                                <div className={cx("side-contentsColumn")} key={index}>
                                    <div className={cx("contentsColumn-bottomValue", "box__statusCell")}>
                                        <BadgeDepositRequestStatus status={value.status}/>
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "box__hidden")} style={{width: 200}}>
                                        {moment(value.requestedAt).format('YY.MM.DD hh:mm a')}
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "box__mobileCell")}
                                         style={{minWidth: 'calc((100% - 380px)/3)', flexGrow: 1}}>
                                        {value.nm}
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "box__hidden", "right")}
                                         style={{minWidth: 'calc((100% - 380px)/3)'}}>
                                        <NumberFormat
                                            thousandSeparator
                                            value={value.point}
                                            suffix={' P'}
                                            displayType='text'/>
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "box__mobileCell", 'right')}
                                         style={{minWidth: 'calc((100% - 380px)/3)', flexGrow: 1}}>
                                        <NumberFormat
                                            thousandSeparator
                                            value={value.amount}
                                            suffix={' 원'}
                                            displayType='text'/>
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue")} style={{width: 80}}>
                                        {value.status === DepositRequestStatus.Wait &&
                                        <ColorButton1
                                            variant="outlined"
                                            size="small"
                                            style={{width: 60}}
                                            type="submit"
                                            onClick={event => {
                                                props.onCancel(value.id);
                                            }}>취소
                                        </ColorButton1>
                                        }
                                    </div>
                                </div>
                            ))}
                            <PagerTemp2
                                page={props.list.page}
                                size={props.list.size}
                                total={props.list.total}
                                onChange={props.onChangePage}/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
