import React, {Fragment} from "react";
import {SendCommissionSettleRequestStatus} from "../../../../graphql/types";

export const BadgeSendCommissionSettleRequestStatus = (props: {
    status: SendCommissionSettleRequestStatus
}) => {
    return (
        <Fragment>
            {props.status === SendCommissionSettleRequestStatus.Requested
            && <span className='badge badge-primary'>승인대기</span>}

            {props.status === SendCommissionSettleRequestStatus.Deny
            && <span className='badge badge-danger'>반려</span>}

            {props.status === SendCommissionSettleRequestStatus.Allow
            && <span className='badge badge-success'>승인완료</span>}

            {props.status === SendCommissionSettleRequestStatus.Cancel
            && <span className='badge badge-secondary'>사용자취소</span>}
        </Fragment>
    )
}
