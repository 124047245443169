import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './CsListDefault.module.scss';
import {OnChangeValue} from "../../../interfaces";
import {MutationCreateUserChatArgs, Query} from "../../../../graphql/types";
import {BizCol12, BizContainer} from "../../../shares/default/bizContainer/BizContainer";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {CsListDefaultUserAlertList} from "./userAlertList/CsListDefaultUserAlertList";
import {CsListDefaultChat} from "./chat/CsListDefaultChat";

const cx = classNamesBind.bind(styles);

export const CsListDefault = (props: {
    value: Query,
    onChangeUserAlertPage: OnChangeValue<number>,
    onCreateUserChat: OnChangeValue<MutationCreateUserChatArgs>
}) => {
    return (
        <BizContainer>
            <BizCol12>
                <BizTitle>관리자 문의</BizTitle>
                <div>* 최근 50개의 내용만 표시 됩니다.</div>
                <CsListDefaultChat {...props}/>
            </BizCol12>
            <BizCol12>
                <div style={{height: 50}}/>
                <BizTitle>알림 내역</BizTitle>
                <CsListDefaultUserAlertList
                    {...props}/>
            </BizCol12>
        </BizContainer>
    )
}
