import React from "react";
import {LoginInputData} from "../AuthLogin";
import {OnChangeValue} from "../../../interfaces";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import {CenterContents} from "../../../shares/default/centerContents/CenterContents";
import {A, navigate} from "hookrouter";
import styles from './AuthLoginTemp1.module.scss';
import classNamesBind from "classnames/bind";
import {Button, Tab, Tabs, TextField} from "@material-ui/core";
import {FbJoinType} from "../../../../graphql/types";
import {FB} from "../../../../utils/fb/FB";

const cx = classNamesBind.bind(styles);

export const AuthLoginTemp1 = (props: {
    value: LoginInputData,
    onChangeValue: OnChangeValue<LoginInputData>,
    onLoginWithEmail: OnChangeValue<LoginInputData>,
    onLoginWithPhoneNumber: OnChangeValue<LoginInputData>,
}) => {
    return (
        <CenterContents animatedContents>
            <div className={cx('cont')}>
                <div className={cx('box-login')}>
                    <h3>LogIn</h3>
                    <h4>Log in on the internal platform</h4>

                    <Tabs
                        value={props.value.loginType}
                        onChange={(ev, value) => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.loginType = value;
                            props.onChangeValue(copy);
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth">
                        <Tab label="이메일" value={FbJoinType.Email}/>
                        <Tab label="전화번호" value={FbJoinType.Phone}/>
                    </Tabs>

                    <div style={{height: 30}}/>
                    {props.value.loginType === FbJoinType.Email && <LoginWithEmail {...props}/>}
                    {props.value.loginType === FbJoinType.Phone && <LoginWithPhoneNumber {...props}/>}
                </div>
            </div>
        </CenterContents>
    )
}

const LoginWithPhoneNumber = (props: {
    value: LoginInputData,
    onChangeValue: OnChangeValue<LoginInputData>,
    onLoginWithPhoneNumber: OnChangeValue<LoginInputData>,
}) => {
    const onChangeValue = (key: string, value: any) => {
        props.onChangeValue(CopyUtil.onChangeValue(props.value, key, value));
    }

    const onLogin = () => {
        props.onLoginWithPhoneNumber(props.value);
    }

    return (
        <form
            className={cx("box-form")}
            onSubmit={event => {
                event.preventDefault();
                onLogin();
            }}>
            <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                type='text'
                style={{marginBottom: 10}}
                placeholder='비밀번호를 입력하여 주십시오.'
                value={props.value.phoneNumber}
                onChange={event => {
                    onChangeValue("phoneNumber", event.target.value);
                }}/>
            <hr/>
            <div className={cx('info')}>* 전화번호 로그인은 인증번호를 문자로 전송해드립니다.</div>
            <div className={cx('info')} style={{marginBottom: 28}}>* 인증에 여러번 실패하시면 로그인 차단 대상이 됩니다.</div>
            <Button
                classes={{root: cx("buttonOne")}}
                variant="contained"
                size="large"
                type="submit"
                fullWidth
                style={{marginBottom: 10}}>
                로그인
            </Button>

            {/*회원가입*/}
            <Button classes={{root: cx("buttonTwo")}}
                    variant="outlined"
                    size="large"
                    type="submit"
                    fullWidth
                    onClick={event => {
                        navigate('/auth/join');
                    }}>
                회원가입
            </Button>
        </form>
    )
}

const LoginWithEmail = (props: {
    value: LoginInputData,
    onChangeValue: OnChangeValue<LoginInputData>,
    onLoginWithEmail: OnChangeValue<LoginInputData>,
}) => {
    const onChangeValue = (key: string, value: any) => {
        props.onChangeValue(CopyUtil.onChangeValue(props.value, key, value));
    }

    const onLogin = () => {
        props.onLoginWithEmail(props.value);
    }

    return (
        <form
            className={cx("box-form")}
            onSubmit={event => {
                event.preventDefault();
                onLogin();
            }}>
            <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type='email'
                style={{marginBottom: 10}}
                placeholder='이메일을 입력하여 주십시오.'
                value={props.value.email}
                onChange={event => {
                    onChangeValue("email", event.target.value);
                }}/>
            <TextField
                label="Password"
                variant="outlined"
                fullWidth
                type='password'
                style={{marginBottom: 10}}
                placeholder='비밀번호를 입력하여 주십시오.'
                value={props.value.pw}
                onKeyUp={event => {
                    if (event.key === 'Enter') {
                        props.onChangeValue(props.value)
                    }
                }}
                onChange={event => {
                    onChangeValue("pw", event.target.value);
                }}/>
            <hr/>
            <Button
                classes={{root: cx("buttonOne")}}
                variant="contained"
                size="large"
                type="submit"
                fullWidth
                style={{marginBottom: 10}}>
                로그인
            </Button>

            {/*회원가입*/}
            <Button
                classes={{root: cx("buttonTwo")}}
                variant="outlined"
                size="large"
                type="submit"
                fullWidth
                onClick={event => {
                    navigate('/auth/join');
                }}>
                회원가입
            </Button>

            {/*회원가입, 비밀번호 찾기 안내 */}
            <div style={{height: 10}}/>
            <p style={{marginRight: 10, textAlign: "right", color: "#0768ac"}}>
                <span style={{cursor: "pointer"}} onClick={FB.resetPwWithEmailUsingSwal}>비밀번호를 잊으셨습니까? 초기화하기</span>
            </p>
        </form>
    )
}
