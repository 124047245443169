import React, {Fragment, ReactNode} from "react";
import {NavBar} from "../Layout";
import {ViewSelector} from "../../shares/default/viewSelector/ViewSelector";
import {LayoutWeb} from "./web/LayoutWeb";
import {LayoutMobile} from "./mobile/LayoutMobile";

export const LayoutDefault = (props: {
    navBar: NavBar[],
    children: ReactNode,
}) => {
    return (
        <Fragment>
            <ViewSelector
                web={
                    <LayoutWeb navBar={props.navBar} children={props.children}/>
                }
                mobile={
                    <LayoutMobile navBar={props.navBar} children={props.children}/>
                }/>
        </Fragment>
    )
}
