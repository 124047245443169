import React from "react";
import classNamesBind from "classnames/bind";
import styles from './MngAgentTemp2.module.scss'
import {MngAgentInputData} from "../MngAgent";
import {OnChangeValue} from "../../../interfaces";
import {MngLayout} from "../../layout/MngLayout";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {PagerTemp2} from "../../../shares/temp2/pager/PagerTemp2";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {Button, TextField, withStyles} from "@material-ui/core";
import AirplayIcon from "@material-ui/icons/Airplay";
import {SwitchTemp2} from "../../../shares/temp2/switch/SwitchTemp2";
import {Query} from "../../../../graphql/types";

const cx = classNamesBind.bind(styles);

const ColorButton1 = withStyles(() => ({
    root: {
        color: "#fff",
        backgroundColor: "#2196f3",
        '&:hover': {
            color: "#2196f3",
            backgroundColor: "#fff",
        },
        paddingLeft: 5,
        paddingRight: 5
    },
}))(Button);

const ColorButton2 = withStyles(() => ({
    root: {
        color: "#fff",
        backgroundColor: "#777777",
        '&:hover': {
            color: "#1e89ba",
            backgroundColor: "#ffffff",
        },
        paddingLeft: 5,
        paddingRight: 5
    },
}))(Button);

export const MngAgentTemp2 = (props: {
    data?: Query,
    value: MngAgentInputData,
    onChangeValue: OnChangeValue<MngAgentInputData>,
    onChangePage: OnChangeValue<{ page: number, email: string }>,
    onChangeAgentActivation: OnChangeValue<{ userId: number, activation: boolean }>
}) => {

    const onFindAgent = () => {
        props.onChangePage({page: 0, email: props.value.email});
    }

    const onInit = () => {
        props.onChangePage({page: 0, email: ''});

    }

    return (
        <MngLayout title={'대리점관리'}>
            {props.data &&
            <FadeIn>
                <div className={cx('fullContainer')}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-icon")}>
                                        <AirplayIcon/>
                                    </div>
                                    <div className={cx("titleBar-text")}>
                                        <div className={cx("text-main")}>
                                            대리점 관리
                                        </div>
                                        <div className={cx("text-sub")}>
                                            Agency management
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "widthCell", "box__hidden")}>
                                        회원번호
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "widthCell")}>
                                        활성화
                                    </div>
                                    <div className={cx("contentsColumn-topHeader")}
                                         style={{flexGrow: 1}}>
                                        이메일
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "widthCell")}>
                                        잔고
                                    </div>
                                </div>

                                {props.data.agentList.list.length === 0 &&
                                <div className={cx("side-contentsColumn")}>
                                    <div style={{flexGrow: 1}}>등록된 대리점이 없습니다.</div>
                                </div>
                                }

                                {props.data.agentList.list.map((value, index) => (
                                    <div className={cx("side-contentsColumn")} key={index}>
                                        <div className={cx("contentsColumn-bottomValue", "widthCell", "box__hidden")}>
                                            {value.id}
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "widthCell")}>
                                            <SwitchTemp2
                                                color='primary'
                                                checked={value.isActivate}
                                                onChange={event => {
                                                    props.onChangeAgentActivation({userId: value.id, activation: event.target.checked});
                                                }}/>
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue")} style={{flexGrow: 1}}>
                                            {value.email}
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "widthCell")}>
                                            {Pipe.toSeparatorNumber(value.point)} P
                                        </div>
                                    </div>
                                ))}

                                <div className={cx("pagerColumn")}>
                                    <div className={cx("pagerColumn-pager")}>
                                        <PagerTemp2
                                            page={props.data.agentList.page}
                                            size={props.data.agentList.size}
                                            total={props.data.agentList.total}
                                            onChange={page => {
                                                props.onChangePage({page, email: props.value.email});
                                            }}/>
                                    </div>
                                    <div className={cx("pagerColumn-button")}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            type='text'
                                            inputProps={{ style: { fontSize: 12}}}
                                            value={props.value.email}
                                            placeholder="검색할 이메일을 입력하여 주십시오."
                                            onKeyUp={event => {
                                                if (event.key === 'Enter') {
                                                    onFindAgent();
                                                }
                                            }}
                                            onChange={event => {
                                                const copy = CopyUtil.copyAll(props.value);
                                                copy.email = event.target.value;
                                                props.onChangeValue(copy);
                                            }}/>
                                        <ColorButton1
                                            variant="outlined"
                                            style={{fontSize: 12, height: 35, width: 50, marginLeft: 10}}
                                            fullWidth
                                            onClick={onFindAgent}>찾기
                                        </ColorButton1>
                                        <ColorButton2
                                            variant="outlined"
                                            style={{fontSize: 12, height: 35, width: 50, marginLeft: 10}}
                                            fullWidth
                                            onClick={onInit}>초기화
                                        </ColorButton2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn>
            }
        </MngLayout>
    )
}
