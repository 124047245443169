import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngTreeUserDefaultList.module.scss'
import {TreeList} from "../../../../../graphql/types";
import {BizNoDataTable} from "../../../../shares/default/bizNoDataTable/BizNoDataTable";
import {Pipe} from "../../../../../utils/pipe/Pipe";
import moment from "moment";

const cx = classNamesBind.bind(styles);

export const MngTreeUserDefaultList = (props: {
    list: TreeList,
}) => {
    return (
        <Fragment>
            <div className={cx('table')}>
                <div className={cx('col-id')}>번호</div>
                <div className={cx('col-created-at')}>회원가입일</div>
                <div className={cx('col-user')}>회원</div>
                <div className={cx('col-balance')}>잔고</div>
            </div>

            {props.list.total === 0 && <BizNoDataTable/>}

            {props.list.list.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div className={cx('col-id')}>{value.id}</div>
                    <div className={cx('col-created-at')}>{moment(value.createdAt).format('YY.MM.DD')}</div>
                    <div className={cx('col-user')}>{value.email}</div>
                    <div className={cx('col-balance', 'text-right')}>{Pipe.toSeparatorNumber(value.point)} P</div>
                </div>
            ))}
        </Fragment>
    )
}
