import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {FbJoinType, User as UserInfo, UserType} from '../graphql/types'

export interface UserState {
    isLogin: boolean;
    user: UserInfo
}

const initState: UserState = {
    isLogin: false,
    user: {
        email: "",
        userType: UserType.General,
        point: 0,
        tax: 0,
        msgPermission: [],
        apiPermission: [],
        fbJoinType: FbJoinType.Email
    }
}

class User extends ImmerReducer<typeof initState> {
    logout() {
        this.draftState.isLogin = false;
        this.draftState.user = {
            email: "",
            userType: UserType.General,
            point: 0,
            tax: 0,
            msgPermission: [],
            apiPermission: [],
            fbJoinType: FbJoinType.Email
        }
    }

    update(user: UserInfo) {
        this.draftState.isLogin = true;
        this.draftState.user = user;
    }
}


export const UserAction = createActionCreators(User);
export const UserReducer = createReducerFunction(User, initState);

