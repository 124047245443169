import React, {Fragment} from 'react';
import classNamesBind from "classnames/bind";
import styles from './CsListDefaultUserAlertList.module.scss';
import {Query} from "../../../../../graphql/types";
import moment from "moment";
import {OnChangeValue} from "../../../../interfaces";
import {Pager} from "../../../../shares/default/pager/Pager";

const cx = classNamesBind.bind(styles);

export const CsListDefaultUserAlertList = (props: {
    value: Query,
    onChangeUserAlertPage: OnChangeValue<number>,
}) => {
    return (
        <Fragment>
            <div className={cx('table')}>
                <div style={{width: 200}}>날짜</div>
                <div style={{width: 'calc(100% - 200px)'}}>내용</div>
            </div>

            {props.value.userAlertList.list.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div style={{width: 200}}>{moment(value.createdAt).format('YY.MM.DD hh:mm a')}</div>
                    <div className='text-left' style={{width: 'calc(100% - 200px)'}}>{value.content}</div>
                </div>
            ))}

            <Pager
                page={props.value.userAlertList.page}
                size={props.value.userAlertList.size}
                total={props.value.userAlertList.total}
                onChange={props.onChangeUserAlertPage}/>
        </Fragment>
    )
}
