import React from "react";
import classNamesBind from "classnames/bind";
import styles from './MngAgentTemp1.module.scss'
import {MngAgentInputData} from "../MngAgent";
import {OnChangeValue} from "../../../interfaces";
import {MngLayout} from "../../layout/MngLayout";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {Button, Checkbox, TextField} from "@material-ui/core";
import {Query} from "../../../../graphql/types";
import {PagerTemp1} from "../../../shares/temp1/pager/PagerTemp1";

const cx = classNamesBind.bind(styles);

export const MngAgentTemp1 = (props: {
    data?: Query,
    value: MngAgentInputData,
    onChangeValue: OnChangeValue<MngAgentInputData>,
    onChangePage: OnChangeValue<{ page: number, email: string }>,
    onChangeAgentActivation: OnChangeValue<{ userId: number, activation: boolean }>
}) => {

    const onFindAgent = () => {
        props.onChangePage({page: 0, email: props.value.email});
    }

    const onInit = () => {
        props.onChangePage({page: 0, email: ''});

    }

    return (
        <MngLayout title={'대리점관리'}>
            {props.data &&
            <FadeIn>
                <div className={cx("fullContainer")}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-left")}>
                                        회원 목록
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cx("secondSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-contentsColumn", "headerColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "numberCell", "hiddenCell")}>
                                        회원번호
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "activateCell")}>
                                        활성화
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "userCell")}>
                                        유저
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "valueCell")}>
                                        잔고
                                    </div>
                                </div>

                                {props.data.agentList.list.length === 0 &&
                                <div className={cx("side-contentsColumn")}>
                                    <div style={{flexGrow: 1}}>등록된 대리점이 없습니다.</div>
                                </div>
                                }

                                {props.data.agentList.list.map((value, index) => (
                                    <div className={cx("side-contentsColumn")} key={index}>
                                        <div className={cx("contentsColumn-bottomValue", "numberCell", "hiddenCell")}>
                                            {value.id}
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "activateCell")}>
                                            <Checkbox
                                                classes={{checked: cx("checkbox-check")}}
                                                checked={value.isActivate}
                                                onChange={event => {
                                                    props.onChangeAgentActivation({
                                                        userId: value.id,
                                                        activation: event.target.checked
                                                    });
                                                }}/>
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "userCell")}>
                                            {value.email}
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "valueCell")}>
                                            {Pipe.toSeparatorNumber(value.point)} P
                                        </div>
                                    </div>
                                ))}

                                <div className={cx("side-searchColumn")}>
                                    <div className={cx("searchColumn-input")}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            type='text'
                                            inputProps={{style: {fontSize: 12}}}
                                            value={props.value.email}
                                            placeholder="검색할 유저명을 입력하여 주십시오."
                                            onKeyUp={event => {
                                                if (event.key === 'Enter') {
                                                    onFindAgent();
                                                }
                                            }}
                                            onChange={event => {
                                                const copy = CopyUtil.copyAll(props.value);
                                                copy.email = event.target.value;
                                                props.onChangeValue(copy);
                                            }}/>
                                    </div>
                                    <div className={cx("searchColumn-button")}>
                                        <Button
                                            classes={{root: cx("buttonOne")}}
                                            variant="outlined"
                                            style={{fontSize: 12, height: 35}}
                                            fullWidth
                                            onClick={onFindAgent}>찾기
                                        </Button>
                                    </div>
                                    <div className={cx("searchColumn-button")}>
                                        <Button
                                            classes={{root: cx("buttonTwo")}}
                                            variant="outlined"
                                            style={{fontSize: 12, height: 35}}
                                            fullWidth
                                            onClick={onInit}>초기화
                                        </Button>
                                    </div>
                                </div>

                                <PagerTemp1
                                    page={props.data.agentList.page}
                                    size={props.data.agentList.size}
                                    total={props.data.agentList.total}
                                    onChange={page => {
                                        props.onChangePage({page, email: props.value.email});
                                    }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn>
            }
        </MngLayout>
    )
}
