import React, {Fragment, useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizDirectNoticeModal.module.scss';
import {gql, useLazyQuery} from "@apollo/client";
import {DirectNotice} from "../../../../graphql/types";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import moment from "moment";

const cx = classNamesBind.bind(styles);

export const BizDirectNoticeModal = () => {
    const [getData, resData] = useLazyQuery<{ directNotice: DirectNotice[] }>(gqlData, {
        context: {
            loadingView: false,
        }
    });
    const [confirmDirectNoticeId, setConfirmDirectNoticeId] = useState<number[]>([]);
    const [showDirectNotice, setShowDirectNotice] = useState<DirectNotice[]>([]);

    useEffect(() => {
        let timer = setInterval(() => {
            getData();
        }, 5000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        if (!resData.data) {
            return;
        }

        const list: DirectNotice[] = [];
        for (let directNotice of resData.data.directNotice) {
            const hasConfirmIdx = confirmDirectNoticeId.findIndex(value => {
                return value === directNotice.id
            })

            if (hasConfirmIdx !== -1) {
                continue
            }
            list.push(directNotice);
        }

        setShowDirectNotice(list);
    }, [resData.data]);

    const onConfirm = () => {
        const copy = CopyUtil.copyAll(confirmDirectNoticeId);
        for (let directNotice of showDirectNotice) {
            copy.push(directNotice.id);
        }
        setConfirmDirectNoticeId(copy);
        setShowDirectNotice([]);
    }

    return (
        <Fragment>
            {showDirectNotice.length !== 0 &&
            <div className={cx('cont-direct-notice')}>
                <div className={cx('box')}>
                    {showDirectNotice.map((value, index) => (
                        <Fragment key={index}>
                            <div className={cx('title')}>{moment(value.createdAt).format('YY.MM.DD - hh:mm')}</div>
                            <div className={cx('contents')}
                                 ref={ref => {
                                     if (ref) {
                                         ref.innerHTML = value.content;
                                     }
                                 }}/>
                        </Fragment>
                    ))}
                    <div className={cx('footer')}>
                        <button
                            className='btn btn-sm btn-success'
                            onClick={onConfirm}>확인
                        </button>
                    </div>
                </div>
            </div>
            }
        </Fragment>
    )
}


const gqlData = gql`
    query Data {
        directNotice {
            id
            content
            urgent
            createdAt
        }
    }
`;
