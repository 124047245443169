import React, {Fragment, ReactNode} from "react";
import {BizMenuLayout} from "../../../shares/default/bizMenuLayout/BizMenuLayout";
import {MngLayoutMenu} from "../MngLayout";

export const MngLayoutDefault = (props: {
    title: ReactNode,
    children: ReactNode,
    menu: MngLayoutMenu[],
}) => {
    return (
        <BizMenuLayout
            data={props.menu}
            title={props.title}>
            {props.children}
            <div style={{height: 30}}/>
        </BizMenuLayout>
    )
}
