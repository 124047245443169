import React, {ReactNode} from 'react';
import classNamesBind from "classnames/bind";
import styles from './BizTitle.module.scss';

const cx = classNamesBind.bind(styles);

export const BizTitle = (props: {
    marginBottom?: number,
    children?: ReactNode,
    desc?: ReactNode,
}) => {
    return (
        <div className={cx('cont')}>
            <h1 className={cx('title')}
                style={{marginBottom: props.marginBottom ? props.marginBottom : 0}}>
                {props.children}
            </h1>
            {props.desc &&
            <div className={cx('desc')}>
                {props.desc}
            </div>
            }
        </div>

    )
}
