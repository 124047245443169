import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngCodeTemp1.module.scss'
import {MngLayout} from "../../layout/MngLayout";
import {MngCodeResp} from "../MngCode";
import moment from "moment";
import {OnChangeValue} from "../../../interfaces";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import copy from "copy-to-clipboard";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {
    CreateApiPermission,
    MutationCreateDisposableJoinCodeWithSmsFeeArgs,
    MutationCreateReusableJoinCodeWithSmsFeeArgs
} from "../../../../graphql/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faCopy, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {PagerTemp1} from "../../../shares/temp1/pager/PagerTemp1";
import Decimal from "decimal.js";
import {useSelector} from "react-redux";
import {UserState} from "../../../../redux/User";

const cx = classNamesBind.bind(styles);

export const MngCodeTemp1 = (props: {
    data?: MngCodeResp,
    onCreateReusableCode: OnChangeValue<MutationCreateReusableJoinCodeWithSmsFeeArgs>,
    onCreateDisposableCode: OnChangeValue<MutationCreateDisposableJoinCodeWithSmsFeeArgs>,
    onDeleteCode: OnChangeValue<string>,
    onChangeReusableCodePage: OnChangeValue<number>,
    onChangeDisposableCodePage: OnChangeValue<number>,
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const [input, setInput] = useState<Input>([]);

    useEffect(() => {
        const nextInput: Input = [];
        for (let hashedApiKey of user.user.apiPermission) {
            nextInput.push({
                nm: hashedApiKey.nm,
                hashedApiKey: hashedApiKey.hashedApiKey,
                isActivate: true,
                smsFee: hashedApiKey.smsFee,
                defaultSmsFee: hashedApiKey.smsFee
            })
        }
        setInput(nextInput);
    }, [user.user.apiPermission]);

    const onCopyClipboard = (joinCode: string) => {
        SwalUtil.ok({
            msg: `클립보드에 복사되었습니다.<br/>${joinCode}`,
            icon: 'success',
        });
        copy(`${window.location.host}/auth/join/Agent/${joinCode}`);
    }

    const handler = {
        onValidation: (): boolean => {
            let hasActivation = false;

            for (let elem of input) {
                if (elem.isActivate) {
                    hasActivation = true;
                }

                if (new Decimal(elem.smsFee).lt(elem.defaultSmsFee)) {
                    SwalUtil.ok({
                        msg: '대리점 회원의 건당가격이 리셀러 회원보다 낮을수 없습니다.',
                        icon: 'error'
                    });
                    return false;
                }
            }

            if (!hasActivation) {
                SwalUtil.ok({
                    msg: '최소 1개 이상의 회선을 활성화 하여 주십시오.',
                    icon: 'error',
                })
            }

            return hasActivation;
        },
        onCreateArgs: (): CreateApiPermission[] => {
            const list: CreateApiPermission[] = [];

            for (let elem of input) {
                if (!elem.isActivate) {
                    continue;
                }

                if (new Decimal(elem.smsFee).lt(elem.defaultSmsFee)) {
                    continue;
                }

                list.push({
                    hashedApiKey: elem.hashedApiKey,
                    smsFee: elem.smsFee
                })
            }

            return list;
        },
        onCreateReusableCode: () => {
            if (!handler.onValidation()) {
                return;
            }

            const args = handler.onCreateArgs()
            props.onCreateReusableCode({
                input: {apiPermission: args}
            })
        },
        onCreateDisposableCode: () => {
            if (!handler.onValidation()) {
                return;
            }

            const args = handler.onCreateArgs()
            props.onCreateDisposableCode({
                input: {apiPermission: args}
            })
        }
    }

    return (
        <MngLayout title={'대리점관리'}>
            {props.data &&
            <FadeIn>
                <>
                    <div className={cx("fullContainer")}>
                        <div className={cx("firstSection")}>
                            <div className={cx("section-side")}>
                                <div className={cx("side-flexBox")}>
                                    <div className={cx("side-titleBar")}>
                                        <div className={cx("titleBar-left")}>
                                            코드 관리
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={cx("secondSection")}>
                            <div className={cx("section-side")}>
                                <div className={cx("side-rowBox")}>
                                    <div className={cx("side-flexBox", "box-rightMargin")}>
                                        <div className={cx("side-title")}>
                                            <div className={cx("title-left")}>
                                                <FontAwesomeIcon icon={faChevronRight}
                                                                 style={{paddingTop: 3, marginRight: 5}}/> 재사용 코드
                                            </div>
                                            <div className={cx("title-right")}>
                                                <Button
                                                    variant="outlined"
                                                    classes={{root: cx("buttonOne")}}
                                                    onClick={handler.onCreateReusableCode}>재사용 코드 생성
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={cx("side-contentsColumn", "borderBottom")}>
                                            <div className={cx("contentsColumn-topHeader", "codeCell")}>
                                                가입코드
                                            </div>
                                            <div className={cx("contentsColumn-topHeader", "dateCell")}>
                                                생성일
                                            </div>
                                            <div className={cx("contentsColumn-topHeader", "statusCell")}>
                                                건당 가격
                                            </div>
                                            <div className={cx("contentsColumn-topHeader", "funcCell")}>
                                                기능
                                            </div>
                                        </div>

                                        {props.data.reusableJoinCodeList.list.length === 0 &&
                                        <div className={cx("side-contentsColumn")}>
                                            <div className={cx("emptyColumn")}>가입 코드가 없습니다.</div>
                                        </div>
                                        }

                                        {props.data.reusableJoinCodeList.list.map((value, index) => (
                                            <div key={index} className={cx("side-contentsColumn", "hover")}>
                                                <div className={cx("contentsColumn-bottomValue", "codeCell")}
                                                     onClick={ev => {
                                                         onCopyClipboard(value.code);
                                                     }}>
                                                    {value.code}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "dateCell")}>
                                                    {moment(value.createdAt).format('YYYY.MM.DD[\n]hh:mm a')}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "statusCell")}>
                                                    {value.apiPermission.map((apiPermission, apiPermissionIdx) => (
                                                        <span key={apiPermissionIdx}>{apiPermission.smsFee} 원</span>
                                                    ))}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "funcCell")}>
                                                    <Tooltip title={'클립보드 복사'}>
                                                        <IconButton
                                                            size='small'
                                                            classes={{label: cx("fontcolor-blue")}}
                                                            onClick={ev => {
                                                                onCopyClipboard(value.code);
                                                            }}>
                                                            <FontAwesomeIcon icon={faCopy}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={'삭제'}>
                                                        <IconButton
                                                            size='small'
                                                            classes={{label: cx("fontcolor-red")}}
                                                            onClick={ev => {
                                                                props.onDeleteCode(value.code);
                                                            }}>
                                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        ))}

                                        <PagerTemp1
                                            page={props.data.reusableJoinCodeList.page}
                                            size={props.data.reusableJoinCodeList.size}
                                            total={props.data.reusableJoinCodeList.total}
                                            onChange={props.onChangeReusableCodePage}/>
                                    </div>

                                    <div className={cx("side-flexBox")}>
                                        <div className={cx("side-title")}>
                                            <div className={cx("title-left")}>
                                                <FontAwesomeIcon icon={faChevronRight}
                                                                 style={{paddingTop: 3, marginRight: 5}}/> 일회용 코드
                                            </div>
                                            <div className={cx("title-right")}>
                                                <Button
                                                    variant="outlined"
                                                    classes={{root: cx("buttonOne")}}
                                                    onClick={handler.onCreateDisposableCode}>일회용 코드 생성
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={cx("side-contentsColumn", "borderBottom")}>
                                            <div className={cx("contentsColumn-topHeader", "codeCell")}>
                                                가입코드
                                            </div>
                                            <div className={cx("contentsColumn-topHeader", "dateCell")}>
                                                생성일
                                            </div>
                                            <div className={cx("contentsColumn-topHeader", "statusCell")}>
                                                건당 가격
                                            </div>
                                            <div className={cx("contentsColumn-topHeader", "funcCell")}>
                                                기능
                                            </div>
                                        </div>

                                        {props.data.disposableJoinCodeList.list.length === 0 &&
                                        <div className={cx("side-contentsColumn")}>
                                            <div className={cx("emptyColumn")}>가입 코드가 없습니다.</div>
                                        </div>
                                        }

                                        {props.data.disposableJoinCodeList.list.map((value, index) => (
                                            <div key={index} className={cx("side-contentsColumn", "hover")}>
                                                <div className={cx("contentsColumn-bottomValue", "codeCell")}
                                                     onClick={ev => {
                                                         onCopyClipboard(value.code);
                                                     }}>
                                                    {value.code}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "dateCell")}>
                                                    {moment(value.createdAt).format('YYYY.MM.DD[\n]hh:mm a')}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "statusCell")}>
                                                    {value.apiPermission.map((apiPermission, apiPermissionIdx) => (
                                                        <span key={apiPermissionIdx}>{apiPermission.smsFee} 원</span>
                                                    ))}
                                                </div>
                                                <div className={cx("contentsColumn-topHeader", "funcCell")}>
                                                    <Tooltip title={'클립보드 복사'}>
                                                        <IconButton
                                                            size='small'
                                                            classes={{label: cx("fontcolor-blue")}}
                                                            onClick={ev => {
                                                                onCopyClipboard(value.code);
                                                            }}>
                                                            <FontAwesomeIcon icon={faCopy}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={'삭제'}>
                                                        <IconButton
                                                            size='small'
                                                            classes={{label: cx("fontcolor-red")}}
                                                            onClick={ev => {
                                                                props.onDeleteCode(value.code);
                                                            }}>
                                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        ))}

                                        <PagerTemp1
                                            page={props.data.disposableJoinCodeList.page}
                                            size={props.data.disposableJoinCodeList.size}
                                            total={props.data.disposableJoinCodeList.total}
                                            onChange={props.onChangeDisposableCodePage}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </FadeIn>
            }
        </MngLayout>
    )
}

type Input = (CreateApiPermission & { isActivate: boolean, defaultSmsFee: number, nm: string })[];
