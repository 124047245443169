import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './NotAuth.module.scss';
import {CenterContents} from "../../shares/default/centerContents/CenterContents";
import {PhotoBackground} from "../../shares/default/photoBackground/PhotoBackground";
import imgWarning from '../../../assets/svg/warning.svg';

const cx = classNamesBind.bind(styles);

export const NotAuth = () => {
    return (
        <PhotoBackground>
            <CenterContents>
                <div className={cx('cont')}>
                    <div className='text-center'>
                        <img src={imgWarning} alt='warning'/>
                        <h3>서비스 <span style={{color: '#f37979'}}>점검중</span> 입니다.</h3>
                    </div>


                    <p className={cx('desc')}>
                        서비스에 불편을 드려 죄송힙니다.<br/>
                        점검시간 동안 서비스 이용이 제한되는 점 양해 부탁드립니다.<br/>
                        감사합니다.
                    </p>

                </div>
            </CenterContents>
        </PhotoBackground>

    )
}
