import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from '../section1/IndexV1Section1.module.scss'
import {useSelector} from "react-redux";
import {SystemState} from "../../../../redux/System";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake, faLightbulb, faTools} from "@fortawesome/free-solid-svg-icons";
import ReactPageScroller from "react-page-scroller";

const cx = classNamesBind.bind(styles);

export const IndexDubai = () => {
    const system = useSelector<any, SystemState>(state => state.system);
    const [currentPage, setCurrentPage] = useState(0);

    const handlePageChange = (value: number) => {
        setCurrentPage(value);
    };

    const handleBeforePageChange = (value: number) => {
    };

    return (
        <ReactPageScroller
            pageOnChange={handlePageChange}
            onBeforePageScroll={handleBeforePageChange}>
            <div className="component first-component" id={cx("firstSection")}>
                <div className={cx("contentBox")}>
                    <h2>
                        <p className={cx("contentBox-first")}>To Meet Our Service,</p>
                        <p className={cx("contentBox-second")}>To Find The Best Choice!</p>
                    </h2>
                    <p className={cx("contentBox-sub")}>{system.init.appNm}은 성공적인 비즈니스에 대한 최선의 선택입니다.</p>
                    <div className={cx("contentBox-indicator")}>
                        <span></span>
                    </div>
                </div>
            </div>
            <div className="component second-component" id={cx("dubaiSecondSection")}>
                <div className={cx("contentBox")}>
                    <h2>
                        <p className={cx("contentBox-first")}>
                            The Ultimate
                        </p>
                        <p className={cx("contentBox-second")}>
                            in Text Messaging
                        </p>
                    </h2>
                    <p className={cx("contentBox-sub")}>
                        높은 수신률, 실시간 전송, 직관적인 UI를 통한 메세징 서비스 최강자
                    </p>
                    <div className={cx("contentBox-indicator")}>
                        <span></span>
                    </div>
                    <div className={cx("contentBox-group")}>
                        <div className={cx("group-title")}>
                            WHAT WE DO
                        </div>
                        <div className={cx("group-process")}>
                            <div style={{width: "33%"}}></div>
                        </div>
                        <div className={cx("group-number")}>
                            01
                        </div>
                    </div>
                    <div className={cx("contentBox-earth")}>
                        <div className={cx("earth-motion")}>
                            <div className={cx("earth-img")}/>
                            <div className={cx("earth-lineOne")}/>
                            <div className={cx("earth-lineTwo")}/>
                        </div>
                        <div className={cx("earth-menu")}>
                        </div>
                        <ul>
                            <li className={cx("item1")}>
                                <div className={cx("menu-circle")}>
                                    높은 수신률
                                </div>
                            </li>
                            <li className={cx("item2")}>
                                <div className={cx("menu-circle")}>
                                    간편한 UI
                                </div>
                            </li>
                            <li className={cx("item3")}>
                                <div className={cx("menu-circle")}>
                                    애니 디바이스
                                </div>
                            </li>
                            <li className={cx("item4")}>
                                <div className={cx("menu-circle")}>
                                    길어진 문자
                                </div>
                            </li>
                            <li className={cx("item5")}>
                                <div className={cx("menu-circle")}>
                                    실시간 전송
                                </div>
                            </li>
                            <li className={cx("item6")}>
                                <div className={cx("menu-circle")}>
                                    쉬운 이력관리
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="component third-component" id={cx("dubaiThirdSection")}>
                <div className={cx("contentBox")}>
                    <h2>
                        <p className={cx("contentBox-first")}>
                            Constant Challenge
                        </p>
                        <p className={cx("contentBox-second")}>
                            Always the Perfect Solution
                        </p>
                    </h2>
                    <p className={cx("contentBox-sub")}>
                        끊임없는 도전과 업데이트를 통해 언제나 업계 최고의 솔루션을 제시합니다.
                    </p>
                    <div className={cx("contentBox-indicator")}>
                        <span></span>
                    </div>
                    <div className={cx("contentBox-group")}>
                        <div className={cx("group-title")}>
                            WHY US
                        </div>
                        <div className={cx("group-process")}>
                            <div style={{width: "66%"}}></div>
                        </div>
                        <div className={cx("group-number")}>
                            02
                        </div>
                    </div>
                    <div className={cx("contentBox-cardBox")}>
                        <ul>
                            <li className={cx("cardBox-card")}>
                                <div className={cx("card-text")}>
                                    매일 이루어지는 테스트와 리포트를 통한 무결성 유지
                                </div>
                                <div className={cx("card-icon")}>
                                    <FontAwesomeIcon icon={faTools} size="2x"/>
                                </div>
                            </li>
                            <li className={cx("cardBox-card")}>
                                <div className={cx("card-text")}>
                                    빠른 피드백을 통한 고객 니즈 파악과 수렴
                                </div>
                                <div className={cx("card-icon")}>
                                    <FontAwesomeIcon icon={faHandshake} size="2x"/>
                                </div>
                            </li>
                            <li className={cx("cardBox-card")}>
                                <div className={cx("card-text")}>
                                    새롭고 유저친화적인 기능 제공에 대한 끊임없는 도전과 열정
                                </div>
                                <div className={cx("card-icon")}>
                                    <FontAwesomeIcon icon={faLightbulb} size="2x"/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="component fourth-component" id={cx("dubaiFourthSection")}>
                <div className={cx("contentBox")}>
                    <h2>
                        <p className={cx("contentBox-first")}>
                            Reward Satisfactory Result
                        </p>
                        <p className={cx("contentBox-second")}>
                            For Customer Confidence
                        </p>
                    </h2>
                    <p className={cx("contentBox-sub")}>
                        고객의 믿음에 가격과 실리를 모두 잡은 만족스러운 성과로 보답하겠습니다.
                    </p>
                    <div className={cx("contentBox-indicator")}>
                        <span></span>
                    </div>
                    <div className={cx("contentBox-group")}>
                        <div className={cx("group-title")}>
                            OUR PRODUCT
                        </div>
                        <div className={cx("group-process")}>
                            <div style={{width: "100%"}}></div>
                        </div>
                        <div className={cx("group-number")}>
                            03
                        </div>
                    </div>
                    <div className={cx("contentBox-diamond")}>
                        <div className={cx("diamond-column")}>
                            <div className={cx("diamond-box")}>
                                <div className={cx("diamond-text")}>단 한 건에 대해서도<br/>경제적인 가격</div>
                            </div>
                            <div className={cx("diamond-box")}>
                                <div className={cx("diamond-text")}>효율적인 선택 요금제</div>
                            </div>
                        </div>
                        <div className={cx("diamond-column")}>
                            <div className={cx("diamond-box")}>
                                <div className={cx("diamond-text")}>실시간 전송에 따른<br/>즉각적인 피드백과<br/>높은 성공률</div>
                            </div>
                            <div className={cx("diamond-box")}>
                                <div className={cx("diamond-text")}>최소의 비용으로<br/>최고의 마케팅 효과</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ReactPageScroller>
    );
};
