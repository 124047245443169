export class NumberUtil {
    static splitNumb(numb: string): BulkData {
        const split = numb.trim();

        if (split === "") {
            return []
        } else {
            const list = numb.trim().split('\n');
            const res = [];

            for (let str of list) {
                if (str === "") {
                    continue;
                }

                res.push(str)
            }

            return res;
        }
    }

    static exceptWrongNumber(data: BulkData): BulkData {
        const res: BulkData = [];
        for (let numb of data) {
            const regEx = /^(010|10)([0-9]{8})$/g;
            if (regEx.exec(numb)) {
                res.push(numb);
            }
        }
        return res;
    }

    static exceptWrongContext(data: BulkData): BulkData {
        const res: BulkData = [];
        for (let numb of data) {
            res.push(numb.replace(/[^0-9]/g, ''))
        }
        return res;
    }

    static exceptDuplicated(data: BulkData): BulkData {
        const map: { [numb: string]: string } = {};
        const res: BulkData = [];
        for (let numb of data) {
            if (!map.hasOwnProperty(numb)) {
                res.push(numb);
                map[numb] = "";
            }
        }
        return res;
    }

    // 두 배열을 비교하여 중복이 있으면 a의 중복을 제거한다.
    static exceptDuplicatedWithCompare(a: BulkData, b: BulkData): { a: BulkData, b: BulkData } {
        const res = [];
        for (let originNumb of a) {
            const hasIndex = b.findIndex(value => {
                return value === originNumb;
            });

            if (hasIndex !== -1) {
                continue;
            }

            res.push(originNumb);
        }

        return {
            a: res,
            b: b
        }
    }

    static appendNationNumber(nationNumber: string, data: BulkData): BulkData {
        const res: BulkData = [];

        for (let numb of data) {
            if (numb.startsWith("01", 0)) {
                res.push(`${nationNumber}${numb.substr(1, numb.length)}`);
            } else if (numb.startsWith("10", 0)) {
                res.push(`${nationNumber}${numb}`)
            }
        }
        return res;
    }

    static toRawData(data: BulkData): string {
        let res = "";
        for (let datum of data) {
            res += `${datum}\n`
        }
        return res;
    }

    static hasOwnNumber(data: BulkData, numb: string): boolean {
        for (let numbInList of data) {
            if (numbInList === numb) {
                return true;
            }
        }
        return false;
    }

    // 두 전화번호를 비교하여 같으면 true, 틀리면 false
    static compareSame(a: string[], b: string[]): boolean {
        if (a.length !== b.length) {
            return false
        }

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }

        return true;
    }

}


export class TemplateNumbUtil {
    static countNumber(msg: string, numb: string): TemplateNumberResult {
        const list = numb.trim().split('\n');
        const resList: TemplateSms[] = [];

        for (let data of list) {
            const splitData = data.split(',');

            if (splitData.length === 0) {
                continue;
            }

            const sms: TemplateSms = {
                number: splitData[0],
                data: splitData.slice(1, splitData.length),
                msg: msg,
                isAvailable: false,
            }

            resList.push(sms);
        }


        return {
            count: resList.length,
            values: resList,
        };
    }

    static parsingMsg(msg: string, data: TemplateNumberResult): TemplateNumberResult {
        const splitMsg = msg.split('###');

        const res: TemplateSms[] = [];

        for (let value of data.values) {
            if ((splitMsg.length - 1) !== value.data.length) {
                res.push({
                    number: value.number,
                    data: value.data,
                    msg: '데이터를 확인하여 주십시오.',
                    isAvailable: false
                });
                continue;
            }

            let resMsg = "";
            for (let i = 0; i < splitMsg.length; i++) {
                resMsg += `${splitMsg[i]}${value.data[i] || ''}`
            }

            res.push({
                number: value.number,
                data: value.data,
                msg: resMsg,
                isAvailable: true
            })
        }

        return {
            count: res.length,
            values: res,
        };
    }

    static extractDuplicatedNumber(data: TemplateNumberResult): TemplateNumberResult {
        const map: { [numb: string]: string } = {};

        const res: TemplateSms[] = [];

        for (let value of data.values) {
            if (!map.hasOwnProperty(value.number)) {
                res.push(value);
                map[value.number] = "";
            }
        }


        return {
            count: res.length,
            values: res
        }
    }

    static extractWrongNumber(data: TemplateNumberResult): TemplateNumberResult {
        const res: TemplateSms[] = [];

        for (let value of data.values) {
            const regEx = /^(010|10)([0-9]{8})$/g;
            if (regEx.exec(value.number)) {
                res.push(value);
            }
        }

        return {
            count: res.length,
            values: res
        }
    }


    static appendNationCode(data: TemplateNumberResult): TemplateNumberResult {
        const res: TemplateSms[] = [];

        for (let value of data.values) {
            if (value.number.startsWith("01", 0)) {
                value.number = "82" + value.number.substr(1, value.number.length);
                res.push(value);
            } else if (value.number.startsWith("10", 0)) {
                value.number = "82" + value.number;
                res.push(value);
            }
        }

        return {
            count: res.length,
            values: res
        }
    }
}


export class NumbUtil {
    static countNumb(numb: string): NumbUtilResult {
        const list = numb.split("\n");
        let res = "";
        let count = 0;

        for (let numbString of list) {
            if (numbString) {
                res += `${numbString}\n`;
                count += 1;
            }
        }

        return {count: count, numb: res.trim()};
    }

    static onlyNumber(numb: string): NumbUtilResult {
        const list = numb.split("\n");
        let res = "";
        let count = 0;

        for (let numbString of list) {
            res += `${numbString.replace(/[^0-9]/g, '')}\n`;
            count += 1;
        }

        return {count: count, numb: res.trim()};
    }

    static wrongContext(numb: string): NumbUtilResult {
        const list = numb.split("\n");
        let res = "";
        let count = 0;
        const regEx = /^(010|10)([0-9]{8})$/g;


        for (let numbString of list) {

            if (regEx.test(numbString)) {
                res += `${numbString}\n`;
                count += 1;
            }
        }

        return {count: count, numb: res.trim()};
    }

    static duplicated(numb: string): NumbUtilResult {
        const list = numb.split("\n");
        let res = "";
        let count = 0;
        const map: { [numb: string]: string } = {};

        for (let numbString of list) {
            if (!map.hasOwnProperty(numbString)) {
                map[numbString] = '';
                res += `${numbString}\n`;
                count += 1;
            }
        }

        return {count: count, numb: res.trim()};
    }
}

export interface NumbUtilResult {
    count: number;
    numb: string;
}

export type BulkData = string[];

export interface TemplateNumberResult {
    count: number;
    values: TemplateSms[];
}

export interface TemplateSms {
    number: string,
    data: string[],
    msg: string,
    isAvailable: boolean,
}
