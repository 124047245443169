import React, {Fragment} from "react";
import {MyPageInfoResp} from "../MypageInfo";
import {OnChangeValue, OnVoidFunction} from "../../../interfaces";
import {useSelector} from "react-redux";
import {UserState} from "../../../../redux/User";
import {FbJoinType, UserType} from "../../../../graphql/types";
import NumberFormat from "react-number-format";
import classNamesBind from "classnames/bind";
import styles from './MyPageInfoTemp1.module.scss'
import moment from "moment";
import Decimal from "decimal.js";
import {Button} from "@material-ui/core";
import {FadeInContainerTemp1} from "../../../shares/temp1/fadeInContainer/FadeInContainerTemp1";
import {BadgeMyPageInfoBalanceTemp1} from "../../../shares/temp1/badgeMypageInfoBalance/BadgeMyPageInfoBalanceTemp1";
import {PagerTemp1} from "../../../shares/temp1/pager/PagerTemp1";

const cx = classNamesBind.bind(styles);

export const MyPageInfoTemp1 = (props: {
    data: MyPageInfoResp,
    onResetPw: OnVoidFunction,
    onChangePage: OnChangeValue<number>
}) => {
    const user = useSelector<any, UserState>(state => state.user);

    return (
        <FadeInContainerTemp1>
            <>
                <div className={cx("fullContainer")}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-left")}>
                                        회원 정보
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cx("secondSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-contentsColumn", "borderBottom")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        아이디
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        {user.user.email}
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn", "borderBottom")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        등급
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        {user.user.userType === UserType.General && "일반회원"}
                                        {user.user.userType === UserType.Reseller && "총판회원"}
                                        {user.user.userType === UserType.Agent && "정회원"}
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn", "borderBottom")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        잔고
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        <NumberFormat
                                            thousandSeparator
                                            value={user.user.point}
                                            suffix={' P'}
                                            displayType='text'/>
                                    </div>
                                </div>
                                {user.user.apiPermission.map((value, index) => (
                                    <Fragment key={index}>
                                        <div className={cx("side-contentsColumn", "borderBottom")} key={index}>
                                            <div className={cx("contentsColumn-leftHeader")}>
                                                건 당 가격
                                            </div>
                                            <div className={cx("contentsColumn-rightValue")}>
                                                {value.smsFee} 원
                                            </div>
                                        </div>
                                        <div className={cx("side-contentsColumn", "borderBottom")}>
                                            <div className={cx("contentsColumn-leftHeader")}>
                                                전송가능 건 수
                                            </div>
                                            <div className={cx("contentsColumn-rightValue")}>
                                                {new Decimal(user.user.point).divToInt(value.smsFee).toNumber()} 건
                                            </div>
                                        </div>
                                    </Fragment>
                                ))}

                                {user.user.fbJoinType === FbJoinType.Email &&
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-leftHeader")}>
                                        비밀번호 변경
                                    </div>
                                    <div className={cx("contentsColumn-rightValue")}>
                                        <Button
                                            classes={{root: cx("buttonOne")}}
                                            variant="outlined"
                                            style={{fontSize: 12}}
                                            onClick={props.onResetPw}>비밀번호변경
                                        </Button>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className={cx("fifthSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-left")}>
                                        포인트 기록
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cx("sixthSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-contentsColumn", "headerColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "dateCell")}>
                                        날짜
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "statusCell")}>
                                        종류
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "prevPointCell")}>
                                        기존 포인트
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "changePointCell")}>
                                        변경 포인트
                                    </div>
                                    <div className={cx("contentsColumn-topHeader", "lastPointCell")}>
                                        최종 포인트
                                    </div>
                                </div>
                                {props.data.pointHistory.list.map((value, index) => {
                                    return (
                                        <div className={cx("side-contentsColumn")} key={index}>
                                            <div className={cx("contentsColumn-bottomValue", "dateCell")}>
                                                {moment(value.createdAt).format('YYYY년 MM월 DD일 hh:mm (A)')}
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "statusCell")}>
                                                <BadgeMyPageInfoBalanceTemp1 value={value.receiptType}/>
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "prevPointCell")}>
                                                <NumberFormat
                                                    thousandSeparator
                                                    displayType='text'
                                                    suffix={' P'}
                                                    value={value.prevPoint}/>
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "changePointCell")}>
                                                <NumberFormat
                                                    thousandSeparator
                                                    displayType='text'
                                                    suffix={' P'}
                                                    value={value.changedPoint}/>
                                            </div>
                                            <div className={cx("contentsColumn-bottomValue", "lastPointCell")}>
                                                <NumberFormat
                                                    thousandSeparator
                                                    displayType='text'
                                                    suffix={' P'}
                                                    value={value.point}/>
                                            </div>
                                        </div>
                                    )
                                })}

                                <PagerTemp1
                                    page={props.data.pointHistory.page}
                                    size={props.data.pointHistory.size}
                                    total={props.data.pointHistory.total}
                                    onChange={props.onChangePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </FadeInContainerTemp1>
    )
}
