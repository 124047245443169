import React from 'react';
import classNamesBind from "classnames/bind";
import styles from './FooterWeb.module.scss';

const cx = classNamesBind.bind(styles);

export const FooterWeb = (props: {
    appName: string
}) => {
    return (
        <div className={cx('cont')}>
            Copyright ⓒ 2020 {props.appName}. <br/>
            This site is provided by SmartInfo Corp. (KR)
            All rights reserved.
        </div>
    )
}
