import React from "react";
import {DepositRequestInput} from "./input/DepositRequestInput";
import {DepositRequestList} from "./list/DepositRequestList";
import {FadeInContainer} from "../../../shares/default/fadeInContainer/FadeInContainer";
import {DepositRequestInputData, DepositRequestResData} from "../DepositRequest";
import {OnChangeValue} from "../../../interfaces";

export const DepositRequestTemp1 = (props: {
    data: DepositRequestResData
    value: DepositRequestInputData,
    setValue: OnChangeValue<DepositRequestInputData>
    onDepositRequest: OnChangeValue<DepositRequestInputData>
    onChangePage: OnChangeValue<number>,
    onCancelDepositRecharge: OnChangeValue<number>
}) => {
    return (
        <FadeInContainer>
            <DepositRequestInput
                value={props.value}
                onChangeValue={props.setValue}
                onRequest={props.onDepositRequest}/>
            <DepositRequestList
                list={props.data.depositRequestHistory}
                onChangePage={props.onChangePage}
                onCancel={props.onCancelDepositRecharge}/>
        </FadeInContainer>
    )
}
