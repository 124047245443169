import React, {Fragment} from "react";
import {RechargeCommissionSettleRequestStatus} from "../../../../graphql/types";

export const BadgeRechargeCommissionSettleRequestStatus = (props: {
    status: RechargeCommissionSettleRequestStatus
}) => {
    return (
        <Fragment>
            {props.status === RechargeCommissionSettleRequestStatus.Requested &&
            <span className='badge badge-primary'>승인대기</span>}

            {props.status === RechargeCommissionSettleRequestStatus.Deny &&
            <span className='badge badge-danger'>반려</span>}

            {props.status === RechargeCommissionSettleRequestStatus.Allow &&
            <span className='badge badge-success'>승인완료</span>}

            {props.status === RechargeCommissionSettleRequestStatus.Cancel &&
            <span className='badge badge-secondary'>사용자취소</span>}
        </Fragment>
    )
}
