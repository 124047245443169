import React from "react";
import classNamesBind from "classnames/bind";
import styles from './LayoutMobileSidebar.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {SystemState} from "../../../../../redux/System";
import {MobileAction} from "../../../../../redux/Mobile";
import {navigate} from "hookrouter";
import {NavBar} from "../../../Layout";
import {OnVoidFunction} from "../../../../interfaces";
import {UserState} from "../../../../../redux/User";

const cx = classNamesBind.bind(styles);

export const LayoutMobileSidebar = (props: {
    onLogout: OnVoidFunction,
    navBar: NavBar[],
}) => {
    const system = useSelector<any, SystemState>(state => state.system);
    const user = useSelector<any, UserState>(state => state.user);

    const dispatch = useDispatch();

    const onClickRouter = (router: string) => {
        navigate(router);
        dispatch(MobileAction.setSidebar(false));
    }

    return (
        <div className={cx('cont')}>
            <div
                className={cx('title')}
                onClick={event => {
                    onClickRouter('/');
                }}>{system.init.appNm}</div>
            {props.navBar.map((value, index) => (
                <div
                    className={cx('menu')}
                    key={index}
                    onClick={event => {
                        onClickRouter(value.router);
                    }}>
                    {value.title}
                </div>
            ))}
            {user.isLogin && (
                <div className={cx("menu")} onClick={props.onLogout}>로그아웃</div>
            )}
        </div>
    )
}
