import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngSendCommissionDefault.module.scss'
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {Pipe} from "../../../../utils/pipe/Pipe";
import moment from "moment";
import {Pager} from "../../../shares/default/pager/Pager";
import {MngLayout} from "../../layout/MngLayout";
import {OnChangeValue} from "../../../interfaces";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {MutationRequestSendCommissionSettleArgs, Query, SettleCommissionType} from "../../../../graphql/types";
import {BizNoDataTable} from "../../../shares/default/bizNoDataTable/BizNoDataTable";
import {BadgeSendCommissionSettleRequestStatus} from "../../../shares/default/badgeSendCommissionSettleRequestStatus/BadgeSendCommissionSettleRequestStatus";
import {BizModal} from "../../../shares/default/bizModal/BizModal";
import {SendCommissionDefaultSettleModal} from "./settleModal/SendCommissionDefaultSettleModal";

const cx = classNamesBind.bind(styles);

export const MngSendCommissionDefault = (props: {
    data?: Query,
    onChangePage: OnChangeValue<number>,
    onChangeRequestPage: OnChangeValue<number>,
    onRequestSettle: OnChangeValue<MutationRequestSendCommissionSettleArgs>
}) => {
    const [settleModal, setSettleModal] = useState(false);

    return (
        <MngLayout title={'대리점관리'}>
            {props.data &&
            <FadeIn>
                <BizModal
                    title={'정산 방식을 선택하여 주십시오.'}
                    size={'lg'}
                    show={settleModal}
                    onChangeShow={setSettleModal}>
                    <SendCommissionDefaultSettleModal
                        onRequestSettle={value => {
                            setSettleModal(false);
                            props.onRequestSettle(value);
                        }}/>
                </BizModal>

                <BizTitle>전송 커미션</BizTitle>
                <div className={cx('table-title')}>합계</div>
                <div className={cx('table')}>
                    <div style={{width: '33%'}}>전체</div>
                    <div style={{width: '33%'}}>지급완료</div>
                    <div style={{width: '34%'}}>미지급</div>
                </div>

                <div className={cx('table')}>
                    <div style={{width: '33%'}}>{Pipe.toSeparatorNumber(props.data.totalSendCommission.total)} 원
                    </div>
                    <div style={{width: '33%'}}>{Pipe.toSeparatorNumber(props.data.totalSendCommission.paid)} 원
                    </div>
                    <div style={{width: '34%'}}>{Pipe.toSeparatorNumber(props.data.totalSendCommission.notPaid)} 원
                    </div>
                </div>

                <div className={cx('table')}>
                    <div className='text-right' style={{width: '100%'}}>
                        <button
                            className='btn btn-sm btn-success'
                            onClick={() => setSettleModal(true)}>정산요청</button>
                    </div>
                </div>


                <div style={{height: 60}}/>
                <div className={cx('table-title')}>정산요청 목록</div>
                <div className={cx('table')}>
                    <div style={{width: '20%'}}>날짜</div>
                    <div style={{width: '20%'}}>상태</div>
                    <div style={{width: '20%'}}>지불종류</div>
                    <div style={{width: '20%'}}>커미션 수</div>
                    <div style={{width: '20%'}}>커미션 합</div>
                </div>

                {props.data.sendCommissionSettleRequestList.list.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div style={{width: '20%'}}>{moment(value.createdAt).format('MM.DD - hh:mm a')}</div>
                        <div style={{width: '20%'}}>
                            <BadgeSendCommissionSettleRequestStatus
                                status={value.status}/>
                        </div>
                        <div style={{width: '20%'}}>
                            {value.commissionType === SettleCommissionType.ChargePoint && "포인트 정산"}
                            {value.commissionType === SettleCommissionType.Withdraw && "입금 정산"}
                        </div>
                        <div className='text-right'
                             style={{width: '20%'}}>{Pipe.toSeparatorNumber(value.commissionCount)} 건
                        </div>
                        <div className='text-right'
                             style={{width: '20%'}}>{Pipe.toSeparatorNumber(value.commission)} 원
                        </div>
                    </div>
                ))}

                {props.data.sendCommissionSettleRequestList.total === 0 && <BizNoDataTable/>}

                <Pager
                    page={props.data.sendCommissionSettleRequestList.page}
                    size={props.data.sendCommissionSettleRequestList.size}
                    total={props.data.sendCommissionSettleRequestList.total}
                    onChange={props.onChangeRequestPage}/>


                <div className={cx('table-title')}>커미션 정보</div>
                <div className={cx('table')}>
                    <div className={cx("dateCell", "hiddenCell")}>날짜</div>
                    <div className={cx("badgeCell")}>지불정보</div>
                    <div className={cx("requestCell", "hiddenCell")}>요청정보</div>
                    <div className={cx("agentCell")}>대리점</div>
                    <div className={cx("marginCell", "hiddenCell")}>건당마진</div>
                    <div className={cx("valueCell", "hiddenCell")}>전송갯수</div>
                    <div className={cx("commissionCell")}>커미션</div>
                </div>

                {props.data.sendCommissionList.list.map((value, index) => {
                    return (
                        <div key={index} className={cx('table', 'hover', 'pointer')}>
                            <div className={cx("dateCell", "hiddenCell")}>
                                {moment(value.createdAt).format('YY.MM.DD')}
                            </div>
                            <div className={cx("badgeCell")}>
                                {value.isWithdrawal &&
                                <span className='badge badge-success'>지급완료</span>}

                                {!value.isWithdrawal &&
                                <span className='badge badge-secondary'>미지급</span>}
                            </div>
                            <div className={cx("requestCell", "hiddenCell")}>
                                {value.isRequestedSettle &&
                                <span className='badge badge-success'>요청됨</span>}

                                {!value.isRequestedSettle &&
                                <span className='badge badge-secondary'>미요청</span>}
                            </div>
                            <div className={cx("agentCell")}>
                                {value.agentEmail}
                            </div>
                            <div className={cx("marginCell", "hiddenCell", "textRight")}>
                                {value.margin} 원
                            </div>
                            <div className={cx("valueCell", "hiddenCell", "textRight")}>
                                {Pipe.toSeparatorNumber(value.agentSendTotal)} 개
                            </div>
                            <div className={cx("commissionCell", "textRight")}>
                                {Pipe.toSeparatorNumber(value.commission)} 원
                            </div>
                        </div>
                    )
                })}

                <Pager
                    page={props.data.sendCommissionList.page}
                    size={props.data.sendCommissionList.size}
                    total={props.data.sendCommissionList.total}
                    onChange={props.onChangePage}/>
            </FadeIn>
            }
        </MngLayout>
    )
}
