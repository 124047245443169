import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './ResultTelbookTemp2.module.scss'
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {OnVoidFunction} from "../../../interfaces";
import {ResultTelbookResp} from "../ResultTelBook";
import {Button, withStyles} from "@material-ui/core";
import SpeakerNoteIcon from "@material-ui/icons/SpeakerNotes";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import {FadeInContainerTemp2} from "../../../shares/temp2/fadeInContainer/FadeInContainerTemp2";

const cx = classNamesBind.bind(styles);

const ColorButton1 = withStyles(() => ({
    root: {
        color: "#fff",
        backgroundColor: "#2196f3",
        '&:hover': {
            color: "#2196f3",
            backgroundColor: "#fff",
        },
        paddingLeft: 5,
        paddingRight: 5
    },
}))(Button);

export const ResultTelbookTemp2 = (props: {
    data: ResultTelbookResp,
    onDownload: OnVoidFunction,
}) => {
    return (
        <Fragment>
            <FadeInContainerTemp2>
                <div className={cx('fullContainer')}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-icon")}>
                                        <SpeakerNoteIcon/>
                                    </div>
                                    <div className={cx("titleBar-text")}>
                                        <div className={cx("text-main")}>
                                            전송 상세 결과
                                        </div>
                                        <div className={cx("text-sub")}>
                                            Sent detail result
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "topBox")}>
                                        요청키
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "topBox")} style={{flexGrow: 1}}>
                                        {props.data.telbook.requestId}
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "topBox")}>
                                        요청일
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "topBox")} style={{flexGrow: 1}}>
                                        {moment(props.data.telbook.createdAt).format('YYYY년 MM월 DD일 hh시 mm분 (A)')}
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "topBox")}>
                                        결과
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "topBox")} style={{flexGrow: 1}}>
                                        요청 : {Pipe.toSeparatorNumber(props.data.telbook.requestTotal)},
                                        완료 : {Pipe.toSeparatorNumber(props.data.telbook.sent)},
                                        실패 : {Pipe.toSeparatorNumber(props.data.telbook.fail)}
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "topBox")}>
                                        메세지
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "topBox")} style={{flexGrow: 1}}>
                                        {props.data.telbook.msg}
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "topBox")}>
                                        결과 다운로드
                                    </div>
                                    <div className={cx("contentsColumn-bottomValue", "topBox")} style={{flexGrow: 1}}>
                                        <ColorButton1
                                            variant="outlined"
                                            style={{fontSize: 12}}
                                            onClick={props.onDownload}>다운로드
                                        </ColorButton1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{height: 40}} />

                    <div className={cx("secondSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-icon")}>
                                        <PlaylistAddCheckIcon/>
                                    </div>
                                    <div className={cx("titleBar-text")}>
                                        <div className={cx("text-main")}>
                                            발송 번호 내역
                                        </div>
                                        <div className={cx("text-sub")}>
                                            Sent number List
                                        </div>
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn")}>
                                    <div className={cx("contentsColumn-topHeader", "statusCell")}>
                                        결과
                                    </div>
                                    <div className={cx("contentsColumn-topHeader")} style={{flexGrow: 1}}>
                                        전화번호
                                    </div>
                                </div>
                                {props.data.telbook.numberList.map((value, index) => (
                                    <div className={cx("side-contentsColumn")} key={index}>
                                        <div className={cx("contentsColumn-bottomValue", "statusCell")}>
                                            {value.status}
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue")} style={{flexGrow: 1}}>
                                            {value.number}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </FadeInContainerTemp2>
        </Fragment>
    )
}
