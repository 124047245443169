import {makeVar} from "@apollo/client";

export const GlobalVars = {
    auth: {
        activation: makeVar(false),
        apiKey: makeVar<string | undefined>(undefined),
        env: makeVar<InitEnv | undefined>(undefined),
    },
    layout: {
        loadingView: makeVar(false),
    },
    notify: {
        chatNotify: makeVar(0),
    },
    user: {}
}


export interface InitEnv {
    twak: string
}
