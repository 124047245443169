import React from "react";
import {BizCol12} from "../../../shares/default/bizContainer/BizContainer";
import {DepositRequestInput} from "./input/DepositRequestInput";
import {DepositRequestList} from "./list/default/DepositRequestList";
import {FadeInContainer} from "../../../shares/default/fadeInContainer/FadeInContainer";
import {DepositRequestInputData, DepositRequestResData} from "../DepositRequest";
import {OnChangeValue} from "../../../interfaces";

export const DepositRequestDefault = (props: {
    data: DepositRequestResData
    value: DepositRequestInputData,
    setValue: OnChangeValue<DepositRequestInputData>
    onDepositRequest: OnChangeValue<DepositRequestInputData>
    onChangePage: OnChangeValue<number>,
    onCancelDepositRecharge: OnChangeValue<number>
}) => {
    return (
        <FadeInContainer>
            <BizCol12>
                <DepositRequestInput
                    value={props.value}
                    onChangeValue={props.setValue}
                    onRequest={props.onDepositRequest}/>
            </BizCol12>

            <BizCol12>
                <DepositRequestList
                    list={props.data.depositRequestHistory}
                    onChangePage={props.onChangePage}
                    onCancel={props.onCancelDepositRecharge}/>
            </BizCol12>
        </FadeInContainer>
    )
}
