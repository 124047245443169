import * as firebase from 'firebase';
import {SwalUtil} from "../swal/swalUtil";
import {ApolloCatch} from "../apollo/apolloCatch";
import {BizErrors} from "../../graphql/errors";
import {ApolloError} from "@apollo/client";
import {GraphQLError} from "graphql";
import {GlobalVars} from "../../globalVars/globalVars";


export class FB {
    private static recapcha: any;
    private static recapchaCode: string;

    static initFirebase(options: any) {
        firebase.initializeApp(options);

        // 전화번호 인증 추가
        firebase.auth().languageCode = 'ko';
    }

    static connectReCAPCHAToContainer(containerId: string) {
        // reCAPTCHA 창 활성화
        FB.recapcha = new firebase.auth.RecaptchaVerifier(containerId, {
            'size': 'invisible',
            'callback': (res: any) => {
                FB.recapchaCode = res
            },
            'expired-callback': () => {
                // 파기되면 다시 렌더링 하기
                FB.connectReCAPCHAToContainer(containerId);
            }
        });

        FB.recapcha.render().then((widgetId: string) => {
            (window as any).recaptchaWidgetId = widgetId;
        })
    }

    static signInWithPhoneNumber(params: {
        phoneNumber: string
    }): Promise<string> {
        GlobalVars.layout.loadingView(true);
        return firebase.auth().signInWithPhoneNumber(params.phoneNumber, FB.recapcha)
            .then(value => {
                return SwalUtil.inputSmsVerifier()
                    .then(verifier => {
                        return value.confirm(verifier)
                    })
                    .then(cred => {
                        if (!cred.user) {
                            return ""
                        }
                        return cred.user.uid
                    })
            })
    }

    static async join(params: {
        email: string,
        pw: string,
    }): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(params.email, params.pw)
                .then(cred => {
                    const {user} = cred;

                    if (!user) {
                        reject();
                        return;
                    }
                    resolve(user.uid);
                })
                .catch(e => {
                    if (!e.hasOwnProperty("code")) {
                        SwalUtil.ok({
                            msg: e,
                            icon: "error"
                        });
                        reject();
                        return;
                    }

                    switch (e.code) {
                        case "auth/email-already-in-use":
                            SwalUtil.ok({
                                msg: '이미 가입되어 있는 이메일 입니다.',
                                icon: 'error'
                            });
                            break;
                        default:
                            SwalUtil.ok({
                                msg: e.code,
                                icon: "error"
                            });
                    }
                    reject();
                })
        })
    }

    static login(params: {
        email: string,
        pw: string,
    }): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(params.email, params.pw)
                .then(cred => {
                    const {user} = cred;

                    if (!user) {
                        resolve();
                        return;
                    }
                    resolve(user.uid)
                })
                .catch(e => {
                    if (!e.hasOwnProperty("code")) {
                        SwalUtil.ok({
                            msg: e,
                            icon: "error"
                        });
                        reject();
                        return;
                    }

                    switch (e.code) {
                        default:
                            SwalUtil.ok({
                                msg: e.code,
                                icon: "error"
                            });
                    }
                    reject();
                })
        })
    }

    static resetPwWithEmailUsingSwal() {
        SwalUtil.input(email => {
            firebase.auth().sendPasswordResetEmail(email)
                .then(() => {
                    SwalUtil.ok({
                        msg: '전송하였습니다.',
                        icon: 'success'
                    })
                })
                .catch(reason => {
                    SwalUtil.ok({
                        msg: `실패하였습니다.<br/>${reason}`,
                        icon: "error"
                    });
                })
        }, '이메일을 입력하여 주십시오.', '')
    }

    static resetPwWithSwal(email: string) {
        SwalUtil.pw(pw => {
            FB.resetPw(email, pw)
                .then(res => {
                    SwalUtil.ok({
                        msg: "비밀번호 변경 이메일을 전송하였습니다.",
                        icon: "success",
                    })
                })
                .catch(ApolloCatch({
                    [BizErrors.loginFail]: () => {
                        SwalUtil.ok({
                            msg: "비밀번호가 틀렸습니다.",
                            icon: "error",
                        })
                    }
                }));

        });
    }


    static resetPw(email: string, pw: string): Promise<void> {
        const err = new ApolloError({
            graphQLErrors: [
                new GraphQLError(BizErrors.loginFail)
            ]
        });

        return new Promise((resolve, reject) => {
            firebase.auth()
                .signInWithEmailAndPassword(email, pw)
                .then(user => {
                    if (!user.user) {
                        reject(err);
                        return;
                    }

                    return firebase.auth().sendPasswordResetEmail(email);
                })
                .then(res => {
                    resolve();
                })
                .catch(reason => {
                    reject(err);
                });
        })

    }
}
