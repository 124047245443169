import React from "react";
import {MyPageInfoResp} from "../MypageInfo";
import {OnChangeValue, OnVoidFunction} from "../../../interfaces";
import {useSelector} from "react-redux";
import {UserState} from "../../../../redux/User";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {FbJoinType, UserType} from "../../../../graphql/types";
import NumberFormat from "react-number-format";
import {BizCol12, BizContainer} from "../../../shares/default/bizContainer/BizContainer";
import classNamesBind from "classnames/bind";
import styles from './MyPageInfoDefault.module.scss'
import moment from "moment";
import {BizBadgeBalanceReceiptType} from "../../../shares/default/bizBadgeBalanceReceiptType/BizBadgeBalanceReceiptType";
import {Pager} from "../../../shares/default/pager/Pager";
import Decimal from "decimal.js";

const cx = classNamesBind.bind(styles);

export const MyPageInfoDefault = (props: {
    data: MyPageInfoResp,
    onResetPw: OnVoidFunction,
    onChangePage: OnChangeValue<number>
}) => {
    const user = useSelector<any, UserState>(state => state.user);

    return (
        <BizContainer>
            <BizCol12>
                <BizTitle>회원정보</BizTitle>
                <div className={cx('table')}>
                    <div style={{width: 150}}>유저</div>
                    <div style={{width: 'calc(100% - 150px)'}}>{user.user.email}</div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: 150}}>등급</div>
                    <div style={{width: 'calc(100% - 150px)'}}>
                        {user.user.userType === UserType.General && "일반회원"}
                        {user.user.userType === UserType.Reseller && "총판회원"}
                        {user.user.userType === UserType.Agent && "정회원"}
                    </div>
                </div>
                <div className={cx('table')}>
                    <div style={{width: 150}}>잔고</div>
                    <div style={{width: 'calc(100% - 150px)'}}>
                        <NumberFormat
                            thousandSeparator
                            value={user.user.point}
                            suffix={' P'}
                            displayType='text'/>
                    </div>
                </div>

                {user.user.fbJoinType === FbJoinType.Email &&
                <div className={cx('table')}>
                    <div style={{width: 150}}>비밀번호 변경</div>
                    <div style={{width: 'calc(100% - 150px)'}}>
                        <button
                            className='btn btn-sm btn-outline-secondary'
                            onClick={props.onResetPw}>비밀번호 변경
                        </button>
                    </div>
                </div>
                }
            </BizCol12>

            <BizCol12>
                <BizTitle>회선정보</BizTitle>
                <div className={cx('table')}>
                    <div style={{width: 150}}>이름</div>
                    <div style={{width: 100}}>건당 가격</div>
                    <div style={{minWidth: 'calc(100% - 250px)', flexGrow: 1}}>전송 가능</div>
                </div>

                {user.user.apiPermission.map((value, index) => (
                    <div key={index} className={cx('table', 'hover')}>
                        <div style={{width: 150}}>{value.nm}</div>
                        <div className={cx('text-right')} style={{width: 100}}>{value.smsFee} 원</div>
                        <div className={cx('text-right')}
                             style={{minWidth: 'calc(100% - 250px)', flexGrow: 1, color: 'gray'}}>
                            {new Decimal(user.user.point).divToInt(value.smsFee).toNumber()} 건
                        </div>
                    </div>

                ))}
            </BizCol12>

            <BizCol12>
                <BizTitle>포인트 사용/충전 기록</BizTitle>
                <div className={cx('table')}>
                    <div className={cx("dateCell")}>사용날짜</div>
                    <div className={cx("statusCell")}>종류</div>
                    <div className={cx("prevPointCell", "hiddenCell")}>변경전 포인트
                    </div>
                    <div className={cx("changePointCell", "hiddenCell")}>변경된 포인트
                    </div>
                    <div className={cx("lastPointCell")}>포인트</div>
                </div>

                {props.data.pointHistory.list.map((value, index) => {
                    return (
                        <div key={index} className={cx('table', 'hover')}>
                            <div className={cx("dateCell")}>
                                {moment(value.createdAt).format('YY.MM.DD hh:mm a')}
                            </div>
                            <div className={cx("statusCell")}>
                                <BizBadgeBalanceReceiptType value={value.receiptType}/>
                            </div>
                            <div className={cx("prevPointCell", "hiddenCell", "textRight")}>
                                <NumberFormat
                                    thousandSeparator
                                    displayType='text'
                                    suffix={' P'}
                                    value={value.prevPoint}/>

                            </div>
                            <div className={cx("changePointCell", "hiddenCell", "textRight")}>
                                <NumberFormat
                                    thousandSeparator
                                    displayType='text'
                                    suffix={' P'}
                                    value={value.changedPoint}/>
                            </div>
                            <div className={cx("lastPointCell", "textRight")}>
                                <NumberFormat
                                    thousandSeparator
                                    displayType='text'
                                    suffix={' P'}
                                    value={value.point}/>
                            </div>
                        </div>
                    )
                })}

                <Pager
                    page={props.data.pointHistory.page}
                    size={props.data.pointHistory.size}
                    total={props.data.pointHistory.total}
                    onChange={props.onChangePage}/>
            </BizCol12>
        </BizContainer>
    )
}
