import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './SettleListDefaultSentSmsGraph.module.scss'
import {Query} from "../../../../../graphql/types";
import {ApexOptions} from "apexcharts";
import moment from "moment";
import Chart from "react-apexcharts";

const cx = classNamesBind.bind(styles);

export const SettleListDefaultSentSmsGraph = (props: {
    data: Query
}) => {
    const [data, setData] = useState<Data[]>([]);

    useEffect(() => {
        if (!props.data) {
            return;
        }

        const list: Data[] = props.data.settle.list.reverse().map(value => {
            return {
                label: moment(value.day).format('YY.MM.DD'),
                value: value.sentSms
            }
        });

        setData(list);
    }, [props.data.settle]);

    const chartData: ApexOptions = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        labels: data.map(value => {
            return value.label
        }),
        series: [
            {
                type: 'bar',
                name: '사용량',
                data: data.map(value => {
                    return value.value
                })
            }
        ],
    }


    return (
        <Fragment>
            <Chart
                type='bar'
                options={chartData}
                series={chartData.series}
                width={'100%'}
                height={'470px'}/>
        </Fragment>
    )
}

interface Data {
    label: string;
    value: number;
}
