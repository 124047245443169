import React, {Fragment, ReactNode, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './LayoutWeb.module.scss'
import {IconButton, Tooltip} from "@material-ui/core";
import {Dashboard, PowerSettingsNew} from "@material-ui/icons";
import {navigate, usePath} from "hookrouter";
import {FooterWeb} from "./footer/FooterWeb";
import {useDispatch, useSelector} from "react-redux";
import {SystemAction, SystemState} from "../../../../redux/System";
import {LayoutState} from "../../../../redux/Layout";
import {UserState} from "../../../../redux/User";
import {DataUtil} from "../../../../utils/data/DataUtil";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../../graphql/errors";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {A} from 'hookrouter';
import {Spring} from "react-spring/renderprops-universal";
import {NavBar} from "../../Layout";

const cx = classNamesBind.bind(styles);

export const LayoutWeb = (props: {
    navBar: NavBar[],
    children: ReactNode
}) => {
    const system = useSelector<any, SystemState>(state => state.system);
    const user = useSelector<any, UserState>(state => state.user);
    const layout = useSelector<any, LayoutState>(state => state.layout);
    const dispatch = useDispatch();
    const path = usePath();
    const [activatedIdx, setActivatedIdx] = useState(0);

    const [aniState, setAniState] = useState(
        ((system.scrollY < 200) && (layout.appBarTransparent)) ?
            AniProps.navbarHide :
            AniProps.navbarShow)

    useEffect(() => {
        const nextIdx = props.navBar.findIndex(value => {
            return path.startsWith(value.router);
        });

        if (nextIdx === -1) {
            setActivatedIdx(0);
        } else {
            setActivatedIdx(nextIdx);
        }

    }, [path]);

    useEffect(() => {
        if ((system.scrollY < 200) && (layout.appBarTransparent)) {
            setAniState(AniProps.navbarHide)
        } else {
            setAniState(AniProps.navbarShow)
        }
    }, [system.scrollY, layout.appBarTransparent])


    const onLogout = () => {
        SwalUtil.yn({
            msg: "로그아웃 하시겠습니까?",
            icon: "question",
            ok: () => {
                DataUtil.logout()
                    .then(res => {
                        navigate('/');
                    })
                    .catch(ApolloCatch({
                        [BizErrors.default]: () => {
                            navigate('/');
                        }
                    }));
            }
        })
    }

    const onChangeTemplate = () => {
        SwalUtil.input(value => {
            dispatch(SystemAction.setInit({...system.init, viewTemplate: value}));
        }, '템플릿 이름을 입력하여 주십시오.', 'Template 이름');
    }

    return (
        <Fragment>
            <Spring
                from={aniState}
                to={aniState}>
                {params => (
                    <Fragment>
                        <header className={cx("header")}>
                            <div className={cx("header__navBar")}>
                                <div className={cx("navBar__container")}>
                                    <div className={cx("navBar__logo")} onClick={event => {
                                        navigate('/');
                                    }}>
                                        {system.init.appNm}
                                    </div>
                                    <div className={cx("navBar__rightMenu")}>
                                        {props.navBar.map((value, index) => {
                                            return (
                                                <A href={value.router}
                                                   key={index}
                                                   className={cx(activatedIdx === index ? "navBar-active" : "navBar-inactive")}
                                                >
                                                    {value.title}
                                                </A>
                                            )
                                        })}
                                        {process.env.REACT_APP_ENV === 'dev' &&
                                        <IconButton onClick={onChangeTemplate} size={'small'}>
                                            <Dashboard/> {system.init.viewTemplate}
                                        </IconButton>
                                        }
                                        {user.isLogin &&
                                        <div
                                            style={{color: params.color}}
                                            onClick={() => onLogout()}>
                                            <Tooltip title='로그아웃'>
                                                <PowerSettingsNew/>
                                            </Tooltip>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </header>
                    </Fragment>
                )}
            </Spring>

            <div className={cx('contents')}>
                {props.children}
            </div>

            <FooterWeb appName={system.init.appNm}/>
        </Fragment>
    )
}

const AniProps = {
    navbarShow: {
        background: 'rgba(0,0,0,0.5)',
        color: 'rgba(255,255,255,1)',
    },
    navbarHide: {
        background: 'rgba(0,0,0,0.0)',
        color: 'rgba(255,255,255,1)',
    },
}



