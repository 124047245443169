import React, {Fragment, ReactNode, useEffect, useState} from "react";
import {MsgPermission, UserType} from "../../graphql/types";
import {useSelector} from "react-redux";
import {UserState} from "../../redux/User";
import {ViewTemplateSelector} from "../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../environment/interfaces";
import {LayoutDefault} from "./default/LayoutDefault";
import {LayoutTemp1} from "./temp1/LayoutTemp1";
import {LayoutTemp2} from "./temp2/LayoutTemp2";
import {LayoutZsms} from "./zsms/LayoutZsms";

export const Layout = (props: {
    children: ReactNode,
}) => {
    const [navBar, setNavBar] = useState<NavBar[]>([]);
    const user = useSelector<any, UserState>(state => state.user);

    useEffect(() => {
        let newNavBar: NavBar[] = [];
        if (user.isLogin) {
            if (user.user.msgPermission.includes(MsgPermission.Bulk)) {
                newNavBar.push({title: '대량전송', router: '/sms/bulk'});
            }

            if (user.user.msgPermission.includes(MsgPermission.Template)) {
                newNavBar.push({title: '탬풀릿전송', router: '/sms/template'});
            }

            if (user.user.userType === UserType.Reseller) {
                newNavBar.push({title: '대리점관리', router: '/mng/code'})
            }

            if (user.user.userType === UserType.Tree) {
                newNavBar.push({title: '대리점관리', router: '/mng/code'})
            }

            newNavBar = [
                ...newNavBar,
                ...AuthNavBar
            ];

        } else {
            newNavBar = [
                ...NotAuthNavBar
            ]
        }

        setNavBar(newNavBar);

    }, [user.isLogin, user.user])

    return (
        <Fragment>
            <ViewTemplateSelector view={{
                [ViewTemplate.default]:
                    <LayoutDefault navBar={navBar} children={props.children}/>,
                [ViewTemplate.temp1]:
                    <LayoutTemp1 navBar={navBar} children={props.children}/>,
                [ViewTemplate.temp2]:
                    <LayoutTemp2 navBar={navBar} children={props.children}/>,
                [ViewTemplate.zsms]:
                    <LayoutZsms navBar={navBar} children={props.children}/>,
            }}/>
        </Fragment>
    )
}

export interface NavBar {
    title: string;
    router: string;
}

const NotAuthNavBar: NavBar[] = [
    {title: '로그인', router: '/auth/login'},
    {title: '회원가입', router: '/auth/join'},
];

const AuthNavBar: NavBar[] = [
    {title: '전송결과', router: '/result/receipt'},
    {title: '입금확인요청', router: '/deposit/request'},
    {title: '마이페이지', router: '/mypage/info'},
    {title: '통계', router: '/settlement'},
    {title: '고객센터', router: '/cs'},
    {title: '공지사항', router: '/notice'},
];
