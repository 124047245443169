import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";


export interface LayoutState {
    appBarTransparent: boolean;
    topMargin: boolean;
    videoBackground: boolean;
}

const initState: LayoutState = {
    appBarTransparent: false,
    topMargin: true,
    videoBackground: false,
}


class Layout extends ImmerReducer<typeof initState> {
    setAppBarTransparent(swt: boolean) {
        this.draftState.appBarTransparent = swt;
    }

    setTopMargin(has: boolean) {
        this.draftState.topMargin = has;
    }

    setVideoBackground(has: boolean) {
        this.draftState.videoBackground = has;
    }
}


export const LayoutAction = createActionCreators(Layout);
export const LayoutReducer = createReducerFunction(Layout, initState);

