import React, {Fragment} from "react";
import {DepositRequestStatus} from "../../../../graphql/types";

export const BadgeDepositRequestStatus = (props: {
    status: DepositRequestStatus
}) => {
    return (
        <Fragment>
            {props.status === DepositRequestStatus.Wait &&
            <span className='badge badge-warning'>대기중</span>
            }

            {props.status === DepositRequestStatus.Allow &&
            <span className='badge badge-success'>승인</span>
            }

            {props.status === DepositRequestStatus.Deny &&
            <span className='badge badge-danger'>거절</span>
            }

            {props.status === DepositRequestStatus.Cancel &&
            <span className='badge badge-secondary'>취소</span>
            }
        </Fragment>
    )
}
