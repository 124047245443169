import React, {Fragment, ReactNode, useEffect, useState} from "react";
import {MngLayoutMenu} from "../MngLayout";
import {navigate, usePath} from "hookrouter";
import {Spring} from "react-spring/renderprops-universal";
import {Divider, Fab, List, ListItem, ListItemText, SwipeableDrawer, Tooltip} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import classNamesBind from "classnames/bind";
import styles from "./MngLayoutTemp2.module.scss";
import {makeStyles} from "@material-ui/core/styles";
import {ViewSelector} from "../../../shares/default/viewSelector/ViewSelector";
import CodeIcon from "@material-ui/icons/Code";
import AirplayIcon from "@material-ui/icons/Airplay";
import QueueIcon from "@material-ui/icons/Queue";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";

const cx = classNamesBind.bind(styles);

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    btmNav: {
        height: 50,
        lineHeight: 50,
        padding: 10,
        color: 'black'
    }
}));

export const MngLayoutTemp2 = (props: {
    children: ReactNode,
    menu: MngLayoutMenu[],
}) => {
    return (
        <ViewSelector
            web={
                <Web data={props.menu} title={'대리점 관리'}>
                    {props.children}
                </Web>
            }
            mobile={
                <Mobile data={props.menu} title={'대리점 관리'}>
                    {props.children}
                </Mobile>
            }/>
    )
}


const Web = (props: Attr) => {
    const [idx, setIdx] = useState(0);
    const activeFontColor = 'rgb(33,150,243)';
    const inactiveFontColor = 'rgb(222,222,222)';
    const path = usePath();

    useEffect(() => {
        const pathList = path.split('/');
        const idx = props.data.findIndex(value => {
            const routerList = value.router.split('/');

            if (routerList.length < pathList.length) {
                return false;
            }

            for (let i = 0; i < pathList.length; i++) {
                if (pathList[i] != routerList[i]) {
                    return false
                }
            }

            return true;
        });
        setIdx(idx !== -1 ? idx : 0);
    }, [path, props.data]);

    return (
        <>
            <div className={cx('fullContainer')}>
                <div className={cx('fullContainer__nav')}>
                    <div className={cx('nav__contentBox')}>
                        {props.data.map((value, index) => {
                            const activated = index === idx;
                            return (
                                <Spring
                                    key={index}
                                    from={{color: inactiveFontColor}}
                                    to={{color: activated ? activeFontColor : inactiveFontColor}}>
                                    {params =>
                                        <div
                                            style={params}
                                            className={cx("contentBox__content")}
                                            onClick={event => {
                                                setIdx(index);
                                                window.scrollTo(0, 0);
                                                navigate(value.router);
                                            }}>
                                            <div className={cx("icon")}>
                                                {value.title === "코드관리" && <CodeIcon/>}
                                                {value.title === "대리점관리" && <AirplayIcon/>}
                                                {value.title === "전송 커미션" && <QueueIcon/>}
                                                {value.title === "충전 커미션" && <OfflineBoltIcon/>}
                                            </div>
                                            <div className={cx("text")}>
                                                {value.title}
                                            </div>
                                        </div>
                                    }
                                </Spring>
                            )
                        })}
                    </div>
                </div>

                <div className={cx('box-contents')}>
                    {props.children}
                </div>
            </div>
        </>
    )
}

const Mobile = (props: Attr) => {
    const classes = useStyles();
    const [drawer, setDrawer] = useState(false);

    return (
        <Fragment>
            <div style={{padding: 10}}>
                {props.children}
            </div>

            <Tooltip title="Add" aria-label="add">
                <Fab
                    className={classes.absolute}
                    onClick={event => {
                        setDrawer(true);
                    }}>
                    <Add/>
                </Fab>
            </Tooltip>

            <SwipeableDrawer
                anchor="bottom"
                onClose={() => {
                    setDrawer(false);
                }}
                onOpen={() => {
                    setDrawer(false);
                }}
                open={drawer}>

                {props.data.map((value, index) => {
                    return (
                        <Fragment key={index}>
                            <List onClick={event => {
                                navigate(value.router);
                                setDrawer(false);
                                window.scrollTo(0, 0);
                            }}>
                                <ListItem>
                                    <ListItemText primary={value.title}/>
                                </ListItem>
                            </List>
                            <Divider/>
                        </Fragment>
                    )
                })}
            </SwipeableDrawer>
        </Fragment>
    )
}


interface Attr {
    title: ReactNode
    children: ReactNode,
    data: BizMenuData[]
}

export interface BizMenuData {
    title: string;
    icon?: ReactNode;
    router: string;
}
