import React, {Fragment, useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './ResultTelbookChart.module.scss'
import Chart from "react-apexcharts";
import {ResultTelbookResp} from "../ResultTelBook";
import {ApexOptions} from "apexcharts";

const cx = classNamesBind.bind(styles);

export const ResultTelbookChart = (props: {
    data: ResultTelbookResp,
}) => {
    const [result, setResult] = useState<Result>({
        delivered: 0, fail: 0, requested: 0, sent: 0, undelivered: 0
    });

    useEffect(() => {
        const nextResult: Result = {
            delivered: 0, fail: 0, requested: 0, sent: 0, undelivered: 0
        }

        nextResult.requested = props.data.telbook.requestTotal;

        for (let telNumber of props.data.telbook.numberList) {
            switch (telNumber.status) {
                case "Sent":
                    nextResult.sent += 1;
                    break;
                case "Fail":
                    nextResult.fail += 1;
                    break;
                case "DlrDelivered":
                    nextResult.delivered += 1;
                    break;
                case "DlrUndelivered":
                    nextResult.undelivered += 1;
                    break;
            }
        }

        setResult(nextResult);
    }, [props.data]);

    const data: Data[] = [
        {label: '수신성공', value: result.delivered},
        {label: '수신결과대기', value: result.requested - (result.delivered + result.undelivered)},
        {label: '수신실패', value: result.undelivered},
        {label: '전송실패', value: result.fail},
    ];


    const chartData: ApexOptions = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
            position: 'bottom'
        },
        labels: data.map(value => {
            return value.label;
        }),
        series: data.map(value => {
            return value.value
        })
    }


    return (
        <Fragment>
            <Chart
                type='donut'
                options={chartData}
                series={chartData.series}
                width={'100%'}
                height={'470px'}/>
        </Fragment>
    )
}

interface Data {
    label: string;
    value: number;
}

interface Result {
    delivered: number;
    undelivered: number;
    requested: number;
    sent: number;
    fail: number;
}
