import React, {Fragment, useEffect, useState} from "react";
import {SendBulkInputData} from "../SmsBulk";
import {OnChangeValue, OnVoidFunction} from "../../../interfaces";
import {HashedApiKey, Query, SendBulkSms, SendTestSms} from "../../../../graphql/types";
import {useSelector} from "react-redux";
import {UserState} from "../../../../redux/User";
import {SystemState} from "../../../../redux/System";
import {NumberUtil} from "../../../../utils/numbUtil/NumbUtil";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import imgIPhone from "../../../../assets/img/phone/iphoneGold.png";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {ProgressBar} from "react-bootstrap";
import {Check, ShortText} from "@material-ui/icons";
import Decimal from "decimal.js";
import classNamesBind from "classnames/bind";
import 'react-perfect-scrollbar/dist/css/styles.css';
import styles from "./SmsBulkDefault.module.scss";
import {BizModal} from "../../../shares/default/bizModal/BizModal";
import {SmsBulkDefaultConfirmModal} from "./confirmModal/SmsBulkDefaultConfirmModal";
import {SmsBulkDefaultCharInputPopover} from "./charInputPopover/SmsBulkDefaultCharInputPopover";
import {SwalUtil} from "../../../../utils/swal/swalUtil";
import {Button} from "@material-ui/core";
import {SmsBulkDefaultSelectApiKeyModal} from "./selectApiKeyModal/SmsBulkDefaultSelectApiKeyModal";
import {SmsBulkDefaultTelReceiverReportModal} from "./telReceiverReportModal/SmsBulkDefaultTelReceiverReportModal";

const cx = classNamesBind.bind(styles);

export const SmsBulkDefault = (props: {
    value: SendBulkInputData,
    onChangeValue: OnChangeValue<SendBulkInputData>,
    data: Query,
    onClearNumb: OnVoidFunction,
    onClearEmoji: OnVoidFunction,
    onSendBulk: OnChangeValue<SendBulkSms>
    onSendTest: OnChangeValue<SendTestSms>
    onLoadTxt: OnVoidFunction,
    onLoadExcel: OnVoidFunction,
}) => {
    const user = useSelector<any, UserState>(state => state.user);
    const system = useSelector<any, SystemState>(state => state.system);
    const [apiPermission, setApiPermission] = useState<HashedApiKey | undefined>
    (user.user.apiPermission.length !== 0 ? user.user.apiPermission[0] : undefined);
    const [confirmModal, setConfirmModal] = useState(false);
    const [apiKeySelectModal, setApiKeySelectModal] = useState(false);
    const [telReportModal, setTelReportModal] = useState(false);
    const [telReportRef, setTelReportRef] = useState<HTMLElement | undefined>();

    useEffect(() => {
        onScrollDownTelReport();
    }, [props.data.telReceiverReport]);

    const onSendBulk = () => {
        if (!apiPermission) {
            return;
        }

        props.onSendBulk({
            hashedApiKey: apiPermission.hashedApiKey,
            msg: props.value.msg.msg,
            numberList: NumberUtil.appendNationNumber
            ('82', NumberUtil.splitNumb(`${props.value.numb.rawNumb}\n${props.value.numb.rawAppendNumb}`))
        })

        setConfirmModal(false);
    }

    const onClickHistory = (msg: string) => {
        const copy = CopyUtil.copyAll(props.value);
        copy.msg.msg = msg;
        props.onChangeValue(copy);
    }

    const onCancel = () => {
        setConfirmModal(false);
    }

    const onAppendTestNumber = (testNumber: string) => {
        let numberList = NumberUtil.splitNumb(`${props.value.numb.rawAppendNumb}\n${props.value.numb.rawNumb}`)
        if (NumberUtil.hasOwnNumber(numberList, testNumber)) {
            SwalUtil.ok({
                msg: `이미 추가되어 있습니다.`,
                icon: 'warning'
            })
            return;
        }


        let appendNumber = NumberUtil.splitNumb(props.value.numb.rawAppendNumb);
        appendNumber = NumberUtil.exceptWrongContext(appendNumber);
        appendNumber.push(testNumber);

        const copy = CopyUtil.copyAll(props.value);
        copy.numb.rawAppendNumb = NumberUtil.toRawData(appendNumber);
        props.onChangeValue(copy);
    }

    const onClickAppendAllTestNumber = () => {
        let numberList = NumberUtil.splitNumb(props.value.numb.rawAppendNumb);
        for (let testNumber of props.data.testNumberList) {
            if (NumberUtil.hasOwnNumber(numberList, testNumber.number)) {
                continue;
            }

            numberList = NumberUtil.exceptWrongContext(numberList);
            numberList.push(testNumber.number);
        }

        const copy = CopyUtil.copyAll(props.value);
        copy.numb.rawAppendNumb = NumberUtil.toRawData(numberList);
        props.onChangeValue(copy);
    }

    const onScrollDownTelReport = () => {
        if (telReportRef) {
            telReportRef.scrollTo(0, telReportRef.scrollHeight);
        }
    }

    onScrollDownTelReport();

    return (
        <Fragment>
            <BizModal
                title={'전송 확인'}
                show={confirmModal}
                onChangeShow={setConfirmModal}>
                {apiPermission &&
                    <SmsBulkDefaultConfirmModal
                        {...props}
                        onCancel={onCancel}
                        onSendBulk={onSendBulk}
                        hashedApiKey={apiPermission}/>
                }
            </BizModal>

            <BizModal
                title={'회선 선택'}
                show={apiKeySelectModal}
                onChangeShow={setApiKeySelectModal}
                size='lg'>
                <SmsBulkDefaultSelectApiKeyModal
                    onChangeApiKey={value => {
                        setApiPermission(value);
                        setApiKeySelectModal(false);
                    }}/>
            </BizModal>

            <BizModal
                title={'테스트 확인'}
                show={telReportModal}
                onChangeShow={setTelReportModal}>
                <SmsBulkDefaultTelReceiverReportModal
                    query={props.data}/>
            </BizModal>


            <div className={cx('cont')}>
                <div className={cx('box')} style={{width: '32%'}}>

                    <div className={cx('cont-iphone')}>
                        <img src={imgIPhone} alt='msg-input'/>
                        <div className={cx('iphone-title')}>{system.init.appNm}</div>
                        <textarea
                            value={props.value.msg.msg}
                            placeholder='문자 메시지 입력'
                            onChange={event => {
                                const copy = CopyUtil.copyAll(props.value);
                                copy.msg.msg = event.target.value;
                                props.onChangeValue(copy);
                            }}/>

                        <div className={cx('cont-emoji')}>
                            <Button size='small' onClick={ev => {
                                window.open('https://app.abit.ly/', '_blank')
                            }}
                                    style={{color: "red"}}
                            >
                                <ShortText />주소 줄이기
                            </Button>
                            <SmsBulkDefaultCharInputPopover {...props}/>
                        </div>


                    </div>
                </div>

                <div className={cx('box')} style={{width: '32%'}}>
                    <BizTitle>안녕하세요! {user.user.email} 님</BizTitle>
                    <div className={cx('box-panel')}>

                        <div className={cx('box-title')}>전화번호</div>
                        <textarea
                            style={{height: 300}}
                            value={props.value.numb.rawNumb}
                            className={cx('form-control', 'msg')}
                            placeholder='전화번호 입력'
                            onChange={event => {
                                const copy = CopyUtil.copyAll(props.value);
                                copy.numb.rawNumb = event.target.value;
                                props.onChangeValue(copy);
                            }}/>

                        <div style={{height: 20}}/>
                        <div className={cx('box-title')}>고정 전화번호</div>
                        <textarea
                            style={{height: 150}}
                            value={props.value.numb.rawAppendNumb}
                            className={cx('form-control', 'msg')}
                            placeholder='고정 전화번호 입력'
                            onChange={event => {
                                const copy = CopyUtil.copyAll(props.value);
                                copy.numb.rawAppendNumb = event.target.value;
                                props.onChangeValue(copy);
                            }}/>

                        <div style={{height: 40}}/>
                        <div className={cx('box-title')}>최근 전송 기록</div>
                        <PerfectScrollbar style={{height: 340}}>
                            {props.data.msgReceipt.list.map((value, index) => (
                                <div className={cx('table', 'hover')} key={index} onClick={event => {
                                    onClickHistory(value.contents);
                                }}>
                                    <div className={cx("dateCell")}>
                                        {moment(value.createdAt).format('MM.DD - hh:mm a')}
                                    </div>
                                    <div className={cx("descCell", "textLeft")}>
                                        {value.contents.substring(0, 8)}...
                                    </div>
                                    <div className={cx("volumeCell", "textRight", "hiddenCell")}>
                                        {Pipe.toSeparatorNumber(value.sent)} 개
                                    </div>
                                </div>
                            ))}

                            {props.data.msgReceipt.list.length === 0 &&
                                <div className={cx('table', 'hover')}>
                                    <div style={{width: '100%'}}>최근 전송 데이터가 없습니다.</div>
                                </div>
                            }
                        </PerfectScrollbar>

                    </div>


                </div>


                <div className={cx('box', 'flex-column')} style={{width: '32%'}}>
                    <BizTitle/>
                    <div className={cx('box-panel')}>

                        {/*회선 선택 */}

                        <div className={cx('box-split')}>
                            <div style={{width: '50%'}}>
                                <div className={cx('box-title')}>잔고 : {Pipe.toSeparatorNumber(user.user.point)} P</div>
                            </div>
                            <div className='text-right' style={{width: '50%'}}>
                                {user.user.apiPermission.length > 1 &&
                                    <button
                                        className='btn btn-sm btn-outline-info'
                                        onClick={() => {
                                            setApiKeySelectModal(true);
                                        }}>변경</button>
                                }
                            </div>
                        </div>
                        {apiPermission &&
                            <div className={cx('table', 'hover', 'selected')}>
                                <div style={{width: 50}}>
                                    <Check color="secondary"/>
                                </div>
                                <div className={'text-left'} style={{width: 150}}>{apiPermission.nm}
                                    <span>({Pipe.toSeparatorNumber(apiPermission.smsFee)} P)</span></div>
                                <div className={'text-right'} style={{minWidth: 'calc(100% -200px)', flexGrow: 1}}>
                            <span style={{
                                color: 'gray',
                            }}>{Pipe.toSeparatorNumber(new Decimal(user.user.point).divToInt(apiPermission.smsFee).toNumber())} 개 전송가능</span>
                                </div>
                            </div>
                        }

                        <div style={{height: 20}}/>
                        <div className={cx('box-title')}>글자</div>
                        <ProgressBar
                            variant={props.value.msg.msgLength < props.value.msg.maxLength ? '' : 'danger'}
                            now={props.value.msg.msgLength / props.value.msg.maxLength * 100}/>
                        <div className='text-right'>({props.value.msg.msgLength} / {props.value.msg.maxLength})</div>

                        <div className={cx('box-title')}>전화번호</div>
                        <ProgressBar
                            variant={props.value.numb.numbLength < props.value.numb.maxLength ? '' : 'danger'}
                            now={props.value.numb.numbLength / props.value.numb.maxLength * 100}/>
                        <div className='text-right'>({props.value.numb.numbLength} / {props.value.numb.maxLength})</div>


                        <div className={cx('box-title')}>첨부파일</div>
                        <div className={cx('table')}>
                            <div className='text-left' style={{width: '100%'}}>
                                <button
                                    className='btn btn-sm btn-outline-info'
                                    style={{marginRight: 10}}
                                    onClick={props.onLoadTxt}>텍스트
                                </button>

                                <button
                                    className='btn btn-sm btn-outline-info'
                                    onClick={props.onLoadExcel}>엑셀
                                </button>
                            </div>
                        </div>

                        <div className={cx('box-title')}>정리</div>
                        <div className={cx('table')}>
                            <div className='text-left' style={{width: '100%'}}>
                                <button
                                    className='btn btn-sm btn-outline-info'
                                    style={{marginRight: 10}}
                                    onClick={props.onClearNumb}>전화번호
                                </button>
                                <br />
                                ※ 문자전송 전에 눌러주시면 잘못된 형식의 전화번호와<br />중복된 전화번호 모두 정돈됩니다.


                                {/*<button*/}
                                {/*    className='btn btn-sm btn-outline-info'*/}
                                {/*    onClick={props.onClearEmoji}>이모지*/}
                                {/*</button>*/}
                            </div>
                        </div>

                        {/* 테스트 번호 입력 */}
                        <div style={{height: 20}}/>
                        <div className={cx('box-title')}>테스트 번호</div>
                        <div className={cx('table')}>
                            <div className='text-left' style={{width: '100%'}}>
                                {props.data.testNumberList.map((value, index) => {
                                    const list = NumberUtil.splitNumb(`${props.value.numb.rawNumb}\n${props.value.numb.rawAppendNumb}`);
                                    const hasNumber = NumberUtil.hasOwnNumber(list, value.number);

                                    return <button
                                        key={index}
                                        className={cx('btn', 'btn-sm', hasNumber ? 'btn-info' : 'btn-outline-info')}
                                        style={{marginRight: 10}}
                                        onClick={() => {
                                            onAppendTestNumber(value.number);
                                        }}>{value.vendor}</button>
                                })}

                                <button
                                    className={cx('btn', 'btn-sm', 'btn-outline-info')}
                                    style={{marginRight: 10}}
                                    onClick={onClickAppendAllTestNumber}>All
                                </button>
                            </div>
                        </div>


                        {/* 테스트 확인 */}
                        {/*<div style={{height: 40}}/>*/}
                        {/*<div className={cx('box-split')}>*/}
                        {/*    <div style={{width: '50%'}}>*/}
                        {/*        <div className={cx('box-title')}>테스트 확인</div>*/}
                        {/*    </div>*/}
                        {/*    <div className='text-right' style={{width: '50%'}}>*/}
                        {/*        <button*/}
                        {/*            className='btn btn-sm btn-outline-info'*/}
                        {/*            onClick={() => {*/}
                        {/*                setTelReportModal(true);*/}
                        {/*            }}>크게보기*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                        {/*<PerfectScrollbar className={cx('cont-tel-reporter')} containerRef={setTelReportRef}>*/}
                        {/*    <div className={cx('box-tel-reporter')}>*/}
                        {/*        {props.data.telReceiverReport.map((value, index) => (*/}
                        {/*            <div key={index} className={cx('msg')}>*/}
                        {/*                <div className={cx('msg-info')}>수신번호 : {value.receiverNumber}</div>*/}
                        {/*                <div className={cx('msg-info')}>발신번호 : {value.senderNumber}</div>*/}
                        {/*                <div className={cx('msg-info')}>수신시각*/}
                        {/*                    : {moment(value.createdAt).format("YY.MM.DD hh:mm a")}</div>*/}
                        {/*                <div className={cx('msg-info')}>통신사 : {value.vendor}</div>*/}
                        {/*                <div className={cx('content')} ref={ref => {*/}
                        {/*                    if (ref) {*/}
                        {/*                        ref.innerText = value.msg;*/}
                        {/*                    }*/}
                        {/*                }}/>*/}
                        {/*            </div>*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*</PerfectScrollbar>*/}


                        <div style={{height: 20}}/>
                        <button
                            style={{width: '100%'}}
                            className='btn btn-success'
                            disabled={!apiPermission}
                            onClick={() => {
                                setConfirmModal(true);
                            }}>전송
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
