import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from "./MngRechargeCommissionTemp1.module.scss"
import {Pipe} from "../../../../utils/pipe/Pipe";
import moment from "moment";
import {MngLayout} from "../../layout/MngLayout";
import {OnChangeValue} from "../../../interfaces";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {BadgeMngCommissionStatus} from "../../../shares/temp1/badgeMngCommissionStatus/BadgeMngCommissionStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {PagerTemp1} from "../../../shares/temp1/pager/PagerTemp1";
import Decimal from "decimal.js";
import {
    MutationRequestRechargeCommissionSettleArgs,
    Query, RechargeCommissionSettleRequestStatus, SettleCommissionType
} from "../../../../graphql/types";
import {BizModal} from "../../../shares/default/bizModal/BizModal";
import {RechargeCommissionTemp1SettleModal} from "./settleModal/RechargeCommissionTemp1SettleModal";
import {Button} from "@material-ui/core";
import {BizNoDataTable} from "../../../shares/default/bizNoDataTable/BizNoDataTable";

const cx = classNamesBind.bind(styles);

export const MngRechargeCommissionTemp1 = (props: {
    data?: Query,
    onChangePage: OnChangeValue<number>,
    onChangeRequestPage: OnChangeValue<number>,
    onRequestSettle: OnChangeValue<MutationRequestRechargeCommissionSettleArgs>,
}) => {
    const [settleModal, setSettleModal] = useState(false);

    return (
        <MngLayout title={'대리점관리'}>
            {props.data &&
            <FadeIn>
                <BizModal
                    title={'정산 방식을 선택하여 주십시오.'}
                    size={'lg'}
                    show={settleModal}
                    onChangeShow={setSettleModal}>
                    <RechargeCommissionTemp1SettleModal
                        onRequestSettle={value => {
                            setSettleModal(false);
                            props.onRequestSettle(value);
                        }}/>
                </BizModal>

                <div className={cx("fullContainer")}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox")}>
                                <div className={cx("side-titleBar")}>
                                    <div className={cx("titleBar-left")}>
                                        충전 커미션
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={cx("secondSection")}>
                            <div className={cx("section-side")}>
                                <div className={cx("side-flexBox")}>
                                    <div className={cx("side-title")}>
                                        <div className={cx("title-left")}>
                                            <FontAwesomeIcon icon={faChevronRight}
                                                             style={{paddingTop: 3, marginRight: 5}}/> 커미션 합계
                                        </div>
                                        <div className={cx("title-right")}/>
                                    </div>
                                    <div className={cx("side-contentsColumn", "borderBottom")}>
                                        <div className={cx("contentsColumn-topHeader", "sumCell")}>
                                            전체
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "completeCell")}>
                                            지급완료
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "incompleteCell")}>
                                            미지급
                                        </div>
                                    </div>
                                    <div className={cx("side-contentsColumn")}>
                                        <div className={cx("contentsColumn-bottomValue", "sumCell")}>
                                            {Pipe.toSeparatorNumber(props.data.totalRechargeCommission.total)} 원
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "completeCell")}>
                                            {Pipe.toSeparatorNumber(props.data.totalRechargeCommission.paid)} 원
                                        </div>
                                        <div className={cx("contentsColumn-bottomValue", "incompleteCell")}>
                                            {Pipe.toSeparatorNumber(props.data.totalRechargeCommission.notPaid)} 원
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={cx("thirdSection")}>
                            <div className={cx("section-side")}>
                                <div className={cx("side-flexBox")}>
                                    <div className={cx("side-title")}>
                                        <div className={cx("title-left")}>
                                            <FontAwesomeIcon icon={faChevronRight}
                                                             style={{paddingTop: 3, marginRight: 5}}/> 커미션 정산요청 내역
                                        </div>
                                        <div className={cx("title-right")}>
                                            <Button
                                                classes={{root: cx("buttonOne")}}
                                                variant="outlined"
                                                style={{fontSize: 12}}
                                                onClick={() => setSettleModal(true)}>정산요청하기</Button>
                                        </div>
                                    </div>
                                    <div className={cx("side-contentsColumn", "borderBottom")}>
                                        <div className={cx("contentsColumn-topHeader", "dateCell", "hiddenCell")}>
                                            날짜
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "statusCell")}>
                                            상태
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "methodCell")}>
                                            지불종류
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "numberCommCell")}>
                                            커미션 수
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "sumCommCell")}>
                                            커미션 합
                                        </div>
                                    </div>
                                    {props.data.rechargeCommissionSettleRequestList.list.map((value, index) => {
                                        return (
                                            <div className={cx("side-contentsColumn")} key={index}>
                                                <div
                                                    className={cx("contentsColumn-bottomValue", "dateCell", "hiddenCell")}>
                                                    {moment(value.createdAt).format('MM.DD hh:mm (A)')}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "statusCell")}>
                                                    {value.status === RechargeCommissionSettleRequestStatus.Requested &&
                                                    <BadgeMngCommissionStatus
                                                        chip={3} label={"승인대기"}/>
                                                    }

                                                    {value.status === RechargeCommissionSettleRequestStatus.Deny &&
                                                    <BadgeMngCommissionStatus
                                                        chip={2} label={"반려"}/>
                                                    }

                                                    {value.status === RechargeCommissionSettleRequestStatus.Allow &&
                                                    <BadgeMngCommissionStatus
                                                        chip={1} label={"승인완료"}/>
                                                    }

                                                    {value.status === RechargeCommissionSettleRequestStatus.Cancel &&
                                                    <BadgeMngCommissionStatus
                                                        chip={4} label={"사용자취소"}/>
                                                    }
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "methodCell")}>
                                                    {value.commissionType === SettleCommissionType.ChargePoint && "포인트 정산"}
                                                    {value.commissionType === SettleCommissionType.Withdraw && "입금 정산"}
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "numberCommCell")}>
                                                    {Pipe.toSeparatorNumber(value.commissionCount)} 건
                                                </div>
                                                <div className={cx("contentsColumn-bottomValue", "sumCommCell")}>
                                                    {Pipe.toSeparatorNumber(value.commission)} 원
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {props.data.rechargeCommissionSettleRequestList.total === 0 && <BizNoDataTable/>}

                                    <PagerTemp1
                                        page={props.data.rechargeCommissionSettleRequestList.page}
                                        size={props.data.rechargeCommissionSettleRequestList.size}
                                        total={props.data.rechargeCommissionSettleRequestList.total}
                                        onChange={props.onChangeRequestPage}/>
                                </div>
                            </div>
                        </div>

                        <div className={cx("fourthSection")}>
                            <div className={cx("section-side")}>
                                <div className={cx("side-flexBox")}>
                                    <div className={cx("side-title")}>
                                        <div className={cx("title-left")}>
                                            <FontAwesomeIcon icon={faChevronRight}
                                                             style={{paddingTop: 3, marginRight: 5}}/> 커미션 상세 내역
                                        </div>
                                        <div className={cx("title-right")}/>
                                    </div>
                                    <div className={cx("side-contentsColumn", "borderBottom")}>
                                        <div className={cx("contentsColumn-topHeader", "dateCell", "hiddenCell")}>
                                            날짜
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "badgeCell")}>
                                            정산
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "badgeCell")}>
                                            요청
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "agentCell")}>
                                            대리점
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "valueCell")}>
                                            충전포인트
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "percentCell")}>
                                            커미션비율
                                        </div>
                                        <div className={cx("contentsColumn-topHeader", "commissionCell")}>
                                            커미션
                                        </div>
                                    </div>
                                    {props.data.rechargeCommissionList.list.map((value, index) => {
                                        return (
                                            <div className={cx("side-contentsColumn")} key={index}>
                                                <div
                                                    className={cx("contentsColumn-topHeader", "dateCell", "hiddenCell")}>
                                                    {moment(value.createdAt).format('YY.MM.DD')}
                                                </div>
                                                <div className={cx("contentsColumn-topHeader", "badgeCell")}>
                                                    {value.isWithdrawal &&
                                                    <BadgeMngCommissionStatus chip={1} label={"지급완료"}/>}
                                                    {!value.isWithdrawal &&
                                                    <BadgeMngCommissionStatus chip={3} label={"미지급"}/>}
                                                </div>
                                                <div className={cx("contentsColumn-topHeader", "requestCell", "hiddenCell")}>
                                                    {value.isRequestedSettle &&
                                                    <BadgeMngCommissionStatus chip={1} label={"요청됨"}/>}
                                                    {!value.isRequestedSettle &&
                                                    <BadgeMngCommissionStatus chip={3} label={"미요청"}/>}
                                                </div>
                                                <div className={cx("contentsColumn-topHeader", "agentCell")}>
                                                    {value.agentEmail}
                                                </div>
                                                <div className={cx("contentsColumn-topHeader", "valueCell")}>
                                                    {Pipe.toSeparatorNumber(value.agentRechargeAmount)} 개
                                                </div>
                                                <div className={cx("contentsColumn-topHeader", "percentCell")}>
                                                    {new Decimal(value.rate).mul(100).toNumber()} %
                                                </div>
                                                <div className={cx("contentsColumn-topHeader", "commissionCell")}>
                                                    {Pipe.toSeparatorNumber(value.commission)} 원
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <PagerTemp1
                                        page={props.data.rechargeCommissionList.page}
                                        size={props.data.rechargeCommissionList.size}
                                        total={props.data.rechargeCommissionList.total}
                                        onChange={props.onChangePage}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn>
            }
        </MngLayout>
    )
}
