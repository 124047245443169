import React, {useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngAgentWithSmsFeeDefault.module.scss'
import {MngLayout} from "../../layout/MngLayout";
import {Agent, MutationUpdateAgentApiPermissionArgs, Query} from "../../../../graphql/types";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {IconButton, Switch} from "@material-ui/core";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {CopyUtil} from "../../../../utils/copy/CopyUtil";
import {Pager} from "../../../shares/default/pager/Pager";
import {FadeIn} from "../../../shares/default/fadeIn/FadeIn";
import {OnChangeValue} from "../../../interfaces";
import {MngAgentWithSmsFeeInputData} from "../MngAgentWithSmsFee";
import {EditOutlined} from "@material-ui/icons";
import {BizModal} from "../../../shares/default/bizModal/BizModal";
import {MngAgentWithSmsFeeDefaultUpdateAgentModal} from "./updateAgentModal/MngAgentWithSmsFeeDefaultUpdateAgentModal";

const cx = classNamesBind.bind(styles);

export const MngAgentWithSmsFeeDefault = (props: {
    query: Query,
    value: MngAgentWithSmsFeeInputData,
    onChangeValue: OnChangeValue<MngAgentWithSmsFeeInputData>,
    onChangePage: OnChangeValue<{ page: number, email: string }>,
    onChangeAgentActivation: OnChangeValue<{ userId: number, activation: boolean }>,
    onUpdateAgentApiPermission: OnChangeValue<MutationUpdateAgentApiPermissionArgs>
}) => {
    const onFindAgent = () => {
        props.onChangePage({page: 0, email: props.value.email});
    }

    const onInit = () => {
        props.onChangePage({page: 0, email: ''});
    }

    const [selAgent, setSelAgent] = useState<Agent | undefined>();


    return (
        <MngLayout title={'대리점관리'}>
            <BizModal
                title={''}
                show={!!selAgent}
                onChangeShow={value => {
                    if (!value) {
                        setSelAgent(undefined);
                    }
                }}>
                {selAgent &&
                <MngAgentWithSmsFeeDefaultUpdateAgentModal
                    {...props}
                    agent={selAgent}/>
                }
            </BizModal>

            <FadeIn>
                <BizTitle>회원 목록</BizTitle>
                <div className={cx('table')}>
                    <div className={cx('mb-hidden')} style={{width: 100}}>회원번호</div>
                    <div className={cx("activateCell")}>활성화</div>
                    <div className={cx("userCell")}>유저</div>
                    <div className={cx("balanceCell")}>잔고</div>
                    <div className={cx("priceCell")}>건당가격</div>
                    <div className={cx("funcCell")}>수정</div>
                </div>

                {props.query.agentList.list.length === 0 &&
                <div className={cx('table')}>
                    <div style={{flexGrow: 1}}>등록된 대리점이 없습니다.</div>
                </div>
                }

                {props.query.agentList.list.map((value, index) => (
                    <div key={index} className={cx('table')}>
                        <div className={cx('mb-hidden')} style={{minWidth: 100}}>{value.id}</div>
                        <div className={cx("activateCell")}>
                            <Switch
                                color='primary'
                                checked={value.isActivate}
                                onChange={event => {
                                    props.onChangeAgentActivation({userId: value.id, activation: event.target.checked});
                                }}/>
                        </div>
                        <div className={cx("userCell")}>{value.email}</div>
                        <div className={cx("balanceCell", "textRight")}>
                            {Pipe.toSeparatorNumber(value.point)} P
                        </div>
                        <div className={cx("priceCell", "textRight")}>
                            {value.apiPermission.map((apiPermission, apiPermissionIdx) => (
                                <span key={index}
                                      style={{marginRight: 10}}>{apiPermission.nm} : {apiPermission.smsFee} 원</span>
                            ))}
                        </div>

                        <div className={cx("funcCell")}>
                            <IconButton size='small' onClick={() => {
                                setSelAgent(value);
                            }}>
                                <EditOutlined/>
                            </IconButton>
                        </div>

                    </div>

                ))}

                <div className={cx('cont-finder')}>
                    <input
                        value={props.value.email}
                        className='form-control form-control-sm'
                        placeholder='이메일을 입력하여 주십시오.'
                        onChange={event => {
                            const copy = CopyUtil.copyAll(props.value);
                            copy.email = event.target.value;
                            props.onChangeValue(copy);
                        }}
                        onKeyUp={event => {
                            if (event.key === 'Enter') {
                                onFindAgent();
                            }
                        }}/>
                    <button
                        style={{marginRight: 10}}
                        className='btn btn-sm btn-success'
                        onClick={onFindAgent}>찾기
                    </button>
                    <button
                        className='btn btn-sm btn-outline-success'
                        onClick={onInit}>
                        초기화
                    </button>
                </div>

                <Pager
                    page={props.query.agentList.page}
                    size={props.query.agentList.size}
                    total={props.query.agentList.total}
                    onChange={page => {
                        props.onChangePage({page, email: props.value.email});
                    }}/>
            </FadeIn>
        </MngLayout>
    )
}
