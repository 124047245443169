import React, {useState} from "react";
import {BizRefreshSession} from "../../shares/default/bizRefreshSession/BizRefreshSession";
import {gql, useMutation, useQuery} from "@apollo/client";
import {MsgReceiptList, Query, SendBulkSms, SendTestSms, TestNumber} from "../../../graphql/types";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";
import {DataUtil} from "../../../utils/data/DataUtil";
import {BizScrollTop} from "../../shares/default/bizScrollTop/BizScrollTop";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {SmsBulkDefault} from "./default/SmsBulkDefault";
import {Pipe} from "../../../utils/pipe/Pipe";
import {CopyUtil} from "../../../utils/copy/CopyUtil";
import {NumberUtil} from "../../../utils/numbUtil/NumbUtil";
import emojiStrip from "emoji-strip";
import {SmsBulkTemp1} from "./temp1/SmsBulkTemp1";
import {readString} from 'react-papaparse';
import {read} from 'xlsx'
import {GlobalVars} from "../../../globalVars/globalVars";

export const SmsBulk = () => {
    const [input, setInput] = useState<SendBulkInputData>({
        msg: {maxLength: 70, msgLength: 0, msg: ""},
        numb: {maxLength: 20000, numbLength: 0, rawNumb: "", rawAppendNumb: ""}
    });
    const [prevNumb, setPrevNumb] = useState<string[]>([]);
    const {data, refetch} = useQuery<Query>(gqlData, {
        pollInterval: 5000,
        context: {
            loadingView: false,
        }
    });
    const [mutSend] = useMutation<{ sendBulkSms: boolean }, { input: SendBulkSms }>(gqlSend);
    const [mutSendTest] = useMutation<{ sendTestSms: boolean }, { input: SendTestSms }>(gqlSendTest);
    const txtFileReader = new FileReader();
    const xlsxFileReader = new FileReader();
    txtFileReader.onload = ev => {
        const result = txtFileReader.result as string;
        const data = readString(result).data;

        let appendTxt = "";
        let count = 0;
        for (let elem of data) {
            if (input.numb.maxLength <= count) {
                SwalUtil.ok({
                    msg: `최대 전화번호 갯수는 ${input.numb.maxLength}입니다.`,
                    icon: "warning"
                })
                break;
            }
            appendTxt += `${elem}\n`;
            count += 1;
        }

        const copy = CopyUtil.copyAll(input);
        copy.numb.rawNumb = `${appendTxt}${copy.numb.rawNumb}`.trim();
        onChangeValue(copy);
    }

    xlsxFileReader.onload = () => {
        const result = xlsxFileReader.result as ArrayBuffer;

        const workbook = read(result, {
            type: 'buffer'
        });

        let appendTxt = "";
        let count = 0;
        for (let sheetName of workbook.SheetNames) {
            const sheet = workbook.Sheets[sheetName] as any;

            for (let sheetKey in sheet) {
                if (input.numb.maxLength <= count) {
                    SwalUtil.ok({
                        msg: `최대 전화번호 갯수는 ${input.numb.maxLength}입니다.`,
                        icon: "warning"
                    })
                    break;
                }

                if (!sheetKey.startsWith("A")) {
                    continue;
                }

                appendTxt += `${sheet[sheetKey].v}\n`
                count += 1;
            }
        }


        const copy = CopyUtil.copyAll(input);
        copy.numb.rawNumb = `${appendTxt}${copy.numb.rawNumb}`.trim();
        onChangeValue(copy);
    };

    const onChangeValue = (args: SendBulkInputData) => {
        let numbList = NumberUtil.splitNumb(args.numb.rawNumb);
        let appendNumbList = NumberUtil.splitNumb(args.numb.rawAppendNumb);
        args.numb.numbLength = numbList.length + appendNumbList.length;
        args.msg.msgLength = args.msg.msg.length;

        if (args.msg.msg.length > 70) {
            SwalUtil.ok({
                msg: '70 자 이상 메시지는 입력할 수 없습니다.',
                icon: 'warning'
            });
            args.msg.msg = input.msg.msg;
        }

        setInput(args);
    }

    const onSendTest = (inputData: SendTestSms) => {
        if (inputData.msg === "") {
            SwalUtil.ok({
                msg: '메세지를 입력하여 주십시오.',
                icon: 'warning'
            });
            return
        }

        if (input.msg.maxLength < inputData.msg.length) {
            SwalUtil.ok({
                msg: `메세지를 ${Pipe.toSeparatorNumber(input.msg.maxLength)} 미만으로 입력하여 주십시오.`,
                icon: 'warning'
            });
            return
        }

        if (inputData.hashedApiKey === "") {
            SwalUtil.ok({
                msg: '회선을 선택하여 주십시오.',
                icon: 'warning'
            });
            return
        }

        SwalUtil.yn({
            msg: "테스트 전송 하시겠습니까?",
            icon: "question",
            ok: () => {
                mutSendTest({
                    variables: {input: inputData}
                }).then(res => {
                    SwalUtil.ok({
                        msg: '전송되었습니다.',
                        icon: 'success'
                    })
                    onClearInput();
                    return DataUtil.refreshSession();
                }).then(value => {
                    return refetch();
                }).catch(ApolloCatch({
                    [BizErrors.default]: SwalUtil.bizError(),
                    [BizErrors.notEnoughBalance]: SwalUtil.bizErrorMsg({
                        msg: "잔고가 부족합니다.",
                        icon: "error"
                    }),
                }));
            }
        })
    }

    const onSendBulk = (inputData: SendBulkSms) => {
        if (inputData.msg === "") {
            SwalUtil.ok({
                msg: '메세지를 입력하여 주십시오.',
                icon: 'warning'
            });
            return
        }

        if (inputData.hashedApiKey === "") {
            SwalUtil.ok({
                msg: '회선을 선택하여 주십시오.',
                icon: 'warning'
            });
            return
        }

        if (inputData.numberList.length === 0) {
            SwalUtil.ok({
                msg: '전화번호를 입력하여 주십시오.',
                icon: 'warning'
            });
            return
        }

        if (input.numb.maxLength < inputData.numberList.length) {
            SwalUtil.ok({
                msg: `전화번호를 ${Pipe.toSeparatorNumber(input.numb.maxLength)} 미만으로 입력하여 주십시오.`,
                icon: 'warning'
            });
            return
        }

        const send = () => {
            GlobalVars.layout.loadingView(true);
            mutSend({
                variables: {input: inputData}
            }).then(() => {
                SwalUtil.ok({
                    msg: '전송되었습니다.',
                    icon: 'success'
                });
                setPrevNumb(inputData.numberList);
                onClearInput();
                GlobalVars.layout.loadingView(true);
                return DataUtil.refreshSession();
            }).then(() => {
                GlobalVars.layout.loadingView(true);
                return refetch();
            }).catch(ApolloCatch({
                [BizErrors.default]: SwalUtil.bizError(),
                [BizErrors.notEnoughBalance]: SwalUtil.bizErrorMsg({
                    msg: "잔고가 부족합니다.",
                    icon: "error"
                }),
            }));
        }

        if (NumberUtil.compareSame(inputData.numberList, prevNumb)) {
            SwalUtil.yn({
                msg: '바로 전 전송 전화번호부와 같습니다. 전송하시겠습니까?',
                icon: "question",
                ok: send
            })
        } else {
            send();
        }
    }


    const onClearNumb = () => {
        const copy = CopyUtil.copyAll(input);

        // 전화번호부
        let numbList = NumberUtil.splitNumb(copy.numb.rawNumb)
        numbList = NumberUtil.exceptWrongContext(numbList);
        numbList = NumberUtil.exceptWrongNumber(numbList);
        numbList = NumberUtil.exceptDuplicated(numbList);

        // 테스트 전화번호부
        let appendNumbList = NumberUtil.splitNumb(copy.numb.rawAppendNumb);
        appendNumbList = NumberUtil.exceptWrongContext(appendNumbList);
        appendNumbList = NumberUtil.exceptWrongNumber(appendNumbList);
        appendNumbList = NumberUtil.exceptDuplicated(appendNumbList);

        // 두 전화번호의 중복 제거
        const exceptDuplicated = NumberUtil.exceptDuplicatedWithCompare(numbList, appendNumbList);
        copy.numb.rawNumb = NumberUtil.toRawData(exceptDuplicated.a);
        copy.numb.rawAppendNumb = NumberUtil.toRawData(exceptDuplicated.b);

        onChangeValue(copy);

        SwalUtil.ok({
            msg: '전화번호가 정리되었습니다',
            icon: 'success'
        });
    }

    const onClearEmoji = () => {
        const copy = CopyUtil.copyAll(input);
        copy.msg.msg = emojiStrip(copy.msg.msg);
        onChangeValue(copy);

        SwalUtil.ok({
            msg: '이모지가 정리되었습니다',
            icon: 'success'
        });
    }

    const onClearInput = () => {
        const copy = CopyUtil.copyAll(input);
        copy.msg.msg = ""
        copy.numb.rawNumb = ""
        copy.numb.numbLength = 0;
        onChangeValue(copy);
    }

    const onLoadTxt = () => {
        const inputElem = document.createElement('input');
        inputElem.setAttribute('hidden', 'true');
        inputElem.setAttribute('type', 'file');
        inputElem.setAttribute('accept', '.txt');

        inputElem.onchange = () => {
            if (!inputElem.files) {
                return
            }

            for (let i = 0; i < inputElem.files.length; i++) {
                let file = inputElem.files[i];


                txtFileReader.readAsText(file);
            }
        }
        inputElem.click();
    }

    const onLoadExcel = () => {
        const inputElem = document.createElement('input');
        inputElem.setAttribute('hidden', 'true');
        inputElem.setAttribute('type', 'file');
        inputElem.setAttribute('accept', '.xlsx');

        inputElem.onchange = () => {
            if (!inputElem.files) {
                return
            }

            for (let i = 0; i < inputElem.files.length; i++) {
                let file = inputElem.files[i];
                xlsxFileReader.readAsArrayBuffer(file);
            }
        }
        inputElem.click();
    }

    return (
        <BizScrollTop>
            <BizRefreshSession>
                {data &&
                <ViewTemplateSelector view={{
                    [ViewTemplate.default]: <SmsBulkDefault
                        value={input}
                        onChangeValue={onChangeValue}
                        data={data}
                        onSendBulk={onSendBulk}
                        onSendTest={onSendTest}
                        onClearNumb={onClearNumb}
                        onClearEmoji={onClearEmoji}
                        onLoadTxt={onLoadTxt}
                        onLoadExcel={onLoadExcel}/>,
                    [ViewTemplate.temp1]: <SmsBulkTemp1
                        value={input}
                        onChangeValue={onChangeValue}
                        data={data}
                        onSendBulk={onSendBulk}
                        onSendTest={onSendTest}
                        onClearNumb={onClearNumb}
                        onClearEmoji={onClearEmoji}/>,
                    [ViewTemplate.zsms]: <SmsBulkTemp1
                        value={input}
                        onChangeValue={onChangeValue}
                        data={data}
                        onSendBulk={onSendBulk}
                        onSendTest={onSendTest}
                        onClearNumb={onClearNumb}
                        onClearEmoji={onClearEmoji}/>,
                }}/>
                }
            </BizRefreshSession>
        </BizScrollTop>
    )
}

export interface PrevNumb {
    numb: string;
    appendNumb: string;
}

export interface SendBulkResp {
    msgReceipt: MsgReceiptList,
    testNumberList: TestNumber[]
}

export interface SendBulkInputData {
    msg: {
        msg: string,
        msgLength: number,
        maxLength: number,
    }
    numb: {
        rawNumb: string,
        rawAppendNumb: string,
        numbLength: number,
        maxLength: number,
    }
}

const gqlSend = gql`
    mutation SendBulkSms($input: SendBulkSms!) {
        sendBulkSms(input: $input)
    }
`

const gqlSendTest = gql`
    mutation SendTestSms($input: SendTestSms!) {
        sendTestSms(input: $input)
    }
`

const gqlData = gql`
    query Data {
        msgReceipt(page: 0, size: 10) {
            page
            size
            total
            list {
                id
                apiKeyNm
                smsFee
                contents
                createdAt
                sent
            }
        }
        testNumberList {
            vendor
            number
        }
        telReceiverReport(size: 50) {
            id
            vendor
            msg
            senderNumber
            receiverNumber
            createdAt
        }
    }
`;
