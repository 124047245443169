import React from "react";
import {DepositRequestInputTemp2} from "./input/DepositRequestInputTemp2";
import {DepositRequestListTemp2} from "./list/DepositRequestListTemp2";
import {FadeInContainer} from "../../../shares/default/fadeInContainer/FadeInContainer";
import {DepositRequestInputData, DepositRequestResData} from "../DepositRequest";
import {OnChangeValue} from "../../../interfaces";

export const DepositRequestTemp2 = (props: {
    data: DepositRequestResData
    value: DepositRequestInputData,
    setValue: OnChangeValue<DepositRequestInputData>
    onDepositRequest: OnChangeValue<DepositRequestInputData>
    onChangePage: OnChangeValue<number>,
    onCancelDepositRecharge: OnChangeValue<number>
}) => {
    return (
        <FadeInContainer>
            <DepositRequestInputTemp2
                value={props.value}
                onChangeValue={props.setValue}
                onRequest={props.onDepositRequest}/>

            <DepositRequestListTemp2
                list={props.data.depositRequestHistory}
                onChangePage={props.onChangePage}
                onCancel={props.onCancelDepositRecharge}/>
        </FadeInContainer>
    )
}
