import React, {ReactNode, useEffect} from "react";
import classNamesBind from "classnames/bind";
import styles from "./bizVideoBackgroundZsms.module.scss";
import video from "../../../../assets/video/zsms/background.mp4";
import {Spring} from "react-spring/renderprops-universal";
import {LayoutAction} from "../../../../redux/Layout";
import {useDispatch} from "react-redux";

const cx = classNamesBind.bind(styles);

export const BizVideoBackgroundZsms = (props: {
    children: ReactNode,
    fadeIn?: boolean
}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(LayoutAction.setAppBarTransparent(true));
        dispatch(LayoutAction.setTopMargin(false));
        return () => {
            dispatch(LayoutAction.setAppBarTransparent(false));
            dispatch(LayoutAction.setTopMargin(true));
        }
    }, [])
    return (
        <>
            <Spring
                from={props.fadeIn ? {opacity: 0} : {opacity: 1}}
                to={{opacity: 1}}>
                {params => (
                    <div className={cx('cont')} style={params}>
                        <video className={cx('video')} autoPlay loop muted>
                            <source src={video} type='video/mp4'/>
                        </video>
                        <div className={cx('filter')}/>
                    </div>
                )}
            </Spring>
            {props.children}
        </>

    )
}
