import React, {useEffect, useState} from "react";
import classNamesBind from "classnames/bind";
import styles from './SmsBulkTemp1ConfirmModal.module.scss'
import {OnVoidFunction} from "../../../../interfaces";
import {SendBulkInputData, SendBulkResp} from "../../SmsBulk";
import emojiStrip from "emoji-strip";
import {NumberUtil} from "../../../../../utils/numbUtil/NumbUtil";
import {Button} from "@material-ui/core";
import {HashedApiKey} from "../../../../../graphql/types";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import Decimal from "decimal.js";
import {Pipe} from "../../../../../utils/pipe/Pipe";
import {ReduxStore} from "../../../../../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";

const cx = classNamesBind.bind(styles);

export const SmsBulkTemp1ConfirmModal = (props: {
    onSendBulk: OnVoidFunction,
    onCancel: OnVoidFunction,
    value: SendBulkInputData,
    data: SendBulkResp,
    hashedApiKey: HashedApiKey,
}) => {
    const [activate, setActivate] = useState<Activation>({
        noMsg: false,
        noNumb: false,
        overMsg: false,
        overNumber: false,
        hasEmoji: false,
        hasWrongNumber: false,
        hasDuplicatedNumber: false,
        notEnoughPoint: false,
        sendAvailable: true,
    });

    const user = useSelector<any, UserState>(state => state.user);

    useEffect(() => {
        const copy: Activation = {
            noMsg: false,
            noNumb: false,
            overMsg: false,
            overNumber: false,
            hasEmoji: false,
            hasWrongNumber: false,
            hasDuplicatedNumber: false,
            notEnoughPoint: false,
            sendAvailable: true
        };

        if (props.value.msg.msg === "") {
            copy.noMsg = true;
        }

        if ((props.value.numb.rawNumb + props.value.numb.rawAppendNumb) === "") {
            copy.noNumb = true;
        }

        if (props.value.msg.maxLength < props.value.msg.msgLength) {
            copy.overMsg = true;
        }
        if (props.value.msg.msg !== emojiStrip(props.value.msg.msg)) {
            copy.hasEmoji = true;
        }

        if (props.value.numb.maxLength < props.value.numb.numbLength) {
            copy.overNumber = true;
        }

        // 전화번호부
        let origin = NumberUtil.splitNumb(`${props.value.numb.rawNumb}\n${props.value.numb.rawAppendNumb}`);
        if (origin.length !== NumberUtil.exceptWrongNumber(origin).length) {
            copy.hasWrongNumber = true;
        }

        if (origin.length !== NumberUtil.exceptDuplicated(origin).length) {
            copy.hasDuplicatedNumber = true;
        }

        // 잔고확인
        copy.notEnoughPoint = new Decimal(props.hashedApiKey.smsFee).mul(props.value.numb.numbLength).gt(ReduxStore.getState().user.user.point);

        // 조건에 하나라도 거절되면 전송이 금지된다.
        if (copy.overNumber || copy.overMsg || copy.hasWrongNumber || copy.noMsg || copy.noNumb || copy.notEnoughPoint) {
            copy.sendAvailable = false;
        }

        setActivate(copy);
    }, [props]);

    return (
        <>
            {!activate.sendAvailable &&
            <div className={cx("modalContainer")}>
                <div className={cx("firstSection")}>
                    <div className={cx("section-side")}>
                        <div className={cx("side-flexBox", "alertTitleBox")}>
                            <FontAwesomeIcon icon={faTimesCircle} size={"5x"}/>
                            <span className={cx("titleBox-text")}>발송할 수 없습니다</span>
                            <div className={cx("side-contentsColumn", "alertDescription")}>
                                {activate.noMsg && <div>문자 내용이 없습니다.</div>}
                                {activate.hasEmoji && <div>문자 내용 중에 발송 불가능한 이모지가 추가되어 있습니다.</div>}
                                {activate.overMsg && <div>문자 내용의 글자 수가 80자 이상입니다.</div>}
                                {activate.noNumb && <div>전화번호가 없습니다.</div>}
                                {activate.overNumber && <div>전화번호가 너무 많습니다.</div>}
                                {activate.hasWrongNumber && <div>잘못된 형식의 전화번호가 있습니다.</div>}
                                {activate.hasDuplicatedNumber && <div>중복된 전화번호가 있습니다.</div>}
                            </div>
                            <div className={cx("side-contentsColumn", "buttonBox")}>
                                <Button
                                    classes={{root: cx("buttonThree")}}
                                    variant="outlined"
                                    onClick={props.onCancel}>돌아가기
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }

            {activate.sendAvailable &&
            <>
                <div className={cx("modalContainer")}>
                    <div className={cx("firstSection")}>
                        <div className={cx("section-side")}>
                            <div className={cx("side-flexBox", "clearBox")}>
                                <FontAwesomeIcon icon={faCheckCircle} size={"5x"}/>
                                <span className={cx("titleBox-text")}>발송 포인트 안내</span>
                                <div className={cx("side-contentsColumn", "borderBottom", "borderTop")}>
                                    <div className={cx("contentsColumn-leftHeader", "balanceHeader")}>
                                        소지 포인트
                                    </div>
                                    <div className={cx("contentsColumn-rightValue", "balanceValue")}>
                                        {Pipe.toSeparatorNumber(user.user.point)} P
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn", "borderBottom")}>
                                    <div className={cx("contentsColumn-leftHeader", "balanceHeader")}>
                                        전송비용
                                    </div>
                                    <div className={cx("contentsColumn-rightValue", "balanceValue")}>
                                        {props.hashedApiKey.smsFee} P X {props.value.numb.numbLength} 개
                                        = {Pipe.toSeparatorNumber(new Decimal(props.hashedApiKey.smsFee).mul(props.value.numb.numbLength).toNumber())} P
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn", "borderBottom", "marginBottom")}>
                                    <div className={cx("contentsColumn-leftHeader", "balanceHeader")}>
                                        전송 후 포인트
                                    </div>
                                    <div className={cx("contentsColumn-rightValue", "balanceValue")}>
                                        {Pipe.toSeparatorNumber(new Decimal(user.user.point).sub(new Decimal(props.hashedApiKey.smsFee).mul(props.value.numb.numbLength)).toNumber())} P {activate.notEnoughPoint &&
                                    <span className={cx("alertColor")}>(잔고가 부족합니다.)</span>}
                                    </div>
                                </div>
                                <div className={cx("side-contentsColumn", "buttonBox")}>
                                    <Button
                                        classes={{root: cx("buttonThree")}}
                                        variant="outlined"
                                        style={{marginRight: 10}}
                                        onClick={props.onCancel}>취소
                                    </Button>
                                    <Button
                                        classes={{root: cx("buttonOne")}}
                                        variant="outlined"
                                        disabled={!activate.sendAvailable}
                                        onClick={props.onSendBulk}>전송
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}

interface Activation {
    noMsg: boolean;
    noNumb: boolean;
    overMsg: boolean;
    hasEmoji: boolean;
    overNumber: boolean;
    hasWrongNumber: boolean;
    hasDuplicatedNumber: boolean;
    sendAvailable: boolean;
    notEnoughPoint: boolean;
}
