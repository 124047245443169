import React, {Fragment, useEffect, useState} from 'react';
import classNamesBind from "classnames/bind";
import styles from './ResultTelBook.module.scss';
import {gql, useLazyQuery} from '@apollo/client';
import {TelBook} from "../../../graphql/types";
import {saveAs} from 'file-saver';
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {ResultTelbookDefault} from "./default/ResultTelbookDefault";
import {ResultTelbookTemp1} from './temp1/ResultTelbookTemp1';
import {ResultTelbookTemp2} from "./temp2/ResultTelbookTemp2";
import {BizRefreshSession} from '../../shares/default/bizRefreshSession/BizRefreshSession';

const cx = classNamesBind.bind(styles);

export const ResultTelBook = (props: {
    id: string,
}) => {
    const [getData, resData] = useLazyQuery<ResultTelbookResp, { requestId: string }>(gqlData);
    const [data, setData] = useState<ResultTelbookResp | undefined>();

    const onDownload = () => {
        if (!data) {
            return;
        }

        let page = "";
        for (let telNumber of data.telbook.numberList) {
            page += `${telNumber.number},${telNumber.status}\n`;
        }

        let dataValue = new Blob([page], {type: 'application/csv'});
        saveAs(dataValue, `${data.telbook.requestId}.csv`);
    }

    useEffect(() => {
        getData({variables: {requestId: props.id}});
    }, [props.id]);

    useEffect(() => {
        if (resData.data) {
            setData(resData.data)
        }
    }, [resData.data]);


    return (
        <Fragment>
            {data &&
            <BizRefreshSession>
                    <ViewTemplateSelector view={{
                        [ViewTemplate.default]:
                            <ResultTelbookDefault
                                data={data}
                                onDownload={onDownload}/>,
                        [ViewTemplate.temp1]:
                            <ResultTelbookTemp1
                                data={data}
                                onDownload={onDownload}/>,
                        [ViewTemplate.temp2]:
                            <ResultTelbookTemp2
                                data={data}
                                onDownload={onDownload}/>,
                        [ViewTemplate.zsms]:
                            <ResultTelbookTemp1
                                data={data}
                                onDownload={onDownload}/>,
                    }}/>
            </BizRefreshSession>
            }
        </Fragment>
    )
}

export interface ResultTelbookResp {
    telbook: TelBook;
}

const gqlData = gql`
query Telbook($requestId: String!){
    telbook(requestId: $requestId) {
        requestId
        msg
        requestTotal
        totalSend
        sent
        fail
        numberList {
            number
            status
        }
        createdAt
    }
}`;
