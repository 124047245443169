import React from "react";
import {Chip} from "@material-ui/core";
import classNamesBind from "classnames/bind";
import styles from "./BadgeMngCommissionStatus.module.scss";

const cx = classNamesBind.bind(styles);

export const BadgeMngCommissionStatus = (props: {
    chip: number,
    label: string
}) => {
    return (
        <>
            {props.chip === 1 &&
            <Chip size="small" label={props.label} className={cx("chip1")} />
            }

            {props.chip === 2 &&
            <Chip size="small" label={props.label} className={cx("chip2")} />
            }

            {props.chip === 3 &&
            <Chip size="small" label={props.label} className={cx("chip3")} />
            }

            {props.chip === 4 &&
            <Chip size="small" label={props.label} className={cx("chip4")} />
            }
        </>
    )
}
