import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {Mutation, MutationNotifiedUserAlertArgs, Query} from "../../../../graphql/types";
import {ApolloCatch} from "../../../../utils/apollo/apolloCatch";
import {ViewTemplateSelector} from "../viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../../environment/interfaces";
import {LayoutUserAlertDefault} from "./default/LayoutUserAlertDefault";
import {GlobalVars} from "../../../../globalVars/globalVars";

export const LayoutUserAlert = () => {
    const {data, refetch} = useQuery<Query>(gqlData, {
        pollInterval: 5000,
        context: {
            loadingView: false,
        },
        onCompleted: value => {
            GlobalVars.notify.chatNotify(value.chatNotify);
        }
    });
    const [mutNotifiedUserAlert] = useMutation<Mutation, MutationNotifiedUserAlertArgs>(gqlNotifiedUserAlert, {
        context: {
            loadingView: false,
        }
    });

    const handler = {
        onNotifiedUserAlert: (args: MutationNotifiedUserAlertArgs) => {
            mutNotifiedUserAlert({
                variables: args
            })
                .then(() => {
                    return refetch()
                })
                .catch(ApolloCatch({}));
        }
    };

    return (
        <>
            {data &&
            <ViewTemplateSelector view={{
                [ViewTemplate.default]:
                    <LayoutUserAlertDefault
                        userAlert={data.userAlert}
                        {...handler}/>
            }}/>
            }
        </>
    )
}

const gqlNotifiedUserAlert = gql`
    mutation NotifedUserAlert($userAlertId: Int!) {
        notifiedUserAlert(userAlertId: $userAlertId)
    }
`

const gqlData = gql`
    query DATA {
        userAlert {
            id
            content
            alertType
            createdAt
        }
        chatNotify
    }
`;
