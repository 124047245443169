import {createActionCreators, createReducerFunction, ImmerReducer} from "immer-reducer";
import {Init} from "../graphql/types";

export enum ViewMode {
    Web, Mobile
}

export enum AppAuth {
    Loading,
    Ok,
    Ban,
    ShutDown
}

export interface SystemState {
    stdWidth: number,
    loadingView: boolean,
    appAuth: AppAuth,
    viewMode: ViewMode,
    init: Init,
    backgroundPhoto: boolean,
    scrollY: number,
}

const initState: SystemState = {
    stdWidth: 998,
    appAuth: AppAuth.Loading,
    loadingView: false,
    viewMode: ViewMode.Web,
    init: {
        fb: "",
        appNm: "",
        env: "",
        viewTemplate: 'default',
    },
    backgroundPhoto: false,
    scrollY: 0,
}

class System extends ImmerReducer<typeof initState> {
    setWindowWidth(width: number) {
        if ((width < this.state.stdWidth) && (this.state.viewMode === ViewMode.Web)) {
            this.draftState.viewMode = ViewMode.Mobile;
        } else if ((this.state.stdWidth <= width) && (this.state.viewMode === ViewMode.Mobile)) {
            this.draftState.viewMode = ViewMode.Web;
        }
    }

    setScrollY(y: number) {
        this.draftState.scrollY = y;
    }

    setInit(init: Init) {
        this.draftState.init = init;
    }

    setAppAuth(auth: AppAuth) {
        this.draftState.appAuth = auth;
    }

    setLoadingView(swt: boolean) {
        this.draftState.loadingView = swt;
    }

    setBackgroundPhoto(swt: boolean) {
        this.draftState.backgroundPhoto = swt;
    }
}


export const SystemAction = createActionCreators(System);
export const SystemReducer = createReducerFunction(System, initState);

