import React from "react";
import classNamesBind from "classnames/bind";
import styles from './DepositRequestInput.module.scss'
import {DepositRequestInputData} from "../../DepositRequest";
import {OnChangeValue} from "../../../../interfaces";
import {BizTitle} from "../../../../shares/default/bizTitle/BizTitle";
import NumberFormat from "react-number-format";
import Decimal from "decimal.js";
import {CopyUnit} from "../../../../../utils/copy/CopyUtil";
import {useSelector} from "react-redux";
import {UserState} from "../../../../../redux/User";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import {Pipe} from "../../../../../utils/pipe/Pipe";
import kingdom_tel_1 from "../../../../../assets/img/deposit/kingdom_tel_1.jpg";
import depositTelegramBanner from "../../../../../assets/img/deposit/depositTelegramBanner.png";

const cx = classNamesBind.bind(styles);

export const DepositRequestInput = (props: {
    value: DepositRequestInputData,
    onChangeValue: OnChangeValue<DepositRequestInputData>,
    onRequest: OnChangeValue<DepositRequestInputData>,
}) => {
    const copyUnit = new CopyUnit(props.onChangeValue);
    const user = useSelector<any, UserState>(state => state.user);


    const onBlurInputPoint = () => {
        if (0 < props.value.point % 1000) {
            // SwalUtil.ok({
            //     msg: "최소 충전 기본단위는 1000원 입니다.",
            //     icon: "info"
            // });
            copyUnit.onChangeValue(props.value, 'point', props.value.point - (props.value.point % 1000));
        }

        if (props.value.point < 10000) {
            SwalUtil.ok({
                msg: "최소 충전 금액은 10,000 원 입니다.",
                icon: "info"
            });
            copyUnit.onChangeValue(props.value, 'point', 10000);
        }
    }


    return (
        <>
            <BizTitle>입금확인요청</BizTitle>
            <div className={cx('table')}>
                <div className='text-left' style={{width: 150}}>입금자명</div>
                <div style={{flexGrow: 1, minWidth: 'calc(50% - 150px)'}}>
                    <input
                        placeholder='입금자명을 입력하여 주십시오.'
                        className='form-control form-control-sm text-center'
                        type='text'
                        value={props.value.nm}
                        onChange={event => {
                            copyUnit.onChangeValue(props.value, 'nm', event.target.value);
                        }}/>
                </div>
                <div className='text-left' style={{width: 150}}>부가세</div>
                <div style={{
                    flexGrow: 1,
                    minWidth: 'calc(50% - 150px)'
                }}>{new Decimal(user.user.tax).times(100).toNumber()} %
                </div>
            </div>

            <div className={cx('table')}>
                <div className='text-left' style={{width: 150}}>충전 포인트</div>
                <div style={{flexGrow: 1, minWidth: 'calc(50% - 150px)'}}>
                    <NumberFormat
                        thousandSeparator
                        allowNegative={false}
                        decimalScale={0}
                        placeholder='충전 포인트를 입력하여 주십시오.'
                        className='form-control form-control-sm text-center'
                        displayType='input'
                        value={props.value.point}
                        onValueChange={value => {
                            copyUnit.onChangeValue(props.value, 'point', value.floatValue || 0);
                        }}
                        onBlur={onBlurInputPoint}/>
                </div>
                <div className='text-left' style={{width: 150}}>입금금액</div>
                <div style={{flexGrow: 1, minWidth: 'calc(50% - 150px)'}}>
                    <NumberFormat
                        thousandSeparator
                        displayType='text'
                        suffix=' 원'
                        value={new Decimal(props.value.point)
                            .times(new Decimal(user.user.tax).add(1))
                            .toNumber()}/>
                </div>
            </div>

            <div className={cx('table')}>
                {/*<div className='text-left' style={{width: 150}}>연락처 <span*/}
                {/*    style={{fontSize: '0.8rem', color: '#c3c3c3'}}>(선택사항)</span>*/}
                {/*</div>*/}
                {/*<div style={{flexGrow: 1, minWidth: 'calc(50% - 150px)'}}>*/}
                {/*    <input*/}
                {/*        value={props.value.tel}*/}
                {/*        className='form-control form-control-sm text-center'*/}
                {/*        placeholder='휴대폰 전화번호를 입력하여 주십시오.'*/}
                {/*        type='text'*/}
                {/*        onChange={event => {*/}
                {/*            copyUnit.onChangeValue(props.value, "tel", event.target.value);*/}
                {/*        }}/>*/}
                {/*</div>*/}

                {/*<div className='text-left' style={{flexGrow: 1, minWidth: '50%'}}>*/}
                {/*    ※ 연락처로 입금정보를 전송 해 드립니다.*/}
                {/*</div>*/}

                <div className={cx("banner")}>
                    <ExportComponent host={window.location.host}/>
                </div>
            </div>

            <div className={cx("table")}>
                <div className='text-left' style={{flexGrow: 1, minWidth: "100%"}}>
                    ※ 계좌문의 : 입금계좌는 텔레그램으로 문의 주시면 친절히 상담 드리겠습니다.
                </div>
            </div>


            <div style={{height: 40}}/>
            <BizTitle>충전 후 정보</BizTitle>
            <div className={cx('table')}>
                <div style={{width: '25%'}}>구분</div>
                <div style={{width: '25%'}}>잔고</div>
                <div style={{width: '25%'}}>충전 포인트</div>
                <div style={{width: '25%'}}>합계</div>
            </div>

            <div className={cx('table')}>
                <div style={{width: '25%'}}>잔고</div>
                <div className='text-right' style={{width: '25%'}}>{Pipe.toSeparatorNumber(user.user.point)} P</div>
                <div className='text-right' style={{width: '25%'}}>{Pipe.toSeparatorNumber(props.value.point)} P</div>
                <div className='text-right'
                     style={{width: '25%'}}>{Pipe.toSeparatorNumber(new Decimal(user.user.point).add(props.value.point).toNumber())} P
                </div>
            </div>

            {user.user.apiPermission.map((value, index) => {
                const hasPointPerSms = new Decimal(user.user.point).divToInt(value.smsFee).toNumber();
                const rechargePointPerSms = new Decimal(props.value.point).divToInt(value.smsFee).toNumber();
                const totalPerSms = hasPointPerSms + rechargePointPerSms;
                return (
                    <div key={index} className={cx('table')}>
                        <div style={{width: '25%'}}>{value.nm}</div>
                        <div className='text-right' style={{width: '25%'}}>{Pipe.toSeparatorNumber(hasPointPerSms)} 건
                        </div>
                        <div className='text-right'
                             style={{width: '25%'}}>{Pipe.toSeparatorNumber(rechargePointPerSms)} 건
                        </div>
                        <div className='text-right' style={{width: '25%'}}>{Pipe.toSeparatorNumber(totalPerSms)} 건</div>
                    </div>
                )
            })}


            <div className={cx('table')}>
                <div className={cx('space')}/>
                <div>
                    <button
                        className='btn btn-success'
                        onClick={() => {
                            props.onRequest(props.value);
                        }}>요청
                    </button>
                </div>
            </div>
        </>
    )
}

function ExportComponent(props: { host: string }) {
    const {host} = props;

    if (host === "kingdom-sms.com" || host === "www.kingdom-sms.com") {
        return <a href="https://t.me/kingdom_smsM" target="_blank" rel="noreferrer"><img src={kingdom_tel_1}
                                                                                         alt={"telegramId"}/></a>
    } else if (host === "my-2017.com" || host === "www.my-2017.com") {
        return <a href="https://t.me/kingdom_smsM" target="_blank" rel="noreferrer"><img src={depositTelegramBanner}
                                                                                         alt={"telegramId"}/></a>
    } else if (host === "dubaisms.net" || host === "www.dubaisms.net") {
        return <a href="https://t.me/Dubai_sms" target="_blank" rel="noreferrer"><img src={depositTelegramBanner}
                                                                                      alt={"telegramId"}/></a>
    } else {
        return <></>
    }
}
