import React from "react";
import classNamesBind from "classnames/bind";
import styles from './SettleListDefault.module.scss'
import {Query} from "../../../../graphql/types";
import {BizTitle} from "../../../shares/default/bizTitle/BizTitle";
import {BizCol12, BizContainer} from "../../../shares/default/bizContainer/BizContainer";
import {Pager} from "../../../shares/default/pager/Pager";
import {OnChangeValue} from "../../../interfaces";
import moment from "moment";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {SettleListDefaultSentSmsGraph} from "./sentSmsGraph/SettleListDefaultSentSmsGraph";
import { A } from "hookrouter";

const cx = classNamesBind.bind(styles);

export const SettleListDefault = (props: {
    data: Query,
    onChangePage: OnChangeValue<number>,
}) => {
    return (
        <BizContainer>
            <BizCol12>
                <BizTitle>통계</BizTitle>
                <SettleListDefaultSentSmsGraph data={props.data}/>

                <div className={cx('table')}>
                    <div className={cx("dateCell")}>날짜</div>
                    <div className={cx("pointCell")}>사용포인트</div>
                    <div className={cx("chargeCell")}>충전금액</div>
                    <div className={cx("chargePointCell")}>충전포인트</div>
                    <div className={cx("spendCell")}>전송량</div>
                </div>


                {props.data.settle.list.map((value, index) => (
                    <A href={`/result/receipt/0/${Pipe.toTimeDay(value.day)}/${Pipe.toTimeDay(value.day)}`} key={index} className={cx('table', 'hover')}>
                        <div className={cx("dateCell")}>
                            {moment(value.day).format('YY.MM.DD')}
                        </div>
                        <div className={cx("pointCell")}>
                            {Pipe.toSeparatorNumber(-value.usedPoint)} P
                        </div>
                        <div className={cx("chargeCell")}>
                            {Pipe.toSeparatorNumber(value.chargeAmount)} 원
                        </div>
                        <div className={cx("chargePointCell")}>
                            {Pipe.toSeparatorNumber(value.chargePoint)} P
                        </div>
                        <div className={cx("spendCell")}>
                            {Pipe.toSeparatorNumber(value.sentSms)} 개
                        </div>
                    </A>
                ))}

                <Pager
                    page={props.data.settle.page}
                    size={props.data.settle.size}
                    total={props.data.settle.total}
                    onChange={props.onChangePage}/>
            </BizCol12>
        </BizContainer>
    )
}
