import React from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {
    Mutation,
    MutationRequestRechargeCommissionSettleArgs,
    MutationRequestSendCommissionSettleArgs,
    Query
} from "../../../graphql/types";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {MngSendCommissionDefault} from "./default/MngSendCommissionDefault";
import {MngSendCommissionTemp1} from "./temp1/MngSendCommissionTemp1";
import {MngSendCommissionTemp2} from './temp2/MngSendCommissionTemp2';
import {navigate} from "hookrouter";
import {SwalUtil} from "../../../utils/swal/swalUtil";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {BizErrors} from "../../../graphql/errors";


export const MngSendCommission = (props: {
    page: number,
    requestPage: number
}) => {
    const {data, refetch} = useQuery<Query, {
        page: number,
    }>(gqlData, {
        variables: props
    });

    const [mutRequest] = useMutation<Mutation, MutationRequestSendCommissionSettleArgs>(gqlRequestSettle);

    const handler = {
        onChangePage: (page: number) => {
            navigate(`/mng/send/${page}/${props.requestPage}`);
        },
        onChangeRequestPage: (page: number) => {
            navigate(`/mng/send/${props.page}/${page}`);
        },
        onRequestSettle: (args: MutationRequestRechargeCommissionSettleArgs) => {
            mutRequest({
                variables: args
            }).then(() => {
                return refetch(props)
            }).catch(ApolloCatch({
                [BizErrors.notFoundCommission]: SwalUtil.bizErrorMsg({
                    msg: '정산요청 할 커미션 정보가 없습니다.',
                    icon: 'warning'
                })
            }));
        },
    }

    return (
        <ViewTemplateSelector view={{
            [ViewTemplate.default]:
                <MngSendCommissionDefault
                    data={data}
                    {...handler}/>,
            [ViewTemplate.temp1]:
                <MngSendCommissionTemp1
                    data={data}
                    {...handler}/>,
            [ViewTemplate.temp2]:
                <MngSendCommissionTemp2
                    data={data}
                    {...handler}/>,
            [ViewTemplate.zsms]:
                <MngSendCommissionTemp1
                    data={data}
                    {...handler}/>,
        }}/>
    )
}
const gqlRequestSettle = gql`
    mutation RequestSettle($type: SettleCommissionType!, $bankNumber: String!) {
        requestSendCommissionSettle(type: $type, bankNumber: $bankNumber)
    }
`;


const gqlData = gql`
    query SendCommission($page: Int!, $requestPage: Int!) {
        totalSendCommission {
            total
            paid
            notPaid
        }
        sendCommissionList(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                agentEmail
                agentSendTotal
                margin
                commission
                isWithdrawal
                isRequestedSettle
                createdAt
                withdrawalAt
            }
        }
        sendCommissionSettleRequestList(page: $requestPage, size: 10) {
            page
            size
            total
            list {
                id
                status
                commission
                commissionCount
                commissionType
                createdAt
            }
        }
    }
`;
