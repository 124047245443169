import React, {ReactNode} from "react";
import {CreateUserData} from "../AuthJoin";
import {OnChangeValue} from "../../../interfaces";
import {FbJoinType, UserType} from "../../../../graphql/types";
import classNamesBind from "classnames/bind";
import styles from "./AuthJoinDefault.module.scss";
import {Tab, Tabs} from "@material-ui/core";
import {CopyUnit, CopyUtil} from "../../../../utils/copy/CopyUtil";
import {A} from "hookrouter";
import {Pipe} from "../../../../utils/pipe/Pipe";
import {BizVideoBackground} from "../../../shares/default/bizVideoBackground/BizVideoBackground";
import {CenterContents} from "../../../shares/default/centerContents/CenterContents";


const cx = classNamesBind.bind(styles);

export const AuthJoinDefault = (props: {
    value: CreateUserData,
    onChangeValue: OnChangeValue<CreateUserData>,
    onCreateUserWithEmail: OnChangeValue<CreateUserData>,
    onCreateUserWithPhoneNumber: OnChangeValue<CreateUserData>,
    onVerifyCode: (code: string, userType: UserType) => void
}) => {
    return (

        <BizVideoBackground>
            <CenterContents animatedContents>
                <div className={cx('cont')}>
                    <h3 className='text-center' style={{marginBottom: 30}}>회원가입</h3>
                    {/*<Tabs*/}
                    {/*    value={props.value.loginType}*/}
                    {/*    onChange={(ev, value) => {*/}
                    {/*        const copy = CopyUtil.copyAll(props.value);*/}
                    {/*        copy.loginType = value;*/}
                    {/*        props.onChangeValue(copy);*/}
                    {/*    }}*/}
                    {/*    indicatorColor="primary"*/}
                    {/*    textColor="primary"*/}
                    {/*    variant="fullWidth">*/}
                    {/*    <Tab label="이메일" value={FbJoinType.Email}/>*/}
                    {/*    <Tab label="전화번호" value={FbJoinType.Phone}/>*/}
                    {/*</Tabs>*/}

                    <form onSubmit={event => {
                        event.preventDefault();
                    }}>
                        {/*<TabPanel value={props.value.loginType} index={FbJoinType.Email}>*/}
                            <JoinWithEmail {...props}/>
                        {/*</TabPanel>*/}
                        {/*<TabPanel value={props.value.loginType} index={FbJoinType.Phone}>*/}
                        {/*    <JoinWithPhoneNumber {...props}/>*/}
                        {/*</TabPanel>*/}
                    </form>

                    <p>아이디가 이미 있으십니까?
                        <A href={'/auth/login'} style={{paddingLeft: 10}}>로그인</A>
                    </p>
                </div>
            </CenterContents>
        </BizVideoBackground>

    )
}

const TabPanel = (props: {
    children: ReactNode,
    value: FbJoinType,
    index: FbJoinType,
}) => {
    return (
        <>
            {props.value === props.index && props.children}
        </>
    );
}


const JoinWithEmail = (props: {
    value: CreateUserData,
    onChangeValue: OnChangeValue<CreateUserData>,
    onVerifyCode: (code: string, userType: UserType) => void,
    onCreateUserWithEmail: OnChangeValue<CreateUserData>
}) => {
    const copyUnit = new CopyUnit(props.onChangeValue);

    return (
        <>
            <p>Email</p>
            <input
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'email', event.target.value);
                }}
                value={props.value.email}
                className="form-control"
                type="email"
                placeholder={'이메일을 입력하여 주십시오.'} required/>

            <p>Password</p>
            <input
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'pw', event.target.value);
                }}
                value={props.value.pw}
                className="form-control"
                type="password"
                placeholder={'비밀번호를 입력하여 주십시오.'} required/>

            <p>Confirm</p>
            <input
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'confirm', event.target.value);
                }}
                value={props.value.confirm}
                className="form-control"
                type="password"
                placeholder={'비빌번호를 다시 입력하여 주십시오.'} required/>

            <p>Contact</p>
            <input
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'contact', event.target.value);
                }}
                value={props.value.contact}
                className="form-control"
                type="text"
                placeholder={'연락처를 입력하여 주십시오.'} required/>
            <div className={cx('info')}>* 휴대폰 번호, 카카오톡, 텔레그램 등 메신져 종류를 같이 기입하여 주십시오.</div>

            <JoinCodeVerifier {...props}/>
            <hr/>

            <button
                disabled={!props.value.verified}
                type="submit"
                className="btn btn-primary btn-block"
                onClick={() => props.onCreateUserWithEmail(props.value)}>
                회원가입
            </button>
        </>
    )
}

const JoinWithPhoneNumber = (props: {
    value: CreateUserData,
    onChangeValue: OnChangeValue<CreateUserData>,
    onCreateUserWithPhoneNumber: OnChangeValue<CreateUserData>,
    onVerifyCode: (code: string, userType: UserType) => void
}) => {
    const copyUnit = new CopyUnit(props.onChangeValue);

    return (
        <>
            <p>전화번호</p>
            <input
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'phoneNumber', event.target.value);
                }}
                value={props.value.phoneNumber}
                className="form-control"
                type="text"
                placeholder={'전화번호를 입력하여 주십시오.'} required/>

            <p>Contact</p>
            <input
                onChange={event => {
                    copyUnit.onChangeValue(props.value, 'contact', event.target.value);
                }}
                value={props.value.contact}
                className="form-control"
                type="text"
                placeholder={'연락처를 입력하여 주십시오.'} required/>
            <div className={cx('info')}>* 휴대폰 번호, 카카오톡, 텔레그램 등</div>
            <div className={cx('info')}>* 메신져 종류를 같이 기입하여 주십시오.</div>

            <JoinCodeVerifier {...props}/>
            <hr/>
            <div className={cx('info')}>* 핸드폰 번호로 인증번호가 발송됩니다. 잠시만 기다려 주십시오.</div>
            <div className={cx('info')}>* 인증 실패를 여러번 하시면 해당 전화번호는 제한되어 회원가입이 불가합니다.</div>

            <div style={{height: 20}}/>

            <button
                id={'joinWithPhoneNumber'}
                disabled={!props.value.verified}
                type="submit"
                className="btn btn-primary btn-block"
                onClick={() => {
                    props.onCreateUserWithPhoneNumber(props.value);
                }}>
                회원가입
            </button>


        </>
    );
}


const JoinCodeVerifier = (props: {
    value: CreateUserData,
    onChangeValue: OnChangeValue<CreateUserData>,
    onVerifyCode: (code: string, userType: UserType) => void,
}) => {
    const copyUnit = new CopyUnit(props.onChangeValue);

    return (
        <>
            <p>Code</p>
            <div className={cx('box-code')}>
                <select
                    className={'form-control'}
                    value={props.value.userType.toString()}
                    onChange={event => {
                        const copy = CopyUtil.copyAll(props.value);
                        copy.userType = Pipe.userType(event.target.value);
                        props.onVerifyCode(props.value.joinCode, copy.userType);
                        props.onChangeValue(copy);
                    }}>
                    <option value={UserType.General.toString()}>일반회원</option>
                    <option value={UserType.Reseller.toString()}>총판회원</option>
                    <option value={UserType.Agent.toString()}>정회원</option>
                    <option value={UserType.Tree.toString()}>추천회원</option>
                </select>
                <input
                    type='text'
                    className='form-control'
                    placeholder='회원가입코드'
                    value={props.value.joinCode}
                    onChange={event => {
                        copyUnit.onChangeValue(props.value, 'joinCode', event.target.value);
                        props.onVerifyCode(event.target.value, props.value.userType);
                    }}/>
            </div>

            <p>
                {props.value.verified && <span>* 가입 가능한 코드 입니다.</span>}
                {!props.value.verified && <span style={{color: '#d45e5e'}}>* 회원 종류와 가입코드를 확인하여 주십시오.</span>}
            </p>
        </>
    )
}
