export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
  Time: any;
  Decimal: any;
  Map: any;
};

export type Board = {
  __typename?: 'Board';
  id: Scalars['Int'];
  title: Scalars['String'];
  content: Scalars['String'];
  adminEmail: Scalars['String'];
  isActivate: Scalars['Boolean'];
  bgColor: Scalars['String'];
  createdAt: Scalars['Time'];
};

export type RequestDepositRecharge = {
  nm: Scalars['String'];
  point: Scalars['Decimal'];
  tel?: Maybe<Scalars['String']>;
};

export type CreateJoinCodeWithSmsFee = {
  apiPermission: Array<CreateApiPermission>;
};

export type PointHistory = {
  __typename?: 'PointHistory';
  id: Scalars['Int'];
  point: Scalars['Decimal'];
  prevPoint: Scalars['Decimal'];
  changedPoint: Scalars['Decimal'];
  receiptType: BalanceReceiptType;
  createdAt: Scalars['Time'];
};

export type PeriodTimeDay = {
  stDate: Scalars['Int'];
  edDate: Scalars['Int'];
};

export type MsgReceiptList = {
  __typename?: 'MsgReceiptList';
  size: Scalars['Int'];
  page: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<MsgReceipt>;
};

export enum FbJoinType {
  Email = 'Email',
  Phone = 'Phone'
}

export type TestNumber = {
  __typename?: 'TestNumber';
  vendor: Scalars['String'];
  number: Scalars['String'];
};

export enum SmsType {
  Admin = 'Admin',
  DepositRequest = 'DepositRequest',
  Template = 'Template',
  Bulk = 'Bulk'
}

export type RechargeCommissionSettleRequestList = {
  __typename?: 'RechargeCommissionSettleRequestList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<RechargeCommissoinSettleRequest>;
};

export type PointHistoryList = {
  __typename?: 'PointHistoryList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<PointHistory>;
};

export type SendBulkSms = {
  msg: Scalars['String'];
  hashedApiKey: Scalars['String'];
  numberList: Array<Scalars['String']>;
};

export type CreateApiPermission = {
  hashedApiKey: Scalars['String'];
  smsFee: Scalars['Decimal'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  userType: UserType;
  point: Scalars['Decimal'];
  tax: Scalars['Decimal'];
  fbJoinType: FbJoinType;
  msgPermission: Array<MsgPermission>;
  apiPermission: Array<HashedApiKey>;
  commissionPermission?: Maybe<Array<CommissionPermission>>;
};

export type HashedApiKey = {
  __typename?: 'HashedApiKey';
  hashedApiKey: Scalars['String'];
  nm: Scalars['String'];
  smsFee: Scalars['Decimal'];
};

export type CreateUser = {
  uid: Scalars['String'];
  joinCode: Scalars['String'];
  loginType: FbJoinType;
  contact: Scalars['String'];
};

export type TelNumber = {
  __typename?: 'TelNumber';
  number: Scalars['String'];
  status: Scalars['String'];
};

export enum AlertType {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success'
}

export type SendCommission = {
  __typename?: 'SendCommission';
  id: Scalars['Int'];
  agentEmail: Scalars['String'];
  agentSendTotal: Scalars['Int'];
  margin: Scalars['Decimal'];
  commission: Scalars['Decimal'];
  isWithdrawal: Scalars['Boolean'];
  isRequestedSettle: Scalars['Boolean'];
  createdAt: Scalars['Time'];
  withdrawalAt?: Maybe<Scalars['Time']>;
};

export type RechargeCommissoinSettleRequest = {
  __typename?: 'RechargeCommissoinSettleRequest';
  id: Scalars['Int'];
  status: RechargeCommissionSettleRequestStatus;
  commission: Scalars['Decimal'];
  commissionCount: Scalars['Int'];
  commissionType: SettleCommissionType;
  createdAt: Scalars['Time'];
};

export type Tree = {
  __typename?: 'Tree';
  id: Scalars['Int'];
  email: Scalars['String'];
  point: Scalars['Decimal'];
  createdAt: Scalars['Time'];
};

export type RechargeCommissionList = {
  __typename?: 'RechargeCommissionList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<RechargeCommission>;
};

export type MsgReceipt = {
  __typename?: 'MsgReceipt';
  id: Scalars['Int'];
  requestId: Scalars['String'];
  contents: Scalars['String'];
  smsFee: Scalars['Decimal'];
  apiKeyNm: Scalars['String'];
  sent: Scalars['Int'];
  createdAt: Scalars['Time'];
};

export type SendTestSms = {
  msg: Scalars['String'];
  hashedApiKey: Scalars['String'];
};

export type UserAlert = {
  __typename?: 'UserAlert';
  id: Scalars['Int'];
  content: Scalars['String'];
  alertType: AlertType;
  createdAt: Scalars['Time'];
};

export enum WriterType {
  Admin = 'Admin',
  User = 'User'
}

export type JoinCodeList = {
  __typename?: 'JoinCodeList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<JoinCode>;
};

export type Settle = {
  __typename?: 'Settle';
  id: Scalars['Int'];
  day: Scalars['Time'];
  chargeAmount: Scalars['Decimal'];
  chargePoint: Scalars['Decimal'];
  usedPoint: Scalars['Decimal'];
  sentSms: Scalars['Int'];
};

export type TemplateSms = {
  numb: Scalars['String'];
  msg: Scalars['String'];
};

export type SendCommissoinSettleRequest = {
  __typename?: 'SendCommissoinSettleRequest';
  id: Scalars['Int'];
  status: SendCommissionSettleRequestStatus;
  commission: Scalars['Decimal'];
  commissionCount: Scalars['Int'];
  commissionType: SettleCommissionType;
  createdAt: Scalars['Time'];
};

export type UpdateAgentApiPermission = {
  hashedApiKey: Scalars['String'];
  smsFee: Scalars['Decimal'];
};

export type SendCommissionSettleRequestList = {
  __typename?: 'SendCommissionSettleRequestList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<SendCommissoinSettleRequest>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createGeneral: Scalars['Boolean'];
  createReseller: Scalars['Boolean'];
  createAgent: Scalars['Boolean'];
  createTree: Scalars['Boolean'];
  login: Scalars['String'];
  logout: Scalars['Boolean'];
  notifiedUserAlert: Scalars['Boolean'];
  createUserChat: Scalars['Boolean'];
  requestDepositRecharge: Scalars['Boolean'];
  cancelDepositRecharge: Scalars['Boolean'];
  createDisposableJoinCodeWithSmsFee: Scalars['Boolean'];
  createReusableJoinCodeWithSmsFee: Scalars['Boolean'];
  updateAgentApiPermission: Scalars['Boolean'];
  deleteJoinCode: Scalars['Boolean'];
  updateAgentActivation: Scalars['Boolean'];
  deleteTreeJoinCode: Scalars['Boolean'];
  createDisposableTreeJoinCodeWithSmsFee: Scalars['Boolean'];
  createReusableTreeJoinCodeWithSmsFee: Scalars['Boolean'];
  requestSendCommissionSettle: Scalars['Boolean'];
  cancelSendCommissionSettle: Scalars['Boolean'];
  requestRechargeCommissionSettle: Scalars['Boolean'];
  cancelRechargeCommissionSettle: Scalars['Boolean'];
  hideMsgReceipt: Scalars['Boolean'];
  hideAllMsgReceipt: Scalars['Boolean'];
  sendBulkSms: Scalars['Boolean'];
  sendTestSms: Scalars['Boolean'];
  sendTemplateSms: Scalars['Boolean'];
};


export type MutationCreateGeneralArgs = {
  input: CreateUser;
};


export type MutationCreateResellerArgs = {
  input: CreateUser;
};


export type MutationCreateAgentArgs = {
  input: CreateUser;
};


export type MutationCreateTreeArgs = {
  input: CreateUser;
};


export type MutationLoginArgs = {
  uid: Scalars['String'];
};


export type MutationNotifiedUserAlertArgs = {
  userAlertId: Scalars['Int'];
};


export type MutationCreateUserChatArgs = {
  input: CreateUserChat;
};


export type MutationRequestDepositRechargeArgs = {
  input: RequestDepositRecharge;
};


export type MutationCancelDepositRechargeArgs = {
  id: Scalars['Int'];
};


export type MutationCreateDisposableJoinCodeWithSmsFeeArgs = {
  input: CreateJoinCodeWithSmsFee;
};


export type MutationCreateReusableJoinCodeWithSmsFeeArgs = {
  input: CreateJoinCodeWithSmsFee;
};


export type MutationUpdateAgentApiPermissionArgs = {
  userId: Scalars['Int'];
  updateAgentPermissionList: Array<UpdateAgentApiPermission>;
};


export type MutationDeleteJoinCodeArgs = {
  code: Scalars['String'];
};


export type MutationUpdateAgentActivationArgs = {
  userId: Scalars['Int'];
  activation: Scalars['Boolean'];
};


export type MutationDeleteTreeJoinCodeArgs = {
  code: Scalars['String'];
};


export type MutationCreateDisposableTreeJoinCodeWithSmsFeeArgs = {
  input: CreateJoinCodeWithSmsFee;
};


export type MutationCreateReusableTreeJoinCodeWithSmsFeeArgs = {
  input: CreateJoinCodeWithSmsFee;
};


export type MutationRequestSendCommissionSettleArgs = {
  type: SettleCommissionType;
  bankNumber: Scalars['String'];
};


export type MutationCancelSendCommissionSettleArgs = {
  sendCommissionSettleRequestId: Scalars['Int'];
};


export type MutationRequestRechargeCommissionSettleArgs = {
  type: SettleCommissionType;
  bankNumber: Scalars['String'];
};


export type MutationCancelRechargeCommissionSettleArgs = {
  rechargeCommissionSettleRequestId: Scalars['Int'];
};


export type MutationHideMsgReceiptArgs = {
  msgReceiptIdList: Array<Scalars['Int']>;
};


export type MutationSendBulkSmsArgs = {
  input: SendBulkSms;
};


export type MutationSendTestSmsArgs = {
  input: SendTestSms;
};


export type MutationSendTemplateSmsArgs = {
  input: SendTemplateSms;
};

export type Init = {
  __typename?: 'Init';
  fb: Scalars['String'];
  appNm: Scalars['String'];
  viewTemplate: Scalars['String'];
  env: Scalars['String'];
};

export type UserChat = {
  __typename?: 'UserChat';
  id: Scalars['Int'];
  content: Scalars['String'];
  writer: WriterType;
  createdAt: Scalars['Time'];
};

export type SendCommissionList = {
  __typename?: 'SendCommissionList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<SendCommission>;
};


export type Query = {
  __typename?: 'Query';
  init: Init;
  user: User;
  verifyCode: Scalars['Boolean'];
  userAlert: Array<UserAlert>;
  userAlertList: UserAlertList;
  chatList: UserChatList;
  chatNotify: Scalars['Int'];
  directNotice: Array<DirectNotice>;
  notice: BoardList;
  popupNotice: Array<Board>;
  depositRequestHistory: DepositRequestHistoryList;
  agent: Agent;
  agentList: AgentList;
  disposableTreeJoinCode: JoinCodeList;
  reusableTreeJoinCode: JoinCodeList;
  treeUserList: TreeList;
  disposableJoinCodeList: JoinCodeList;
  reusableJoinCodeList: JoinCodeList;
  totalSendCommission: TotalSendCommission;
  totalRechargeCommission: TotalRechargeCommission;
  rechargeCommissionList: RechargeCommissionList;
  sendCommissionList: SendCommissionList;
  sendCommissionSettleRequestList: SendCommissionSettleRequestList;
  rechargeCommissionSettleRequestList: RechargeCommissionSettleRequestList;
  pointHistory: PointHistoryList;
  msgReceipt: MsgReceiptList;
  telbook: TelBook;
  settle: SettleList;
  telReceiverReport: Array<TelReceiverReport>;
  testNumberList: Array<TestNumber>;
};


export type QueryVerifyCodeArgs = {
  code: Scalars['String'];
  userType: UserType;
};


export type QueryUserAlertListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryChatListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryNoticeArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryDepositRequestHistoryArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryAgentArgs = {
  userId: Scalars['Int'];
};


export type QueryAgentListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
};


export type QueryDisposableTreeJoinCodeArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryReusableTreeJoinCodeArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryTreeUserListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  email: Scalars['String'];
};


export type QueryDisposableJoinCodeListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryReusableJoinCodeListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryRechargeCommissionListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QuerySendCommissionListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QuerySendCommissionSettleRequestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryRechargeCommissionSettleRequestListArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryPointHistoryArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryMsgReceiptArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
  periodTimeDay?: Maybe<PeriodTimeDay>;
};


export type QueryTelbookArgs = {
  requestId: Scalars['String'];
};


export type QuerySettleArgs = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};


export type QueryTelReceiverReportArgs = {
  size: Scalars['Int'];
};

export enum UserType {
  General = 'General',
  Reseller = 'Reseller',
  Agent = 'Agent',
  Tree = 'Tree'
}

export type DepositRequestHistory = {
  __typename?: 'DepositRequestHistory';
  id: Scalars['Int'];
  nm: Scalars['String'];
  smsFee: Scalars['Decimal'];
  amount: Scalars['Decimal'];
  point: Scalars['Decimal'];
  requestedAt: Scalars['Time'];
  status: DepositRequestStatus;
};



export type CreateUserChat = {
  content: Scalars['String'];
};

export type BoardList = {
  __typename?: 'BoardList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Board>;
};

export type Agent = {
  __typename?: 'Agent';
  id: Scalars['Int'];
  email: Scalars['String'];
  point: Scalars['Decimal'];
  isActivate: Scalars['Boolean'];
  apiPermission: Array<HashedApiKey>;
  createdAt: Scalars['Time'];
};

export type TotalRechargeCommission = {
  __typename?: 'TotalRechargeCommission';
  total: Scalars['Decimal'];
  paid: Scalars['Decimal'];
  notPaid: Scalars['Decimal'];
};

export enum SendCommissionSettleRequestStatus {
  Requested = 'Requested',
  Allow = 'Allow',
  Deny = 'Deny',
  Cancel = 'Cancel'
}

export enum RechargeCommissionSettleRequestStatus {
  Requested = 'Requested',
  Allow = 'Allow',
  Deny = 'Deny',
  Cancel = 'Cancel'
}

export type TelBook = {
  __typename?: 'TelBook';
  requestId: Scalars['String'];
  msg: Scalars['String'];
  requestTotal: Scalars['Int'];
  totalSend: Scalars['Int'];
  sent: Scalars['Int'];
  fail: Scalars['Int'];
  numberList: Array<TelNumber>;
  createdAt: Scalars['Time'];
};

export type TotalSendCommission = {
  __typename?: 'TotalSendCommission';
  total: Scalars['Decimal'];
  paid: Scalars['Decimal'];
  notPaid: Scalars['Decimal'];
};

export type FrequentMsg = {
  __typename?: 'FrequentMsg';
  id: Scalars['Int'];
  msg: Scalars['String'];
};

export enum MsgPermission {
  Bulk = 'Bulk',
  Template = 'Template'
}

export enum DepositRequestStatus {
  Wait = 'Wait',
  Cancel = 'Cancel',
  Deny = 'Deny',
  Allow = 'Allow'
}

export type JoinCode = {
  __typename?: 'JoinCode';
  code: Scalars['String'];
  reusable: Scalars['Boolean'];
  apiPermission: Array<HashedApiKey>;
  createdAt: Scalars['Time'];
};

export enum CommissionPermission {
  Send = 'Send',
  Recharge = 'Recharge'
}

export type DirectNotice = {
  __typename?: 'DirectNotice';
  id: Scalars['Int'];
  content: Scalars['String'];
  urgent: Scalars['Boolean'];
  createdAt: Scalars['Time'];
};

export enum BalanceReceiptType {
  Init = 'Init',
  Admin = 'Admin',
  Msg = 'Msg',
  Deposit = 'Deposit',
  SharePoint = 'SharePoint',
  SendTemplate = 'SendTemplate',
  SendBulk = 'SendBulk',
  Commission = 'Commission'
}

export type SettleList = {
  __typename?: 'SettleList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Settle>;
};

export type DepositRequestHistoryList = {
  __typename?: 'DepositRequestHistoryList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<DepositRequestHistory>;
};

export type UserAlertList = {
  __typename?: 'UserAlertList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<UserAlert>;
};

export type UserChatList = {
  __typename?: 'UserChatList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<UserChat>;
};

export type TreeList = {
  __typename?: 'TreeList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Tree>;
};

export type SendTemplateSms = {
  contents: Scalars['String'];
  hashedApiKey: Scalars['String'];
  data: Array<TemplateSms>;
};

export type TelReceiverReport = {
  __typename?: 'TelReceiverReport';
  id: Scalars['Int'];
  receiverNumber: Scalars['String'];
  senderNumber: Scalars['String'];
  msg: Scalars['String'];
  vendor: Scalars['String'];
  createdAt: Scalars['Time'];
};


export type RechargeCommission = {
  __typename?: 'RechargeCommission';
  id: Scalars['Int'];
  agentEmail: Scalars['String'];
  agentRechargeAmount: Scalars['Decimal'];
  agentRechargePoint: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  commission: Scalars['Decimal'];
  isWithdrawal: Scalars['Boolean'];
  isRequestedSettle: Scalars['Boolean'];
  createdAt: Scalars['Time'];
  withdrawalAt?: Maybe<Scalars['Time']>;
};

export enum SettleCommissionType {
  ChargePoint = 'ChargePoint',
  Withdraw = 'Withdraw'
}

export type AgentList = {
  __typename?: 'AgentList';
  page: Scalars['Int'];
  size: Scalars['Int'];
  total: Scalars['Int'];
  list: Array<Agent>;
};
