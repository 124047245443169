import React, {Fragment} from "react";
import classNamesBind from "classnames/bind";
import styles from './MngCodeWithTreeDefaultList.module.scss'
import {JoinCodeList} from "../../../../../graphql/types";
import moment from "moment";
import {IconButton, Tooltip} from "@material-ui/core";
import {DeleteOutline, FileCopyOutlined} from "@material-ui/icons";
import {BizNoDataTable} from "../../../../shares/default/bizNoDataTable/BizNoDataTable";
import {SwalUtil} from "../../../../../utils/swal/swalUtil";
import copy from "copy-to-clipboard";
import {OnChangeValue} from "../../../../interfaces";

const cx = classNamesBind.bind(styles);

export const MngCodeWithTreeDefaultList = (props: {
    list: JoinCodeList,
    onDelete: OnChangeValue<string>
}) => {
    const onCopyClipboard = (joinCode: string) => {
        SwalUtil.ok({
            msg: `클립보드에 복사되었습니다.<br/>${joinCode}`,
            icon: 'success',
        });

        copy(`${window.location.host}/auth/join/Tree/${joinCode}`);
    }

    return (
        <Fragment>
            <div className={cx('table')}>
                <div className={cx('col-code')}>가입코드</div>
                <div className={cx('col-created-at')}>생성일</div>
                <div className={cx('col-api')}>회선정보</div>
                <div className={cx('col-func')}>기능</div>
            </div>

            {props.list.total === 0 && <BizNoDataTable/>}

            {props.list.list.map((value, index) => (
                <div key={index} className={cx('table', 'hover')}>
                    <div className={cx('col-code', 'pointer')} onClick={() => onCopyClipboard(value.code)}>
                        {value.code} <FileCopyOutlined/>
                    </div>
                    <div className={cx('col-created-at')}>{moment(value.createdAt).format('MM.DD - hh:mm a')}</div>
                    <div className={cx('col-api')}>{value.apiPermission.map((api, idx) => (
                        <span key={idx} style={{display: 'block'}}>{api.nm} : {api.smsFee} 원</span>
                    ))}</div>
                    <div className={cx('col-func')}>
                        <Tooltip title={'삭제'}>
                            <IconButton onClick={() => props.onDelete(value.code)}>
                                <DeleteOutline/>
                            </IconButton>
                        </Tooltip>

                    </div>
                </div>

            ))}
        </Fragment>
    )
}
