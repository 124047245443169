import React from 'react';
import {gql, useQuery} from "@apollo/client";
import {Query} from "../../../graphql/types";
import {ViewTemplateSelector} from "../../shares/default/viewTemplateSelector/ViewTemplateSelector";
import {ViewTemplate} from "../../../environment/interfaces";
import {SettleListDefault} from "./default/SettleListDefault";
import {FadeIn} from "../../shares/default/fadeIn/FadeIn";
import {ApolloCatch} from "../../../utils/apollo/apolloCatch";
import {SettleListTemp1} from "./temp1/SettleListTemp1";

export const SettlementList = (props: {
    page: number
}) => {
    const {data, loading, error, refetch} = useQuery<Query, {
        page: number,
    }>(gqlData, {
        variables: {
            page: props.page
        }
    });

    const handler = {
        onChangePage: (page: number) => {
            refetch({page}).catch(ApolloCatch({}));
        }
    };

    return (
        <>
            {data && <FadeIn>
                <ViewTemplateSelector view={{
                    [ViewTemplate.default]:
                        <SettleListDefault
                            data={data}
                            {...handler}/>,
                    [ViewTemplate.temp1]:
                        <SettleListTemp1
                            data={data}
                            {...handler}/>,
                    [ViewTemplate.zsms]:
                        <SettleListTemp1
                            data={data}
                            {...handler}/>,
                }}/>
            </FadeIn>}
        </>
    )
}

const gqlData = gql`
    query DATA($page: Int!) {
        settle(page: $page, size: 20) {
            page
            size
            total
            list {
                id
                day
                usedPoint
                chargeAmount
                chargePoint
                sentSms
            }
        }
    }
`;
