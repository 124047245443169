export const CharList: string[] = ['~',
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '\\',
    '"',
    '\'',
    '+',
    '=',
    '`',
    '|',
    '(',
    ')',
    '[',
    ']',
    '{',
    '}',
    ':',
    ';',
    '-',
    '_',
    '-',
    '＃',
    '＆',
    '＆',
    '＠',
    '§',
    '※',
    '☆',
    '★',
    '○',
    '●',
    '◎',
    '◇',
    '◆',
    '□',
    '■',
    '△',
    '▲',
    '▽',
    '▼',
    '→',
    '←',
    '←',
    '↑',
    '↓',
    '↔',
    '〓',
    '◁',
    '◀',
    '▷',
    '▶',
    '♤',
    '♠',
    '♡',
    '♥',
    '♧',
    '♣',
    '⊙',
    '◈',
    '▣',
    '◐',
    '◑',
    '▒',
    '▤',
    '▥',
    '▨',
    '▧',
    '▦',
    '▩',
    '♨',
    '☏',
    '☎',
    '☜',
    '☞',
    '¶',
    '†',
    '‡',
    '↕',
    '↗',
    '↙',
    '↖',
    '↘',
    '♭',
    '♩',
    '♪',
    '♬',
    '㉿',
    '㈜',
    '№',
    '㏇',
    '™',
    '㏂',
    '㏘',
    '℡',
    '®',
    'ª',
    'º',
    '─',
    '│',
    '┌',
    '┐',
    '┘',
    '└',
    '├',
    '┬',
    '┤',
    '┴',
    '┼',
    '━',
    '┃',
    '┏',
    '┓',
    '┛',
    '┗',
    '┣',
    '┳',
    '┫',
    '┻',
    '╋',
    '┠',
    '┯',
    '┨',
    '┷',
    '┿',
    '┝',
    '┰',
    '┥',
    '┸',
    '╂',
    '┒',
    '┑',
    '┚',
    '┙',
    '┖',
    '┕',
    '┎',
    '┍',
    '┞',
    '┟',
    '┡',
    '┢',
    '┦',
    '┧',
    '┩',
    '┪',
    '┭',
    '┮',
    '┱',
    '┲',
    '┵',
    '┶',
    '┹',
    '┺',
    '┽',
    '┾',
    '╀',
    '╁',
    '╃',
    '╄',
    '╅',
    '╆',
    '╇',
    '╈',
    '╉',
    '╊',
    '＋',
    '－',
    '＜',
    '＝',
    '＞',
    '±',
    '×',
    '÷',
    '≠',
    '≤',
    '≥',
    '∞',
    '∴',
    '♂',
    '♀',
    '∠',
    '⊥',
    '⌒',
    '∂',
    '∇',
    '≡',
    '≒',
    '≪',
    '≫',
    '√',
    '∽',
    '∝',
    '∵',
    '∫',
    '∬',
    '∈',
    '∋',
    '⊆',
    '⊇',
    '⊂',
    '⊃',
    '∪',
    '∩',
    '∧',
    '∨',
    '￢',
    '⇒',
    '⇔',
    '∀',
    '∃',
    '∮',
    '∑',
    '∏',
    '！',
    '＇',
    '，',
    '．',
    '／',
    '：',
    '；',
    '？',
    '＾',
    '＿',
    '｀',
    '｜',
    '￣',
    '、',
    '。',
    '·',
    '‥',
    '…',
    '¨',
    '〃',
    '­',
    '―',
    '∥',
    '＼',
    '∼',
    '´',
    '～',
    'ˇ',
    '˘',
    '˝',
    '˚',
    '˙',
    '¸',
    '˛',
    '¡',
    '¿',
    'ː',
    '＂',
    '”',
    '〔',
    '〕',
    '｛',
    '｝',
    '‘',
    '’',
    '“',
    '”',
    '〔',
    '〕',
    '〈',
    '〉',
    '《',
    '》',
    '「',
    '」',
    '『',
    '』',
    '【',
    '】',
    '㉠',
    '㉡',
    '㉢',
    '㉣',
    '㉤',
    '㉥',
    '㉦',
    '㉧',
    '㉨',
    '㉩',
    '㉪',
    '㉫',
    '㉬',
    '㉭',
    '㉮',
    '㉯',
    '㉰',
    '㉱',
    '㉲',
    '㉳',
    '㉴',
    '㉵',
    '㉶',
    '㉷',
    '㉸',
    '㉹',
    '㉺',
    '㉻',
    '㈀',
    '㈁',
    '㈂',
    '㈃',
    '㈄',
    '㈅',
    '㈆',
    '㈇',
    '㈈',
    '㈉',
    '㈊',
    '㈋',
    '㈌',
    '㈍',
    '㈎',
    '㈏',
    '㈐',
    '㈑',
    '㈒',
    '㈓',
    '㈔',
    '㈕',
    '㈖',
    '㈗',
    '㈘',
    '㈙',
    '㈚',
    '㈛',
    'ⓐ',
    'ⓑ',
    'ⓒ',
    'ⓓ',
    'ⓔ',
    'ⓕ',
    'ⓖ',
    'ⓗ',
    'ⓘ',
    'ⓙ',
    'ⓚ',
    'ⓛ',
    'ⓜ',
    'ⓝ',
    'ⓞ',
    'ⓟ',
    'ⓠ',
    'ⓡ',
    'ⓢ',
    'ⓣ',
    'ⓤ',
    'ⓥ',
    'ⓦ',
    'ⓧ',
    'ⓨ',
    'ⓩ',
    '⒜',
    '⒝',
    '⒞',
    '⒟',
    '⒠',
    '⒡',
    '⒢',
    '⒣',
    '⒤',
    '⒥',
    '⒦',
    '⒧',
    '⒨',
    '⒩',
    '⒪',
    '⒫',
    '⒬',
    '⒭',
    '⒮',
    '⒯',
    '⒰',
    '⒱',
    '⒲',
    '⒳',
    '⒴',
    '⒵',
    '①',
    '②',
    '③',
    '④',
    '⑤',
    '⑥',
    '⑦',
    '⑧',
    '⑨',
    '⑩',
    '⑪',
    '⑫',
    '⑬',
    '⑭',
    '⑮',
    '⑴',
    '⑵',
    '⑶',
    '⑷',
    '⑸',
    '⑹',
    '⑺',
    '⑻',
    '⑼',
    '⑽',
    '⑾',
    '⑿',
    '⒀',
    '⒁',
    '⒂'
];